import React, {useState} from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import "react-datepicker/dist/react-datepicker.css";
import * as CryptoJS from "crypto-js";
import {getAssetDetails} from "../../../services/asset.service";
import {getAssetQuoteData, saveAssetQuotes} from "../../../services/disposal.service";
import FromFieldDate from "../../../atoms/FromFieldDate";
import FromFieldNumber from "../../../atoms/FromFieldNumber";

import FromSpFieldTextArea from '../../../atoms/FromSpFieldTextArea';

const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function SingleAssetQuote() {


    const [activeTab, setActiveTab] = useState(0);

    const [formSubmitted1, setFormSubmitted1] = useState(false);
    const [formSubmitted2, setFormSubmitted2] = useState(false);
    const [formSubmitted3, setFormSubmitted3] = useState(false);

    const [success, setSuccess] = useState(false);

    const [tag, setTag] = useState('');
    const [ErrTag, setErrTag] = useState(false);

    const [dinno, setDinno] = useState('');

    const [quote_id, setQuoteId] = useState('');

    const [buyerName1, setBuyerName1] = useState('');
    const [buyerName1Error, setBuyerName1Error] = useState('');

    const [buyerName2, setBuyerName2] = useState('');
    const [buyerName2Error, setBuyerName2Error] = useState('');

    const [buyerName3, setBuyerName3] = useState('');
    const [buyerName3Error, setBuyerName3Error] = useState('');

    const [buyerAddress1, setBuyerAddress1] = useState('');
    const [buyerAddress1Error, setbuyerAddress1Error] = useState('');

    const [buyerAddress2, setBuyerAddress2] = useState('');
    const [buyerAddress2Error, setbuyerAddress2Error] = useState('');

    const [buyerAddress3, setBuyerAddress3] = useState('');
    const [buyerAddress3Error, setbuyerAddress3Error] = useState('');

    const [buyerPAN1, setBuyerPAN1] = useState('');
    const [buyerPAN1Error, setbuyerPAN1Error] = useState('');

    const [buyerPAN2, setBuyerPAN2] = useState('');
    const [buyerPAN2Error, setbuyerPAN2Error] = useState('');

    const [buyerPAN3, setBuyerPAN3] = useState('');
    const [buyerPAN3Error, setbuyerPAN3Error] = useState('');

    const [buyerGSTN1, setBuyerGSTN1] = useState('');
    const [buyer1GSTNError, setBuyerGSTN1Error] = useState('');

    const [buyerGSTN2, setBuyerGSTN2] = useState('');
    const [buyer2GSTNError, setBuyerGSTN2Error] = useState('');

    const [buyerGSTN3, setBuyerGSTN3] = useState('');
    const [buyer3GSTNError, setBuyerGSTN3Error] = useState('');

    const [buyer1PriceQuoted, setBuyer1PriceQuoted] = useState('');
    const [buyer1PriceQuotedError, setBuyer1PriceQuotedError] = useState('');

    const [buyer2PriceQuoted, setBuyer2PriceQuoted] = useState('');
    const [buyer2PriceQuotedError, setBuyer2PriceQuotedError] = useState('');

    const [buyer3PriceQuoted, setBuyer3PriceQuoted] = useState('');
    const [buyer3PriceQuotedError, setBuyer3PriceQuotedError] = useState('');

    const [quotedReceivedDate1, setQuotedReceivedDate1] = useState('');
    const [quotedReceivedDateError1, setquotedReceivedDate1Error] = useState('');

    const [quotedReceivedDate2, setQuotedReceivedDate2] = useState('');
    const [quotedReceivedDateError2, setquotedReceivedDate2Error] = useState('');

    const [quotedReceivedDate3, setQuotedReceivedDate3] = useState('');
    const [quotedReceivedDateError3, setquotedReceivedDate3Error] = useState('');

    const [quotedExpiryDate1, setQuotedExpiryDate1] = useState('');
    const [quotedExpiryDateError1, setquotedExpiryDate1Error] = useState('');

    const [quotedExpiryDate2, setQuotedExpiryDate2] = useState('');
    const [quotedExpiryDateError2, setquotedExpiryDate2Error] = useState('');

    const [quotedExpiryDate3, setQuotedExpiryDate3] = useState('');
    const [quotedExpiryDateError3, setquotedExpiryDate3Error] = useState('');


    const [errMsg, setErrMsg] = useState('');

    const [assetInfo, setAssetInfo] = useState({});


    const handleFormSubmit = (e) => {
        e.preventDefault();
        var valid_Name = true;

        // Validate the inputs before submitting the form

        if (!(tag) && tag !== 0) {
            setErrTag('TAG is required');
            valid_Name = false;
        } else {
            setErrTag('');
        }


        if (valid_Name) {

            handleNextClick();
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const handleNextClick = () => {
        // Handle the "Next" button click to move to the next tab
        setActiveTab(activeTab + 1);

    };

    const handleFormSubmit1 = (e) => {
        e.preventDefault();
        var valid_Name1 = true;

        if (!(buyerName1) && buyerName1 !== 0) {
            setBuyerName1Error('Buyer 1 name is required');
            valid_Name1 = false;
        } else {
            setBuyerName1Error('');
        }
        if (!(buyerAddress1) && buyerAddress1 !== 0 && (!TEXT_SPCL_REGEX.test(buyerAddress1))) {
            setbuyerAddress1Error('Buyer 1 address is required');
            valid_Name1 = false;
        } else {
            setbuyerAddress1Error('');
        }
        if (!(buyerPAN1) && buyerPAN1 !== 0) {
            setbuyerPAN1Error('Buyer 1 PAN address is required');
            valid_Name1 = false;
        } else {
            setbuyerPAN1Error('');
        }
        if (!(buyerGSTN1) && buyerGSTN1 !== 0) {
            setBuyerGSTN1Error('Buyer 1 GSTN is required');
            valid_Name1 = false;
        } else {
            setBuyerGSTN1Error('');
        }
        if (!(buyer1PriceQuoted) && buyer1PriceQuoted !== 0) {
            setBuyer1PriceQuotedError('Buyer 1 Price quoted is required');
            valid_Name1 = false;
        } else {
            setBuyer1PriceQuotedError('');
        }
        if (!(quotedReceivedDate1) && quotedReceivedDate1 !== 0) {
            setquotedReceivedDate1Error('Buyer 1 quoted date is required');
            valid_Name1 = false;
        } else {
            setquotedReceivedDate1Error('');
        }
        if (!(quotedExpiryDate1) && quotedExpiryDate1 !== 0) {
            setquotedExpiryDate1Error('Buyer 1 quoted expiry date is required');
            valid_Name1 = false;
        } else {
            setquotedExpiryDate1Error('');
        }

        if (valid_Name1) {
            //alert('test1');
            saveAssetQuote1(); //submit form
            //saveDisposalTAG(); //submit form
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const handleFormSubmit2 = (e) => {
        e.preventDefault();
        var valid_Name2 = true;

        if (!(buyerName2) && buyerName2 !== 0) {
            setBuyerName2Error('Buyer 2 name is required');
            valid_Name2 = false;
        } else {
            setBuyerName2Error('');
        }
        if (!(buyerAddress2) && buyerAddress2 !== 0 && (!TEXT_SPCL_REGEX.test(buyerAddress2))) {
            setbuyerAddress2Error('Buyer 2 address is required');
            valid_Name2 = false;
        } else {
            setbuyerAddress2Error('');
        }
        if (!(buyerPAN2) && buyerPAN2 !== 0) {
            setbuyerPAN2Error('Buyer 2 PAN address is required');
            valid_Name2 = false;
        } else {
            setbuyerPAN2Error('');
        }
        if (!(buyerGSTN2) && buyerGSTN2 !== 0) {
            setBuyerGSTN2Error('Buyer 2 GSTN is required');
            valid_Name2 = false;
        } else {
            setBuyerGSTN2Error('');
        }
        if (!(buyer2PriceQuoted) && buyer2PriceQuoted !== 0) {
            setBuyer2PriceQuotedError('Buyer 2 Price quoted is required');
            valid_Name2 = false;
        } else {
            setBuyer2PriceQuotedError('');
        }
        if (!(quotedReceivedDate2) && quotedReceivedDate2 !== 0) {
            setquotedReceivedDate2Error('Buyer 2 quoted date is required');
            valid_Name2 = false;
        } else {
            setquotedReceivedDate2Error('');
        }
        if (!(quotedExpiryDate2) && quotedExpiryDate2 !== 0) {
            setquotedExpiryDate2Error('Buyer 2 quoted expiry date is required');
            valid_Name2 = false;
        } else {
            setquotedExpiryDate2Error('');
        }

        if (valid_Name2) {
            //alert('test1');
            saveAssetQuote2(); //submit form
            //saveDisposalTAG(); //submit form
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const handleFormSubmit3 = (e) => {
        e.preventDefault();
        var valid_Name3 = true;

        if (!(buyerName3) && buyerName3 !== 0) {
            setBuyerName3Error('Buyer 3 name is required');
            valid_Name3 = false;
        } else {
            setBuyerName3Error('');
        }
        if (!(buyerAddress3) && buyerAddress3 !== 0 && (!TEXT_SPCL_REGEX.test(buyerAddress3))) {
            setbuyerAddress3Error('Buyer 3 address is required');
            valid_Name3 = false;
        } else {
            setbuyerAddress3Error('');
        }
        if (!(buyerPAN3) && buyerPAN3 !== 0) {
            setbuyerPAN3Error('Buyer 3 PAN address is required');
            valid_Name3 = false;
        } else {
            setbuyerPAN3Error('');
        }
        if (!(buyerGSTN3) && buyerGSTN3 !== 0) {
            setBuyerGSTN3Error('Buyer 3 GSTN is required');
            valid_Name3 = false;
        } else {
            setBuyerGSTN3Error('');
        }
        if (!(buyer3PriceQuoted) && buyer3PriceQuoted !== 0) {
            setBuyer3PriceQuotedError('Buyer 3 Price quoted is required');
            valid_Name3 = false;
        } else {
            setBuyer3PriceQuotedError('');
        }
        if (!(quotedReceivedDate3) && quotedReceivedDate3 !== 0) {
            setquotedReceivedDate3Error('Buyer 3 quoted date is required');
            valid_Name3 = false;
        } else {
            setquotedReceivedDate3Error('');
        }
        if (!(quotedExpiryDate3) && quotedExpiryDate3 !== 0) {
            setquotedExpiryDate3Error('Buyer 3 quoted expiry date is required');
            valid_Name3 = false;
        } else {
            setquotedExpiryDate3Error('');
        }
        if (valid_Name3) {
            //alert('test1');
            saveAssetQuote3(); //submit form
            //saveDisposalTAG(); //submit form
        } else {
            return false;
        }
        // Perform form submission logic here
    };
    const getTagInfo = function () {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAssetDetails(oData).then(function (res) {
            var aInfo = {};
            if (res?.data?.data?.assetInfo) {
                setErrMsg('');
                aInfo = res.data.data.assetInfo;
                setAssetInfo(aInfo);

            } else {
                setAssetInfo({});
                setErrMsg('Assets Not Found');
            }
        })
    }

    const saveAssetQuote1 = function () {
        try {

            if (quote_id !== '' && quote_id !== null) {

                var obj = {
                    'id': quote_id,

                    'tag': assetInfo.tag,
                    'din_no': assetInfo.din_no,
                    'buyer_no': "Buyer 1",
                    'buyer_name': buyerName1,
                    'buyer_address': buyerAddress1,
                    'buyer_PAN': buyerPAN1,
                    'buyer_GSTN': buyerGSTN1,
                    'buyer_price_quoted': buyer1PriceQuoted,
                    'buyer_price_quoted_date': (quotedReceivedDate1 ? getFormatedDate(quotedReceivedDate1) : ''),
                    'buyer_price_quoted_expiry_date': (quotedExpiryDate1 ? getFormatedDate(quotedExpiryDate1) : ''),
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'tag': assetInfo.tag,
                    'din_no': assetInfo.din_no,
                    'buyer_no': "Buyer 1",
                    'buyer_name': buyerName1,
                    'buyer_address': buyerAddress1,
                    'buyer_PAN': buyerPAN1,
                    'buyer_GSTN': buyerGSTN1,
                    'buyer_price_quoted': buyer1PriceQuoted,
                    'buyer_price_quoted_date': (quotedReceivedDate1 ? getFormatedDate(quotedReceivedDate1) : ''),
                    'buyer_price_quoted_expiry_date': (quotedExpiryDate1 ? getFormatedDate(quotedExpiryDate1) : ''),
                    'created_by': sessionStorage.getItem('LoggedInUserId'),

                }
            }


            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj, 'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveAssetQuotes(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetDisposalInfo();
                    setFormSubmitted1(true);
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const saveAssetQuote2 = function () {
        try {

            if (quote_id !== '' && quote_id !== null) {

                var obj = {
                    'id': quote_id,

                    'tag': assetInfo.tag,
                    'din_no': assetInfo.din_no,

                    'buyer_no': "Buyer 2",
                    'buyer_name': buyerName2,
                    'buyer_address': buyerAddress2,
                    'buyer_PAN': buyerPAN2,
                    'buyer_GSTN': buyerGSTN2,
                    'buyer_price_quoted': buyer2PriceQuoted,
                    'buyer_price_quoted_date': (quotedReceivedDate2 ? getFormatedDate(quotedReceivedDate2) : ''),
                    'buyer_price_quoted_expiry_date': (quotedExpiryDate2 ? getFormatedDate(quotedExpiryDate2) : ''),
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'tag': assetInfo.tag,
                    'din_no': assetInfo.din_no,
                    'buyer_no': "Buyer 2",
                    'buyer_name': buyerName2,
                    'buyer_address': buyerAddress2,
                    'buyer_PAN': buyerPAN2,
                    'buyer_GSTN': buyerGSTN2,
                    'buyer_price_quoted': buyer2PriceQuoted,
                    'buyer_price_quoted_date': (quotedReceivedDate2 ? getFormatedDate(quotedReceivedDate2) : ''),
                    'buyer_price_quoted_expiry_date': (quotedExpiryDate2 ? getFormatedDate(quotedExpiryDate2) : ''),
                    'created_by': sessionStorage.getItem('LoggedInUserId'),

                }
            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj, 'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveAssetQuotes(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetDisposalInfo();
                    setFormSubmitted2(true);
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const saveAssetQuote3 = function () {
        try {

            if (quote_id !== '' && quote_id !== null) {

                var obj = {
                    'id': quote_id,

                    'tag': assetInfo.tag,
                    'din_no': assetInfo.din_no,

                    'buyer_no': "Buyer 3",
                    'buyer_name': buyerName3,
                    'buyer_address': buyerAddress3,
                    'buyer_PAN': buyerPAN3,
                    'buyer_GSTN': buyerGSTN3,
                    'buyer_price_quoted': buyer3PriceQuoted,
                    'buyer_price_quoted_date': (quotedReceivedDate3 ? getFormatedDate(quotedReceivedDate3) : ''),
                    'buyer_price_quoted_expiry_date': (quotedExpiryDate3 ? getFormatedDate(quotedExpiryDate3) : ''),
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'tag': assetInfo.tag,
                    'din_no': assetInfo.din_no,

                    'buyer_no': "Buyer 3",
                    'buyer_name': buyerName3,
                    'buyer_address': buyerAddress3,
                    'buyer_PAN': buyerPAN3,
                    'buyer_GSTN': buyerGSTN3,
                    'buyer_price_quoted': buyer3PriceQuoted,
                    'buyer_price_quoted_date': (quotedReceivedDate3 ? getFormatedDate(quotedReceivedDate3) : ''),
                    'buyer_price_quoted_expiry_date': (quotedExpiryDate3 ? getFormatedDate(quotedExpiryDate3) : ''),
                    'created_by': sessionStorage.getItem('LoggedInUserId'),

                }
            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj, 'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveAssetQuotes(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetDisposalInfo();
                    setFormSubmitted3(true);
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const resetAssetDisposalInfo = function () {
        setTag('');

        setBuyerName1('');
        setBuyerName2('');
        setBuyerName3('');
        setBuyerAddress1('');
        setBuyerAddress2('');
        setBuyerAddress3('');
        setBuyerPAN1('');
        setBuyerPAN2('');
        setBuyerPAN3('');
        setBuyerGSTN1('');
        setBuyerGSTN2('');
        setBuyerGSTN3('');
        setBuyer1PriceQuoted('');
        setBuyer2PriceQuoted('');
        setBuyer3PriceQuoted('');
        setQuotedReceivedDate1('');
        setQuotedReceivedDate2('');
        setQuotedReceivedDate3('');
        setQuotedExpiryDate1('');
        setQuotedExpiryDate2('');
        setQuotedExpiryDate3('');

    }

    const [selectedBuyer, setSelectedBuyer] = useState('');

    const handleBuyerChange = (e) => {
        setSelectedBuyer(e.target.value);

        // Reset all previous values before fetching new data
        setQuoteId("");

        setBuyerName1("");
        setBuyerAddress1("");
        setBuyerPAN1("");
        setBuyerGSTN1("");
        setBuyer1PriceQuoted("");
        setQuotedReceivedDate1(null);
        setQuotedExpiryDate1(null);

        setBuyerName2("");
        setBuyerAddress2("");
        setBuyerPAN2("");
        setBuyerGSTN2("");
        setBuyer2PriceQuoted("");
        setQuotedReceivedDate2(null);
        setQuotedExpiryDate2(null);

        setBuyerName3("");
        setBuyerAddress3("");
        setBuyerPAN3("");
        setBuyerGSTN3("");
        setBuyer3PriceQuoted("");
        setQuotedReceivedDate3(null);
        setQuotedExpiryDate3(null);

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag, 'din_no': assetInfo.din_no, 'buyer_no': e.target.value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getAssetQuoteData(oData).then(function (res) {

            if (res?.data?.data && res.data.data.length) {
                var Info = res.data.data[0];
                setQuoteId(Info.id);

                setBuyerName1(Info.buyer_name);
                setBuyerAddress1(Info.buyer_address);
                setBuyerPAN1(Info.buyer_PAN);
                setBuyerGSTN1(Info.buyer_GSTN);
                setBuyer1PriceQuoted(Info.buyer_price_quoted);
                setQuotedReceivedDate1(new Date(Info.buyer_price_quoted_date));
                setQuotedExpiryDate1(new Date(Info.buyer_price_quoted_expiry_date));

                setBuyerName2(Info.buyer_name);
                setBuyerAddress2(Info.buyer_address);
                setBuyerPAN2(Info.buyer_PAN);
                setBuyerGSTN2(Info.buyer_GSTN);
                setBuyer2PriceQuoted(Info.buyer_price_quoted);
                setQuotedReceivedDate2(new Date(Info.buyer_price_quoted_date));
                setQuotedExpiryDate2(new Date(Info.buyer_price_quoted_expiry_date));

                setBuyerName3(Info.buyer_name);
                setBuyerAddress3(Info.buyer_address);
                setBuyerPAN3(Info.buyer_PAN);
                setBuyerGSTN3(Info.buyer_GSTN);
                setBuyer3PriceQuoted(Info.buyer_price_quoted);
                setQuotedReceivedDate3(new Date(Info.buyer_price_quoted_date));
                setQuotedExpiryDate3(new Date(Info.buyer_price_quoted_expiry_date));
            }
        })

    };


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Single Asset Quote</h5>
                </div>
            </div>


            {success ? (<div className="alert alert-success" role="alert"><i
                className="bi bi-check-circle-fill"></i> {success}</div>) : (errMsg ? (
                <div className="alert alert-danger" role="alert"><i
                    className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>) : (''))}

            <div className="tab-content">

                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>

                    <div className='row '>
                        <div className='col-12'>&nbsp;</div>
                        <div className='row'>
                            <div className='col-10'></div>
                            <div className='col-2'></div>
                        </div>
                        : <div className='row'>
                        <div className='col'><h5 className='align-right'>TAG </h5></div>
                        <div className='col'>
                            <input onChange={function (event) {
                                setTag(event.target.value)
                            }} inputValue={assetInfo.tag} type="text"
                                   setInputValue={setTag}
                                   setErrorMsg={setErrTag}
                                   className="form-control"
                                   placeholder='Enter asset TAG'/>
                            <p className='invalid-feedback'>{ErrTag}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-primary btn-md'><i
                                className="bi bi-search"></i> Search
                            </button>
                        </div>

                    </div>

                    </div>

                    <div className="row mt-4">
                        <h3 style={{
                            fontSize: '15px',
                            fontWeight: '700',
                            borderBottom: '1px solid #d5d5d5',
                            paddingBottom: '10px'
                        }}>Asset Information</h3>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Financial Classification"
                                placeholder="Financial Classification"
                                inputValue={assetInfo.financials_classification ? assetInfo.financials_classification : ''}
                                readOnly="readonly"
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText
                                readOnly="readonly"
                                label="Invoice Date"
                                inputValue={assetInfo.invoice_date ? getFormatedDate(assetInfo.invoice_date) : ''}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Supplier Name"
                                placeholder="Supplier Name"
                                inputValue={assetInfo.supplier_name ? assetInfo.supplier_name : ''}
                                readOnly="readonly"
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                label="Supplier Invoice No"
                                placeholder='Supplier Invoice No'
                                inputValue={assetInfo.invoice_no ? assetInfo.invoice_no : ''}
                                readOnly="readonly"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Capitalized Value"
                                placeholder="Capitalized Value"
                                inputValue={assetInfo.total_cost_without_tax ? assetInfo.total_cost_without_tax : ''}
                                readOnly="readonly"
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                readOnly="readonly"
                                label="Acc Dep upto Date"
                                inputValue={assetInfo.depreciation_date ? getFormatedDate(assetInfo.depreciation_date) : ''}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                readOnly="readonly"
                                label="WDV Value on Date"
                                inputValue={assetInfo.created_at ? getFormatedDate(assetInfo.created_at) : ''}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Sale Initiated By"
                                placeholder="Sale Initiated By"
                                inputValue={assetInfo.sale_initiated_by}
                                readOnly="readonly"
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                readOnly="readonly"
                                label="Date of Initiation"
                                inputValue={assetInfo.initiation_date ? getFormatedDate(assetInfo.initiation_date) : ''}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Sale Authorized By"
                                placeholder="Sale Authorized By"
                                inputValue={assetInfo.sale_authorized_by}
                                readOnly="readonly"
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                readOnly="readonly"
                                label="Date of Authorization"
                                inputValue={assetInfo.authorization_date ? getFormatedDate(assetInfo.authorization_date) : ''}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Reason for Disposal"
                                placeholder="Reason for Disposal"
                                inputValue={assetInfo.disposal_reason}
                                readOnly="readonly"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Upload Authorization Mail"
                                placeholder="Upload Authorization Mail"
                                inputValue=''
                                readOnly="readonly"
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-4"></div>
                        <form name="" onSubmit={handleFormSubmit}>
                            <button className="btn btn-primary btn-md"> View Quotes</button>
                        </form>
                        <div className="col-4"></div>
                    </div>

                </div>


                <div className={`tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="TAG"
                                placeholder=""
                                inputValue={assetInfo.tag}
                                setInputValue={setTag}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                label="DIN"
                                placeholder="DIN"
                                inputValue={assetInfo.din_no}
                                setInputValue={setDinno}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="row mt-4">
                            <div className='col-3'></div>
                            <div className='col-3'>
                                <label htmlFor="buyer no">Select Buyer</label>
                                <select id="Buyer 1" className="form-select" onChange={handleBuyerChange}
                                        value={selectedBuyer}>
                                    <option value="">Select Buyer</option>
                                    <option value="Buyer 1">Buyer 1</option>
                                    <option value="Buyer 2">Buyer 2</option>
                                    <option value="Buyer 3">Buyer 3</option>
                                    {/* Add other options as needed */}
                                </select>
                            </div>
                        </div>

                        {selectedBuyer === 'Buyer 1' && (
                            <div className='col-12'>
                                {/* Buyer Form 1 */}

                                <div className='row mt-2'>
                                    <div className="col-6">
                                        <FromFieldText label="Name" star="*"
                                                       placeholder="Buyer 1 Name"
                                                       inputValue={buyerName1}
                                                       setInputValue={setBuyerName1}
                                                       setErrorMsg={buyerName1Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromSpFieldTextArea maxLength = "500" numRows = "3" label="Address" star="*"
                                                       placeholder="Buyer 1 address"
                                                       inputValue={buyerAddress1}
                                                       setInputValue={setBuyerAddress1}
                                                       setErrorMsg={buyerAddress1Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldText label="PAN" star="*"
                                                       placeholder="Buyer 1 PAN address"
                                                       inputValue={buyerPAN1}
                                                       setInputValue={setBuyerPAN1}
                                                       setErrorMsg={buyerPAN1Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldText label="GSTN" star="*"
                                                       placeholder="Buyer 1 GSTN"
                                                       inputValue={buyerGSTN1}
                                                       setInputValue={setBuyerGSTN1}
                                                       setErrorMsg={buyer1GSTNError}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>

                                        <FromFieldNumber
                                            star="*"
                                            label="Price Quoted"
                                            placeholder="Buyer 1 Price Quoted"
                                            inputValue={buyer1PriceQuoted}
                                            setInputValue={(value) => {
                                                // Validate that the entered value is a number and less than or equal to 100
                                                const numericValue = Number(value);

                                                if (!isNaN(numericValue) && numericValue >= 0) {
                                                    setBuyer1PriceQuoted(value);
                                                    setBuyer1PriceQuotedError(""); // Clear the error when the input is valid
                                                } else {
                                                    setBuyer1PriceQuotedError("Please enter a valid amount"); // Set the error message when validation fails
                                                }
                                            }}
                                            setErrorMsg={buyer1PriceQuotedError} // Pass the error message to the component
                                        />

                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldDate label="Quoted Received Date" star="*"
                                                       placeholder="Buyer 1 Quoted Received Date"
                                                       inputValue={quotedReceivedDate1}
                                                       setInputValue={setQuotedReceivedDate1}
                                                       setErrorMsg={quotedReceivedDateError1}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldDate label="Quoted Expiry Date" star="*"
                                                       placeholder="Buyer 1 Quoted Expiry Date"
                                                       inputValue={quotedExpiryDate1}
                                                       setInputValue={setQuotedExpiryDate1}
                                                       setErrorMsg={quotedExpiryDateError1}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className='col-3 text-center'>{!formSubmitted1 && (
                                        <form name="" onSubmit={handleFormSubmit1}>
                                            <button className="btn btn-primary btn-md float-end">Submit</button>
                                        </form>
                                    )}</div>

                                    <div className='col-3 text-center'>
                                        <form name="" onSubmit={resetAssetDisposalInfo}>
                                            <button className="btn btn-secondary btn-md float-end"> Cancel</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}

                        {selectedBuyer === 'Buyer 2' && (
                            <div className='col-12'>
                                {/* Buyer Form 2 */}

                                <div className='row mt-2'>
                                    <div className="col-6">
                                        <FromFieldText label="Name" star="*"
                                                       placeholder="Buyer 2 Name"
                                                       inputValue={buyerName2}
                                                       setInputValue={setBuyerName2}
                                                       setErrorMsg={buyerName2Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromSpFieldTextArea maxLength = "500" numRows = "3" label="Address" star="*"
                                                       placeholder="Buyer 2 address"
                                                       inputValue={buyerAddress2}
                                                       setInputValue={setBuyerAddress2}
                                                       setErrorMsg={buyerAddress2Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldText label="PAN" star="*"
                                                       placeholder="Buyer 2 PAN address"
                                                       inputValue={buyerPAN2}
                                                       setInputValue={setBuyerPAN2}
                                                       setErrorMsg={buyerPAN2Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldText label="GSTN" star="*"
                                                       placeholder="Buyer 2 GSTN"
                                                       inputValue={buyerGSTN2}
                                                       setInputValue={setBuyerGSTN2}
                                                       setErrorMsg={buyer2GSTNError}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>

                                        <FromFieldNumber
                                            star="*"
                                            label="Price Quoted"
                                            placeholder="Buyer 2 Price Quoted"
                                            inputValue={buyer2PriceQuoted}
                                            setInputValue={(value) => {
                                                // Validate that the entered value is a number and less than or equal to 100
                                                const numericValue = Number(value);

                                                if (!isNaN(numericValue) && numericValue >= 0) {
                                                    setBuyer2PriceQuoted(value);
                                                    setBuyer2PriceQuotedError(""); // Clear the error when the input is valid
                                                } else {
                                                    setBuyer2PriceQuotedError("Please enter a valid amount"); // Set the error message when validation fails
                                                }
                                            }}
                                            setErrorMsg={buyer2PriceQuotedError} // Pass the error message to the component
                                        />

                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldDate label="Quoted Received Date" star="*"
                                                       placeholder="Buyer 2 Quoted Received Date"
                                                       inputValue={quotedReceivedDate2}
                                                       setInputValue={setQuotedReceivedDate2}
                                                       setErrorMsg={quotedReceivedDateError2}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldDate label="Quoted Expiry Date" star="*"
                                                       placeholder="Buyer 2 Quoted Expiry Date"
                                                       inputValue={quotedExpiryDate2}
                                                       setInputValue={setQuotedExpiryDate2}
                                                       setErrorMsg={quotedExpiryDateError2}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className='col-3 text-center'>{!formSubmitted2 && (
                                        <form name="" onSubmit={handleFormSubmit2}>
                                            <button className="btn btn-primary btn-md float-end"> Submit </button>
                                        </form>
                                    )}</div>

                                    <div className='col-3 text-center'>
                                        <form name="" onSubmit={resetAssetDisposalInfo}>
                                            <button className="btn btn-secondary btn-md float-end"> Cancel</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}

                        {selectedBuyer === 'Buyer 3' && (
                            <div className='col-12'>
                                {/* Buyer Form 3 */}

                                <div className='row mt-2'>
                                    <div className="col-6">
                                        <FromFieldText label="Name" star="*"
                                                       placeholder="Buyer 3 Name"
                                                       inputValue={buyerName3}
                                                       setInputValue={setBuyerName3}
                                                       setErrorMsg={buyerName3Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromSpFieldTextArea maxLength = "500" numRows = "3" label="Address" star="*"
                                                       placeholder="Buyer 3 address"
                                                       inputValue={buyerAddress3}
                                                       setInputValue={setBuyerAddress3}
                                                       setErrorMsg={buyerAddress3Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldText label="PAN" star="*"
                                                       placeholder="Buyer 3 PAN address"
                                                       inputValue={buyerPAN3}
                                                       setInputValue={setBuyerPAN3}
                                                       setErrorMsg={buyerPAN3Error}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldText label="GSTN" star="*"
                                                       placeholder="Buyer 3 GSTN"
                                                       inputValue={buyerGSTN3}
                                                       setInputValue={setBuyerGSTN3}
                                                       setErrorMsg={buyer3GSTNError}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>

                                        <FromFieldNumber
                                            star="*"
                                            label="Price Quoted"
                                            placeholder="Buyer 3 Price Quoted"
                                            inputValue={buyer3PriceQuoted}
                                            setInputValue={(value) => {
                                                // Validate that the entered value is a number and less than or equal to 100
                                                const numericValue = Number(value);

                                                if (!isNaN(numericValue) && numericValue >= 0) {
                                                    setBuyer3PriceQuoted(value);
                                                    setBuyer3PriceQuotedError(""); // Clear the error when the input is valid
                                                } else {
                                                    setBuyer3PriceQuotedError("Please enter a valid amount"); // Set the error message when validation fails
                                                }
                                            }}
                                            setErrorMsg={buyer3PriceQuotedError} // Pass the error message to the component
                                        />

                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldDate label="Quoted Received Date" star="*"
                                                       placeholder="Buyer 3 Quoted Received Date"
                                                       inputValue={quotedReceivedDate3}
                                                       setInputValue={setQuotedReceivedDate3}
                                                       setErrorMsg={quotedReceivedDateError3}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <FromFieldDate label="Quoted Expiry Date" star="*"
                                                       placeholder="Buyer 3 Quoted Expiry Date"
                                                       inputValue={quotedExpiryDate3}
                                                       setInputValue={setQuotedExpiryDate3}
                                                       setErrorMsg={quotedExpiryDateError3}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className='col-3 text-center'>{!formSubmitted3 && (
                                        <form name="" onSubmit={handleFormSubmit3}>
                                            <button className="btn btn-primary btn-md float-end">Submit</button>
                                        </form>
                                    )}</div>

                                    <div className='col-3 text-center'>
                                        <form name="" onSubmit={resetAssetDisposalInfo}>
                                            <button className="btn btn-secondary btn-md float-end"> Cancel</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>

            </div>

        </div>

    )
}

export default SingleAssetQuote;