import React, { useState, useRef, useEffect,useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { createMonthDepreciation,lockMonthDepreciation,getCreateMonthData } from '../../../services/asset.service';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import './AssetInfo.css';
import  * as CryptoJS  from "crypto-js";
export default function UserAssetDepreciation() {
  const errRef = useRef();
  // const [validName, setValidName] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('');
  
  const [fnSession, setFnSession] = useState('');
  const [ErrSession, setErrSession] = useState('');
  const [month, setMonth] = useState('');
  const [ErrMonth, setErrMonth] = useState('');
  const [items, setItems] = useState('');
  const [aprilMonth, setAprilMonth] = useState('');
  const [mayMonth, setMayMonth] = useState('');
  const [juneMonth, setJuneMonth] = useState('');
  const [julyMonth, setJulyMonth] = useState('');
  const [augustMonth, setAugustMonth] = useState('');
  const [septemberMonth, setSeptemberMonth] = useState('');
  const [octoberMonth, setOctoberMonth] = useState('');
  const [novemberMonth, setNovemberMonth] = useState('');
  const [decemberMonth, setDecemberMonth] = useState('');
  const [januaryMonth, setJanuaryMonth] = useState('');
  const [februaryMonth, setFebruaryMonth] = useState('');
  const [marchMonth, setMarchMonth] = useState('');
  
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const Tag = query.get('tag');
  useEffect(()=>{
    setErrSession('');
    setItems('');
    /*getAssets().then(res=>{
      if(res?.data?.data?.length) setItems(res.data.data);
    })*/
  },[]);

  const handleFinancialYear = (e) => {
    let value= e.target.value;
    if(value!==''){
      setFnSession(value);  setErrSession(''); //alert(fnSession);
      var obj = {
        'fy_year': parseInt(value) +'-'+ (parseInt(value)+1),
        'token': sessionStorage.getItem('LoggedInToken'),
      };
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':obj,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData = {'token':EncryptData}
      //console.log(data1);
      getCreateMonthData(oData).then(res=>{
        console.log(res.data.data); setItems('');
        if(res?.data?.data?.res?.length){setItems(res.data.data.res);} 
        //console.log('items');
        //console.log(items);
      });
      if(items?.length){
      const april  = items.find(obj => { return obj.month == 4; });
      setAprilMonth(april);
      const may  = items.find(obj => { return obj.month == 5; });
      setMayMonth(may);
      const june  = items.find(obj => { return obj.month == 6; });
      setJuneMonth(june);
      const july  = items.find(obj => { return obj.month == 7; });
      setJulyMonth(july);
      const august  = items.find(obj => { return obj.month == 8; });
      setAugustMonth(august);
      const september  = items.find(obj => { return obj.month == 9; });
      setSeptemberMonth(september);
      const october  = items.find(obj => { return obj.month == 10; });
      setOctoberMonth(october);
      const november  = items.find(obj => { return obj.month == 11; });
      setNovemberMonth(november);
      const december  = items.find(obj => { return obj.month == 12; });
      setDecemberMonth(december);
      const january  = items.find(obj => { return obj.month == 1; });
      setJanuaryMonth(january);
      const february  = items.find(obj => { return obj.month == 2; });
      setFebruaryMonth(february);
      const march  = items.find(obj => { return obj.month == 3; });
      setMarchMonth(march);
      //  console.log(may);
      }else{ 
      setAprilMonth('');
      setMayMonth('');
      setJuneMonth('');
      setJulyMonth('');
      setAugustMonth('');
      setSeptemberMonth('');
      setOctoberMonth('');
      setNovemberMonth('');
      setDecemberMonth('');
      setJanuaryMonth('');
      setFebruaryMonth('');
      setMarchMonth('');
      }
    }else{
        setErrSession('Please Select Financial Year')
      }
    //calInvValInrExRate() 
  } 
  
  const clickMonthTable = (e) => {
    let value = e.currentTarget.getAttribute("value");
    // alert(value);
    if(fnSession!=='' && value!==''){ 
      var session = fnSession;
      if(value=="1"||value=="2"||value=="3"){
        session=(parseInt(fnSession)+1);
      }
      setErrSession(''); //alert(fnSession);
      let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
          'finance_month': value,'finance_year': session,'back_url':'user-asset-depreciation'
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      //redierct
      window.location.href = "/generate-depreciation?finance="+ EncryptID;
    }else{
      setErrSession('Please Select Financial Year')
    }
  }
  
  
  
  
  // readAssets();
  return (
    <div className='container'>
      <div className='row single__assets'>
        <div className="col">
            <h5>Generate Depreciation</h5>
        </div>

        { /*<div className="alert alert-warning" role="alert">
            <i className="fa fa-info-circle"></i>
            Please Fill all mandetory field.
        </div> */ }
      {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>):('')
                ) }
        
        </div>
            
    <div className="row">
    <div className="col-2"><label> Select Financial Year </label></div>
      <div className="col-4">
        <select onChange={handleFinancialYear} name="nature_of_addition" className="form-select form-select-sm mb-3">
            <option value="">Select Financial Year</option>
            <option value="2025">FY-2025-26</option>
            <option value="2024">FY-2024-25</option>
            <option value="2023">FY-2023-24</option>
            <option value="2022">FY-2022-23</option>
            <option value="2021">FY-2021-22</option>
            <option value="2020">FY-2020-21</option>
            <option value="2019">FY-2019-20</option>
            <option value="2018">FY-2018-19</option>
            <option value="2017">FY-2017-18</option>
            <option value="2016">FY-2016-17</option>
            <option value="2015">FY-2015-16</option>
            <option value="2014">FY-2014-15</option>
            <option value="2013">FY-2013-14</option>
            <option value="2012">FY-2012-13</option>
            <option value="2011">FY-2011-12</option>
            <option value="2010">FY-2010-11</option>
            <option value="2009">FY-2009-10</option>
            <option value="2008">FY-2008-09</option>
            <option value="2007">FY-2007-08</option>
            <option value="2006">FY-2006-07</option>
            <option value="2005">FY-2005-06</option>
            </select>
            <span className="invalid-feedback"> {ErrSession} </span>
        </div>
        <div className="col-6">
        <button onClick={handleFinancialYear} value={fnSession} className='btn btn-info btn-md'><i className="bi bi-search"></i> Fetch </button>
        </div>
      </div>
    <div className="row">
      { aprilMonth?( 
      <div className="col-3">
      { (aprilMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>April</h1></div>
        </div>
            ):(<div>
            <a value="4" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>April</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
          <div className='month-div'><h1>April</h1></div>
      </div>) }
      { mayMonth?( 
      <div className="col-3">
      { (mayMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>May</h1></div>
        </div>
            ):(<div><a value="5" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>May</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
          <div className='month-div'><h1>May</h1></div>
        
      </div>) }
      { juneMonth?( 
      <div className="col-3">
      { (juneMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>June</h1></div>
        </div>
            ):(<div><a value="6" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>June</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
      <div className='month-div'><h1>June</h1></div>
        
      </div>)}
      { julyMonth?( 
      <div className="col-3">
      { (julyMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>July</h1></div>
        </div>
            ):(<div><a value="7" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>July</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
      <div className='month-div'><h1>July</h1></div>
        
      </div>)}
    </div>
    <div className="row">
    
      { augustMonth?( 
      <div className="col-3">
      { (augustMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>August</h1></div>
        </div>
            ):(<div><a value="8" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>August</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
        <div className='month-div'><h1>August</h1></div>
        
      </div>)}
      { septemberMonth?( 
      <div className="col-3">
      { (septemberMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>September</h1></div>
        </div>
            ):(<div><a value="9" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>September</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
      <div className='month-div'><h1>September</h1></div>
      </div>
      )}
      { octoberMonth?( 
      <div className="col-3">
      { (octoberMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>October</h1></div>
        </div>
            ):(<div><a value="10" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>October</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
      <div className='month-div'><h1>October</h1></div>
      </div>)}
      { novemberMonth?( 
      <div className="col-3">
      { (novemberMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>November</h1></div>
        </div>
            ):(<div><a value="11" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>November</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
        <div className='month-div'><h1>November</h1></div>
        
      </div>
      )}
    </div>
    
    <div className="row">
    { decemberMonth?( 
      <div className="col-3">
      { (decemberMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>December</h1></div>
        </div>
            ):(<div><a value="12" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>December</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
      <div className='month-div'><h1>December</h1></div>
        
      </div>)}
    { januaryMonth?( 
      <div className="col-3">
      { (januaryMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>January</h1></div>
        </div>
        ):(<div><a value="1" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>January</h1></div></a>
        </div>)
      }
      </div>
      ):(
      <div className="col-3">
      <div className='month-div'><h1>January</h1></div>
      </div>)}
      { februaryMonth?( 
      <div className="col-3">
      { (februaryMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>February</h1></div>
        </div>
            ):(<div><a value="2" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>February</h1></div></a>
            </div>)
      }
      </div>
      ):(
      <div className="col-3">
        <div className='month-div'><h1>February</h1></div>
        
      </div>)}
      { marchMonth?( 
      <div className="col-3">
      { (marchMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>March</h1></div>
        </div>
            ):(<div><a value="3" onClick={clickMonthTable} href="#"><div className='month-div month-y'><h1>March</h1></div></a>
        </div>)
      }
      </div>
      ):(
      <div className="col-3">
      <div className='month-div'><h1>March</h1></div>
        
      </div>)}
    </div>
      <div className='row save__button text-center'>
                <div className='col-md-12'>
                    {/*<button type="submit" className="btn btn-primary btn-md" > Genrate </button>*/}
                </div>
            </div>
    </div>
  )
}

