import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getRFIDList, deleteRFID} from '../../services/user.service'
import * as CryptoJS from "crypto-js";

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}


function ListRFIDReader() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const columns = [
        {"field": "rfid_reader_name", "editable": false, "hide": false, "headerName": "Reader Name", "width": 130},
        {"field": "rfid_reader_location", "editable": false, "hide": false, "headerName": "Reader Location", "width": 230},
        {"field": "rfid_reader_make", "editable": false, "hide": false, "headerName": "Reader Make", "width": 230},
        {"field": "rfid_reader_model_no", "editable": false, "hide": false, "headerName": "Reader Model", "width": 230},
        {"field": "rfid_reader_sr_no", "editable": false, "hide": false, "headerName": "Serial Number", "width": 230},
        {"field": "rfid_reader_ip", "editable": false, "hide": false, "headerName": "IP Address", "width": 230},
        {"field": "company_name", "editable": false, "hide": false, "headerName": "Company Name", "width": 280},
        {
            "field": "created_at", "editable": false, "hide": false, "headerName": "create Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },
        {
            field: 'actions', headerName: 'Actions', width: 120, renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'rfid_id': params.row.id,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (
                    <span>
                        <a href={`/add-rfid-reader?rfid_ID=${EncryptID}`} className="p-2">
                        <button type="button"className="btn btn-primary btn-sm">
                            <i className="bi bi-pencil"></i></button></a>
                        <button type="button"
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => handleDeleteClick(params.row.id)}
                        >
                            <i className="bi bi-trash"></i>
                        </button>
                    </span>
                );
            }
        }
    ];

    const handleDeleteClick = (rfidID) => {
        //alert(costId);
        //console.log(`Deleting cost center with ID: ${costId}`);


        const confirmDelete = window.confirm("Are you sure you want to delete this RFID data?");

        if (confirmDelete) {

            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'rfidID': rfidID},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            deleteRFID(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    window.location.reload();
                }
            });

        } else {
            window.location.reload();
        }



    };

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        // Show loader when the request starts
        setLoading(true);

        // Fetch division list
        getRFIDList(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching rfid list:', error);
            })
            .finally(() => {
                // Hide loader when the request completes (either success or failure)
                setLoading(false);
            });
    }, []);  // Empty dependency array means this effect runs once on mount


    // readDivision();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>View RFID Reader List</h5></div>

            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}

        </div>
    )
}

export default ListRFIDReader
