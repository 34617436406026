import axios from "axios";
var baseURL = process.env.REACT_APP_API_BASE_URL;
var apis = {
    'saveFlagAssetSTF' : `${baseURL}flagging/saveflagassetstf`,
    'getPendingIR' :`${baseURL}flagging/getpendingir`,
    'getPendingER' :`${baseURL}flagging/getpendinger`,
    'updateReclasifyIr' :`${baseURL}flagging/updatereclasifyir`,
    'updateReclasifyEr' :`${baseURL}flagging/updatereclasifyer`,
}
export function saveFlagAssetSTF(data) {
    return axios.put(apis.saveFlagAssetSTF,data);
}
export function getPendingIR(data) {
    return axios.put(apis.getPendingIR,data);
}
export function getPendingER(data) {
    return axios.put(apis.getPendingER,data);
}
export function updateReclasifyIr(data) {
    return axios.put(apis.updateReclasifyIr,data);
}
export function updateReclasifyEr(data) {
    return axios.put(apis.updateReclasifyEr,data);
}


