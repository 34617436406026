import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import FromSpFieldTextArea from '../../../atoms/FromSpFieldTextArea';
import {getAllocation, saveComment, getComment} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import FromFieldTextArea from "../../../atoms/FromFieldTextArea";


function AddComments() {
    const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,500}$/;
    const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;
    const [assetInfo, setAssetInfo] = useState({});
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [tag, setTag] = useState('');
    const [ErrTag, setErrTag] = useState(false);

    const [additionalcomments, setAdditionalComments] = useState('');
    const [ErrAdditionalComments, setErrAdditionalComments] = useState(false);

    const resetAssetInfo = function () {
        setTag('');
        setAdditionalComments('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');

    const comment_id = query.get('comment_ID');

    useEffect(() => {
        if (comment_id !== '' && comment_id !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(comment_id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptCommentId = decrypt_data.commentid;
            getCommentInfo(decryptCommentId); // get comment info
            //alert(Tag);
        } else {
            resetAssetInfo();
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

    }, [])
    const getCommentInfo = function (decryptCommentId) {
        resetAssetInfo();

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'comment_id': decryptCommentId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getComment(oData1).then(function (res) {
            //getTransferData(Tag).then(function(res){
            if (res?.data?.data) {
                var aInfo = res.data.data[0];
                setAssetInfo(aInfo);
                setAdditionalComments(aInfo.comment);
                setTag(aInfo.tag);

            } else {
                setErrMsg('TAG comment Not Found');
            }

        })
    }
    const getTagInfo = function () {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAllocation(oData).then(function (res) {
            //console.log(res.data.data);
            var aInfo = {};
            //getTransferData(tag).then(function(res){
            if (res?.data?.data?.assetInfo) {
                setErrMsg('');
                aInfo = res.data.data.assetInfo;
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
            } else {

                setErrMsg('Assets Not Found');
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(division);
        //alert(tag);
        if (!TEXT_REGEX.test(tag) && tag !== 0) {
            setErrTag('TAG is required');
            valid_Name = false;
            //return;
        } else {
            setErrTag('');
        }

        if (!TEXT_SPCL_REGEX.test(additionalcomments)) {
            setErrAdditionalComments('Additional comments are required');
            valid_Name = false;
            //return;
        } else {
            setErrAdditionalComments('');
        }

        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function (decryptCommentId) {
        if (comment_id !== '' && comment_id !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(comment_id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedCommentId = decrypt_data.commentid;
            var obj = {
                'comment': additionalcomments,
                'tag': tag,
                'comment_id': decryptedCommentId,
                'created_by': sessionStorage.getItem('LoggedInUserId')
            }
        }else {
            var obj = {
                'comment': additionalcomments,
                'tag': tag,
                'created_by': sessionStorage.getItem('LoggedInUserId')
            }
        }

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            saveComment(oData).then(function (res) {
                console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                //console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>Additional Comment</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    (comment_id !== '' && comment_id !== null) ? <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div> : <div className='row'>
                        <div className='col'><h5 className='align-right'>TAG </h5></div>
                        <div className='col'><input  onChange={function(event){setTag(event.target.value)}} inputValue={tag} type="text" className="form-control" placeholder='Enter the asset TAG'/><p className='invalid-feedback'>{ErrTag}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-info btn-md'><i className="bi bi-search"></i> Search
                            </button>
                            &nbsp;<a href={'/add-comments'}>
                            <button className='btn btn-secondary btn-md'><i className="bi bi-arrow-clockwise"></i> Reset</button>
                        </a>&nbsp;</div>
                    </div>
                }

            </div>
            <div className='row'></div>
            <div className="row">

                <div className="col-6">
                    <FromFieldText  
                        label="TAG" 
                        inputValue={assetInfo.tag?assetInfo.tag:''}
                        readOnly="readonly"
                    />
                </div>
                <div className='col-6'>&nbsp;</div>
                <div className='col-6'>&nbsp;</div>
            </div>

            <form onSubmit={handleSubmit}>


                <div className="row">

                    <div className="col-6">
                        <FromSpFieldTextArea star="*" numRows = "10" maxLength = "500"
                                       label="Additional Comments"
                                       inputValue={additionalcomments}
                                       setInputValue={setAdditionalComments}
                                       setErrorMsg={ErrAdditionalComments}
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        {(comment_id !== '' && comment_id !== null) ?
                        (<button className="btn btn-primary btn-md"> Update </button>) : <button className="btn btn-primary btn-md"> SAVE </button> }

                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default AddComments


