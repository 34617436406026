import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { getAllManual } from '../../services/user.service'
import { getSignedImageUrl } from '../../services/asset.service'
import  * as CryptoJS  from "crypto-js";
/*
import AWS from 'aws-sdk';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();
*/
const handleDownloadFile = async (id, imageId) => {
    // Use the method to get the image and initiate the download
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'fileKey': imageId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    
        let oData1 = {'token': EncryptData}
        try {
            const res = await getSignedImageUrl(oData1);
            if (res?.data?.url) {
                const url = res.data.url;
                // Fetch the file as a blob
                const response = await fetch(url);
                const blob = await response.blob();
                // Extract the filename from the URL
                const filename = imageId.substring(imageId.lastIndexOf('/') + 1);
                // Create a temporary download link
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = filename; // Set the desired file name
                document.body.appendChild(a);
                a.click();
                // Clean up
                document.body.removeChild(a);
                URL.revokeObjectURL(downloadUrl);
            } 
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
  };

const columns = [
    {"field":"id","editable":false,"hide":false ,"headerName":"S.No.","width":80 },
    {"field":"name","editable":true,"hide":false ,"headerName":"Name","width":230 },
    {"field":"version","editable":false,"hide":false ,"headerName":"Version","width":180},
    {"field":"company_name","editable":false,"hide":false ,"headerName":"Company","width":350},
    {"field":"role_name","editable":false,"hide":false ,"headerName":"Role","width":230},
    {"field":"remarks","editable":false,"hide":false ,"headerName":"Remark","width":230},
    {"field":"manual_doc","editable":false,"hide":false ,"headerName":"Manual","width":230,
        renderCell: (params) => (<div><a href="#" onClick={() => handleDownloadFile(params.row.id, params.row.manual_doc) } >Download</a> &nbsp;
          </div>
      ) },
    {"field":"status","editable":false,"hide":false ,"headerName":"Status","width":60,
      renderCell: (params) => (
        <span>{(params.row.status==="1")?'Active':'Deactive'}</span>
    ) },
    {
        field: 'actions', headerName: 'Actions', width: 80, renderCell: (params) => {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'manualid': params.row.id,
                'compid': params.row.company_id,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            return (
                <span>
            <a href={`/add-user-manual/?manualid=${EncryptData}`}>
                <button type="button" className="btn btn-primary btn-sm">
                    <i className="bi bi-pencil"></i>
                </button>
            </a>
        </span>
            );
        }
    }
];

function UsersList() {
  const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };
        setLoading(true); // Set loading to true when starting the API request
        getAllManual(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false); // Set loading to false when the API request is completed (regardless of success or failure)
            });
    }, []);
    // readUser();
  return (
      <div className='container'>
          <div className='row'>
              <div className='col-md-12'>
                  <h5>User Manual List</h5>
              </div>
          </div>
          <div className='row'>
              <div className='col-md-12'>

                      {loading ? (
                          <div className="text-center">
                              <div className="spinner-border text-primary" role="status">
                                  <span className="visually-hidden">Loading...</span>
                              </div>
                          </div>
                      ) : (
                          <DataGrid
                              rows={items}
                              getRowId={(row) => row.id}
                              columns={columns}
                              pageSize={50}
                              autoHeight
                              rowsPerPageOptions={[50]}
                          />
                      )}

              </div>
          </div>
      </div>

  )
}

export default UsersList
