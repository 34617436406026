import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
//import { getUserList } from '../../services/user.service'
import {deleteYearName, getYearNameList} from '../../services/asset.service'
import * as CryptoJS from "crypto-js";

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);

function ListTypeYearOfAddition() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    /*var onCellEditStopFn = (event,newCell) => {
      var obj = {};
      obj[newCell.field] = event.target.value;
    }*/
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const columns = [

        {"field": "id", "editable": false, "hide": false, "headerName": "S.No.", "width": 130},
        {"field": "year_of_addition", "editable": true, "hide": false, "headerName": "Year Of Addition", "width": 230},
        {"field": "company_name", "editable": false, "hide": false, "headerName": "Company Name", "width": 350},

        {"field": "created_by_name", "editable": false, "hide": false, "headerName": "Created By", "width": 280},
        {
            "field": "status", "editable": false, "hide": false, "headerName": "Status", "width": 60,
            renderCell: (params) => (
                <span>{(params.row.status == '1') ? 'Active' : 'Deactive'}</span>
            )
        },
        {
            field: 'actions', headerName: 'Actions', width: 120, renderCell: (params) => {
                let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'yearId': params.row.id,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

                return (
                    <span>
            <a href={`/division/add-type-year-of-addition/?yearid=${EncryptData}`} className="p-2">
                <button type="button" className="btn btn-primary btn-sm">
                    <i className="bi bi-pencil"></i>
                </button>
            </a>

                    <button
                        type="button"
                        className="btn btn-danger btn-sm ml-2"
                        onClick={() => handleDeleteClick(params.row.id)}>
                            <i className="bi bi-trash"></i>
                        </button>
        </span>
                );
            }
        }
    ];

    const handleDeleteClick = (costId) => {

        const confirmDelete = window.confirm("Are you sure you want to delete this year?");

        if (confirmDelete) {

            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'addition_id': costId},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            deleteYearName(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    window.location.reload();
                }
            });

        } else {
            window.location.reload();
        }



    };

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = { 'token': EncryptData };

        setLoading(true);

        getYearNameList(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    // readUser();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Year of Addition</h5></div>

            </div>
            <Box sx={{height: 500, width: '100%'}}>
                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        rows={items}
                        getRowId={(row) => row.id}
                        columns={columns}
                        pageSize={50}
                        autoHeight
                        rowsPerPageOptions={[50]}
                    />
                )}</Box>
        </div>
    )
}



export default ListTypeYearOfAddition
