import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import * as CryptoJS from "crypto-js";
import QRCode from 'qrcode';
import {getGatePassDetailByGatePassNo} from "../../services/asset.service";
import {getCompanyProfileData} from '../../services/user.service';

function getFormattedDate(dateString) {
    if (!dateString) return null;
    const dte = new Date(dateString);
    const year = dte.getFullYear();
    const month = String(dte.getMonth() + 1).padStart(2, '0');
    const day = String(dte.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function GatePassPrint() {
    const [info, setInfo] = useState({});
    const [gatePassDetails, setGatePassDetails] = useState({
        gatePassNumber: '',
        currentDate: '',
        destinationCity: '',
        destinationCountry: '',
        reasonForTakingOut: '',
        materials: []
    });
    const [companyname, setCompanyName,] = useState('');
    const [companyaddress, setCompanyAddress] = useState('');
    const query = new URLSearchParams(useLocation().search);
    const gatePassNo = query.get('gatePassNo');

    useEffect(() => {
        if (gatePassNo) {
            decryptAndFetchGatePass(gatePassNo);
        }
    }, [gatePassNo]);

    const decryptAndFetchGatePass = (encryptedGatePassNo) => {
        try {
            const decryptedBytes = CryptoJS.AES.decrypt(
                encryptedGatePassNo.replace(/ /g, '+'),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            );
            const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            fetchGatePassDetails(decryptedData.gatePassNo);

        } catch (error) {
            console.error("Error decrypting gate pass number:", error);
        }
    };

    const fetchGatePassDetails = (decryptedGatePassNo) => {
        const encryptedRequest = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: {gatePassNo: decryptedGatePassNo},
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = {token: encryptedRequest};

        getGatePassDetailByGatePassNo(requestData).then((res) => {
            const data = res?.data?.data;
            if (data) {
                const aInfo = data.gate_pass_data;
                setInfo(aInfo);
                setGatePassDetails({
                    gatePassNumber: decryptedGatePassNo,
                    currentDate: new Date(aInfo.gatePassDate),
                    destinationCity: aInfo.destinationCity,
                    destinationCountry: aInfo.destinationCountry,
                    reasonForTakingOut: aInfo.reasonTakingOut,
                    materials: data.tag_details.map(material => ({
                        tag: material.tag,
                        tagName: material.tag_name,
                        description: material.asset_description,
                        qty: material.qty,
                        currencyType: material.invoice_currency,
                        value: material.value_of_asset,
                        invoiceNo: material.invoice_no,
                        remarks: material.remark
                    }))
                });
            }
        }).catch((error) => {
            console.error("Error fetching gate pass details:", error);
        });
        getCompanyProfileData(requestData).then(function (res) {
            console.log(res.data.data);
            if (res?.data?.data.length) {
                var aInfo = res.data.data[0];
                setCompanyName(aInfo.company_name);
                setCompanyAddress(aInfo.company_address);
            }
        }).catch((error) => {
            console.error("Error fetching gate pass details:", error);
        });
    };

    const printGatePass = () => {

        QRCode.toDataURL(gatePassNumber, {
            width: 800,
            margin: 2,
        }, (err, url) => {
            if (err) return console.error(err);
            const col3 = document.createElement("img");
            col3.setAttribute("src", url);
            col3.setAttribute("style", "width:90px");
            //document.getElementById('qr_image').innerHTML(col3);
            // Clear any existing content and append the new image
            const qrImageElement = document.getElementById('qr_image');
            qrImageElement.innerHTML = ""; // Clear existing content
            qrImageElement.appendChild(col3);
        });

        const printContents = document.getElementById('printGatePass').innerHTML;
        const docprint = window.open();
        docprint.document.write(`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en">
            <head><title>Print QR Codes</title>
            <head>
                <title>Print Gate Pass</title>
                <style>
                    @media print {
                        @page {size: A4; margin: 15mm; } .no-print { display: none; }
                        html, body { width: 210mm; height: 297mm; font-size: 12px; margin: 0; padding: 0; }
                        table { border-collapse: collapse; width: 100%; }
                        th, td { border: 1px solid black; padding: 5px;  }
                    }
                </style>
            </head>
            <body onload="window.print(); ">
                <div>${printContents}</div>
            </body>
        </html>`);

        docprint.document.close();
        docprint.focus();
        docprint.print();
    };


    const {
        gatePassNumber,
        currentDate,
        destinationCity,
        destinationCountry,
        reasonForTakingOut,
        materials
    } = gatePassDetails;

    return (
        <div className="container" id="printGatePass">
            <div style={{padding: '15px', border: '1px solid', margin: '15px',}}>

                <table border="0" width="100%" style={{textAlign: 'center'}}>
                    <tr>
                        <th style={{width: '25%', border: 0}}></th>
                        <th style={{width: '50%', border: 0}}>
                            <h2>{companyname}</h2>
                            <label>{companyaddress}</label>
                        </th>
                        <th style={{width: '25%', border: 0}}></th>
                    </tr>
                </table>

                <table border="1" width="100%" style={{border: '0px', textAlign: 'left'}}>
                    <tr>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}>&nbsp;</td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                    </tr>
                    <tr>
                        <td style={{width: '25%', border: 0}} colSpan={2}><h5>{info.catOfEmp} - {info.gatePassType} -
                            Gate Pass</h5></td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                    </tr>

                    <tr>
                        <td style={{width: '25%', border: 0}}><label><strong>Gate Pass No:</strong> </label></td>
                        <td style={{width: '25%', border: 0}}>{gatePassNumber}</td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}} rowSpan={4} id="qr_image">{<img style={{width: '130px'}}
                                                                                              src="qr_code_sample.png"
                                                                                              alt="QR Code"/>}</td>
                    </tr>
                    <tr>
                        <td style={{width: '25%', border: 0}}><label><strong>Current Date:</strong> </label></td>
                        <td style={{width: '25%', border: 0}}>{new Date(currentDate).toLocaleDateString()}</td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                    </tr>
                    <tr>
                        <td style={{width: '25%', border: 0}}><label><strong>Destination City:</strong></label></td>
                        <td style={{width: '25%', border: 0}}>{destinationCity}</td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                    </tr>
                    <tr>
                        <td style={{width: '25%', border: 0}}><label><strong>Destination Country:</strong></label></td>
                        <td style={{width: '25%', border: 0}}>{destinationCountry}</td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                    </tr>
                    <tr>
                        <td colSpan={4} style={{width: '25%', border: 0}}>Security Office may please
                            allow <b>Smt/Sri {info.titles}</b> Designation <b>{info.designation}</b> of
                            <b> {info.vendorName}</b> to take out the following materials
                            from <b>{info.division_name}</b>.<br/>
                            {info.gatePassType !== "Non Returnable" && (
                                <>
                                    The probable date of return of the material is <b>{getFormattedDate(info.expectedDate)}</b>.
                                </>
                            )}
                        </td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{width: '25%', border: 0}}><label><strong>Reason for Taking
                            Out:</strong> {reasonForTakingOut}</label></td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                    </tr>
                    <tr>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}} colSpan={2}>&nbsp;</td>
                        <td style={{width: '25%', border: 0}}></td>
                        <td style={{width: '25%', border: 0}}></td>
                    </tr>
                </table>
                <table border="1" width="100%" style={{textAlign: 'center'}}>
                    <thead>
                    <tr>
                        <th>Sl No</th>
                        <th>TAG No</th>
                        <th>TAG Name</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Currency Type</th>
                        <th>Value</th>
                        <th>Invoice No</th>
                        <th>Remarks</th>
                    </tr>
                    </thead>
                    <tbody>
                    {materials.map((mat, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{mat.tag}</td>
                            <td>{mat.tagName}</td>
                            <td>{mat.description}</td>
                            <td>{mat.qty}</td>
                            <td>{mat.currencyType}</td>
                            <td>{mat.value}</td>
                            <td>{mat.invoiceNo}</td>
                            <td>{mat.remarks}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <div className="row mt-4 mb-2 no-print">
                    <div className="col-md-12 d-flex justify-content-center">
                        <button onClick={printGatePass} className="btn btn-secondary btn-sm">
                            <i className="bi bi-printer"></i> Print
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GatePassPrint;
