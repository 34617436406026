import React, {useEffect, useState} from 'react';
//import Box from '@mui/material/Box';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getContinentList, deleteContinent} from '../../services/user.service'
import * as CryptoJS from "crypto-js";
function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);
function ListContinent() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const columns = [
        {"field": "continent_name", "editable": false, "hide": false, "headerName": "Continent Name", "width": 300},
        {"field": "continent_code", "editable": false, "hide": false, "headerName": "Continent Code", "width": 300},
        {
            "field": "created_at", "editable": false, "hide": false, "headerName": "create Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },
        {
            field: 'actions', headerName: 'Actions', width: 120, renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'continent_id': params.row.id,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (
                    <span>
                        <a href={`/country/addcontinent?continent_id=${EncryptID}`} className="p-2">
                        <button type="button" className="btn btn-primary btn-sm">
                            <i className="bi bi-pencil"></i></button></a>
                        <button
                            type="button"
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => handleDeleteClick(params.row.id)}
                        >
                            <i className="bi bi-trash"></i>
                        </button>
                    </span>
                );
            }
        }
    ];

    const handleDeleteClick = (continentId) => {

        const confirmDelete = window.confirm("Are you sure you want to delete this continent name?");

        if (confirmDelete) {

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'continent_id': continentId},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            deleteContinent(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    window.location.reload();
                }
            });

        } else {
            window.location.reload();
        }
    };

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = { 'token': EncryptData };

        setLoading(true);

        getContinentList(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // readDepartment();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Continent List</h5></div>
            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}

        </div>
    )
}

export default ListContinent
