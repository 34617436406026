import React, { useState,useEffect,useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer, GridToolbarExport,GridOverlay } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import { updateFlagAsset,acceptFlagAsset } from '../../../services/asset.service';
import { getBranchTransferList } from '../../../services/transfer.service';
import ConfirmBox from '../../../atoms/ConfirmBox';
import  * as CryptoJS  from "crypto-js";

function getFormatedDate(dateString) {
  if(dateString!=null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function PendingTransfer() {

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [rejectReason, setRejectReason] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleConfirmOpen = () => {
    setIsConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const handleConfirm = (inputValue) => {
    
    console.log('Confirmed with input:', inputValue);
    //setRejectReason(inputValue);
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data': {'flag_id':'','flag_ids':selectionModel,'flag_status':'2','reject_reason':inputValue},
        'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token': EncryptData}
    try {
        //setLoading('..'); // Set loading before sending API request
        updateFlagAsset(oData).then(function (res) {
        //setLoading(false); // Stop loading
        setSuccess(res.data.message); //alert('a');
        const updatedData = items.filter((item) => !selectionModel.includes(item.id) );
          setItems(updatedData);
      });
    } catch (err) {
        setSuccess(false);
        setLoading(false); // Stop loading
        if (!err?.data1) {
            console.log(err);
            //setErrMsg('No Server Response');
            //setSuccess("Successfully saved Single Asset");
        } else if (err.data1?.status === 409) {
            //setErrMsg('Record already exist');
        } else {
            setErrMsg('Insert/Update Failed');
        }
        //elementRef.current.focus();
    }
  };
  
  const handleReject = async (flagId) => {
      //console.log('flagid'+flagId);
    if(selectionModel.length>0){ setErrMsg('');
    if (window.confirm('Are you sure you want to reject selected items?')){
      handleConfirmOpen();
    }}else{ setErrMsg('Please select atleast one asset.')}
  };
  const handleAccept = async (flagId) => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'status':'5','flag_ids':selectionModel},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            //setLoading('..'); // Set loading before sending API request
            acceptFlagAsset(oData).then(function (res) {
            //setLoading(false); // Stop loading
            setSuccess(res.data.message); //alert('a');
            const updatedData = items.filter((item) => item.id !== flagId);
            setItems(updatedData);
            });
        } catch (err) {
          setSuccess(false);
          setLoading(false); // Stop loading
          if (!err?.data1) {
              console.log(err);
              //setErrMsg('No Server Response');
              //setSuccess("Successfully saved Single Asset");
          } else if (err.data1?.status === 409) {
              //setErrMsg('Record already exist');
          } else {
              setErrMsg('Insert/Update Failed');
          }
          //elementRef.current.focus();
        }
  };
  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
};
  
  const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tin_no","hideable":true,"hide":true,"editable":false,"headerName":"TIN No","width":50},
    {"field":"tag","editable":false,"hide":false ,"headerName":"TAG No","width":250,
      renderCell: (params) => {
        let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
            'tag': params.row.tag,
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        return (<a href={`/pending-transfer-view?tag=${EncryptID}`} >{params.row.tag}</a>)
        }
    },
    {"field":"status","editable":true,"hide":false ,"headerName":"Allocation Status","width":280},
    {"field":"created_by","editable":true,"hide":false ,"headerName":"Invoice No","width":280},
    {"field":"created_at","editable":true,"hide":false ,"headerName":"Invoice Date","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
      ) },
    {"field":"cat_of_flag","editable":true,"hide":false ,"headerName":"CAT","width":280},
    {"field":"remarks","hideable":true,"hide":true ,"editable":true,"headerName":"Remarks","width":250},
    {"field":"purpose_of_grouping","editable":true,"hide":false ,"headerName":"Purpose","width":130},
    {"field":"existing_ownership","editable":true,"hide":false ,"headerName":"Existing Control Dept","width":130},
    {"field":"new_ownership","editable":true,"hide":false ,"headerName":"New Control Dept","width":130},
    {"field":"created_at","editable":true,"hide":false ,"headerName":"Date of Flagging","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
    ) },
  ];

  const CustomLoader = () => (
    <GridOverlay>
      <CircularProgress color="primary" size={30}  />
    </GridOverlay>
  );
function CustomToolbar() {
  return (
    <GridToolbarContainer>
    </GridToolbarContainer>
  );
}
const [items, setItems] = useState([]);
  useEffect(()=>{
    let isMounted = true;
    const fetchData = async () => {
      try {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':{'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        const res = await getBranchTransferList(oData);
        /*getBranchTransferList(oData).then(res=>{
          if(res?.data?.data?.length) setItems(res.data.data);
        });*/
        if (isMounted && res?.data?.data?.length) {
          setItems(res.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  
    // Cleanup function to handle unmounting
    return () => {
      isMounted = false;
    };
  },[])
  
  // readAssets();
  return (
    <div className='container' ><h5> Pending List </h5>
      {success ? (<div className="alert alert-success" role="alert">
        <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
          <div className="alert alert-danger" role="alert">
            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
      )}
      
      <div className='filter-container1 col-12'>
      <div className='col-8'></div><div className='col-2'>
        <button className='btn btn-danger btn-sm text-right' onClick={handleReject}>Reject Process</button>
        </div>
      </div>
      
      <DataGrid
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={50}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        //height={500}
        autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        components={{
            LoadingOverlay: CustomLoader,
            Toolbar: CustomToolbar,
        }}
        loading={loading}
        // initialState={{
        //     pinnedColumns: { left: ['tag'] },
        //   }}
      />
      <ConfirmBox
        isOpen={isConfirmOpen}
        onClose={handleConfirmClose}
        onConfirm={handleConfirm}
      />
    </div>
  )
}

export default PendingTransfer
