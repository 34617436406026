import React, {useEffect, useState} from 'react';
import * as CryptoJS from "crypto-js";
import FromSpFieldTextArea from "../../atoms/FromSpFieldTextArea";
import {createTicket, getModuleList, getSubModuleList, getTicketSubjectList} from "../../services/tickets.service";

import AWS from 'aws-sdk';
// Configure AWS with your credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,2000}$/;

function RaiseTickets() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [ticketDesc, setTicketDesc] = useState('');
    const [ErrTicketDesc, setErrTicketDesc] = useState(false);

    const [ticketSubject, setTicketSubject] = useState('');
    const [ErrTicketSubject, setErrticketSubject] = useState(false);

    const [ticketSubjectlist, setTicketSubjectlist] = useState([]);

    const [modules, setModules] = useState('');
    const [ErrModules, setErrModules] = useState(false);

    const [subModules, setSubModules] = useState('');
    const [ErrSubModules, setErrSubModules] = useState(false);

    const [mods, setMods] = useState([]);
    const [subMods, setSubMods] = useState([]);


    const [uploadMail, setUploadMail] = useState('');
    const [uploadInvoiceError, setUploadInvoiceError] = useState('');

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getModuleList(oData).then(res => {
            if (res?.data?.data?.length) setMods(res.data.data);
        });

        getTicketSubjectList(oData).then(res => {
            if (res?.data?.data?.length) setTicketSubjectlist(res.data.data);
        });

    }, [])


    const handleSubject = (e) => {
        setTicketSubject(e.target.value);
    }

    const handleModule = (e) => {
        let mod_id = e.target.value;
        setModules([e.target.value]); // Set as an array
        let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'module_id': mod_id},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData1};
        getSubModuleList(oData1).then(res => {
            if (Array.isArray(res.data.data)) {
                setSubMods(res.data.data);
            } else {
                setSubMods([]); // Set to an empty array if data is not an array
            }
        });

    }

    const handleSubModule = (e) => {
        let value = e.target.value;
        setSubModules([e.target.value]); // Set as an array
    }

    const resetUserInfo = function () {
        setTicketSubject('');
        setTicketDesc('');
        setModules('');
        setSubModules('');
        setUploadMail('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(ticketSubject)) {
            setErrticketSubject('Ticket Subject is required');
            valid_Name = false;
            //return;
        } else {
            setErrticketSubject('');
        }
        if (!TEXT_SPCL_REGEX.test(ticketDesc)) {
            setErrTicketDesc('Ticket Description is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrTicketDesc('');
        }
        if (!TEXT_REGEX.test(modules)) {
            setErrModules('Ticket Module is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrModules('');
        }
        if (!TEXT_REGEX.test(subModules)) {
            setErrSubModules('Ticket Sub Module is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrSubModules('');
        }

        if (valid_Name) {
            saveData();
        } else {
            return false;
        }
    }

    const handleUploadInvoice = (e) => {
        const files = e.target.files;
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 2 * 1024 * 1024; // 2 MB
        let errorMsg = '';
        let validFiles = [];

        for (let file of files) {
            if (!validFileTypes.includes(file.type)) {
                errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                break;
            }
            if (file.size > maxSize) {
                errorMsg = 'File size should not exceed 2 MB.';
                break;
            }
            validFiles.push(file);
        }

        if (errorMsg) {
            setUploadInvoiceError(errorMsg);
            setUploadMail([]);
        } else {
            setUploadInvoiceError('');
            setUploadMail(validFiles);
        }
    };

    const saveData = function () {

        setIsLoading(true);

        const formData = new FormData();
        let jsonData = {
            'ticketSubject': ticketSubject,
            'ticketDesc': ticketDesc,
            'moduleID': modules,
            'subModuleID': subModules,
            'companyshortname': sessionStorage.getItem("LoggedInCompanyShortName"),
            'created_by': sessionStorage.getItem('LoggedInUserId'),
            'company_id': sessionStorage.getItem('LoggedInCompanyId'),
            'division_id': sessionStorage.getItem('LoggedInDivisionId'),
            'department_id':sessionStorage.getItem('LoggedInDepartmentId'),
        }
        for (let i = 0; i < uploadMail.length; i++) {
            const file = uploadMail[i];
            formData.append("ticket_image[]", file);
        }
        //console.log(oData);
        var objAudit = {'audit_module':'Create Ticket','audit_method':'created','audit_tag':ticketDesc,'old_values':'','new_values':formData}
        jsonData.audit_params = objAudit;
        jsonData.authToken = sessionStorage.getItem('LoggedInToken');
        //jsonData.dimension = types;
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        formData.append("metadata", encryptedData);
        try {

            createTicket(formData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
        finally {
            setIsLoading(false);
        }
    }

    const uploadTicketFile = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'image/' + img_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                return params.name;
            }
        });
    };

    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>Raise Ticket</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row'></div>

            <form onSubmit={handleSubmit}>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Module Name</label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">

                        <select onChange={handleModule} className="form-select form-select-sm mb-3">
                            <option value="">Select Module</option>
                            {mods && mods.length > 0 ? (
                                mods.map((option) => (
                                    <option value={option.id}
                                            selected={(option.id == modules) ? "selected" : ''}>{option.module_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select><span className="invalid-feedback"> {ErrModules} </span>
                    </div>
                </div>

                <div className="row form-fields mb-3">
                    <div className="label col-3">
                        <label>Sub Module</label><span className="star error"> *</span>
                    </div>


                    <div className="item col-3">
                        <select onChange={handleSubModule} className="form-select form-select-sm">
                            <option value="">Select Sub Module</option>
                            {subMods && subMods.length > 0 ? (
                                subMods.map((option) => (
                                    <option value={option.id}
                                            selected={(subModules === option.id) ? "selected" : ''}>{option.sub_module_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrSubModules} </span>
                    </div>

                </div>

                <div className="row form-fields">

                    <div className="label col-3">
                        <label>Ticket Subject</label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleSubject}>
                            <option value="">Select Ticket Subject</option>
                            {ticketSubjectlist && ticketSubjectlist.length > 0 ? (
                                ticketSubjectlist.map((option) => (
                                    <option value={option.id}
                                            selected={(option.id == ticketSubject) ? "selected" : ''}>{option.ticket_subject}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrTicketSubject} </span>
                    </div>

                </div>

                <div className="row mt-3">

                    <div className="col-6">
                        <FromSpFieldTextArea star="*" numRows="15" maxLength="2000"
                                             label="Ticket Description"
                                             inputValue={ticketDesc}
                                             setInputValue={setTicketDesc}
                                             setErrorMsg={ErrTicketDesc}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <label style={{marginRight: '10px'}}>Upload image/screenshot (if any)</label>
                        <input type="file" name='uploadInvoice' multiple onChange={handleUploadInvoice}/>
                        <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed. Maximum size:
                            2 MB.</small>
                        <span className="invalid-feedback"> {uploadInvoiceError} </span>
                    </div>

                </div>


                <div className="row mt-2">
                    <div className="col-3"></div>
                    <div className="col-6">

                        <button
                            className="btn btn-primary btn-md"
                            //onClick={saveData}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Loading...' : 'Raise'}
                        </button>


                        &nbsp;&nbsp;
                        <button
                            className="btn btn-secondary btn-md">Cancel
                        </button>


                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default RaiseTickets


