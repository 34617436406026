import axios from "axios";

var baseURL = process.env.REACT_APP_API_BASE_URL;

var apis = {

    'saveAssetDisposal': `${baseURL}disposal/single-add`,
    'getLastDinNo': `${baseURL}disposal/get-last-din`,
    'getAssetsDisposalData': `${baseURL}disposal/getassetsdisposaldata`,
    'getAllDisposalTagList': `${baseURL}disposal/getalldisposaltaglist`,
    'saveAssetQuotes': `${baseURL}disposal/saveassetquotes`,
    'getDisposalAssetDetails': `${baseURL}disposal/getdisposalassetdetails`,
    'getBuyerQuotedPrice': `${baseURL}disposal/getbuyerquotedprice`,
    'saveFinalisedDeal': `${baseURL}disposal/savefinaliseddeal`,
    'getFinalizeBuyerList': `${baseURL}disposal/getfinalizebuyerlist`,
    'getQuoteList': `${baseURL}disposal/getquotelist`,
    'getQuoteTagsList': `${baseURL}disposal/getquotetagslist`,
    'getBuyerList': `${baseURL}disposal/getbuyerlist`,
    'getAssetQuoteData': `${baseURL}disposal/getassetquotedata`,
    'uploadBulkDisposal': `${baseURL}disposal/uploadbulkdisposal`,
    'uploadBulkAssetQuotes': `${baseURL}disposal/uploadbulkassetquotes`,
    'uploadBulkFinalizeDeal': `${baseURL}disposal/uploadbulkfinalizedeal`,
    'checkFinalizeDinNo': `${baseURL}disposal/checkfinalizedinno`,
    'getDinNoTagList': `${baseURL}disposal/getdinnotaglist`,
    'getBuyerList1': `${baseURL}disposal/getbuyerlist1`,

}

export function saveAssetDisposal(data) {
    return axios.post(apis.saveAssetDisposal, data);
}

export function saveAssetQuotes(data) {
    return axios.put(apis.saveAssetQuotes, data);
}

export function getLastDinNo(data) {
    return axios.put(apis.getLastDinNo, data);
}

export function getAssetsDisposalData(data) {
    return axios.put(apis.getAssetsDisposalData, data);
}

export function getAllDisposalTagList(data) {
    return axios.put(apis.getAllDisposalTagList, data);
}

export function getDisposalAssetDetails(data) {
    return axios.put(apis.getDisposalAssetDetails, data);
}

export function getBuyerQuotedPrice(data) {
    return axios.put(apis.getBuyerQuotedPrice, data);
}

export function getDinNoTagList(data) {
    return axios.put(apis.getDinNoTagList, data);
}

export function saveFinalisedDeal(data) {
    return axios.put(apis.saveFinalisedDeal, data);
}

export function getFinalizeBuyerList(data) {
    return axios.put(apis.getFinalizeBuyerList, data);
}

export function getQuoteList(data) {
    return axios.put(apis.getQuoteList, data);
}

export function getQuoteTagsList(data) {
    return axios.put(apis.getQuoteTagsList, data);
}

export function getBuyerList(data) {
    return axios.put(apis.getBuyerList, data);
}

export function getAssetQuoteData(data) {
    return axios.put(apis.getAssetQuoteData, data);
}

export function uploadBulkDisposal(data) {
    return axios.post(apis.uploadBulkDisposal, data);
}

export function uploadBulkAssetQuotes(data) {
    return axios.post(apis.uploadBulkAssetQuotes, data);
}

export function uploadBulkFinalizeDeal(data) {
    return axios.post(apis.uploadBulkFinalizeDeal, data);
}

export function checkFinalizeDinNo(data) {
    return axios.post(apis.checkFinalizeDinNo, data);
}
export function getBuyerList1(data) {
    return axios.put(apis.getBuyerList1, data);
}
