import React,{useState,useEffect} from 'react'
import { useLocation } from "react-router-dom";
import { calculateDepreciation } from '../services/asset.service';
import  * as CryptoJS  from "crypto-js";
import ConfirmBox from './ConfirmBox';
function MultiListPopUp(props) {
    
    var block = props.show1? "display-block": "";
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [overwriteDep, setOverwriteDep] = useState('No');
    const [overwriteBtn, setOverwriteBtn] = useState(false);
    const [financeInfo1, setFinanceInfo1] = useState(false);
    const [listOptions1, setListData1] = useState([]);
    //const [listOptions2, setListData2] = useState([]);
    
    var asset_data = [...props.data1];
    //console.log('asset_data='); console.log(asset_data);
    const [foundAssets, setFoundAssets] = useState(asset_data);

    
    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
        const results = asset_data.filter((asset) => {
            return asset.tag.startsWith(keyword);
        });
        setFoundAssets(results);
        } else {
        setFoundAssets(asset_data);
        // If the text field is empty, show all users
        }
        setName(keyword);
    };
    const [back_url, setBackUrl] = useState(false);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Finance = query.get('finance'); 
    //const elementRef = useRef();
    useEffect(() => {
        if(Finance!='' && Finance!=null){
            const decryptedBytes = CryptoJS.AES.decrypt(Finance.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
              // Convert the decrypted bytes to a string
              const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
              //const decryptedFinance = decrypt_data.finance;
              setBackUrl(decrypt_data?.back_url);
              setFinanceInfo1(decrypt_data);
          }
        //setListData2({listOptions1});
        //setFoundAssets(asset_data);
        setListData1(listOptions1);
    }, []);
    // check array pushListOptions
    //refrest_ListData1
    const refrest_ListData1 = (e) => {
        setListData1(listOptions1);
    }
    const pushListOptions=(obj)=>{
        var flag=0;
        setLoading('..');
        listOptions1.forEach((elem)=>{
          if(obj.value===elem.value && obj.label===elem.label){
            flag=1;
        }
      });
      setLoading(false);
      if(flag===1){
          return false;
      }else{
          return true;
      }
    };

   //search filter 
   // the value of the search field 
  const [name, setName] = useState('');
   // the search result
  //const [foundAssets, setFoundAssets] = useState(asset_data);
  //console.log('foundAssets='); console.log(foundAssets);
  
  //start validation   
  const handleSubmit = async (e) => { 
      e.preventDefault(); var valid_Name=true;
  if(financeInfo1.finance_year ===''){
        setValidName(false);
        alert("Financial year is missing"); valid_Name=false;
  }
  if(financeInfo1.finance_month ===''){ 
        setValidName(false);
        alert('Month is missing'); valid_Name=false;
  }
  if(listOptions1.length===0){ //alert(listOptions1.length);
        setValidName(false);
        alert('Please select atleast one Asset'); valid_Name=false;
  }
  if (!valid_Name) { //alert(valid_Name); 
          return false;
      }else{ //alert(mon); alert(fy);
          saveData();
      }
  }
  const handleOverwriteDep = async (e) => {
    e.preventDefault();
    const overwriteConfirmation = window.confirm("Are you sure you want to Overwrite?");
    //alert(overwriteConfirmation);
    if (overwriteConfirmation) {
        //alert("Confirmed! Proceeding...");
        //setOverwriteDep('Yes'); 
        saveData('Yes');
    } else {
        // User clicked "Cancel"
        return false;
    }
  }

  const saveData = async (overwrite='No') => {
    var obj = {
      //'tag': tag, 
      'owerwrite_dep': overwrite, 
      'session':financeInfo1.finance_year, 
      'month':financeInfo1.finance_month,
      'assets':listOptions1,
      'company_id':sessionStorage.getItem('LoggedInCompanyId'),
      'division_id':sessionStorage.getItem('LoggedInDivisionId'),
    }
    
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':obj,
        'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token':EncryptData}
    try {  setLoading('..'); // Set loading before sending API request
        calculateDepreciation(oData).then(function(res){
            //console.log(res);
            if(res.data.message.warning=="Already Exist"){
                setSuccess('');
                setErrMsg(' Depriciation Already Exist. Data: ' + res.data.message.tags.join(", ") );
                setOverwriteBtn(true);
            }else if(res.data.message.success=="Inserted"){
                setErrMsg('');
                setSuccess(' Successfully Genrated Depreciation. Data: '+ res.data.message.tags.join(", ") );
            }
            //elementRef.current.focus();
        }); setValidName(false);
        setLoading(false); // Stop loading
    } catch (err) { setSuccess(false);
        setLoading(false); // Stop loading
        if (!err?.data1) {
            console.log(err);
            //setErrMsg('No Server Response');
            //setSuccess("Successfully saved Single Asset");
        } else if (err.data1?.status === 409) {
            //setErrMsg('Record already exist');
        } else {
            setErrMsg('Insert/Update Failed');
        }
        //elementRef.current.focus();
    }
  }

    const handleMoveList1 = (e) => {
        const selectedOptions =[];
        //alert('dd');
        var options1 = document.getElementById('lstBox1').options;
        //console.log(options1);
        for (var i = 0, l = options1.length; i < l; i++) {
            if (options1[i].selected) {
                var option1 = {
                    label: options1[i].label,
                    value: options1[i].value,
                }
                if (pushListOptions(option1)) {
                    listOptions1.push(option1);
                }
            }
        } //setListData2(listOptions1); 
        setListData1((prevList) => prevList.filter((option) => !selectedOptions.find((selected) => selected.value === option.value)));
        
        //console.log('listOptions1');console.log(listOptions1);
    }
    const handleMoveList2 = (e) => {
        const selectedOptions2 =[];
        var options2 = document.getElementById('lstBox2').options;
        //console.log(options1);
        for (var i = 0, l = options2.length; i < l; i++) {
            if (options2[i].selected) {
                var option1 = {
                    label: options2[i].label,
                    value: options2[i].value,
                }
                if (pushListOptions(option1)) {
                    listOptions1.push(option1);
                }
            }
        } //setListData2(listOptions1); 
        setListData1((prevList) => prevList.filter((option) => !selectedOptions2.find((selected) => selected.value === option.value)));
        
        //console.log('listOptions1');console.log(listOptions1);
    }
    const handleAllMoveList1 = (e) => { //alert('dd');
        //var listOptions = [];
        var options1 = document.getElementById('lstBox1').options;
        for (var i = 0, l = options1.length; i < l; i++) {
            var option1 = {
                label: options1[i].label,
                value: options1[i].value,
            }
            if (pushListOptions(option1)) {
                listOptions1.push(option1);
            }
            //listOptions1.push(option1);
        } //setListData2(listOptions1); console.log(listOptions2);
        setListData1(listOptions1);
    }
    const handleAllRemove = (e) => { //alert('dd');
        //var options2 = document.getElementById('lstBox1').options;
        //alert(options.length);
        setListData1([]); 
    }
    
    return (
        <div className={block +" custom-modal width-80 height-80"}>
        
        
        <div className="custom-close p-1" > <span onClick={ ()=>{props.showsetter1(false)} } className="bi bi-x-circle-fill"></span> </div>
            <div className="title custom-modal-title">
                <h5>{props.title1}</h5> 
                
            </div>
            {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            <div className="subject-info-box-1">
            <input  type="search"  value={name} onChange={filter} className="form-control" placeholder="Filter"
            />
            <select multiple="multiple" id='lstBox1' className="form-select form-select-sm">
            {foundAssets && foundAssets.length > 0 ? (
                foundAssets.map((option) => (
                <option value={option.tag}>{option.tag}</option>
                ))
            ) : (
            <h1>No results</h1>
            )}
            </select>
        </div>

        <div className="subject-info-arrows text-center">{loading ? (<div className="spinner-border text-primary" role="status">
                              <span className="sr-only">{loading}</span>
                            </div>) : ('') }
        <button onClick={filter} value=""  className="btn btn-info btn-sm refresh">Refresh</button><br /><br />
        <input type='button' id='btnAllRight' onClick={handleAllMoveList1} value='Add All' className="btn btn-default" /><br />
        <input type='button' id='btnRight' onClick={handleMoveList1} value='>' className="btn btn-default" /><br />
        
        <input type='button' id='btnAllLeft' onClick={handleAllRemove} value='Remove All' className="btn btn-default" />
        </div>
    
        <div className="subject-info-box-2">
        {/*<div className="subject-refresh">
        <button onClick={refrest_ListData1} value=""  className="form-control btn-info refresh">Refresh</button>
            </div>*/}
        <select multiple="multiple" id='lstBox2' className="form-select form-select-sm">
            {   listOptions1.map((option) => (
                <option value={option.value}>{option.label}</option>
                )) }
        </select>
        </div>

        <div className="clearfix"></div>
        {(overwriteBtn)?(<form name="" onSubmit={handleOverwriteDep}>
            <button onClick={ () =>{ //data.push();
                                    props.datasetter1(asset_data);
                                    }
                            } 
            className="btn btn-info btn-md float-end"> Overwrite </button>
            
        </form>):(<form name="" onSubmit={handleSubmit}>
            <button onClick={ () =>{ //data.push();
                                    props.datasetter1(asset_data);
                                    }
                            } 
            className="btn btn-primary btn-md float-end"> Submit </button>
            
        </form>)
        }
        
    </div>
    )
}

export default MultiListPopUp