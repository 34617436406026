import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getRoleList} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import {deleteRole} from "../../services/asset.service";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);

function AssetList() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const columns = [
        /*{"field":"division_short_name","editable":false,"hide":false ,"headerName":"Role Short Name","width":130,
        renderCell: (params) => (
          <a href={`/division/view?divid=${params.value}`}>{params.value}</a>
        ) },*/
        {"field": "role_short_name", "editable": false, "hide": false, "headerName": "Role Code", "width": 150},
        {"field": "role_name", "editable": false, "hide": false, "headerName": "Role Name", "width": 250},
        {"field": "company_name", "editable": false, "hide": false, "headerName": "Company Name", "width": 350},
        {
            "field": "created_at", "editable": false, "hide": false, "headerName": "create Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },

        {
            field: 'actions', headerName: 'Actions', width: 120, renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'roleid': params.row.id,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (
                    <span>
                        <a href={`/role/add?roleid=${EncryptID}`} className="p-2">
                        <button type="button" className="btn btn-primary btn-sm">
                            <i className="bi bi-pencil"></i></button></a>
                        <button
                            type="button"
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => handleDeleteClick(params.row.id)}
                        >
                            <i className="bi bi-trash"></i>
                        </button>
                    </span>
                );
            }
        }

    ];

    const handleDeleteClick = (roleId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this role?');

        if (confirmDelete) {
            setLoading(true);

            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(
                JSON.stringify({
                    param_data: { roleId },
                    authToken: sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();
            let oData = { token: EncryptData };

            deleteRole(oData)
                .then(function (res) {
                    if (res?.data?.errors?.errno > 0) {
                        setErrMsg(res.data.message);
                        setSuccess(false);
                    } else {
                        setSuccess(res.data.message);
                        window.location.reload();
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(
            JSON.stringify({
                authToken: sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();
        let oData = { token: EncryptData };

        getRoleList(oData)
            .then((res) => {
                if (res?.data?.data?.length) setItems(res.data.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // readRole();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Role List</h5></div>

            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}
        </div>
    )
}

export default AssetList
