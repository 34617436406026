import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import imageCompression from 'browser-image-compression';
import Select from 'react-select';
import FromFieldText from '../../../atoms/FromFieldText';
import './SingleAsset.css';
import { getAllAssetsTag, getAssetsImages, getInvoices, getSTPI, getSuppliers,
    saveSingleImage, getAssetsImagesCount , getAllImagesByTag,getAllImagesByInvoice } from '../../../services/asset.service';

//import FromFieldLabel from '../../../atoms/FromFieldLabel';
import * as CryptoJS from "crypto-js";
const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
//const cors = require("cors");
//app.use(cors(corsOptions));
export default function UploadAssetImage() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const elementRef = useRef();

    useEffect(() => {
        if (Tag !== '' && Tag !== null) {
            getAssetsImagesInfo(Tag);
            //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAllAssetsTag(oData).then(res => {
            //console.log(res);
            if (res?.data?.data?.length) setAllAssetsData(res.data.data);
        });
        getSuppliers(oData).then(res => {
            if (res?.data?.data?.length) setSuppliersData(res.data.data);
        });
    }, []);
    const getAssetsImagesInfo = function (Tag) {
        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'tag': Tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            getAssetsImages(oData1).then(function (res) {
                if (res?.data?.data) {
                    //console.log(res.data.data);
                    //setImgDesc(stpiInfo.year_of_addition);
                    //setTag(stpiInfo.tag);
                }
            });
        } catch (err) {
            //setAssetInfo(''); 
        }
    }
    //const userRef = useRef();
    const errRef = useRef();
    const [imgDesc, setImgDesc,] = useState('');
    const [ErrImgDesc, setErrImgDesc] = useState(false);
    const [uploadassetimage, setUploadAssetImage] = useState([]);
    const [ErrUploadAssetImage, setErrUploadAssetImage] = useState(false);

    const [tag, setTag] = useState('');
    const [supplier, setSupplier] = useState('');
    const [invoice, setInvoice] = useState('');

    const [ErrTag, setErrTag] = useState(false);
    const [ErrInvoice, setErrInvoice] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [assetInfo, setAssetInfo] = useState({});
    const [allAssets, setAllAssetsData] = useState([]);
    const [suppliers, setSuppliersData] = useState([]);
    const [invoices, setInvoicesData] = useState([]);
    const [imgdimension, setImgDimension] = useState([]);
    const [imgFile, setImgFile] = useState([]);
    const [imgTypes, setImgTypes] = useState([]);
    const [imageCount, setImageCount] = useState(0);
    const [imagelist, setImageList] = useState([]);
    const [uploadfrontimage, setUploadFrontImage] = useState(null);
    const [ErrUploadFrontImage, setErrUploadFrontImage] = useState(false);
    const [ErrUploadMaxImage, setErrUploadMaxImage] = useState(false);
    const [uploadbackimage, setUploadBackImage] = useState(null);
    const [ErrUploadBackImage, setErrUploadBackImage] = useState(false);
    const [uploadtopimage, setUploadTopImage] = useState(null);
    const [ErrUploadTopImage, setErrUploadTopImage] = useState(false);
    const [uploadbottomimage, setUploadBottomImage] = useState(null);
    const [ErrUploadBottomImage, setErrUploadBottomImage] = useState(false);
    const [uploadleftimage, setUploadLeftImage] = useState(null);
    const [ErrUploadLeftImage, setErrUploadLeftImage] = useState(false);
    const [uploadrightimage, setUploadRightImage] = useState(null);
    const [ErrUploadRightImage, setErrUploadRightImage] = useState(false);
    const [uploadotherimage, setUploadOtherImage] = useState(null);
    const [ErrUploadOtherImage, setErrUploadOtherImage] = useState(false);

    const maxFiles = 7-imageCount; // Maximum number of files allowed
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    
    const handleUploadFrontImage = (e) => {

        const files = e.target.files;
        if(files[0]){
        //alert(files[0].type);

        if (!allowedTypes.includes(files[0].type)) {
            setErrUploadFrontImage('Only JPG, JPEG, and PNG files are allowed.');
            return;
        }
        // Check file size
        if (files[0].size > maxFileSize) {
             setErrUploadFrontImage('File size must be less than 5MB.'); 
             return;
        }
        // Check file size and count
        if (files.length > maxFiles) {
            setErrUploadMaxImage(`You can only upload a maximum of ${maxFiles} files.`);
            return;
        }
        setImgFile((prev) => [...prev, { type: 'front', file: files[0] }]);
        setUploadFrontImage(files[0]);
        }
        
    }
    const handleUploadBackImage = (e) => {
        const files = e.target.files;
        if(files[0]){
           
            if (!allowedTypes.includes(files[0].type)) {
                setErrUploadBackImage('Only JPG, JPEG, and PNG files are allowed.');
                return;
            }
            // Check file size
            if (files[0].size > maxFileSize) {
                setErrUploadBackImage('File size must be less than 5MB.'); 
                return;
            }
            // Check file size and count
            if (files.length > maxFiles) {
                setErrUploadMaxImage(`You can only upload a maximum of ${maxFiles} files.`);
                return;
            }
            setImgFile((prev) => [...prev, { type: 'back', file: files[0] }]);
        }
    }
    const handleUploadTopImage = (e) => {
        const files = e.target.files;
        if(files[0]){
            
            if (!allowedTypes.includes(files[0].type)) {
                setErrUploadTopImage('Only JPG, JPEG, and PNG files are allowed.');
                return;
            }
            // Check file size
            if (files[0].size > maxFileSize) {
                setErrUploadTopImage('File size must be less than 5MB.'); 
                return;
            }
            // Check file size and count
            if (files.length > maxFiles) {
                setErrUploadMaxImage(`You can only upload a maximum of ${maxFiles} files.`);
                return;
            }
            setImgFile((prev) => [...prev, { type: 'top', file: files[0] }]);
        }
    }
    const handleUploadBottomImage = (e) => {
        const files = e.target.files;
        if(files[0]){
            
            if (!allowedTypes.includes(files[0].type)) {
                setErrUploadBottomImage('Only JPG, JPEG, and PNG files are allowed.');
                return;
            }
            // Check file size
            if (files[0].size > maxFileSize) {
                setErrUploadBottomImage('File size must be less than 5MB.'); 
                return;
            }
            // Check file size and count
            if (files.length > maxFiles) {
                setErrUploadMaxImage(`You can only upload a maximum of ${maxFiles} files.`);
                return;
            }
            setImgFile((prev) => [...prev, { type: 'bottom', file: files[0] }]);
        }
    }
    const handleUploadLeftImage = (e) => {
        const files = e.target.files;
        if(files[0]){
            
            if (!allowedTypes.includes(files[0].type)) {
                setErrUploadLeftImage('Only JPG, JPEG, and PNG files are allowed.');
                return;
            }
            // Check file size
            if (files[0].size > maxFileSize) {
                setErrUploadLeftImage('File size must be less than 5MB.'); 
                return;
            }
            // Check file size and count
            if (files.length > maxFiles) {
                setErrUploadMaxImage(`You can only upload a maximum of ${maxFiles} files.`);
                return;
            }
            setImgFile((prev) => [...prev, { type: 'left', file: files[0] }]);
        }
    }
    const handleUploadRightImage = (e) => {
        const files = e.target.files;
        if(files[0]){
        
            if (!allowedTypes.includes(files[0].type)) {
                setErrUploadRightImage('Only JPG, JPEG, and PNG files are allowed.');
                return;
            }
            // Check file size
            if (files[0].size > maxFileSize) {
                setErrUploadRightImage('File size must be less than 5MB.'); 
                return;
            }
            // Check file size and count
            if (files.length > maxFiles) {
                setErrUploadMaxImage(`You can only upload a maximum of ${maxFiles} files.`);
                return;
            }
            setImgFile((prev) => [...prev, { type: 'right', file: files[0] }]);
        }
    }
    const handleUploadOtherImage = (e) => {
        const files = e.target.files;
        if(files[0]){
            
            if (!allowedTypes.includes(files[0].type)) {
                setErrUploadOtherImage('Only JPG, JPEG, and PNG files are allowed.');
                return;
            }
            // Check file size
            if (files[0].size > maxFileSize) {
                setErrUploadOtherImage('File size must be less than 5MB.'); 
                return;
            }
            // Check file size and count
            if (files.length > maxFiles) {
                setErrUploadMaxImage(`You can only upload a maximum of ${maxFiles} files.`);
                return;
            }
            setImgFile((prev) => [...prev, { type: 'other', file: files[0] }]);

        }
    }
    
    // Calculate the remaining number of files
    const remainingFiles = maxFiles - uploadassetimage.length;


    const optionList = [];
    allAssets && allAssets.length > 0 ? (
        allAssets.map((option) => (
            optionList.push({value: option.tag, label: option.tag})
        ))
    ) : (
        optionList.push({value: "", label: "No Data"})
    )

    const [selectedOptions, setSelectedOptions] = useState();

    function handleSelect(data) {
        setSelectedOptions(data);
        setTag(data.value);
        // Fetch image count for the selected tag
        fetchImageCount(data.value);

    }

    const fetchImageCount = (selectedTag) => {

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': selectedTag,},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData3 = {'token': EncryptData}

        // Make an API call to get the image count
        getAssetsImagesCount(oData3).then(res => {

            // Assuming the API response contains image count in data property
            const imageCount = res.data.data[0]?.count;

            // Update the state with the fetched image count
            setImageCount(imageCount);
        }).catch(error => {
            console.error('Error fetching image count:', error);
            // Handle error if needed
        });
    };
    const fetchInvoiceCount = (invoice_no) => {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'tag': tag,'invoice_no': invoice_no,'supplier_name': supplier,},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData3 = {'token': EncryptData}
            // Make an API call to get the image count
            getAssetsImagesCount(oData3).then(res => {
                // Assuming the API response contains image count in data property
                const imageCount = res.data.data[0]?.count;
                // Update the state with the fetched image count
                setImageCount(imageCount);
            }).catch(error => {
                console.error('Error fetching image count:', error);
                // Handle error if needed
            });
        };
    //get supplier list
    const supplierList = [];
    suppliers && suppliers.length > 0 ? (
        suppliers.map((option) => (
            supplierList.push({value: option.supplier_name, label: option.supplier_name})
        ))
    ) : (
        supplierList.push({value: "", label: "No Data"})
    )

    const [supplierOptions, setSupplierOptions] = useState();

    function handleSupplier(data) {
        setSupplierOptions(data);
        //console.log(data.value);
        setSupplier(data.value);
        //get invoice_number
        var oParam1 = {
            'supplier_name': data.value,
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': oParam1,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData2 = {'token': EncryptData}
        getInvoices(oData2).then(res => {
            if (res?.data?.data?.length) setInvoicesData(res.data.data);
        });
    }

    //get Invoice list
    const invoiceList = [];
    invoices && invoices.length > 0 ? (
        invoices.map((option) => (
            invoiceList.push({value: option.invoice_no, label: option.invoice_no})
        ))
    ) : (
        invoiceList.push({value: "", label: "No Data"})
    )

    const [invoiceOptions, setInvoiceOptions] = useState();

    function handleInvoice(data) {
        setInvoiceOptions(data);
        //console.log(data.value);
        setInvoice(data.value);
        fetchInvoiceCount(data.value);
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag,'invoice_no': data.value,'supplier_name': supplier,},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData3 = {'token': EncryptData}
        getAllImagesByInvoice(oData3).then(function (res) {
            if (res.status === 200) {
                var data = res?.data?.data;
                
                if (data.length) {
                    console.log('getAllImagesByInvoice'); console.log(res.data.data);
                    setImageList(data);
                }
            }
        });
    }

    //subit image form
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true; //alert(valid_Name);
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(imgDesc);
        if (tag == "" && invoice == '') {
            setErrInvoice('Tag OR Invoice is required');
            valid_Name = false;
        } else {
            setErrInvoice('');
        }
        /*if (!TEXT_REGEX.test(tag)) { 
			setErrTag('Tag is required'); valid_Name=false;
			//return;
		}else{ setErrTag('');  }
        
        if (!TEXT_REGEX.test(invoice)) { 
            setErrInvoice('Invoice is required'); valid_Name=false;
            //return;
        }else{ setErrInvoice('');  } 
        */
        if (!TEXT_REGEX.test(imgDesc)) {
            setErrImgDesc('Image description is required');
            valid_Name = false;
            //return;
        } else {
            setErrImgDesc('');
        }
        //alert(uploadfrontimage);
        if(imagelist.filter(option => option.asset_dimension === "front").length == 0){
        if (uploadfrontimage ==  null || uploadfrontimage == '') { //alert('uploadassetimage');
            setErrUploadFrontImage('Upload asset image is required');
            valid_Name = false;
            //return;
        } else {
            setErrUploadFrontImage('');
        } }else{ setErrUploadFrontImage(''); }
        if(imgFile.length==0){
            setErrUploadMaxImage('Image is required');
            valid_Name = false;
        }else{ setErrUploadMaxImage(''); }
        if (valid_Name) { //alert('validName');
            saveData(); //submit form
        } else {
            //alert(valid_Name);
            return false;
        }
    }
    const resetAssetInfo = function () {
        setTag('');setSelectedOptions('');setImgDesc('');
        setUploadFrontImage(''); setUploadBackImage(''); setUploadTopImage('');
        setUploadBottomImage(''); setUploadLeftImage(''); setUploadRightImage(''); 
        setUploadOtherImage('');
        
    }
    const getTagInfo = function () {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData3 = {'token': EncryptData}
        getSTPI(oData3).then(function (res) {
            //console.log(res.data.data);
            var aInfo = {};
            if (res?.data?.data?.assetInfo) {
                setErrMsg('');
                aInfo = res.data.data.assetInfo;
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
            } else {
                setAssetInfo({});
                setErrMsg('Assets Not Found');
            }

        });
        getAllImagesByTag(oData3).then(function (res) {
            if (res.status === 200) {
                var data = res?.data?.data;
                
                if (data.length) {
                    console.log('getAllImagesByTag'); console.log(res.data.data);
                    setImageList(data);
                }
            }
        });
    }

    const saveData = function () {
        
            /*formData.append('tag',tag);
            formData.append('invoice_no',invoice);
            formData.append('supplier_name',supplier);
            formData.append('asset_image_desc',imgDesc); 
            formData.append('upload_dir','images'); 
            formData.append('created_by',sessionStorage.getItem('LoggedInUserId'));
            //formData.append('images[front]', uploadfrontimage);
            //formData.append('images[back]', uploadbackimage);
            const files = [uploadfrontimage, uploadbackimage];
            files.forEach((file) => formData.append("images[]", file));
            console.log(imgFile);*/
            
            
        try { const formData = new FormData();
            var types = [];
            imgFile.forEach(({ file, type }, index) => {
                formData.append("images[]", file);
                //formData.append("dimension[]", type); // Store dimension or type as needed
                //setImgTypes((prev) => [...prev,type]);
                types.push(type);
              });
            const jsonData = {
                tag,
                invoice_no: invoice,
                supplier_name: supplier,
                asset_image_desc: imgDesc,
                upload_dir: "images",
                created_by: sessionStorage.getItem("LoggedInUserId"),
                authToken: sessionStorage.getItem("LoggedInToken"),
                companyshortname: sessionStorage.getItem("LoggedInCompanyShortName"),
                users:{ 
                    'company_id':sessionStorage.getItem('LoggedInCompanyId'),
                    'department_id':sessionStorage.getItem('LoggedInDepartmentId'),
                    'division_id':sessionStorage.getItem('LoggedInDivisionId'),
                }
              };
              const objAudit = {'audit_module':'Upload Images','audit_method':'created','audit_tag':tag,'old_values':'','new_values':JSON.stringify(jsonData)};
              jsonData.audit_params = objAudit;
              jsonData.dimension = types;
              const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
              
              formData.append("metadata", encryptedData);

                saveSingleImage(formData, { headers: { 'Content-Type': 'multipart/form-data' }, }).then(function (res) {
                    if ((typeof (res.data.errors) !== "undefined") && res.data.errors.errno > 0) {
                        setErrMsg(res.data.message);
                        setSuccess(false);
                    } else {
                        setSuccess(res.data.message);
                        setDisabled(true);
                        setErrMsg(false);
                        setErrTag('');
                        resetAssetInfo();
                    }
                });
            //}
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    }

    return (
        <div className='container'>
            <div className='row single__assets' ref={elementRef}>
                <div className="col">
                    <h5>Upload Asset Images</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandetory field.
                </div>
                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    (Tag !== '' && Tag !== null) ? <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div> : <div className='row'>
                        <div className='col-4'><h5 className='align-right'>TAG </h5></div>
                        <div className='col-4'>
                            {/*<input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' />*/}
                            <Select options={optionList} placeholder="Select One" value={selectedOptions}
                                    onChange={handleSelect}/>
                            <span className="invalid-feedback"> {ErrTag} </span></div>

                        <div className='col-4'>
                            <button onClick={getTagInfo} className='btn btn-primary btn-md'><i
                                className="bi bi-search"></i> Search
                            </button>
                            &nbsp;<a href={'/upload-image-new'}>
                            <button className='btn btn-secondary btn-md'><i className="bi bi-arrow-clockwise"></i> Reset</button>
                        </a>&nbsp;</div>

                        <div className='col-4'></div><div className='col-2'><h5>OR</h5></div><div className='col-4'></div>
                        <div class="filter-container"><div className='row'>

                        <div className='col-2'>
                            <label>Search by Supplier</label>
                        </div>
                        <div className='col-3'>
                            <Select options={supplierList} placeholder="Select One" value={supplierOptions}
                                    onChange={handleSupplier}/>
                        </div>
                        <div className='col-2'>
                            <label>Search by Invoice No.</label>
                        </div>
                        <div className='col-3'>
                            <Select options={invoiceList} placeholder="Select One" value={invoiceOptions}
                                    onChange={handleInvoice}/><span className="invalid-feedback"> {ErrInvoice} </span>
                        </div>
                        <div className='col-3'>
                            </div>
                        </div></div>
                    </div>


                }

            </div>
            <div className='row'></div>
            <div className="row">

                <div className="col-6">
                    <FromFieldText
                        label="TAG"
                        inputValue={tag ? tag : ''}
                        readOnly="readonly"
                    />
                </div>
                <div className='col-6'></div>
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className='row single__asset__form'>
                    <div className="col-6">
                        <FromFieldText label="Image Description" star="*"
                                       inputValue={imgDesc}
                                       setInputValue={setImgDesc}
                            //checkInputValue={checkCharcterOnly}
                                       setErrorMsg={ErrImgDesc}
                        />

                    </div>
                    <div className='col-6'>

                    </div>
                </div>
                <div className='row'>
                <div className="col-6">
                    <div className='row single__asset__form'>
                        <div className="col-6">
                            <div className="label">
                                <label> Upload Front View Image </label>
                                <span className="star error">  </span>
                            </div>
                            
                        </div>
                        <div className='col-6'>
                        {imagelist.filter(option => option.asset_dimension === "front").length > 0 ? (
                        <div>
                            <input type="file" name='images' disabled="disabled" onChange={handleUploadFrontImage}/>
                            <span className="invalid-feedback"> {ErrUploadFrontImage} </span>
                            </div>
                        
                        ):(
                            <div>
                                <input type="file" name='images' onChange={handleUploadFrontImage}/>
                                <span className="invalid-feedback"> {ErrUploadFrontImage} </span>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='row single__asset__form'>
                        <div className="col-6">
                            <div className="label">
                                <label> Upload Back View Image </label>
                                <span className="star error">  </span>
                            </div>
                        </div>
                        <div className='col-6'>
                        {imagelist.filter(option => option.asset_dimension === "back").length > 0 ? (
                           <div>
                            <input type="file" name='images' disabled="disabled" onChange={handleUploadBackImage}/>
                            <span className="invalid-feedback"> {ErrUploadBackImage} </span>
                            </div>
                        ):(
                            <div>
                                <input type="file" name='images'  onChange={handleUploadBackImage}/>
                                <span className="invalid-feedback"> {ErrUploadBackImage} </span>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
                </div>
                <div className='row'>
                    <div className="col-6">
                    <div className='row single__asset__form'>
                        <div className="col-6">
                            <div className="label">
                                <label> Upload Left View Image </label>
                                <span className="star error">  </span>
                            </div>
                        </div>
                        <div className='col-6'>
                        {imagelist.filter(option => option.asset_dimension === "left").length > 0 ? (
                            <div>
                            <input type="file" name='images' disabled="disabled" onChange={handleUploadLeftImage}/>
                            <span className="invalid-feedback"> {ErrUploadLeftImage} </span>
                            </div>
                        ):(
                            <div>
                                <input type="file" name='images'  onChange={handleUploadLeftImage}/>
                                <span className="invalid-feedback"> {ErrUploadLeftImage} </span>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='row single__asset__form'>
                        <div className="col-6">
                            <div className="label">
                                <label> Upload Right View Image </label>
                                <span className="star error">  </span>
                            </div>
                        </div>
                        <div className='col-6'>
                        {imagelist.filter(option => option.asset_dimension === "right").length > 0 ? (
                           <div>
                            <input type="file" name='images' disabled="disabled" onChange={handleUploadRightImage}/>
                            <span className="invalid-feedback"> {ErrUploadRightImage} </span>
                            </div>
                        ):(
                            <div>
                                <input type="file" name='images'  onChange={handleUploadRightImage}/>
                                <span className="invalid-feedback"> {ErrUploadRightImage} </span>
                            </div>
                        )}
                            
                        </div>
                    </div>
                </div>
                </div>
                <div className='row'>
                    <div className="col-6">
                        <div className='row single__asset__form'>
                            <div className="col-6">
                                <div className="label">
                                    <label> Upload Top View Image </label>
                                    <span className="star error">  </span>
                                </div>
                            </div>
                            <div className='col-6'>
                            {imagelist.filter(option => option.asset_dimension === "top").length > 0 ? (
                                <div>
                                <input type="file" name='images' disabled="disabled" onChange={handleUploadTopImage}/>
                                <span className="invalid-feedback"> {ErrUploadTopImage} </span>
                                </div>
                            ):(
                                <div>
                                    <input type="file" name='images'  onChange={handleUploadTopImage}/>
                                    <span className="invalid-feedback"> {ErrUploadTopImage} </span>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='row single__asset__form'>
                            <div className="col-6">
                                <div className="label6">
                                    <label> Upload Bottom View Image </label>
                                    <span className="star error">  </span>
                                </div>
                            </div>
                            <div className='col-6'>
                            {imagelist.filter(option => option.asset_dimension === "bottom").length > 0 ? (
                                <div>
                                <input type="file" name='images' disabled="disabled" onChange={handleUploadBottomImage}/>
                                <span className="invalid-feedback"> {ErrUploadBottomImage} </span>
                                </div>
                            ):(
                                <div>
                                    <input type="file" name='images'  onChange={handleUploadBottomImage}/>
                                    <span className="invalid-feedback"> {ErrUploadBottomImage} </span>
                                </div>
                            )}
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-6">
                        <div className='row single__asset__form'>
                            <div className="col-6">
                                <div className="label">
                                    <label>Upload Other View Image </label>
                                    <span className="star error">  </span>
                                </div>
                            </div>
                            <div className='col-6'>
                            {imagelist.filter(option => option.asset_dimension === "other").length > 0 ? (
                                <div>
                                <input type="file" name='images' disabled="disabled" onChange={handleUploadOtherImage}/>
                                <span className="invalid-feedback"> {ErrUploadOtherImage} </span>
                                </div>
                            
                            ):(
                                <div>
                                    <input type="file" name='images' onChange={handleUploadOtherImage}/>
                                    <span className="invalid-feedback"> {ErrUploadOtherImage} </span>
                                </div>
                            )}
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div>
                            <p>Remaining attachments: {remainingFiles}</p>
                            <span className="invalid-feedback"> {ErrUploadMaxImage} </span>
                        </div>
                    </div>
                </div>
                
                <div className='row save__button text-center'>
                    <div className='col-md-12'>
                        {(Tag !== '' && Tag !== null) ? <div>
                                <button className="btn btn-primary btn-md">Update</button>
                                &nbsp;&nbsp;<a href={'/asset-details?tag=' + Tag}
                                               className="btn btn-secondary btn-md">Cancel</a></div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                      disabled={disabled}><i class="bi bi-upload"></i> UPLOAD </button>
                        }

                    </div>
                </div>
            </form>
        </div>
    )
}
