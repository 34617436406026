import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {
    getDepartmentList, getIRAssignmentData,
    getLastAssignmentNo,
    getSubControlDepartmentList,
    getSubDepartmentList,
    getUsersList,
    saveAssignemnt,
    saveAssignmentEquipment
} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromSpFieldTextAreaHari from "../../atoms/FromSpFieldTextAreaHari";

import AWS from 'aws-sdk';
import {getPendingIR } from '../../services/flagging.service';
import {getNotAllocatedFlag} from "../../services/asset.service";
// Configure AWS with your credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
function CreateNewIRAssignment() {
    const [activeTab, setActiveTab] = useState(0);
    const [validName, setValidName] = useState(true);
    const [loading, setLoading] = useState(true);
    const [listOptions1, setListData1] = useState([]);
    const [foundAssets, setFoundAssets] = useState();
    const [oldfoundAssets, setOldFoundAssets] = useState([]);
    const [name, setName] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [selectedRepairs, setSelectedRepairs] = useState([]);
    const [ErrSelectedRepairs, setErrSelectedRepairs] = useState('');
    const [error, setError] = useState(false);

    const [userId, setUsersId] = useState('');
    const [ErrUserID, setErrUserId] = useState('');

    const [formCategoryId, setFormCategoryId] = useState('');
    const [ErrFormCategoryId, setErrFormCategoryId] = useState('');

    const [formControlSubDepartmentID, setFormControlSubDepartmentID] = useState('');
    const [ErrFormControlSubDepartmentID, setErrFormControlSubDepartmentID] = useState('');

    const [assignedDate, setAssignedDate] = useState('');
    const [ErrAssignedDate, setErrAssignedDate] = useState('');

    const [endUserName, setEndUserName] = useState('');
    const [ErrEndUserName, setErrEndUserName] = useState('');

    const [descriptions, setDescriptions] = useState([]);  // Array to store descriptions
    const [ErrDescription, setErrDescription] = useState([]);

    const [userEmailID, setUserEmailID] = useState('');
    const [ErrUserEmailID, setErrUserEmailID] = useState('');

    const [irCategory, setIRCat] = useState('');

    const [assignmentNumber, setAssignmentNumber] = useState('');
    const [ErrAssignmentNumber, setErrAssignmentNumber] = useState('');

    const [detailProblem, setDetailProblem] = useState('');
    const [ErrDetailProblem, setErrDetailProblem] = useState('');

    const [uploadFile, setUploadFile] = useState('');
    const [uploadFileError, setUploadFileError] = useState('');

    const [uploadScreenShot, setUploadScrrenShot] = useState('');
    const [uploadScrrenShotError, setUploadScrrenShotError] = useState('');

    const [repairedIssue, setRepairedIssue] = useState('');
    const [ErrRepairedIssue, setErrRepairedIssue] = useState(false);

    const [accidentalDamage, setAccidentalDamage] = useState('');
    const [ErrAccidentalDamage, setErrAccidentalDamage] = useState(false);

    const [amcCovered, setAmcCovered] = useState('');
    const [ErrAmcCovered, setErrAmcCovered] = useState(false);

    const [showTextField, setShowTextField] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [ErrAdditionalInfo, setErrAdditionalInfo] = useState('');

    const [formControlDepartment, setFormControlDepartment] = useState([]);
    const [formControlSubDepartment, setFormControlSubDepartment] = useState([]);

    const [subDepartment, setSubDepartment] = useState('');
    const [ErrSubDepartment, setErrSubDepartment] = useState(false);

    const [subDepartments, setSubDepartments] = useState([]);

    const [userLists, setUserLists] = useState([]);

    const [isUpdate, setIsUpdate] = useState(false);

    const filter = (e) => {
        const keyword = e.target.value.toLowerCase(); // Convert input to lowercase
        setName(e.target.value);

        if (keyword !== '') {
            const results = oldfoundAssets.filter((asset) =>
                asset.tag.toLowerCase().startsWith(keyword) // Convert tag to lowercase for comparison
            );
            setFoundAssets(results);
        } else {
            setFoundAssets(oldfoundAssets);
        }
    };

    const resetUserInfo = () => {
        //assignmentNumber('');
        setFormCategoryId('');
        setAssignedDate('');
        setEndUserName('');
        setUserEmailID('');
        setSelectedRepairs('');
        setFormControlSubDepartmentID('');
        setUsersId('');
        setDescriptions('');
        setUserEmailID('');
        setAssignmentNumber('');
        setDetailProblem('');
        setRepairedIssue('');
        setAccidentalDamage('');
        setAmcCovered('');
        setShowTextField('');
        setAdditionalInfo('');
        setFormControlDepartment('');
    };

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Assignment_no = query.get('assignment_no');

    useEffect(() => {
        if (Assignment_no !== '' && Assignment_no !== null) {
            setIsUpdate(true);
            const decryptedBytes = CryptoJS.AES.decrypt(Assignment_no.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptCommentId = decrypt_data.assignment_no;
            getAssignmentInfo(decryptCommentId); // get comment info
        }
    }, [])

    const getAssignmentInfo = function (decryptCommentId) {

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'assignment_no': decryptCommentId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getIRAssignmentData(oData1).then(function (res) {
            if (res?.data?.data && res.data.data.length > 0) {
                var aInfo = res.data.data[0]; //  Access the first object in the array
                var aInfo1 = res.data.equipment; // Stores equipment list

                setIRCat(aInfo.cat_ir);
                setAssignmentNumber(aInfo.assignment_no);
                setAssignedDate(new Date(aInfo.assignedDate));
                setUserEmailID(aInfo.endUser_emailId);
                setEndUserName(aInfo.endUser_name);
                if (aInfo.repairs) {
                    setSelectedRepairs(
                        Array.isArray(aInfo.repairs)
                            ? aInfo.repairs.map(r => r.trim())
                            : aInfo.repairs.split(",").map(r => r.trim())
                    );
                } else {
                    setSelectedRepairs([]);
                }

                setRepairedIssue(aInfo.equipment_listed_repairs);
                setAccidentalDamage(aInfo.issue_caused);
                setAmcCovered(aInfo.under_warrenty);
                setAdditionalInfo(aInfo.under_warrenty_details);
                setDetailProblem(aInfo.problem_desc);
                setFormCategoryId(aInfo.control_department_id);
                setSubDepartment(aInfo.sub_control_department_id);
                setUsersId(aInfo.assigned_user_name);  //
                setActiveTab(1);
                if (aInfo1 && aInfo1.length > 0) {
                    const formattedEquipment = aInfo1.map((item) => ({
                        label: item.tag_name || "No Tag Name",  // Use `tag_name` correctly
                        value: item.tag || "No Tag", // Use `tag` correctly
                    }));

                    setListData1(formattedEquipment);
                    setDescriptions(aInfo1.map((item) => item.equipment_desc || ""));
                }

            } else {
                setErrMsg('Assignment no Not Found');
            }
        });
    }

    useEffect(() => {
        console.log("Selected Control Dept:", formCategoryId);
        console.log("Selected Sub Control Dept:", subDepartment);
        console.log("Selected User ID:", userId);
    }, [formCategoryId, subDepartment, userId]);

    useEffect(() => {
        setShowTextField(amcCovered === "Yes");
    }, [amcCovered]);

    const subDepartmentIdFromUrl = query.get('subDeptId');
    const [isSubDepartmentsLoaded, setIsSubDepartmentsLoaded] = useState(false);

    const userIdFromUrl = query.get('userIdd');
    const [isUsersListLoaded, setIsUsersListLoaded] = useState(false);

    useEffect(() => {

        const fetchData = async () => {

            try {
                const CdEncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data': {'only_self_department_id':sessionStorage.getItem('LoggedInDepartmentId')},
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                const oCdData = {'token': CdEncryptData};

                const res = await getDepartmentList(oCdData);
                if (res?.data?.data?.length) setFormControlDepartment(res.data.data);

                const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                const oData = {'token': EncryptData};

                let IrEncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data': {'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                let oIrData = { 'token': IrEncryptData };

                getPendingIR(oIrData).then((res) => {
                    if (res?.data?.data?.length) {
                        setFoundAssets(res.data.data);
                        setOldFoundAssets(res.data.data); // Correctly updating oldFoundAssets
                    }
                    setLoading(false);
                });

                getLastAssignmentNo(oData).then(res => {

                    let lastDinId = ((res.data.total) ? res.data.total : 0) + 1;
                    let newlastDinId = lastDinId.toString().padStart(4, '0');
                    let currentYear = new Date().getFullYear();
                    let divisionCode = sessionStorage.LoggedInDivisionCode;
                    //setAssignmentNumber('IRAI' + divisionCode + '-' + currentYear + newlastDinId);
                    setAssignmentNumber(prev => prev || ('IRAI' + divisionCode + '-' + currentYear + newlastDinId));
                });

            } catch (error) {
                console.error("Error fetching company profile list: ", error);
            }

        };

        fetchData();
    }, []);

    useEffect(() => {
        if (formCategoryId && formCategoryId !== '') {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'department_id': formCategoryId},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = {'token': EncryptData};

            getSubDepartmentList(oData).then(res => {
                if (res?.data?.data?.length) {
                    setSubDepartments(res.data.data);
                } else {
                    setSubDepartments([]);
                }
            }).catch(() => {
                setSubDepartments([]);
            });
        }
    }, [formCategoryId]);

    useEffect(() => {
        if (subDepartment && subDepartment !== '') {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'sub_department_id': subDepartment},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = {'token': EncryptData};

            getUsersList(oData).then(res => {
                if (res?.data?.data?.length) {
                    setUserLists(res.data.data); // Set user list
                    setIsUsersListLoaded(true);
                } else {
                    setUserLists([]); // Clear list if no data
                }
            }).catch(() => {
                setUserLists([]); // Handle errors
            });
        }
    }, [subDepartment]);

    const handleControlDepartment = (e) => {
        const departmentId = e.target.value;
        setFormCategoryId(departmentId); // Set department ID

        if (departmentId === '') {
            setSubDepartments([]); // Clear sub-departments if no department selected
            setUserLists([]); // Clear users list
        }
        const EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'dep_id':departmentId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData1 = {'token': EncryptData1};

        const res2 = getSubControlDepartmentList(oData1);
        if (res2?.data?.data?.length) setFormControlSubDepartment(res2.data.data);
    };

    const handleSubDepartment = (e) => {
        const subDepartmentId = e.target.value;
        setSubDepartment(subDepartmentId); // Set selected sub-department ID

        if (subDepartmentId === '') {
            setUserLists([]); // Clear users if no sub-department selected
        }
    };

    // Handle user selection (update only the selected user ID)
    const handleUsersList = (e) => {
        const selectedUserId = e.target.value;
        setUsersId(selectedUserId); // Correctly update the userId state
    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        if (listOptions1.length === 0) { //alert(listOptions1.length);
            setValidName(false);
            alert('Please select atleast one Asset');
            valid_Name = false;
        }
        if (!valid_Name) { //alert(valid_Name);
            return false;
        } else { //alert(mon); alert(fy);
            //saveData();
            handleNextClick();
        }
    }
    const handleNextClick = () => {
        // Handle the "Next" button click to move to the next tab
        setActiveTab(activeTab + 1);
    };

    const navigate = useNavigate(); //useNavigate inside component

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid = true;
        let errorMessages = [];

        if (!formCategoryId) {
            setErrFormCategoryId('Control department is required');
            errorMessages.push('Control department is required');
            valid = false;
        } else {
            setErrFormCategoryId('');
        }
        if (!userId) {
            setErrUserId('User Name is required');
            errorMessages.push('User Name is required');
            valid = false;
        } else {
            setErrUserId('');
        }
        /*descriptions.forEach((description, index) => {
            if (!description) {
                errorMessages[index] = 'Item Description is required';
                valid = false;
            } else {
                errorMessages[index] = '';  // Clear error if valid
            }
        });*/
        if (!assignmentNumber) {
            setErrAssignmentNumber('Assignment is required');
            errorMessages.push('Assignment is required');
            valid = false;
        } else {
            setErrAssignmentNumber('');
        }
        if (!assignedDate) {
            setErrAssignedDate('Assignment date is required');
            errorMessages.push('Assignment date is required');
            valid = false;
        } else {
            setErrAssignedDate('');
            console.log('assignedDate is valid');
        }
        if (!endUserName) {
            setErrEndUserName('End User name is required');
            errorMessages.push('End User name is required');
            valid = false;
        } else {
            setErrEndUserName('');
        }
        if (!userEmailID) {
            setErrUserEmailID('End User email id is required');
            errorMessages.push('End User email id is required');
            valid = false;
        } else {
            setErrUserEmailID('');
        }
        if (!detailProblem) {
            setErrDetailProblem('Enter Detailed Problem');
            errorMessages.push('Enter Detailed Problem');
            valid = false;
        } else {
            setErrDetailProblem('');
        }
        if (!selectedRepairs.length) {
            setErrSelectedRepairs('Please select at least one repair');
            errorMessages.push('Please select at least one repair');
            valid = false;
        } else {
            setErrSelectedRepairs('');
        }
        if (!repairedIssue) {
            setErrRepairedIssue('Please select yes or no');
            errorMessages.push('Please select yes or no');
            valid = false;
        } else {
            setErrRepairedIssue('');
        }

        if (!accidentalDamage) {
            setErrAccidentalDamage('Please select yes or no');
            errorMessages.push('Please select yes or no');
            valid = false;
        } else {
            setErrAccidentalDamage('');
            console.log('accidentalDamage is valid');
        }

        if (!amcCovered) {
            setErrAmcCovered('Please select yes or no');
            errorMessages.push('Please select yes or no');
            valid = false;
        } else {
            setErrAmcCovered('');
            console.log('amcCovered is valid');
        }

        if (amcCovered === "Yes" && !additionalInfo) {
            setErrAdditionalInfo('This field is required when AMC is covered');
            errorMessages.push('This field is required when AMC is covered');
            valid = false;
        } else {
            setErrAdditionalInfo('');
            console.log('additionalInfo is valid');
        }

        if (!valid) {
            console.log("Form submission errors:", errorMessages);
            //alert(`Error: Please fix the following issues:\n\n${errorMessages.join('\n')}`);
        } else {
            saveData();
            console.log("Form submitted successfully");
        }
    };

    const handleUploadFile = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 2 MB
        const maxFiles = 5;
        let errorMsg = '';
        let validFiles = [];

        if (files.length > maxFiles) {
            errorMsg = `You can only upload up to ${maxFiles} files.`;
        } else {
            for (let file of files) {
                if (!validFileTypes.includes(file.type)) {
                    errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                    break;
                }
                if (file.size > maxSize) {
                    errorMsg = 'File size should not exceed 5 MB.';
                    break;
                }
                validFiles.push(file);
            }
        }

        if (errorMsg) {
            setUploadFileError(errorMsg);
            setUploadFile([]);
        } else {
            setUploadFileError('');
            setUploadFile(validFiles);
        }
    };
    const handleUploadScreenshot = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 2 MB
        const maxFiles = 5;
        let errorMsg = '';
        let validFiles = [];

        if (files.length > maxFiles) {
            errorMsg = `You can only upload up to ${maxFiles} files.`;
        } else {
            for (let file of files) {
                if (!validFileTypes.includes(file.type)) {
                    errorMsg = 'Only JPEG, PNG, and PDF files are allowed.';
                    break;
                }
                if (file.size > maxSize) {
                    errorMsg = 'File size should not exceed 5 MB.';
                    break;
                }
                validFiles.push(file);
            }
        }

        if (errorMsg) {
            setUploadScrrenShotError(errorMsg);
            setUploadScrrenShot([]);
        } else {
            setUploadScrrenShotError('');
            setUploadScrrenShot(validFiles);
        }
    };
    const saveData = () => {
        try {

            const file = uploadFile[0];
            let img_name = '';
            if (file) {
                const fileName = file.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let img_name = assignmentNumber + '_' + '.' + fileExtension;

                uploadFileServer(file, img_name);

            }
            const screenshot = uploadScreenShot[0];
            let screenshot_name = '';
            if (file) {
                const fileName = screenshot.name;

                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let screenshot_name = assignmentNumber + '_' + '.' + fileExtension;

                uploadFileServer(screenshot, screenshot_name);

            }

            const formData = new FormData();
            let jsonData = {
                'cat_ir': "IRAI",
                'assignment_no': assignmentNumber,
                'assignedDate': assignedDate,
                'endUser_name': endUserName,
                'endUser_emailId': userEmailID,
                'repairs': selectedRepairs,
                'problem_desc': detailProblem,
                'upload_files': img_name,
                'upload_scrrenshots': screenshot_name,
                'equipment_listed_repairs': repairedIssue,
                'issue_caused': accidentalDamage,
                'under_warrenty': amcCovered,
                'under_warrenty_details': additionalInfo,
                'control_department_id': formCategoryId,
                'sub_control_department_id': subDepartment,
                'assigned_user_name': userId,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
                'companyshortname': sessionStorage.getItem("LoggedInCompanyShortName"),
                'company_id':sessionStorage.getItem('LoggedInCompanyId'),
                'department_id':sessionStorage.getItem('LoggedInDepartmentId'),
                'division_id':sessionStorage.getItem('LoggedInDivisionId'),
            }
            for (let i = 0; i < uploadFile.length; i++) {
                const file = uploadFile[i];
                formData.append("upload_image[]", file);
            }
            for (let i = 0; i < uploadScreenShot.length; i++) {
                const file = uploadScreenShot[i];
                formData.append("upload_screenShot[]", file);
            }
            //console.log(oData);
            var objAudit = {'audit_module':'Internal Repair','audit_method':'created','audit_tag':listOptions1,'old_values':'','new_values':formData}
            jsonData.audit_params = objAudit;
            jsonData.authToken = sessionStorage.getItem('LoggedInToken');
            //jsonData.dimension = types;
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            formData.append("metadata", encryptedData);

            saveAssignemnt(formData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            var obj1 = {

                'cat_ir': "IRAI",
                'assignment_no': assignmentNumber,
                'tag': listOptions1,
                'tag_name': listOptions1,
                'equipment_desc': descriptions,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }

            let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData1};
            saveAssignmentEquipment(oData1).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const uploadFileServer = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'image/' + img_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                return params.name;
            }
        });
    };

    const repairs = [
        "Touchpad", "Keyboard", "Fan", "System Board", "Optical drive",
        "AC Adapter", "Hinge", "CD-ROM", "Printer", "Application problem",
        "No power", "Won't boot (software)", "Other", "Mouse", "LCD",
        "Hard Drive", "Unit was reloaded", "Heat sink", "CPU", "RAM memory",
        "Removable drive", "Network card", "Virus", "Won't boot (hardware)"
    ];

    const handleCheckboxChange = (repair) => {
        if (selectedRepairs.includes(repair)) {
            setSelectedRepairs(selectedRepairs.filter(item => item !== repair));
        } else {
            setSelectedRepairs([...selectedRepairs, repair]);
        }
        setError(false);
    };

    const handleRepairedIssue = (e) => {
        setRepairedIssue(e.target.value);
    }
    const handleAccidentalDamage = (e) => {
        setAccidentalDamage(e.target.value);
    }
    const handleAMC = (event) => {
        const value = event.target.value;
        setAmcCovered(value);

        // Show text field only if "Yes" is selected
        setShowTextField(value === "Yes");
    };

    const handleMoveList1 = (e) => {
        const selectedOptions = [];
        //alert('dd');
        var options1 = document.getElementById('lstBox1').options;
        //console.log(options1);
        for (var i = 0, l = options1.length; i < l; i++) {
            if (options1[i].selected) {
                var option1 = {
                    label: options1[i].label,
                    value: options1[i].value,
                }
                if (pushListOptions(option1)) {
                    listOptions1.push(option1);
                }
            }
        } //setListData2(listOptions1);
        setListData1((prevList) => prevList.filter((option) => !selectedOptions.find((selected) => selected.value === option.value)));

        //console.log('listOptions1');console.log(listOptions1);
    }
    const handleMoveList2 = (e) => {
        const selectedOptions2 = [];
        var options2 = document.getElementById('lstBox2').options;
        //console.log(options1);
        for (var i = 0, l = options2.length; i < l; i++) {
            if (options2[i].selected) {
                var option1 = {
                    label: options2[i].label,
                    value: options2[i].value,
                }
                if (pushListOptions(option1)) {
                    listOptions1.push(option1);
                }
            }
        } //setListData2(listOptions1);
        setListData1((prevList) => prevList.filter((option) => !selectedOptions2.find((selected) => selected.value === option.value)));

        //console.log('listOptions1');console.log(listOptions1);
    }
    const handleAllMoveList1 = (e) => {
        //var listOptions = [];
        var options1 = document.getElementById('lstBox1').options;
        for (var i = 0, l = options1.length; i < l; i++) {
            var option1 = {
                label: options1[i].label,
                value: options1[i].value,
            }
            if (pushListOptions(option1)) {
                listOptions1.push(option1);
            }
            //listOptions1.push(option1);
        } //setListData2(listOptions1); console.log(listOptions2);
        setListData1(listOptions1);
    }
    const pushListOptions = (obj) => {
        var flag = 0;
        setLoading('..');
        listOptions1.forEach((elem) => {
            if (obj.value === elem.value && obj.label === elem.label) {
                flag = 1;
            }
        });
        setLoading(false);
        if (flag === 1) {
            return false;
        } else {
            return true;
        }
    };
    const handleAllRemove = (e) => {
        const confirmRemove = window.confirm("Are you sure you want to remove all TAG from list?");
        if (confirmRemove) {
            setListData1([]);
        }
    }

    const handleDescriptionChange = (index, value) => {
        setDescriptions((prevDescriptions) => {
            const updatedDescriptions = [...prevDescriptions];
            updatedDescriptions[index] = value;
            return updatedDescriptions;
        });
    };

    useEffect(() => {
        console.log("Updated listOptions1:", listOptions1);
    }, [listOptions1]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <h5>
                        {
                            isUpdate
                                ? `Update New IR Assignment - ${irCategory === 'IRAI' ? 'IT' : 'Others'}`
                                : `Create New IR Assignment - ${irCategory === 'IRAI' ? 'IT' : irCategory ? 'Others' : 'IT'}`
                        }

                    </h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory fields.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i>{success}
                    </div>
                ) : (
                    errMsg && (
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}
                        </div>
                    )
                )}
            </div>

            <div className="tab-content">
                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>
                    <div>
                        <>
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <div className="subject-info-box-1">
                                    <input
                                        type="search"
                                        value={name}
                                        onChange={filter}
                                        className="form-control"
                                        placeholder="Filter"
                                    />
                                    <select multiple="multiple" id="lstBox1" className="form-select form-select-sm">
                                        {foundAssets?.length > 0 ? (
                                            foundAssets.map((option) => (
                                                <option key={option.tag} value={option.tag}>
                                                    {option.tag}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">No Data</option> // Handles empty state
                                        )}

                                    </select>
                                    <p>Total count: {foundAssets?.length || 0}</p>
                                </div>
                            )}
                        </>

                        {/*{(!foundAssets || foundAssets.length === 0) && <p>No results</p>}*/}


                        <div className="subject-info-arrows text-center">{loading ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">{loading}</span>
                            </div>) : ('')}
                            {/*<button onClick={filter} value="" className="btn btn-info btn-sm refresh">Refresh</button>*/}
                            <br/><br/>
                            <input type='button' id='btnAllRight' onClick={handleAllMoveList1} value='Add All'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnRight' onClick={handleMoveList1} value='>'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnRight' onClick={handleMoveList2} value='<'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnAllLeft' onClick={handleAllRemove} value='Remove All'
                                   className="btn btn-default"/>
                        </div>
                        <div className="subject-info-box-2">
                            <select multiple="multiple" id='lstBox2' className="form-select form-select-sm">
                                {listOptions1 && listOptions1.length > 0 ? (
                                    listOptions1.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))
                                ) : (
                                    <h1>No results</h1>
                                )}
                            </select>
                            <p>Total count: {listOptions1.length}</p>
                        </div>


                        <div className="clearfix"></div>


                        <form name="" onSubmit={handleSubmit1}>
                            <button className="btn btn-primary btn-md float-end"> Next</button>
                        </form>

                    </div>
                </div>
                <div className={`container tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>
                    <form onSubmit={handleSubmit}>

                        <div className="row mt-2">

                            <div className='col-md-6'>
                                <FromFieldDate
                                    label="Assigned Date" star="*" placeholder="YYYY-MM-DD"
                                    inputValue={assignedDate}
                                    setInputValue={setAssignedDate}
                                    setErrorMsg={ErrAssignedDate}
                                />
                            </div>
                            <div className="col-6">
                                <FromFieldText
                                    readOnly={true}
                                    star="*"
                                    label="Assignment Number"
                                    inputValue={assignmentNumber}
                                    setInputValue={setAssignmentNumber}
                                    setErrorMsg={setErrAssignmentNumber}
                                    errorMsg={ErrAssignmentNumber}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">

                            <div className='col-md-6'>
                                <FromFieldText
                                    star="*"
                                    label="End User Email ID"
                                    placeholder="End User Email ID"
                                    inputValue={userEmailID}
                                    setInputValue={setUserEmailID}
                                    setErrorMsg={ErrUserEmailID}
                                />
                            </div>
                            <div className="col-6">
                                <FromFieldText
                                    star="*"
                                    label="End User Name"
                                    placeholder="End User Name"
                                    inputValue={endUserName}
                                    setInputValue={setEndUserName}
                                    setErrorMsg={ErrEndUserName}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <h6 className="text-center">List of Equipment</h6>
                            <table className="table table-bordered">
                                <thead className="text-center">
                                <tr>
                                    <th>Sl #</th>
                                    <th>TAG No</th>
                                    <th>TAG Name</th>
                                    <th>Item Description</th>
                                </tr>
                                </thead>
                                <tbody className="text-center">
                                {listOptions1.length > 0 ? (
                                    listOptions1.map((option, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{option.value}</td> {/* Correctly mapped to tag */}
                                            <td>{option.label}</td> {/* Correctly mapped to tag_name */}
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name={`itemDescription[${index}]`}
                                                    placeholder="Description"
                                                    value={descriptions[index] ?? ""}
                                                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                                />
                                                {ErrDescription[index] && (
                                                    <span className="error-message">{ErrDescription[index]}</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No Data</td>
                                    </tr>
                                )}
                                </tbody>

                            </table>
                        </div>

                        <div className="p-3">
                            <label><strong>Please Select the Repairs<span className="error">*</span></strong></label>
                            <div className="row">
                                {repairs.map((repair, index) => (
                                    <div className="col-6 col-md-4 mb-2" key={index}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`repair-${index}`}
                                                onChange={() => handleCheckboxChange(repair)}
                                                checked={Array.isArray(selectedRepairs) && selectedRepairs.some(selected => selected.toLowerCase() === repair.toLowerCase())}
                                            />
                                            <label className="form-check-label" htmlFor={`repair-${index}`}>
                                                {repair}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {ErrSelectedRepairs && (
                                <div className="text-danger">{ErrSelectedRepairs}</div>
                            )}
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label> Was the equipment listed above been repaired for the same issue? <span
                                    className="error">*</span></label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-3">
                                <select onChange={handleRepairedIssue} name="alternative_verification"
                                        className="form-select form-select-sm">
                                    <option value="">Select Repaired Issue</option>
                                    <option value="Yes"
                                            selected={repairedIssue === "Yes" ? "selected" : ''}>Yes
                                    </option>
                                    <option value="No"
                                            selected={repairedIssue === "No" ? "selected" : ''}>No
                                    </option>
                                </select>
                                <span className="invalid-feedback">{ErrRepairedIssue} </span>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label> Was the issue caused by accidental damage like water spill, equipment was
                                    dropped?<span className="error">*</span></label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-3">
                                <select onChange={handleAccidentalDamage} name="alternative_verification"
                                        className="form-select form-select-sm">
                                    <option value="">Select Accidental Damage</option>
                                    <option value="Yes"
                                            selected={accidentalDamage === "Yes" ? "selected" : ''}>Yes
                                    </option>
                                    <option value="No"
                                            selected={accidentalDamage === "No" ? "selected" : ''}>No
                                    </option>
                                </select>
                                <span className="invalid-feedback">{ErrAccidentalDamage} </span>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label>Is asset covered under warranty/AMC/Insurance?<span
                                    className="error">*</span></label>
                                <span className="star error"></span>
                            </div>
                            <div className="col-md-3">
                                <select onChange={handleAMC} name="amcCovered" className="form-select form-select-sm">
                                    <option value="">Select Accidental Damage</option>
                                    <option value="Yes" selected={amcCovered === "Yes"}>Yes</option>
                                    <option value="No" selected={amcCovered === "No"}>No</option>
                                </select>
                                <span className="invalid-feedback">{ErrAmcCovered}</span>
                            </div>
                        </div>

                        {/* Additional Information div */}
                        {showTextField && (
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <label>Additional Information<span className="error">*</span></label>
                                </div>
                                <div className="col-md-6">
                                    <FromSpFieldTextAreaHari
                                        maxLength={2000}
                                        numRows={5}
                                        className="form-control"
                                        inputValue={additionalInfo}
                                        setInputValue={setAdditionalInfo}
                                        onChange={(e) => setAdditionalInfo(e.target.value)}
                                        errorMsg={ErrAdditionalInfo}
                                    />
                                    <span className="invalid-feedback">{ErrAdditionalInfo}</span>
                                </div>
                            </div>
                        )}
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label>Brief description of the problem<span className="error">*</span></label>
                            </div>
                            <div className='col-md-6'>
                                <FromSpFieldTextAreaHari maxLength="2000" numRows="5"
                                                         inputValue={detailProblem}
                                                         setInputValue={setDetailProblem}
                                                         setErrorMsg={ErrDetailProblem}
                                />
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label>Upload Files of the Problem (if applicable)</label>
                                    <br/>
                                    <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed.
                                        Maximum
                                        size: 5 MB. Maximum 5 files allowed at
                                        once. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                                    <input type="file" name="uploadFile" multiple onChange={handleUploadFile}/>
                                    <span className="invalid-feedback">{uploadFileError}</span>
                                </div>


                                <div className="col-md-12 mt-3">
                                    <label>Upload Screenshots of the Problem (if
                                        applicable) &nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <br/>
                                    <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed.
                                        Maximum
                                        size: 5 MB. Maximum 5 files allowed
                                        once.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                                    <input type="file" name='uploadScreenshot' multiple
                                           onChange={handleUploadScreenshot}/>
                                    <span className="invalid-feedback"> {uploadScrrenShotError} </span>
                                </div>

                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-12">

                            </div>

                        </div>

                        <div className="row mt-3">
                            <div className="label col-6">
                                <label>Assignment given to: Select Control Dept </label><span
                                className="star error"> *</span>
                            </div>
                            <div className="item col-3">
                                <select className="form-select form-select-sm" onChange={handleControlDepartment}
                                        value={formCategoryId}>
                                    <option value="">Select Control Dept</option>
                                    {formControlDepartment && formControlDepartment.length > 0 ? (
                                        formControlDepartment.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.department_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrFormCategoryId} </span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="label col-6">
                                <label>Select Sub Control Dept </label><span className="star error"> *</span>
                            </div>
                            <div className="item col-3">
                                <select value={subDepartment} onChange={handleSubDepartment}
                                        className="form-select form-select-sm">
                                    <option value="">Select Sub Control Department</option>
                                    {subDepartments && subDepartments.length > 0 ? (
                                        subDepartments.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.sub_department_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrFormControlSubDepartmentID} </span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-6">
                                <label>Assign User Name<span className="error">*</span></label>
                                <span className="star error">  </span>
                            </div>
                            <div className="item col-3">
                                <select value={userId} onChange={handleUsersList}
                                        className="form-select form-select-sm">
                                    <option value="">Select User</option>
                                    {userLists && userLists.length > 0 ? (
                                        userLists.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name} / {option.email}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrUserID} </span>
                            </div>
                        </div>

                        {!Assignment_no ? (
                            <div className="row mt-4">
                                <div className="col-md-6 d-flex justify-content-center">
                                    <button onClick={handleBackClick} className="btn btn-secondary btn-sm ms-3">
                                        Back
                                    </button>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                    >
                                        Create Assignment
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="row mt-4">
                                <div className="col-md-6 d-flex justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm me-2"
                                        onClick={handleBackClick}
                                    >
                                        Back
                                    </button>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                    >
                                        Update Assignment
                                    </button>
                                </div>
                            </div>
                        )}

                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateNewIRAssignment;