import React, {useEffect, useRef, useState} from 'react';
import {getGatePassDetailByGatePassNo,  } from '../../services/asset.service';
import * as CryptoJS from "crypto-js";
import FromFieldTextArea from '../../atoms/FromFieldTextArea';
import {getLastGatePassNo} from '../../services/user.service';
import FromFieldText from "../../atoms/FromFieldText";
import FromFieldDate from "../../atoms/FromFieldDate";
import {useLocation} from "react-router-dom";


function SecurityViewGatePass() {
    const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [info, setInfo] = useState([]);
    const [gatePassNo, setGatePassNo] = useState('');
    const [ErrGatePassNo, setErrGatePassNo] = useState(false);
    const [gatePassDate, setGatePassDate] = useState('');
    const [ErrGatePassDate, setErrGatePassDate] = useState(false);
    const [catOfEmp, setCatOfEmp] = useState('');
    const [ErrCatOfEmp, setErrCatOfEmp] = useState(false);

    const [gatePassType, setGatePassType] = useState('');
    const [ErrGatePassType, setErrGatePassType] = useState(false);

    const [employeeID, setEmployeeID] = useState('');
    const [ErrEmployeeID, setErrEmployeeID] = useState(false);

    const [costCenter, setCostCenter] = useState('');
    const [ErrCostCenter, setErrCostCenter] = useState(false);

    const [titles, setTitles] = useState('');
    const [ErrTitles, setErrTitles] = useState(false);

    const [designation, setDesignation] = useState('');
    const [ErrDesignation, setErrDesignation] = useState(false);

    const [vendorName, setVendorName] = useState('');
    const [ErrVendorName, setErrVendorName] = useState(false);

    const [address, setAddress] = useState('');
    const [ErrAddress, setErrAddress] = useState(false);

    const [destinationCity, setDestinationCity] = useState('');
    const [ErrDestinationCity, setErrDestinationCity] = useState(false);

    const [destinationCountry, setDestinationCountry] = useState('');
    const [ErrDestinationCountry, setErrDestinationCountry] = useState(false);

    const [expectedDate, setExpectedDate] = useState('');
    const [ErrExpectedDate, setErrExpectedDate] = useState(false);

    const [reasonTakingOut, setReasonTakingOut] = useState('');
    const [ErrReasonTakingOut, setErrReasonTakingOut] = useState(false);

    const [descriptions, setDescription] = useState('');
    const [ErrDescription, setErrDescription] = useState([]);

    const [mInfo, setMInfo] = useState([]);

    const [material, setMaterials] = useState([{ slNo: 1, tagNo: '', tagName: '', description: '', qty: '', currencyType: '', value: '', invoiceNo: '', remarks: '' }]);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const gatePassNos = query.get('gatePassNo');
    const elementRef = useRef();

    useEffect(() => {
        if (gatePassNos !== '' && gatePassNos !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(gatePassNos.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedGatePassNo = decrypt_data.gatePassNo;
            getTransferInfo(decryptedGatePassNo);
        }
    }, []);

    const getTransferInfo = function (decryptedGatePassNo) {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'gatePassNo': decryptedGatePassNo },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = { 'token': EncryptData };
        getGatePassDetailByGatePassNo(oData1).then(function (res) {
            if (res?.data?.data) {
                var aInfo = res.data.data.gate_pass_data;
                setMInfo(res.data.data.tag_details);

                setGatePassNo(decryptedGatePassNo);
                setCatOfEmp(aInfo.catOfEmp);
                setGatePassType(aInfo.gatePassType);
                setEmployeeID(aInfo.employeeID);
                setCostCenter(aInfo.costCenter);
                setTitles(aInfo.titles);
                setDesignation(aInfo.designation);
                setVendorName(aInfo.vendorName);
                setAddress(aInfo.address);
                setExpectedDate(new Date(aInfo.expectedDate));
                setGatePassDate(new Date(aInfo.gatePassDate));
                setDestinationCity(aInfo.destinationCity);
                setDestinationCountry(aInfo.destinationCountry);
                setReasonTakingOut(aInfo.reasonTakingOut);
                setInfo(aInfo);
            }
        });
    };

    useEffect(() => {
        if (mInfo.length > 0) {
            const mappedMaterials = mInfo.map(material => ({
                tag: material.tag,
                tag_name: material.tag_name,
                asset_description: material.asset_description,
                qty: material.qty,
                invoice_currency: material.invoice_currency,
                invoice_no: material.invoice_no,
                value_of_asset: material.value_of_asset,
                remark: material.remark
            }));

            setMaterials(mappedMaterials);
        }
    }, [mInfo]);

    const handleCheckboxChange = (index) => {
        setMaterials(prevState => prevState.map((item, i) => i === index ? { ...item, selected: !item.selected } : item));
    };

    const handleRemarkChange = (index, value) => {
        setMaterials(prevState => prevState.map((item, i) => i === index ? { ...item, remark: value } : item));
    };

    const handleSubmit = () => {
        const selectedItems = material.filter(item => item.selected);
        console.log("Submitting remarks for:", selectedItems);
        // Add API call logic here
    };

    return (
        <div className='container'>

            {success ? (<div className="alert alert-success" role="alert"><i
                className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
                    <div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
            )}
            <div className="title">
                <h5>Security Clearance View Gate Pass</h5>

            </div>
            <div>
                <div className="title">

                    <div className='row'>
                        <div className='col-10'>&nbsp;</div>
                    </div>
                    <div className='row'>
                        <div className="col-6">
                            <div className="row ">
                                <div className="col-6">
                                    <label>CAT <span className="star error"> * </span></label>
                                </div>
                                <div className="col-6">
                                    <select disabled="true"
                                            className="form-select form-select-sm mb-3">
                                        <option value="">Select One</option>
                                        <option value="Employee"
                                                selected={catOfEmp === "Employee" ? "selected" : ''}>Employee
                                        </option>
                                        <option value="Vendor"
                                                selected={catOfEmp === "Vendor" ? "selected" : ''}>Vendor
                                        </option>
                                    </select>
                                    <span className="invalid-feedback">{ErrCatOfEmp}</span>
                                </div>
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="row ">
                                <div className="col-6">
                                    <label>Gate Pass Type <span className="star error"> * </span></label>
                                </div>
                                <div className="col-6">
                                    <select disabled="true"
                                            className="form-select form-select-sm mb-3">
                                        <option value="">Select One</option>
                                        <option value="Returnable"
                                                selected={gatePassType === "Returnable" ? "selected" : ''}>Returnable
                                        </option>
                                        <option value="Non Returnable"
                                                selected={gatePassType === "Non Returnable" ? "selected" : ''}>Non
                                            Returnable
                                        </option>
                                    </select>
                                    <span className="invalid-feedback">{ErrGatePassType}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Conditional rendering based on the selected CAT */}
                    {catOfEmp === "Employee" && (
                        <div className='row'>
                            <div className="col-6">
                                <FromFieldText readOnly="readOnly"
                                               star="*"
                                               label="Employee ID"
                                               inputValue={employeeID}
                                               setInputValue={setEmployeeID}
                                               setErrorMsg={ErrEmployeeID}
                                />
                            </div>
                            <div className='col-6'>
                                <FromFieldText readOnly="readOnly"
                                               star="*"
                                               label="Cost Centre"
                                               inputValue={costCenter}
                                               setInputValue={setCostCenter}
                                               setErrorMsg={ErrCostCenter}
                                />
                            </div>
                        </div>
                    )}


                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText readOnly="readOnly"
                                           star="*"
                                           label="Smt / Sri"
                                           inputValue={titles}
                                           setInputValue={setTitles}
                                           setErrorMsg={ErrTitles}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText readOnly="readOnly"
                                           star="*"
                                           label="Designation"
                                           inputValue={designation}
                                           setInputValue={setDesignation}
                                           setErrorMsg={ErrDesignation}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText readOnly="readOnly"
                                           star="*"
                                           label="Gate Pass Number"
                                           inputValue={gatePassNo}
                                           setInputValue={setGatePassNo}
                                           setErrorMsg={ErrGatePassNo}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldDate readOnly="readOnly"
                                           star="*"
                                           label="Gate Pass Date"
                                           inputValue={gatePassDate}
                                           setInputValue={setGatePassDate}
                                           setErrorMsg={ErrGatePassDate}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText readOnly="readOnly"
                                           star="*"
                                           label="Vendor Company Name"
                                           inputValue={vendorName}
                                           setInputValue={setVendorName}
                                           setErrorMsg={ErrVendorName}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText readOnly="readOnly"
                                           star="*"
                                           label="Material taken to (Address)"
                                           inputValue={address}
                                           setInputValue={setAddress}
                                           setErrorMsg={ErrAddress}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText readOnly="readOnly"
                                           star="*"
                                           label="Destination City"
                                           inputValue={destinationCity}
                                           setInputValue={setDestinationCity}
                                           setErrorMsg={ErrDestinationCity}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText readOnly="readOnly"
                                           star="*"
                                           label="Destination Country"
                                           inputValue={destinationCountry}
                                           setInputValue={setDestinationCountry}
                                           setErrorMsg={ErrDestinationCountry}
                            />
                        </div>

                    </div>

                    <div className='row mb-2'>
                        <div className='col-6'>
                            <FromFieldDate
                                readOnly={true} // Set to true to disable the field
                                star="*"
                                label="Expected Date of Return"
                                inputValue={expectedDate}
                                setInputValue={setExpectedDate}
                                setErrorMsg={ErrExpectedDate}
                            />

                        </div>
                        <div className='col-6'>
                            <FromFieldTextArea readOnly="readOnly"
                                               star="*"
                                               label="Reason for Taking Out"
                                               inputValue={reasonTakingOut}
                                               setInputValue={setReasonTakingOut}
                                               setErrorMsg={ErrReasonTakingOut}
                            />
                        </div>
                        <div className='col-6'></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-12'>
                            <table border="1" width="100%" style={{borderCollapse: 'collapse', textAlign: 'center', fontSize: '13px'}}>
                                <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Sl No</th>
                                    <th>TAG No</th>
                                    <th>TAG Name</th>
                                    <th>Descriptions</th>
                                    <th>Qty</th>
                                    <th>Currency Type</th>
                                    <th>Value</th>
                                    <th>Invoice No</th>
                                    <th>Remarks</th>
                                </tr>
                                </thead>
                                <tbody>
                                {material.map((material, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={material.selected}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{material.tag}</td>
                                        <td>{material.tag_name}</td>
                                        <td>{material.asset_description}</td>
                                        <td>{material.qty}</td>
                                        <td>{material.invoice_currency}</td>
                                        <td>{material.value_of_asset}</td>
                                        <td>{material.invoice_no}</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={material.remark}
                                                onChange={(e) => handleRemarkChange(index, e.target.value)}
                                                disabled={!material.selected}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <button onClick={handleSubmit} className="btn btn-primary mt-3">Submit Remarks</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default SecurityViewGatePass