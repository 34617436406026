import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';

import {getAllCostCenterData, deleteCostCenter} from '../../../services/asset.service';


import * as CryptoJS from "crypto-js";

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);
function ViewCostCenter() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    const columns = [
        {"field": "company_name","hide": false, "headerName": "Company Name", "width": 350},

        {"field": "cost_center_code", "hide": false, "headerName": "Cost Center Code", "width": 200},

        {"field": "cost_center_name", "hide": false, "headerName": "Cost Center Name", "width": 350},

        {"field": "created_by_name", "hide": false, "headerName": "Created By Name", "width": 150},

        {
            field: 'actions', headerName: 'Actions', width: 120, renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'costid': params.row.id,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (
                    <div>
                        <a href={`/add-cost-center/?cost_ID=${EncryptID}`} className="p-2">
                            <button type="button" className="btn btn-primary btn-sm">
                                <i className="bi bi-pencil"></i>
                            </button>
                        </a>
                        <button
                            type="button"
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => handleDeleteClick(params.row.id)}
                        >
                            <i className="bi bi-trash"></i>
                        </button>
                    </div>
                );
            }
        }
    ];


    const handleDeleteClick = (costId) => {
        //alert(costId);
        //console.log(`Deleting cost center with ID: ${costId}`);


        const confirmDelete = window.confirm("Are you sure you want to delete this cost center?");

        if (confirmDelete) {

            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'cost_id': costId},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            deleteCostCenter(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    window.location.reload();
                }
            });

        } else {
            window.location.reload();
        }



    };

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        // Set loading to true before making the request
        setLoading(true);

        // Fetch data
        getAllCostCenterData(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                // Set loading to false after the request is completed (success or failure)
                setLoading(false);
            });
    }, []);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Cost Center List</h5></div>
                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}
        </div>
    )
}

export default ViewCostCenter
