import React, { useState,useEffect } from 'react';
import './atom.css';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer,GridOverlay } from '@mui/x-data-grid';
import FromFieldText from './FromFieldText';
import FromFieldDate from './FromFieldDate';
import FromFieldTextArea from './FromFieldTextArea';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from 'react-modal';
import { getTransferAssetByTinNo ,getReceivedByTinNo } from '../services/transfer.service';
import  * as CryptoJS  from "crypto-js";

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,255}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;
const ReceviedConfirmBox = ({ isTinNo, isOpen, onClose, onConfirm }) => {
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [ErrSelectionModel, setErrSelectionModel] = useState('');
  const [dateOfReceipt, setDateOfReceipt] = useState('');
  const [ErrDateOfReceipt, setErrDateOfReceipt] = useState(false);
  const [confirmedByPICName, setConfirmedByPICName] = useState('');
  const [ErrConfirmedByPICName, setErrConfirmedByPICName] = useState(false);
  const [receiptRemarks, setReceiptRemarks] = useState('');
  const [ErrReceiptRemarks, setErrReceiptRemarks] = useState(false);
  const [receiptStatus, setReceiptStatus] = useState('');
  const [ErrReceiptStatus, setErrReceiptStatus] = useState(false);
  const [items, setItems] = useState([]);
  const [receiveItems, setReceiveItems] = useState([]);
  const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tag","editable":false,"hide":false ,"headerName":"TAG No","width":250},
    {"field":"tin_no","hideable":true,"hide":false,"editable":false,"headerName":"TIN No","width":280},
  ];
  const receiveColumns = [
    {"field":"sl_no","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tag","editable":false,"hide":false ,"headerName":"TAG No","width":250},
    {"field":"tin_no","hideable":true,"hide":false,"editable":false,"headerName":"TIN No","width":280},
  ];

  useEffect(() => {
    const getTransferInfo = async function (decryptedTinNo) {
      setLoading(true);
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data': { 'tin_no': decryptedTinNo },
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData1 = { 'token': EncryptData };
      try {
        const res = await getTransferAssetByTinNo(oData1);
        if (res?.data?.data.length) {
          setItems(res.data.data);
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching transfer info:", error);
      } finally {
        setLoading(false);
      }
    }
    const getTransferReceive = async function (decryptedTinNo) {
      setLoading(true);
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data': { 'tin_no': decryptedTinNo },
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData1 = { 'token': EncryptData };
      try {
        const res = await getReceivedByTinNo(oData1);
        if (res?.data?.data.length) {
          setReceiveItems(res.data.data);
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching transfer info:", error);
      } finally {
        setLoading(false);
      }
    }
    if (isTinNo) {
    getTransferInfo(isTinNo);
      getTransferReceive(isTinNo);
    }
  }, [isTinNo]);

  const CustomLoader = () => (
    <GridOverlay>
      <CircularProgress color="primary" size={30} />
    </GridOverlay>
  );
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
      </GridToolbarContainer>
    );
  }
  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
    const allIds = items.map(item => item.id);
    if(allIds.length == newSelection.length){
      setReceiptStatus('Full');
    }
  };
  const handleReceiptStatus = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      setReceiptStatus(e.target.value);
      if (value === "Full") {
        // Select all rows and disable the checkboxes
        const allIds = items.map(item => item.id);
        setSelectionModel(allIds); // Check all checkboxes
      } else {
        setSelectionModel([]);  // Clear selection if not "Full"
      }
  }
  const isRowSelectable = (params) => {
    // Disable all checkboxes if "Full" is selected
    return receiptStatus !== "Full";
  };
  const handleConfirm = async (e) => {
    e.preventDefault();
    var valid_Name = true;
    if (dateOfReceipt=='') { 
      setErrDateOfReceipt('Date of Receipt is required'); valid_Name=false;
    }else{ setErrDateOfReceipt('');  }
    if (!TEXT_REGEX.test(confirmedByPICName)) { 
      setErrConfirmedByPICName('Confirmed by PIC Name is required'); valid_Name=false;
    }else{ setErrConfirmedByPICName('');  }
    if (!TEXT_SPCL_REGEX.test(receiptRemarks)) { 
      setErrReceiptRemarks('Remarks is required'); valid_Name=false;
    }else{ setErrReceiptRemarks('');  }
    if (!TEXT_REGEX.test(receiptStatus)) { 
      setErrReceiptStatus('Remarks is required'); valid_Name=false;
    }else{ setErrReceiptStatus('');  }
    if (selectionModel.length==0) { 
      setErrSelectionModel("Please select atleast one asset"); valid_Name=false;
    }
    if (!valid_Name) { //alert(valid_Name);
      return false;
    } else { //alert(mon); alert(fy);
      onConfirm(dateOfReceipt,confirmedByPICName,receiptRemarks,receiptStatus,selectionModel);
      onClose();
      setDateOfReceipt(); setConfirmedByPICName(); setReceiptRemarks(''); setReceiptStatus(''); setSelectionModel([]);
    }
    
  };
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Confirm Box"
      style={modalStyles}
    >
      <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title">Branch Transfer Receipt Confirmation</h5>
        <button type="button" class="btn-close" onClick={onClose}></button>
        </div>
        <div class="modal-body" style={modalBody}>
        <div className="row form-fields">
            <div className="label col-6" > 
                <label> Receipt Type </label>
                <span className="star error"> * </span>
            </div>
            <div className="item col-6" >
                <select onChange={handleReceiptStatus} name="receipt_status" className="form-select form-select-sm">
                    <option value="">Select One</option>
                    <option value="Full" selected={(receiptStatus === "Full") ? "selected" : ""} >Full</option>
                    <option value="Partial">Partial</option>
                </select> <span className="invalid-feedback"> {ErrReceiptStatus} </span>
            </div>
        </div>
        <FromFieldDate 
                label="Date of Receipt" 
                star="*"
                inputValue={dateOfReceipt}
                setInputValue={setDateOfReceipt}
                setErrorMsg={ErrDateOfReceipt}
            />
        
        <FromFieldText 
                label="Received by (Name)" 
                star="*"
                inputValue={confirmedByPICName}
                setInputValue={setConfirmedByPICName}
                setErrorMsg={ErrConfirmedByPICName}
            />
        
            <FromFieldTextArea 
                label="Remarks" 
                star="*"
                inputValue={receiptRemarks}
                setInputValue={setReceiptRemarks}
                setErrorMsg={ErrReceiptRemarks}
            />
        </div>
        <div class="modal-footer mt-10">
        <div className='col-6'>
        <button  class="btn btn-primary btn-md" onClick={handleConfirm}> Confirm </button>&nbsp;&nbsp;
        <button class="btn btn-secondary btn-md" onClick={onClose}> Cancel </button>&nbsp;&nbsp;
        </div><div className='col-6'></div>
        <Box sx={{ height: 500, width: '100%' }}><span className="invalid-feedback"> {ErrSelectionModel} </span>
        <div className='row'><div className='col-12'><h5>Pending to Receive</h5></div></div>
      <DataGrid
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={50}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        //height={500}
        autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        isRowSelectable={isRowSelectable} // Disable rows when "Full" is selected
        components={{
            LoadingOverlay: CustomLoader,
            Toolbar: CustomToolbar,
        }}
        loading={loading}
      />
      <div className='row'><div className='col-12'><h5>Received Earlier</h5></div></div>
      <DataGrid
        rows={receiveItems}
        getRowId={(row) => row.id}
        columns={receiveColumns}
        pageSize={50}
        //checkboxSelection
        //selectionModel={selectionModel}
        //onSelectionModelChange={handleSelectionModelChange}
        //height={500}
        autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        //isRowSelectable={isRowSelectable} // Disable rows when "Full" is selected
        components={{
            LoadingOverlay: CustomLoader,
            Toolbar: CustomToolbar,
        }}
        loading={loading}
      />
      </Box>
      </div>
      </div>
      </div>
    </Modal>
  );
};

const modalStyles = {
  content: {
    width: '400px',
    height: '400px',
    margin: 'auto',
  },
};
const  modalBody ={
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default ReceviedConfirmBox;