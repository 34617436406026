import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { getAssignmentListIR, updateAssignment, acceptAssignment } from '../../services/user.service';
import ConfirmBox from '../../atoms/ConfirmBox';
import * as CryptoJS from 'crypto-js';

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    } else {
        return null;
    }
}

function AcceptRejectAssignment() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);  // Array of selected row IDs
    const [rejectReason, setRejectReason] = useState('');

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const handleConfirmOpen = () => {
        setIsConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setIsConfirmOpen(false);
    };

    const handleConfirm = async (inputValue) => {
        let EncryptData = CryptoJS.AES.encrypt(
            JSON.stringify({
                param_data: {
                    assignment_ids: selectionModel,
                    status: '3',
                    reject_reason: inputValue,
                    assinment_accepted_by: sessionStorage.getItem('LoggedInUserId'),
                    assignment_accepted_date: getFormatedDate(new Date()),
                },
                authToken: sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();

        let oData = { token: EncryptData };
        try {
            updateAssignment(oData).then((res) => {
                setSuccess(res.data.message);
                const updatedData = items.filter((item) => !selectionModel.includes(item.id));
                setItems(updatedData);
                setSelectionModel([]);  // Clear the selection after updating
            });
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            setErrMsg('Insert/Update Failed');
        }
    };

    const handleReject = async () => {
        if (selectionModel.length > 0) {
            setErrMsg('');
            handleConfirmOpen();
        } else {
            setErrMsg('Please select at least one asset.');
        }
    };

    const handleAccept = async () => {
        if (selectionModel.length > 0) {
            setErrMsg('');

            let EncryptData = CryptoJS.AES.encrypt(
                JSON.stringify({
                    param_data: {
                        status: '2',
                        assignment_ids: selectionModel,
                        assinment_accepted_by: sessionStorage.getItem('LoggedInUserId'),
                        assignment_accepted_date: getFormatedDate(new Date()),
                    },
                    authToken: sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            let oData = { token: EncryptData };
            try {
                acceptAssignment(oData).then((res) => {
                    setSuccess(res.data.message);
                    const updatedData = items.filter((item) => !selectionModel.includes(item.id));
                    setItems(updatedData);
                    setSelectionModel([]);  // Clear the selection after accepting
                });
            } catch (err) {
                setSuccess(false);
                setLoading(false);
                setErrMsg('Insert/Update Failed');
            }
        } else {
            setErrMsg('Please select at least one asset.');
        }
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);  // Only update selection state based on user input
    };

    const columns = [
        { field: 'id' ,"hide":true, headerName: 'Sl No', width: 50},
        { field: 'tag', "hide":true, headerName: 'TAG', width: 230 },
        { field: 'tag_name',"hide":true, headerName: 'TAG Name', width: 230 },
        
        { field: 'assignment_no', headerName: 'Assignment Number', width: 230,
            renderCell: (params) => {
                    let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                        'assignment_no': params.row.assignment_no,
                    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                    return (<a href={`/create-new-ir-assignment?assignment_no=${EncryptID}`} >{params.row.assignment_no}</a>)
                  } 
         },
        { field: 'rfid_reader_location2', headerName: 'Repair Category', width: 230 },
        { field: 'equipment_listed_repairs', headerName: 'Earlier Repaired', width: 230 },
        { field: 'issue_caused', headerName: 'Any Accident', width: 230 },
        { field: 'under_warrenty', headerName: 'Covered under Warranty/AMC/Insurance', width: 230 },
        { field: 'assigned_user_name', headerName: 'Assigned To', width: 230 },
        { field: 'cat_ir', headerName: 'CAT of IR', width: 230 },
        {"field":"status","hide":false ,"headerName":"Status","width":130,
            renderCell: (params) => (
              <div>{(params.row.status=='1')?(<span class="btn-btn-warning p-1">Pending</span>):''}{(params.row.status=='3')?(<span class="btn-btn-danger p-1">Rejected</span>):''}{(params.row.status=='2')?(<span class="btn-btn-success p-1">Accepted</span>):''} &nbsp; </div>
            )},
        {
            field: 'assignedDate',
            headerName: 'Assignment Created Date',
            width: 230,
            renderCell: (params) => <p>{getFormatedDate(params.row.assignedDate)}</p>,
        },
    ];

    function CustomToolbar() {
        return <GridToolbarContainer></GridToolbarContainer>;
    }

    const [items, setItems] = useState([]);
    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(
            JSON.stringify({
                param_data: { 'assign_user_id': sessionStorage.getItem('LoggedInUserId') },
                authToken: sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();

        let oData = { token: EncryptData };
        getAssignmentListIR(oData).then((res) => {
            if (res?.data?.data?.length) setItems(res.data.data);
        });
    }, []);

    return (
        <div className="container">
            <h5> Accept Reject Assignment </h5>
            {success ? (
                <div className="alert alert-success" role="alert">
                    <i className="bi bi-check-circle-fill"></i>
                    {success}
                </div>
            ) : errMsg ? (
                <div className="alert alert-danger" role="alert">
                    <i className="bi bi-exclamation-triangle-fill"></i>
                    {errMsg}
                </div>
            ) : (
                ''
            )}

            <div className="filter-container1 col-12 mb-2">
                <div className="col-8"></div>
                <div className="col-2">
                    <button className="btn btn-primary btn-md text-right" onClick={handleAccept}>
                        <i className="bi bi-bookmark-check"></i> Accept
                    </button>
                    &nbsp;
                    <button className="btn btn-danger btn-md text-right" onClick={handleReject}>
                        <i className="bi bi-bookmark-x"></i> Reject
                    </button>
                </div>
            </div>

            <DataGrid
                rows={items}
                getRowId={(row) => row.assignment_no}
                columns={columns}
                checkboxSelection
                autoHeight
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}  // Correctly updates selection state
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />

            <ConfirmBox isOpen={isConfirmOpen} onClose={handleConfirmClose} onConfirm={handleConfirm} />
        </div>
    );
}

export default AcceptRejectAssignment;
