import React, {useEffect, useState} from 'react';
import {
    getTAGDetails, getNotAllocatedFlag, saveGatePass,
    saveGatePassRemark, getAllCostCenterData, getCostCenterByCompany, getTAGFromTinNO} from '../../services/asset.service';
import Select from 'react-select';
import * as CryptoJS from "crypto-js";
import FromFieldTextArea from '../../atoms/FromFieldTextArea';
import {getLastGatePassNo} from '../../services/user.service';
import FromFieldText from "../../atoms/FromFieldText";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromFieldTextER from "../../atoms/FromFieldTextER";
import { useLocation } from 'react-router-dom';

function GatePassFilled() {
    const [activeTab, setActiveTab] = useState(0); // State to track the active tab
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOptions1, setListData1] = useState([]);
    const [info, setInfo] = useState([]);
    const [remarks, setRemarks] = useState({});
    const [items, setItems] = useState([]);
    const [foundAssets, setFoundAssets] = useState([]);
    const [oldfoundAssets, setOldFoundAssets] = useState([]);
    const [textBoxValue, setTextBoxValue] = useState('');
    const [gatePassNo, setGatePassNo] = useState('');
    const [ErrGatePassNo, setErrGatePassNo] = useState(false);
    const [gatePassDate, setGatePassDate] = useState('');
    const [ErrGatePassDate, setErrGatePassDate] = useState(false);
    const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

    const [catOfEmp, setCatOfEmp] = useState('');
    const [ErrCatOfEmp, setErrCatOfEmp] = useState(false);

    const [gatePassType, setGatePassType] = useState('');
    const [ErrGatePassType, setErrGatePassType] = useState(false);

    const [employeeID, setEmployeeID] = useState('');
    const [ErrEmployeeID, setErrEmployeeID] = useState(false);

    const [costCenter, setCostCenter] = useState('');
    const [ErrCostCenter, setErrCostCenter] = useState(false);

    const [titles, setTitles] = useState('');
    const [ErrTitles, setErrTitles] = useState(false);

    const [designation, setDesignation] = useState('');
    const [ErrDesignation, setErrDesignation] = useState(false);

    const [vendorName, setVendorName] = useState('');
    const [ErrVendorName, setErrVendorName] = useState(false);

    const [address, setAddress] = useState('');
    const [ErrAddress, setErrAddress] = useState(false);

    const [destinationCity, setDestinationCity] = useState('');
    const [ErrDestinationCity, setErrDestinationCity] = useState(false);

    const [destinationCountry, setDestinationCountry] = useState('');
    const [ErrDestinationCountry, setErrDestinationCountry] = useState(false);

    const [expectedDate, setExpectedDate] = useState('');
    const [ErrExpectedDate, setErrExpectedDate] = useState(false);

    const [reasonTakingOut, setReasonTakingOut] = useState('');
    const [ErrReasonTakingOut, setErrReasonTakingOut] = useState(false);

    const [descriptions, setDescription] = useState('');
    const [ErrDescription, setErrDescription] = useState([]);

    const [cost_center_codes, setCost_Center_Codes] = useState([]);

    const [costcentercode, setCostCenterCode] = useState('');
    const [ErrCostCenterCode, setErrCostCenterCode] = useState(false);

    const [tagValues, setTagValues] = useState('');

    const location = useLocation();
    const decryptDin = (encryptedDin) => {
        const bytes = CryptoJS.AES.decrypt(
            encryptedDin,
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        );
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData); // Parse the decrypted string back into an array
    };
    const queryParams = new URLSearchParams(location.search);
    const encryptedDin = queryParams.get('tinNo');

    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getNotAllocatedFlag(oData).then(res => {
            if (res?.data?.data?.length) setFoundAssets(res.data.data);
        });
        getCostCenterByCompany(oData).then(res => {
            if (res?.data?.data?.length) setCost_Center_Codes(res.data.data);
        });
        getAllCostCenterData(oData).then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
        });

        const dinValues = decryptDin(encryptedDin);

        let DinEncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'tin_no': dinValues },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oDinNo = { 'token': DinEncryptData };

        getTAGFromTinNO(oDinNo).then(res => {
            if (res?.data?.data) {
                const tagValues1 = res.data.data.map(item => item.tag);

                setTagValues(tagValues1);

                if (tagValues1.length > 0) {
                    let TagEncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                        'param_data': { 'tags': tagValues1 },
                        'authToken': sessionStorage.getItem('LoggedInToken'),
                    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

                    const oTagData = { 'token': TagEncryptData };

                    getTAGDetails(oTagData).then(function (res) {
                        if (res?.data?.data) {
                            setInfo(res.data.data);
                        }
                    }).catch((err) => {
                        console.error("Error fetching TAG details: ", err);
                    });
                } else {
                    console.error("No tags selected");
                }
            }
        });
        
    }, []);

    const optionCostCenterList = [];
    cost_center_codes && cost_center_codes.length > 0 ? (
        cost_center_codes.map((option) => (
            optionCostCenterList.push({value: option.cost_center_code, label: option.cost_center_code})
        ))
    ) : (
        optionCostCenterList.push({value: "", label: "No Data"})
    )

    const [selectedOptionsCostCenterList, setOptionsCostCenterList] = useState();

    function handleCostCenter(data) {
        //console.log(data);
        setOptionsCostCenterList(data);
        setCostCenterCode(data.value);
        
    }
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        if (!TEXT_REGEX.test(catOfEmp)) { 
            setErrCatOfEmp('Category is required');
            valid_Name = false; 
        } else {
            setErrCatOfEmp('');
        }
        if (!TEXT_REGEX.test(gatePassType)) { 
            setErrGatePassType('Gate Pass type is required');
            valid_Name = false;
        } else {
            setErrGatePassType('');
        } if (catOfEmp === 'Employee') {
            if (!TEXT_REGEX.test(employeeID)) { 
                setErrEmployeeID('Employee Id is required');
                valid_Name = false;
            } else {
                setErrEmployeeID('');
            }
            if (!TEXT_REGEX.test(costcentercode)) { 
                setErrCostCenterCode('Cost Center is required');
                valid_Name = false;
            } else {
                setErrCostCenterCode('');
            }
        } else {
            setErrEmployeeID(''); // Clear any previous error if not 'Employee'
        }
        if (catOfEmp === 'Vendor') {
            if (!TEXT_REGEX.test(vendorName)) {
                setErrVendorName('Vendor name is required');
                valid_Name = false;
            } else {
                setErrVendorName('');
            }
        } else {
            setErrEmployeeID(''); // Clear any previous error if not 'Employee'
        }
        if (!TEXT_REGEX.test(titles)) { 
            setErrTitles('Title is required');
            valid_Name = false; 
        } else {
            setErrTitles('');
        }if (!TEXT_REGEX.test(designation)) {
            setErrDesignation('Title is required');
            valid_Name = false;
        } else {
            setErrDesignation('');
        }if (!TEXT_REGEX.test(gatePassNo)) {
            setErrGatePassNo('Gate Pass no is required');
            valid_Name = false;
        } else {
            setErrGatePassNo('');
        }if (!gatePassDate) {
            setErrGatePassDate('Gate Pass Date is required');
            valid_Name = false;
        } else {
            setErrGatePassDate('');
        }if (!TEXT_REGEX.test(address)) {
            setErrAddress('Material taken to (Address) is required');
            valid_Name = false;
        } else {
            setErrAddress('');
        }if (!TEXT_REGEX.test(destinationCity)) {
            setErrDestinationCity('Destination city is required');
            valid_Name = false;
        } else {
            setErrDestinationCity('');
        }if (!TEXT_REGEX.test(destinationCountry)) {
            setErrDestinationCountry('Destination country is required');
            valid_Name = false;
        } else {
            setErrDestinationCountry('');
        }if (gatePassType === "Returnable" && !expectedDate) {
            setErrExpectedDate('Expected date of return is required');
            valid_Name = false;
        } else {
            setErrExpectedDate('');
        }if (!TEXT_REGEX.test(reasonTakingOut)) {
            setErrReasonTakingOut('Reason taking out is required');
            valid_Name = false;
        } else {
            setErrReasonTakingOut('');
        }
        if (!valid_Name) { //alert(valid_Name);
            return false;
        } else { //alert('save'); 
            saveData();
            //handleNextClick();
        }
    }

    const resetUserInfo = () => {
        setCatOfEmp('');
        setGatePassType('');
        setEmployeeID('');
        setCostCenter('');
        setTitles('');
        setDesignation('');
        setDescription('');
        setGatePassNo('');
        setGatePassDate('');
        setVendorName('');
        setAddress('');
        setDestinationCity('');
        setDestinationCountry('');
        setExpectedDate('');
        setReasonTakingOut('');

    };
    const saveData = () => { 
        try {

            var obj = {
                'catOfEmp': catOfEmp,
                'gatePassType': gatePassType,
                'employeeID': employeeID,
                'costCenter': costcentercode,
                'titles': titles,
                'designation': designation,
                'gatePassNo': gatePassNo,
                'gatePassDate': gatePassDate,
                'vendorName': vendorName,
                'address': address,
                'destinationCity': destinationCity,
                'destinationCountry': destinationCountry,
                'expectedDate': expectedDate,
                'reasonTakingOut': reasonTakingOut,
                //'existing_ownership_id': sessionStorage.getItem('LoggedInDepartmentId'),
                'created_by': sessionStorage.getItem('LoggedInUserId'),
                //'status':'4'
            };
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            saveGatePass(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            var obj1 = {

                'gatePassNo': gatePassNo,
                'tag': tagValues,
                'remark': descriptions,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }

            let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData1};
            saveGatePassRemark(oData1).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const handleCATofEmp = (event) => {
        setCatOfEmp(event.target.value);  // Update the state with the selected value
    };

    const handleGatePassType = (event) => {
        setGatePassType(event.target.value);  // Update the state with the selected value

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'catOfEmp': catOfEmp,'gatePassType': event.target.value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        // After updating GatePassType, call getLastGatePassNo and update GatePassNo
        getLastGatePassNo(oData).then(res => {
            let lastGatePassNo = res.data.lastGatePassNo;
            let newLastDinId = "0001";  // Default if no previous record exists

            if (lastGatePassNo) {

                // Ensure extracting correct number
                let lastNumberMatch = lastGatePassNo.match(/(\d+)$/);
                if (lastNumberMatch) {
                    let lastNumber = parseInt(lastNumberMatch[1]); // Extract last numeric part
                    newLastDinId = (lastNumber + 1).toString().padStart(4, '0'); // Increment
                }
            }

            let currentYear = new Date().getFullYear();
            let divisionCode = sessionStorage.LoggedInDivisionCode;
            let prefix = '';

            // Ensure correct prefix based on conditions
            if (catOfEmp === 'Employee') {
                prefix = event.target.value === 'Returnable' ? 'ER' : 'ENR';
            } else if (catOfEmp === 'Vendor') {
                prefix = event.target.value === 'Returnable' ? 'VR' : 'VNR';
            }

            let newGatePassNo = `GP${divisionCode}${currentYear}${prefix}${newLastDinId}`;
            setGatePassNo(newGatePassNo);
        });
    };

    const handleDescriptionChange = (index, value) => {
        const newDescriptions = [...descriptions];
        newDescriptions[index] = value;
        setDescription(newDescriptions);
    };

    return (
        <div className='container'>

            {success ? (<div className="alert alert-success" role="alert"><i
                className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
                    <div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
            )}

            <div className="title">
                <h5>Gate Pass</h5>
            </div>
            <div>

                <div className="title">

                    <div className='row'>
                        <div className="col-6">
                            <div className="row ">
                                <div className="col-6">
                                    <label>CAT <span className="star error"> * </span></label>
                                </div>
                                <div className="col-6">
                                    <select onChange={handleCATofEmp}
                                            className="form-select form-select-sm mb-3">
                                        <option value="">Select One</option>
                                        <option value="Employee"
                                                selected={catOfEmp === "Employee" ? "selected" : ''}>Employee
                                        </option>
                                        <option value="Vendor"
                                                selected={catOfEmp === "Vendor" ? "selected" : ''}>Vendor
                                        </option>
                                    </select>
                                    <span className="invalid-feedback">{ErrCatOfEmp}</span>
                                </div>
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="row ">
                                <div className="col-6">
                                    <label>Gate Pass Type <span className="star error"> * </span></label>
                                </div>
                                <div className="col-6">
                                    <select onChange={handleGatePassType}
                                            className="form-select form-select-sm mb-3">
                                        <option value="">Select One</option>
                                        <option value="Returnable"
                                                selected={gatePassType === "Returnable" ? "selected" : ''}>Returnable
                                        </option>
                                        <option value="Non Returnable"
                                                selected={gatePassType === "Non Returnable" ? "selected" : ''}>Non
                                            Returnable
                                        </option>
                                    </select>
                                    <span className="invalid-feedback">{ErrGatePassType}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Conditional rendering based on the selected CAT */}
                    {catOfEmp === "Employee" && (
                        <div className='row'>
                            <div className="col-6">
                                <FromFieldText
                                    star="*"
                                    label="Employee ID"
                                    inputValue={employeeID}
                                    setInputValue={setEmployeeID}
                                    setErrorMsg={ErrEmployeeID}
                                />
                            </div>
                            <div className='col-6'>
                                {/*<FromFieldText
                                            star="*"
                                            label="Cost Centre"
                                            inputValue={costCenter}
                                            setInputValue={setCostCenter}
                                            setErrorMsg={ErrCostCenter}
                                        />*/}
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Cost Center Code </label>
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            options={optionCostCenterList}
                                            placeholder="Select One"
                                            value={selectedOptionsCostCenterList}
                                            onChange={handleCostCenter}
                                        />
                                        <span className="invalid-feedback"> {ErrCostCenterCode} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText
                                star="*"
                                label="Smt / Sri"
                                inputValue={titles}
                                setInputValue={setTitles}
                                setErrorMsg={ErrTitles}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText
                                star="*"
                                label="Designation"
                                inputValue={designation}
                                setInputValue={setDesignation}
                                setErrorMsg={ErrDesignation}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText
                                readOnly
                                star="*"
                                label="Gate Pass Number"
                                inputValue={gatePassNo}
                                setInputValue={setGatePassNo}
                                setErrorMsg={ErrGatePassNo}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldDate
                                star="*"
                                label="Gate Pass Date"
                                inputValue={gatePassDate}
                                setInputValue={setGatePassDate}
                                setErrorMsg={ErrGatePassDate}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        {catOfEmp === "Vendor" && (
                            <div className="col-6">
                                <FromFieldText
                                    star="*"
                                    label="Vendor Company Name"
                                    inputValue={vendorName}
                                    setInputValue={setVendorName}
                                    setErrorMsg={ErrVendorName}
                                />
                            </div>
                        )}
                        <div className='col-6'>
                            <FromFieldText
                                star="*"
                                label="Material taken to (Address)"
                                inputValue={address}
                                setInputValue={setAddress}
                                setErrorMsg={ErrAddress}
                            />
                        </div>

                    </div>

                    <div className='row'>

                        <div className="col-6">
                            <FromFieldText
                                star="*"
                                label="Destination City"
                                inputValue={destinationCity}
                                setInputValue={setDestinationCity}
                                setErrorMsg={ErrDestinationCity}
                            />
                        </div>
                        <div className='col-6'>
                            <FromFieldText
                                star="*"
                                label="Destination Country"
                                inputValue={destinationCountry}
                                setInputValue={setDestinationCountry}
                                setErrorMsg={ErrDestinationCountry}
                            />
                        </div>

                    </div>

                    <div className='row mb-2'>
                        {gatePassType === "Returnable" && (
                            <div className='col-6'>
                                <FromFieldDate
                                    star="*"
                                    label="Expected Date of Return"
                                    inputValue={expectedDate}
                                    setInputValue={setExpectedDate}
                                    setErrorMsg={ErrExpectedDate}
                                />
                            </div>
                        )}
                        <div className='col-6'>
                            <FromFieldTextArea
                                star="*"
                                label="Reason for Taking Out"
                                inputValue={reasonTakingOut}
                                setInputValue={setReasonTakingOut}
                                setErrorMsg={ErrReasonTakingOut}
                            />
                        </div>
                        <div className='col-6'></div>
                    </div>

                    <div className="row mt-4">
                        <div className='col-12'>
                            <table className="table">
                                <thead>
                                <th>TAG No</th>
                                <th>TAG Name</th>
                                <th>Descriptions</th>
                                <th>Qty</th>
                                <th>Currency Type</th>
                                <th>Value</th>
                                <th>Invoice No</th>
                                <th>Remarks</th>
                                </thead>
                                <tbody>
                                {info && info.length > 0 ? (
                                    info.map((option, index) => (
                                        <tr key={index}>
                                            <td>{option.tag}</td>
                                            <td>{option.tag_name}</td>
                                            <td>{option.asset_description}</td>
                                            <td>{option.qty}</td>
                                            <td>{option.invoice_currency}</td>
                                            <td>{option.value_of_asset}</td>
                                            <td>{option.invoice_no}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name={`itemDescription[${index}]`}
                                                    placeholder="Enter Remark"
                                                    value={descriptions[index] || ''}  // Ensure descriptions is an array
                                                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                                />
                                                {ErrDescription[index] && (
                                                    <span
                                                        className="error-message">{ErrDescription[index]}</span>  // Ensure ErrDescription[index] is a single string
                                                )}
                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8">No Data</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>

                <div className='col-6'>
                    <form name="" onSubmit={handleSubmit1}>
                        <button className="btn btn-primary btn-md float-end"> Submit
                        </button>
                    </form>
                </div>
                <div className="col-md-6">

                </div>
            </div>


        </div>

    )
}

export default GatePassFilled
    

