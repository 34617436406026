import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getPhyVeriMaster} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const columns = [
    {
        "field": "phy_varify_table_name", "editable": false, "hide": false, "headerName": "PV table name", "width": 250,
        renderCell: (params) => (
            <a href={`/physical-verfication-master/view?pvid=${params.row.id}`}>{params.value}</a>
        )
    },
    {"field": "company_name", "editable": false, "hide": true, "headerName": "Company Name", "width": 350},
    {"field": "division_name", "editable": false, "hide": true, "headerName": "Division Name", "width": 350},
    {
        "field": "start_date",
        "editable": false,
        "hide": false,
        "headerName": "Start Date",
        "width": 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.start_date)}</p>
        )
    },
    {
        "field": "end_date",
        "editable": false,
        "hide": false,
        "headerName": "End Date",
        "width": 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.end_date)}</p>
        )
    },
    {
        "field": "extended_end_date",
        "editable": false,
        "hide": false,
        "headerName": "Extended end Date",
        "width": 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.extended_end_date)}</p>
        )
    },
    {
        "field": "created_at",
        "editable": false,
        "hide": false,
        "headerName": "Created Date",
        "width": 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.created_at)}</p>
        )
    },
    {
        field: 'actions', headerName: 'Actions', width: 80, renderCell: (params) => {
            let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                'masterid': params.row.id,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (
                <span><a href={`/physical-verfication-master/?mid=${EncryptID}`}>
                    <button type="button" className="btn btn-primary btn-sm">
                        <i className="bi bi-pencil"></i></button></a></span>
            );
        }
    }
];

function PhysicalVerificationList() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }
    const CustomLoader = () => (
        <GridOverlay>
            <CircularProgress color="primary" size={30}/>
        </GridOverlay>
    );
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport/>
            </GridToolbarContainer>
        );
    }
    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    useEffect(() => {
        setLoading(true);
        const offset = calculateOffset(page, pageSize);
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'offset': offset, 'pageSize': pageSize},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        getPhyVeriMaster(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                    setTotalItems(res.data.total || 0);
                }
            })
            .finally(() => {
                // Set loading to false when data fetching is done (success or error)
                setLoading(false);
            });
    }, []);

    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Physical Verification Master List</h5></div>

            </div>
            <Box sx={{height: 500, width: '100%'}}>

                <Box>
                    <DataGrid
                        rows={items}
                        getRowId={(row) => row.id}
                        columns={columns}
                        autoHeight
                        components={{
                            LoadingOverlay: CustomLoader,
                            Toolbar: CustomToolbar,
                            Pagination: () => (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                    <Box sx={{mr: 4}}>
                                        Showing {items.length} records out of {totalItems}
                                    </Box>
                                    <FormControl variant="outlined" sx={{minWidth: 120}}>
                                        <InputLabel>Rows per page</InputLabel>
                                        <Select
                                            value={pageSize}
                                            onChange={(event) => handlePageSizeChange(Number(event.target.value))}
                                            label="Rows per page"
                                        >
                                            {[50, 100, 200, 500].map((size) => (
                                                <MenuItem key={size} value={size}>
                                                    {size}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                        shape="rounded"
                                    />
                                </Box>
                            ),
                        }}
                        loading={loading}
                    />
                </Box>

            </Box>
        </div>
    )
}

export default PhysicalVerificationList
