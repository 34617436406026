import axios from "axios";

var baseURL = process.env.REACT_APP_API_BASE_URL;

var apis = {
    'saveAssetTransfer': `${baseURL}transfer/savetransfer`,
    'getLastTinNo': `${baseURL}transfer/getlasttin`,
    'bulkUploadAssetTransfer' : `${baseURL}transfer/uploadinitiatetransfer`,
    'getAssetsTransferData': `${baseURL}transfer/getassetstransferdata`,
    'getBranchTransferList' :`${baseURL}transfer/getbranchtransferlist`,
    'getBranchTransferInfo':`${baseURL}transfer/getbranchtransferinfo`,
    'getFlagInListInfo':`${baseURL}transfer/getflaginlistinfo`,
    'getTransferByTinNo':`${baseURL}transfer/gettransferbytinno`,
    'getTransferAssetByTinNo':`${baseURL}transfer/gettransferassetbytinno`,
    'getTransferMadeByTinNo':`${baseURL}transfer/gettransfermadebytinno`,
    'getReceivedByTinNo':`${baseURL}transfer/getreceivedbytinNo`,
    'updateBranchTransfer':`${baseURL}transfer/updatebranchtransfer`,
    'saveTransportDetails':`${baseURL}transfer/savetransportdetails`,
    'getTransportDetails':`${baseURL}transfer/gettransportdetails`,
    'getTransportByTinNo':`${baseURL}transfer/gettransportbytinNo`,
    'getTransportViewByTinNo':`${baseURL}transfer/gettransportviewbytinNo`,
    'getMadeTransferList' :`${baseURL}transfer/getmadetransferlist`,
    'getTransferReceivedList' :`${baseURL}transfer/gettransferreceivedlist`,
    'getConfirmTransferReceivedList' :`${baseURL}transfer/getconfirmtransferreceivedlist`,
    'ReceviedTransferAsset' :`${baseURL}transfer/receviedtransferasset`,
}
export function saveAssetTransfer(data) {
    return axios.post(apis.saveAssetTransfer, data);
}
export function getLastTinNo(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getLastTinNo,data);
}
export function bulkUploadAssetTransfer(data) {
    return axios.post(apis.bulkUploadAssetTransfer,data);
}
export function getAssetsTransferData(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAssetsTransferData,data);
}
export function getBranchTransferList(data) {
    return axios.put(apis.getBranchTransferList,data);
}
export function getBranchTransferInfo(data) {
    return axios.put(apis.getBranchTransferInfo,data);
}
export function getFlagInListInfo(data) {
    return axios.put(apis.getFlagInListInfo,data);
}
export function updateBranchTransfer(data) {
    return axios.put(apis.updateBranchTransfer,data);
}
export function getTransferByTinNo(data) {
    return axios.put(apis.getTransferByTinNo,data);
}
export function getTransferAssetByTinNo(data) {
    return axios.put(apis.getTransferAssetByTinNo,data);
}
export function getTransferMadeByTinNo(data) {
    return axios.put(apis.getTransferMadeByTinNo,data);
}
export function getReceivedByTinNo(data) {
    return axios.put(apis.getReceivedByTinNo,data);
}
export function getTransportByTinNo(data) {
    return axios.put(apis.getTransportByTinNo,data);
}
export function getTransportViewByTinNo(data) {
    return axios.put(apis.getTransportViewByTinNo,data);
}
export function saveTransportDetails(data) {
    return axios.put(apis.saveTransportDetails,data);
}
export function getTransportDetails(data) {
    return axios.put(apis.getTransportDetails,data);
}
export function getMadeTransferList(data) {
    return axios.put(apis.getMadeTransferList,data);
}
export function getTransferReceivedList(data) {
    return axios.put(apis.getTransferReceivedList,data);
}
export function getConfirmTransferReceivedList(data) {
    return axios.put(apis.getConfirmTransferReceivedList,data);
}
export function ReceviedTransferAsset(data) {
    return axios.put(apis.ReceviedTransferAsset,data);
}
