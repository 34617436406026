import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import Select from 'react-select';
import FromFieldText from '../../../atoms/FromFieldText';
import FromSpFieldText from '../../../atoms/FromSpFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromFieldSelect from '../../../atoms/FromFieldSelect';
import FromFieldNumber from '../../../atoms/FromFieldNumber';
import FromSpFieldTextArea from '../../../atoms/FromSpFieldTextArea';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './SingleAsset.css';
import {
    getCostCenterByCompany,
    getCountOfChildTag,
    getCountOfTagByPrefix,
    getParentAssets,
    getSTPI,
    saveSingle
} from '../../../services/asset.service';
import {getDepartmentList} from '../../../services/user.service';
import {
    getInvoiceCurrencyList,
    getTagDivisionList,
    getTypeOfAdditionList,
    getUnitOfMeasureList,
    getYearOfAdditionList
} from '../../../services/master.service';
import * as CryptoJS from "crypto-js";

Date.prototype.dbFormatedDate = function () {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const TEXT_REGEX = /^[A-Za-z0-9-_/.,:@= ]{1,155}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%:$#@*!= ]{1,500}$/;
const NUMBER_REGEX = /^[0-9.]{1,15}$/;
const TEXT_DATE = /^[a-zA-Z0-9-_+:/() ]{1,35}$/;
export default function SingleAsset() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const BackUrl = query.get('back_url');
    const elementRef = useRef();
    const [selectedYearOptions, setSelectedYearOptions] = useState(null);
    useEffect(() => {
        if (Tag !== '' && Tag !== null) {

            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTag = decrypt_data.tag;
            getTagAasetInfo(decryptedTag);
            //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getYearOfAdditionList(oData).then(res => {
            if (res?.data?.data?.length) setYear_of_Addition_Items(res.data.data);
        });
        getTypeOfAdditionList(oData).then(res => {
            if (res?.data?.data?.length) setType_of_Addition_Items(res.data.data);
        });
        getInvoiceCurrencyList(oData).then(res => {
            if (res?.data?.data?.length) setInvoice_Currency_Items(res.data.data);
        });
        getUnitOfMeasureList(oData).then(res => {
            if (res?.data?.data?.length) setUnitOfMeasure_items(res.data.data);
        });
        getTagDivisionList(oData).then(res => {
            if (res?.data?.data?.length) setTAG_Division_Items(res.data.data);
        })
        getDepartmentList(oData).then(res => {
            if (res?.data?.data?.length) setDepartments(res.data.data);
        });
        getCostCenterByCompany(oData).then(res => {
            if (res?.data?.data?.length) setCost_Center_Codes(res.data.data);
        });
        getParentAssets(oData).then(res => {
            if (res?.data?.data?.length) setParentAssetsData(res.data.data);
        });
    }, [])

    function reset() {
        setyearaddition(''); settypeaddition(''); setproject(''); setpono('');
        setsuppiler(''); setsuppilername(''); setinvoiceno('');
        setAccountingVoucherNo(''); setInternalApprovalNo('');
        setCostCenterCode(''); setCostCenterName('');
        setForwardingAndInsurance(''); setinstallation('');
        setErrection(''); setTotalCostWithoutTax('');
        setCustoms(''); setCGST(''); setSGST(''); setIGST('');
        setOtherTaxes(''); setTotalCostWithTax('');
        setTotalTax(''); setinvoicecurrency(''); setexchangerate('');
        setinvoicevaluefx(''); setinvoicevalueinr(''); setQty('');
        setUnitOfMeasure(''); settax(''); setNameAsset(''); setdetailasset('');
        setuniqueasset(''); setmake(''); setmodalno(''); setpartno('');
        setNatureOfPresence(''); setNatureOfAddition('');
        setPrincipalAssetTag(''); setCompRefNo('');
        settag(''); setTagName(''); setTagDivision('');
        setinvoicedate('');setboedate('');setshippingawbdate('');
        setSelectedYearOptions(''); setOwnershipId('');
        setyearaddition('');setSelectedOwnershipOptions(''); 
        setbillofentryno(''); setshippingawbno('');setSecuritySealDate('');
        setOptionsInvoiceCurrencyList('');setinvoicecurrency('');
        setOptionsCostCenterList(''); setCostCenterCode('');
        setUnitOfMeasure(''); setOptionsUnitOfMeasureList('');
        setSelectedOptions(''); setParentTag('');
    }

    const setOldAssetauditInfo = function (auditInfo) {
        var AuditOldData = {
            'tag': auditInfo.tag,
            'year_of_addition': auditInfo.year_of_addition,
            'type_of_addition': auditInfo.type_of_addition,
            'project_marking': auditInfo.project_marking,
            'po_no': auditInfo.po_no,
            'supplier_code': auditInfo.supplier_code,
            'supplier_name': auditInfo.supplier_name,
            'invoice_no': auditInfo.invoice_no,
            'invoice_date': (auditInfo.invoice_date ? getFormatedDate(auditInfo.invoice_date) : ''),
            'boe_no': auditInfo.boe_no,
            'boe_date': (auditInfo.boe_date ? getFormatedDate(auditInfo.boe_date) : ''),
            'shipping_awb_no': auditInfo.shipping_awb_no,
            'shipping_awb_date': (auditInfo.shipping_awb_date ? getFormatedDate(auditInfo.shipping_awb_date) : ''),
            'accounting_voucher_no': auditInfo.accounting_voucher_no,
            'internal_approval_no': auditInfo.internal_approval_no,
            'cost_center_code': auditInfo.cost_center_code,
            'cost_center_name': auditInfo.cost_center_name,
            'invoice_currency': auditInfo.invoice_currency,
            'exchange_rate': auditInfo.exchange_rate,
            'invoice_value_fx': auditInfo.invoice_value_fx,
            'invoice_value_inr': auditInfo.invoice_value_inr,
            'forwarding_and_insurance': auditInfo.forwarding_and_insurance,
            'installation': auditInfo.installation,
            'errection': auditInfo.errection,
            'total_cost_without_tax': auditInfo.total_cost_without_tax,
            'customs': auditInfo.customs,
            'cgst': auditInfo.cgst,
            'sgst': auditInfo.sgst,
            'igst': auditInfo.igst,
            'other_taxes': auditInfo.other_taxes,
            'total_tax': auditInfo.total_tax,
            'qty': auditInfo.qty,
            'value_of_asset': auditInfo.value_of_asset,
            'tax': auditInfo.tax,
            'asset_name': auditInfo.asset_name,
            'asset_description': auditInfo.asset_description,
            'asset_serial_no': auditInfo.asset_serial_no,
            'manufacturer': auditInfo.manufacturer,
            'model_no': auditInfo.model_no,
            'nature_of_presence': auditInfo.nature_of_presence,
            'nature_of_addition': auditInfo.nature_of_addition,
            'parent_tag': auditInfo.parent_tag,
            'principal_asset_tag': auditInfo.principal_asset_tag,
            'part_no': auditInfo.part_no,
            'comp_ref_no': auditInfo.comp_ref_no,
            'tag_name': auditInfo.tag_name,
            'tag_division': auditInfo.tag_division,
            'unit_of_measure': auditInfo.unit_of_measure,
            'ownership_id': auditInfo.ownership_id,
            'security_seal_date': (auditInfo.security_seal_date ? getFormatedDate(auditInfo.security_seal_date) : ''),
            //'asset_image':uploadassetimage,
            //'asset_invoice':uploadinvoicename,
            'company_id': auditInfo.company_id,
            'division_id': auditInfo.division_id,
            'created_by': auditInfo.created_by,
        };
        setAuditInfo(AuditOldData);
    }
    const getTagAasetInfo = function (Tag) {

        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'tag': Tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            getSTPI(oData1).then(function (res) {
                if (res?.data?.data) {
                    var stpiInfo = res.data.data.assetInfo;
                    setOldAssetauditInfo(stpiInfo);
                    //alert(res.data.data.assetInfo);
                    //console.log(stpiInfo);
                    setSelectedYearOptions({'value':stpiInfo.year_of_addition,'label':stpiInfo.year_of_addition});
                    settag(stpiInfo.tag);
                    setyearaddition(stpiInfo.year_of_addition);
                    settypeaddition(stpiInfo.type_of_addition);
                    //setTypeOfAdditionOptions({'value':stpiInfo.type_of_addition,'label':stpiInfo.type_of_addition});
                    setproject(stpiInfo.project_marking);
                    setpono(stpiInfo.po_no);
                    setsuppiler(stpiInfo.supplier_code);
                    setsuppilername(stpiInfo.supplier_name);
                    setinvoiceno(stpiInfo.invoice_no);

                    setbillofentryno(stpiInfo.boe_no);
                    //setboedate(new Date(stpiInfo.boe_date));

                    setshippingawbno(stpiInfo.shipping_awb_no);
                    //setshippingawbdate((new Date(stpiInfo.shipping_awb_no)));


                    setAccountingVoucherNo(stpiInfo.accounting_voucher_no);
                    setInternalApprovalNo(stpiInfo.internal_approval_no);
                    setCostCenterCode(stpiInfo.cost_center_code);
                    setCostCenterName(stpiInfo.cost_center_name);
                    setOptionsCostCenterList({'value':stpiInfo.cost_center_name,'label':stpiInfo.cost_center_name});
                    setForwardingAndInsurance(stpiInfo.forwarding_and_insurance);
                    setinstallation(stpiInfo.installation);
                    setCustoms(stpiInfo.customs);
                    setErrection(parseFloat(stpiInfo.errection).toFixed(2));
                    setTotalCostWithoutTax(parseFloat(stpiInfo.total_cost_without_tax).toFixed(2));
                    setCGST(parseFloat(stpiInfo.cgst).toFixed(2));
                    setSGST(parseFloat(stpiInfo.sgst).toFixed(2));
                    setIGST(parseFloat(stpiInfo.igst).toFixed(2));
                    setOtherTaxes(parseFloat(stpiInfo.other_taxes).toFixed(2));
                    setTotalCostWithTax(parseFloat(stpiInfo.value_of_asset).toFixed(2));
                    setTotalTax(parseFloat(stpiInfo.total_tax).toFixed(2));
                    setinvoicevaluefx(parseFloat(stpiInfo.invoice_value_fx).toFixed(2));
                    setinvoicevalueinr(parseFloat(stpiInfo.invoice_value_inr).toFixed(2));
                    settax(stpiInfo.tax);
                    setinvoicecurrency(stpiInfo.invoice_currency);
                    setOptionsInvoiceCurrencyList({'value':stpiInfo.invoice_currency,'label':stpiInfo.invoice_currency});
                    setexchangerate(stpiInfo.exchange_rate);
                    setQty(stpiInfo.qty);
                    setUnitOfMeasure(stpiInfo.unit_of_measure);
                    setOptionsUnitOfMeasureList({'value':stpiInfo.unit_of_measure,'label':stpiInfo.unit_of_measure});
                    //setvalueasset(stpiInfo.value_of_asset);

                    //setvaluetax(stpiInfo.value_with_tax);

                    setNameAsset(stpiInfo.asset_name);
                    setdetailasset(stpiInfo.asset_description);
                    setuniqueasset(stpiInfo.asset_serial_no);
                    setmake(stpiInfo.manufacturer);
                    setmodalno(stpiInfo.model_no);
                    setpartno(stpiInfo.part_no);
                    setNatureOfPresence(stpiInfo.nature_of_presence);
                    setNatureOfAddition(stpiInfo.nature_of_addition);
                    setPrincipalAssetTag(stpiInfo.principal_asset_tag);
                    //settag(stpiInfo.tag);
                    setCompRefNo(stpiInfo.comp_ref_no)
                    setTagName(stpiInfo.tag_name);
                    setTagDivision(stpiInfo.tag_division);
                    setOwnershipId(stpiInfo.ownership_id);
                    setSelectedOwnershipOptions({'value':stpiInfo.ownership_id,'label':stpiInfo.department_name});
                    setinvoicedate((new Date(stpiInfo.invoice_date)));
                    setSecuritySealDate((new Date(stpiInfo.security_seal_date)));

                    setshippingawbdate((new Date(stpiInfo.shipping_awb_date)));
                    setboedate(new Date(stpiInfo.boe_date));

                    //console.log((new Date(stpiInfo.invoice_date)));
                    //setinvoicedate( stpiInfo.invoice_date?.dbFormatedDate());
                }
            })
        } catch (err) {
            //setAssetInfo('');
        }

    }
    //const userRef = useRef();
    const errRef = useRef();
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);

    const [yearaddition, setyearaddition,] = useState('');
    const [ErrYearAddition, setErrYearAddition] = useState(false);

    const [typeaddition, settypeaddition] = useState('');
    const [ErrTypeAddition, setErrTypeAddition] = useState(false);

    const [groupingName, setGroupingName] = useState('');

    const [project, setproject] = useState('');
    const [pono, setpono] = useState('');
    const [suppiler, setsuppiler] = useState('');

    const [suppilername, setsuppilername] = useState('');
    const [ErrSuppilerName, setErrSuppilerName] = useState(false);

    const [invoiceno, setinvoiceno] = useState('');
    const [ErrInvoiceNo, setErrInvoiceNo] = useState(false);

    const [invoicedate, setinvoicedate] = useState('');
    const [ErrInvoiceDate, setErrInvoiceDate] = useState(false);

    const [billofentryno, setbillofentryno] = useState('');
    const [ErrBillOfEntryNo, setErrBillOfEntryNo] = useState(false);

    const [boedate, setboedate] = useState('');
    const [ErrBOEDate, setErrBOEDate] = useState(false);

    const [shippingawbno, setshippingawbno] = useState('');
    const [ErrShippingAWBNo, setErrShippingAWBNo] = useState(false);

    const [shippingawbdate, setshippingawbdate] = useState('');
    const [ErrShippingAWBDate, setErrShippingAWBDate] = useState(false);


    const [securitysealdate, setSecuritySealDate] = useState('');
    const [ErrSecuritySealDate, setErrSecuritySealDate] = useState(false);

    const [accountingvoucherno, setAccountingVoucherNo] = useState('');
    const [ErrAccountingVoucherNo, setErrAccountingVoucherNo] = useState(false);
    const [internalapprovalno, setInternalApprovalNo] = useState('');
    const [ErrInternalApprovalNo, setErrInternalApprovalNo] = useState(false);
    const [costcentercode, setCostCenterCode] = useState('');
    const [ErrCostCenterCode, setErrCostCenterCode] = useState(false);
    const [costcentername, setCostCenterName] = useState('');
    const [ErrCostCenterName, setErrCostCenterName] = useState(false);

    const [invoicecurrency, setinvoicecurrency] = useState('INR');
    const [ErrInvoiceCurrency, setErrInvoiceCurrency] = useState(false);

    const [exchangerate, setexchangerate] = useState('1');
    const [ErrExchangeRate, setErrExchangeRate] = useState(false);
    const [invoicevaluefx, setinvoicevaluefx] = useState('');
    const [ErrInvoiceValuefx, setErrInvoiceValuefx] = useState(false);

    const [invoicevalueinr, setinvoicevalueinr] = useState('');
    const [ErrInvoiceValueinr, setErrInvoiceValueinr] = useState(false);

    const [forwardingandinsurance, setForwardingAndInsurance] = useState('');
    const [ErrForwardingAndInsurance, setErrForwardingAndInsurance] = useState(false);

    const [installation, setinstallation] = useState('');
    const [ErrInstallation, setErrInstallation] = useState(false);
    const [errection, setErrection] = useState('');
    const [ErrErrection, setErrErrection] = useState(false);

    const [totalcostwithouttax, setTotalCostWithoutTax] = useState('');
    const [ErrTotalCostWithoutTax, setErrTotalCostWithoutTax] = useState(false);
    const [Customs, setCustoms] = useState('');
    const [ErrCustoms, setErrCustoms] = useState(false);
    const [cgst, setCGST] = useState('');
    const [ErrCGST, setErrCGST] = useState(false);
    const [sgst, setSGST] = useState('');
    const [ErrSGST, setErrSGST] = useState(false);
    const [igst, setIGST] = useState('');
    const [ErrIGST, setErrIGST] = useState(false);

    const [othertaxes, setOtherTaxes] = useState('');
    const [ErrOtherTaxes, setErrOtherTaxes] = useState(false);
    //const [totaltax, setTotalTax] = useState(Customs+cgst+sgst+igst+othertaxes);
    const [totaltax, setTotalTax] = useState(0);
    const [ErrTotalTax, setErrTotalTax] = useState(false);
    //const [totalcostwithtax, setTotalCostWithTax] = useState((totalcostwithouttax+totaltax));
    const [totalcostwithtax, setTotalCostWithTax] = useState(0);
    const [ErrTotalCostWithTax, setErrTotalCostWithTax] = useState(false);

    const [qty, setQty] = useState();
    const [ErrQty, setErrQty] = useState(false);

    const [valueasset, setvalueasset] = useState();
    const [ErrValueAsset, setErrValueAsset] = useState(false);

    const [tax, settax] = useState('');
    const [ErrTax, setErrTax] = useState(false);

    const [valuetax, setvaluetax] = useState('');
    const [ErrValueTax, setErrValueTax] = useState(false);

    const [nameasset, setNameAsset] = useState('');
    const [ErrNameAsset, setErrNameAsset] = useState(false);

    const [detailasset, setdetailasset] = useState('');
    const [ErrDetailAsset, setErrDetailAsset] = useState(false);

    const [uniqueasset, setuniqueasset] = useState('');
    const [ErrUniqueAsset, setErrUniqueAsset] = useState(false);

    const [make, setmake] = useState('');
    const [ErrMake, setErrMake] = useState(false);

    const [modalno, setmodalno] = useState('');
    const [ErrModelNo, setErrModalNo] = useState(false);

    const [partno, setpartno] = useState('');
    const [ErrPartNo, setErrPartNo] = useState(false);

    const [natureOfPresence, setNatureOfPresence] = useState('');
    const [ErrNatureOfPresence, setErrNatureOfPresence] = useState(false);

    const [natureOfAddition, setNatureOfAddition] = useState('');
    const [ErrNatureOfAddition, setErrNatureOfAddition] = useState(false);

    const [principalAssetTag, setPrincipalAssetTag] = useState('');
    const [ErrErrPrAsTag, setErrPrAsTag] = useState(false);

    const [tag, settag] = useState('');
    const [ErrTAG, setErrTag] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [textShow, setTextShow] = useState(false);
    const [parentTag, setParentTag] = useState('');

    const [ErrParentTag, setErrParentTag] = useState('');

    const [compRefNo, setCompRefNo] = useState('');
    const [ErrCompRefNo, setErrCompRefNo] = useState(false);

    const [tagname, setTagName] = useState('');
    const [ErrTagName, setErrTagName] = useState(false);

    const [tagdivision, setTagDivision] = useState(sessionStorage.getItem('LoggedInDivisionShortName'));
    const [ErrTagDivision, setErrTagDivision] = useState(false);

    const [unitofmeasure, setUnitOfMeasure] = useState('');
    const [ErrUnitOfMeasure, setErrUnitOfMeasure] = useState(false);
    const [ownershipId, setOwnershipId] = useState('');
    const [ErrOwnershipId, setErrOwnershipId] = useState(false);

    const [departments, setDepartments] = useState([]);
    const [parentAssets, setParentAssetsData] = useState([]);
    //master
    const [year_of_addition_items, setYear_of_Addition_Items] = useState([]);
    const [type_of_addition_items, setType_of_Addition_Items] = useState([]);
    const [invoice_currency_items, setInvoice_Currency_Items] = useState([]);
    const [cost_center_codes, setCost_Center_Codes] = useState([]);

    const [TAG_division_items, setTAG_Division_Items] = useState([]);
    const [UnitOfMeasure_items, setUnitOfMeasure_items] = useState([]);
    const [auditInfo, setAuditInfo] = useState([]);
    /*const [fnclassification, setfnclassification] = useState('');
    const [ErrFnClassification, setErrFnClassification] = useState(false);
    const [classifications, setClasifications] = useState([]);*/

    const handleSealdate = (date) => {
        setSecuritySealDate(date);
        let before_prefix = "";
        const selectedMonth = date.getMonth() + 1; // Months are zero-based
        const selectedYear = `${date.getFullYear()}`.slice(-2);//date.getFullYear();
        if (groupingName === "Consumable") {
            before_prefix = 'C-' + sessionStorage.getItem('LoggedInDivisionCode') + '' + String(selectedMonth).padStart(2, '0') + '' + selectedYear + '';
        } else {
            before_prefix = sessionStorage.getItem('LoggedInDivisionCode') + '' + String(selectedMonth).padStart(2, '0') + '' + selectedYear + '';
        }
        //console.log(before_prefix);
        if (natureOfAddition == 'Added to Existing Asset') { //For Parent
            //find parent number of asset
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'parent_tag': parentTag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            getCountOfChildTag(oData).then(res => {
                if (res?.data?.data?.length) {
                    let oData = res.data.data;
                    let number = oData[0].tag_count;
                    number = number + 1;
                    //console.log('number'+number);
                    settag(parentTag + '/' + number);
                } else {
                    let number = 1;
                    settag(parentTag + '/' + number);
                }
            });

        } else { //For Child
            //find number of asset
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'prefix': before_prefix},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            getCountOfTagByPrefix(oData).then(res => {
                if (res?.data?.data?.length) {
                    let oData = res.data.data;
                    //console.log(oData);
                    let number = oData[0].tag_count;

                    number = number + 1;
                    //console.log('number' + number);
                    settag(before_prefix + String(number).padStart(4, '0'));
                } else {
                    let number = 1;
                    settag(before_prefix + String(number).padStart(4, '0'));
                }
            });

        }

    }

    const handleTagDivision = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setTagDivision(e.target.value);
    }
    const handleNatureOfAddition = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setNatureOfAddition(e.target.value);
        if (value === "Added to Existing Asset") {
            setTextShow(true);
        } else {
            setTextShow(false);
        }
        setPrincipalAssetTag("No");
    }

    const invoicevalueinr_handler = (e) => {
        setinvoicevalueinr(e.target.value);
        calTotalCostWithoutTax(); //calTotalCostWithTax(); calTotalTax();
    }
    const forwardingandinsurance_handler = (e) => {
        setForwardingAndInsurance(e.target.value);
        calTotalCostWithoutTax(); //calTotalCostWithTax(); calTotalTax();

    }
    const installation_handler = (e) => {
        setinstallation(e.target.value);
        calTotalCostWithoutTax();  //calTotalCostWithTax(); calTotalTax();
    }
    const errection_handler = (e) => {
        setErrection(e.target.value);
        calTotalCostWithoutTax();  //calTotalCostWithTax(); calTotalTax();
    }
    const calTotalCostWithoutTax = () => { //alert(invoicevaluefx);
        let TCWOT = ((parseFloat(invoicevalueinr)) + (parseFloat(forwardingandinsurance)) + (parseFloat(installation)) + (parseFloat(errection))).toFixed(2);
        setTotalCostWithoutTax(TCWOT);
        //console.log('TotalCostWithoutTax' + totalcostwithouttax);
        //calTotalCostWithTax(); //calTotalTax();
    }
    const Customs_handler = (e) => {
        setCustoms(e.target.value);
        calTotalTax(); //calTotalCostWithTax(); 
    }
    const cgst_handler = (e) => {
        setCGST(e.target.value);
        calTotalTax(); //calTotalCostWithTax();
    }
    const sgst_handler = (e) => {
        setSGST(e.target.value);
        calTotalTax(); //calTotalCostWithTax(); 
    }
    const igst_handler = (e) => {
        setIGST(e.target.value);
        calTotalTax(); //calTotalCostWithTax(); 
    }
    const othertaxes_handler = (e) => {
        setOtherTaxes(e.target.value);
        calTotalTax(); //calTotalCostWithTax(); 
    }
    const calTotalTax = () => {
        let TT = ((parseFloat(Customs)) + (parseFloat(cgst)) + (parseFloat(sgst)) + (parseFloat(igst)) + (parseFloat(othertaxes))).toFixed(2);
        setTotalTax(TT);
        //console.log('TotalTax' + totaltax);
        calTotalCostWithTax(TT);
        //console.log('TotalCostWithTax' + totalcostwithtax);
    }
    const calTotalCostWithTax = (TT) => {
        //console.log('totalcostwithouttax -' + TT);
        //setTotalCostWithTax( ((parseFloat(totalcostwithouttax?totalcostwithouttax:0)) + (parseFloat(totaltax?totaltax:0))).toFixed(2) );
        let TCWT = ((parseFloat(totalcostwithouttax ? totalcostwithouttax : 0)) + (parseFloat(TT ? TT : 0))).toFixed(2);
        setTotalCostWithTax(TCWT);
        //console.log('totalcostwithtax-' + TCWT);
    }
    const exchangerate_handler = (e) => {
        setexchangerate(e.target.value)
        calInvValInrExRate()
    }
    const invoicevaluefx_handler = (e) => {
        setinvoicevaluefx(e.target.value);
        calInvValInrExRate();
    }
    const calInvValInrExRate = () => {
        let IVR = ((parseFloat(invoicevaluefx)) * (parseFloat(exchangerate))).toFixed(2);
        setinvoicevalueinr(IVR);
        //console.log('invoicevalueinr' + invoicevalueinr);
        //setTotalCostWithTax(IVR + totalcostwithouttax+totaltax);
        calTotalCostWithoutTax();
        calTotalTax();
    }

    const optionListOwnership = [];
    //console.log(departments);
    departments && departments.length > 0 ? (
        departments.map((option) => (
            optionListOwnership.push({value: option.id, label: option.department_name})
        ))
    ) : (
        optionListOwnership.push({value: "", label: "No Data"})
    )
    const [selectedOwnershipOptions, setSelectedOwnershipOptions] = useState();

    function handleOwnership(data) {
        setSelectedOwnershipOptions(data);
        //console.log(data);
        setOwnershipId(data.value);
    }

    const optionListYear = [];
    year_of_addition_items && year_of_addition_items.length > 0 ? (
        year_of_addition_items.map((option) => (
            optionListYear.push({value: option.year_of_addition, label: option.year_of_addition})
        ))
    ) : (
        optionListYear.push({value: "", label: "No Data"})
    )
    


    function handleYearAddition(data) {
        setSelectedYearOptions(data);
        //setOwnershipId(data.value);
        setyearaddition(data.value);
    }


    /*const optionTypeOfAddition = [];
    type_of_addition_items && type_of_addition_items.length > 0 ? (
        type_of_addition_items.map((option) => (
            optionTypeOfAddition.push({value: option.additions_type_name, label: option.additions_type_name})
        ))
    ) : (
        optionTypeOfAddition.push({value: "", label: "No Data"})
    )
    const [selectedTypeOfAddition, setTypeOfAdditionOptions] = useState();
    
    function handleTypeAddition(data) {
        setTypeOfAdditionOptions(data);
        settypeaddition(data.value);
    } */
   
    const handleTypeAddition = (e) => {
        const [value1, value2] = e.target.value.split(',');
        settypeaddition(value1);
        setGroupingName(value2);
    }


    const optionList = [];
    parentAssets && parentAssets.length > 0 ? (
        parentAssets.map((option) => (
            optionList.push({value: option.tag, label: option.tag})
        ))
    ) : (
        optionList.push({value: "", label: "No Data"})
    )

    const [selectedOptions, setSelectedOptions] = useState();

    function handleSelect(data) {
        setSelectedOptions(data);
        setParentTag(data.value);
    }


    const optionCostCenterList = [];
    cost_center_codes && cost_center_codes.length > 0 ? (
        cost_center_codes.map((option) => (
            optionCostCenterList.push({value: option.cost_center_code, label: option.cost_center_code})
        ))
    ) : (
        optionCostCenterList.push({value: "", label: "No Data"})
    )

    const [selectedOptionsCostCenterList, setOptionsCostCenterList] = useState();

    function handleCostCenter(data) {
        //console.log(data);
        setOptionsCostCenterList(data);
        setCostCenterCode(data.value);
        let selectedItemValue = data.value;
        if (selectedItemValue != '') {
            let selectedOption = cost_center_codes.find(item => item.cost_center_code === selectedItemValue);
            //console.log(selectedOption);
            setCostCenterName(selectedOption.cost_center_name);
        } else {
            setCostCenterName('');
        }
    }

    const optionUnitOfMeasureList = [];
    UnitOfMeasure_items && UnitOfMeasure_items.length > 0 ? (
        UnitOfMeasure_items.map((option) => (
            optionUnitOfMeasureList.push({value: option.tag_unit_of_measure_name, label: option.tag_unit_of_measure_name})
        ))
    ) : (
        optionUnitOfMeasureList.push({value: "", label: "No Data"})
    )

    const [selectedUnitOfMeasureList, setOptionsUnitOfMeasureList] = useState();

    function handleUnitOfMeasure(data) {
        setUnitOfMeasure(data.value);
        setOptionsUnitOfMeasureList(data);
    }


    const optionInvoiceCurrencyList = [];
    invoice_currency_items && invoice_currency_items.length > 0 ? (
        invoice_currency_items.map((option) => (
            optionInvoiceCurrencyList.push({value: option.currency_type_name, label: option.currency_type_name})
        ))
    ) : (
        optionInvoiceCurrencyList.push({value: "", label: "No Data"})
    )

    const [selectedInvoiceCurrencyList, setOptionsInvoiceCurrencyList] = useState();

    function handleInvoiceCurrency(data) {
        setOptionsInvoiceCurrencyList(data);
        setinvoicecurrency(data.value);
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true; //alert(valid_Name);
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(yearaddition);
        if (!TEXT_REGEX.test(yearaddition)) {
            setErrYearAddition('Year of addition is required');
            valid_Name = false; //alert('1');
            //return;
        } else {
            setErrYearAddition('');
        }
        if (!TEXT_REGEX.test(typeaddition)) {
            setErrTypeAddition('Type of addition is required');
            valid_Name = false; //alert('2');
            //return;
        } else {
            setErrTypeAddition('');
        }
        if (!TEXT_REGEX.test(suppilername)) {
            setErrSuppilerName('Suppiler name is required');
            valid_Name = false; //alert('3');
            //return;
        } else {
            setErrSuppilerName('');
        }
        if (!TEXT_SPCL_REGEX.test(invoiceno)) {
            setErrInvoiceNo('Invoice no is required');
            valid_Name = false; //alert('4');
            //return;
        } else {
            setErrInvoiceNo('');
        }
        if (invoicedate !== '') {
            setErrInvoiceDate('');
        } else {
            setErrInvoiceDate('Invoice date is required');
            valid_Name = false; //alert('5');
        }
        if (securitysealdate !== '') {
            setErrSecuritySealDate('');
        } else {
            setErrSecuritySealDate('Security seal date is required');
            valid_Name = false; //alert('5');
        }
        if (!TEXT_SPCL_REGEX.test(billofentryno)) {
            setErrBillOfEntryNo('Bill of Entry No is required');
            valid_Name = false; //alert('5');
        } else {
            setErrBillOfEntryNo('');

        }
        if (boedate !== '') {
            setErrBOEDate('');
        } else {
            setErrBOEDate('Date of BOE is required');
            valid_Name = false; //alert('5');
        }
        if (!TEXT_SPCL_REGEX.test(shippingawbno)) {
            setErrShippingAWBNo('Shipping / AWB No is required');
            valid_Name = false; //alert('5');
        } else {
            setErrShippingAWBNo('');

        }
        if (shippingawbdate !== '') {
            setErrShippingAWBDate('');
        } else {
            setErrShippingAWBDate('Date of Shipping/AWB is required');
            valid_Name = false; //alert('5');
        }

        /*if (!TEXT_REGEX.test(accountingvoucherno)) {
			setErrAccountingVoucherNo('Accounting voucher no. is required'); valid_Name=false; //alert('6');
			//return;
		}else{ setErrAccountingVoucherNo('');  }

        if (!TEXT_REGEX.test(internalapprovalno)) {
			setErrInternalApprovalNo('Internal approval no. is required'); valid_Name=false; //alert('7');
			//return;
		}else{ setErrInternalApprovalNo('');  }
        
        if (!TEXT_REGEX.test(costcentercode)) {
			setErrCostCenterCode('Cost center code is required'); valid_Name=false; //alert('7');
			//return;
		}else{ setErrCostCenterCode('');  }

        if (!TEXT_REGEX.test(costcentername)) {
			setErrCostCenterName('Cost center name is required'); valid_Name=false; //alert('9');
			//return;
		}else{ setErrCostCenterName('');  }*/

        if (!TEXT_REGEX.test(invoicecurrency)) {
            setErrInvoiceCurrency('Invoice currency is required');
            valid_Name = false; //alert('10');
            //return;
        } else {
            setErrInvoiceCurrency('');
        }
        if (!TEXT_REGEX.test(exchangerate)) {
            setErrExchangeRate('Exchange rate is required');
            valid_Name = false; //alert('11');
            //return;
        } else {
            setErrExchangeRate('');
        }

        if (!TEXT_REGEX.test(forwardingandinsurance)) {
            setErrForwardingAndInsurance('Forwarding and insurance is required');
            valid_Name = false; //alert('12');
            //return;
        } else if (!NUMBER_REGEX.test(forwardingandinsurance)) {
            setErrForwardingAndInsurance('Forwarding and insurance should be a number');
            valid_Name = false; //alert('13');
        } else {
            setErrForwardingAndInsurance('');
        }

        if (!TEXT_REGEX.test(installation)) {
            setErrInstallation('Installation is required');
            valid_Name = false; //alert('14');
            //return;
        } else if (!NUMBER_REGEX.test(installation)) {
            setErrInstallation('Installation should be a number');
            valid_Name = false; //alert('15');
        } else {
            setErrInstallation('');
        }

        if (!TEXT_REGEX.test(errection)) {
            setErrErrection('Errection is required');
            valid_Name = false; //alert('16');
            //return;
        } else if (!NUMBER_REGEX.test(errection)) {
            setErrErrection('Errection should be a number');
            valid_Name = false; //alert('17');
        } else {
            setErrErrection('');
        }

        if (!TEXT_REGEX.test(totalcostwithouttax)) {
            setErrTotalCostWithoutTax('Total Cost Without Tax p.u is required');
            valid_Name = false; //alert('18');
            //return;
        } else if (!TEXT_REGEX.test(totalcostwithouttax)) {
            setErrTotalCostWithoutTax('Total Cost Without Tax p.u should be a number');
            valid_Name = false; //alert('19');
        } else {
            setErrTotalCostWithoutTax('');
        }

        if (!TEXT_REGEX.test(Customs)) {
            setErrCustoms('Customs is required');
            valid_Name = false; //alert('20');
            //return;
        } else if (!NUMBER_REGEX.test(Customs)) {
            setErrCustoms('Customs should be a number');
            valid_Name = false; //alert('21');
        } else {
            setErrCustoms('');
        }

        if (!TEXT_REGEX.test(cgst)) {
            setErrCGST('CGST is required');
            valid_Name = false; //alert('22');
            //return;
        } else if (!NUMBER_REGEX.test(cgst)) {
            setErrCGST('CGST should be a number');
            valid_Name = false; //alert('23');
        } else {
            setErrCGST('');
        }

        if (!TEXT_REGEX.test(sgst)) {
            setErrSGST('SGST is required');
            valid_Name = false; //alert('24');
            //return;
        } else if (!NUMBER_REGEX.test(sgst)) {
            setErrSGST('SGST should be a number');
            valid_Name = false; //alert('25');
        } else {
            setErrSGST('');
        }

        if (!TEXT_REGEX.test(igst)) {
            setErrIGST('IGST is required');
            valid_Name = false; //alert('26');
            //return;
        } else if (!NUMBER_REGEX.test(igst)) {
            setErrIGST('IGST should be a number');
            valid_Name = false; //alert('27');
        } else {
            setErrIGST('');
        }

        if (!TEXT_REGEX.test(othertaxes)) {
            setErrOtherTaxes('Other taxes is required');
            valid_Name = false; //alert('286');
            //return;
        } else if (!NUMBER_REGEX.test(othertaxes)) {
            setErrOtherTaxes('Other taxes should be a number');
            valid_Name = false; //alert('29');
        } else {
            setErrOtherTaxes('');
        }

        if (!TEXT_REGEX.test(totaltax)) {
            setErrTotalTax('Total tax is required');
            valid_Name = false; //alert('30');
            //return;
        } else if (!NUMBER_REGEX.test(totaltax)) {
            setErrTotalTax('Total tax should be a number');
            valid_Name = false; //alert('31');
        } else {
            setErrTotalTax('');
        }

        if (!TEXT_REGEX.test(totalcostwithtax)) {
            setErrTotalCostWithTax('Total cost with tax is required');
            valid_Name = false; //alert('32');
            //return;
        } else if (!NUMBER_REGEX.test(totalcostwithtax)) {
            setErrTotalCostWithTax('Total cost with tax should be a number');
            valid_Name = false; //alert('33');
        } else {
            setErrTotalCostWithTax('');
        }

        if (!invoicevaluefx) {
            setErrInvoiceValuefx('Basic Cost p.u in Fx is required');
            valid_Name = false; //alert('34');
            //return;
        } else if (!NUMBER_REGEX.test(invoicevaluefx)) {
            setErrInvoiceValuefx('Basic Cost p.u in Fx should be a number');
            valid_Name = false; //alert('35');
        } else {
            setErrInvoiceValuefx('');
        }

        if (!invoicevalueinr) {
            //Total Cost Without Tax p.u INR =>Basic Cost p.u in INR
            setErrInvoiceValueinr('Basic Cost p.u in INR is required');
            valid_Name = false; //alert('36');
            //return; 
        } else if (!NUMBER_REGEX.test(invoicevalueinr)) {
            setErrInvoiceValueinr('Basic Cost p.u in INR should be a number');
            valid_Name = false; //alert('37');
        } else {
            setErrInvoiceValueinr('');
        }

        if (!qty) {
            setErrQty('Qty is required');
            valid_Name = false; //alert('38');
            //return;
        } else if (!NUMBER_REGEX.test(qty)) {
            setErrQty('Qty should be a number');
            valid_Name = false; //alert('39');
        } else {
            setErrQty('');
        }
        if (!TEXT_REGEX.test(unitofmeasure)) {
            setErrUnitOfMeasure('Unit of measure is required');
            valid_Name = false; //alert('9');
            //return;
        } else {
            setErrUnitOfMeasure('');
        }
        /*if (!valueasset) {
			setErrValueAsset('Value Asset is required'); valid_Name=false; //alert('40');
			//return;
		}else if (!NUMBER_REGEX.test(valueasset)) { 
            setErrValueAsset('Value Asset should be a number'); valid_Name=false; //alert('41');
        }else{ setErrValueAsset('');  }

        if (!exchangerate) {
            setErrTax('Exchange rate is required');
            valid_Name = false; //alert('42');
            //return;
        } else if (!NUMBER_REGEX.test(exchangerate)) {
            setErrTax('Exchange rate be a number');
            valid_Name = false; //alert('43');
        } else {
            setErrTax('');
        }*/

        /*if (!valuetax) {
			setErrValueTax('Total Cost with Tax p.u is required'); valid_Name=false;
			//alert(4);//return;
		}else if (!NUMBER_REGEX.test(valuetax)) { 
            //alert(1); setErrValueTax('Total Cost with Tax p.u should be a number'); valid_Name=false;
        }else{ setErrValueTax(''); }*/
        if (!TEXT_SPCL_REGEX.test(nameasset)) {
            setErrNameAsset('Name Asset is required');
            valid_Name = false; //alert('44');
            //return;
        } else {
            setErrNameAsset('');
        }
        if (!TEXT_SPCL_REGEX.test(detailasset)) {
            setErrDetailAsset('Detail Asset is required');
            valid_Name = false; //alert('45');
            //return;
        } else {
            setErrDetailAsset('');
        }
        if (!TEXT_SPCL_REGEX.test(uniqueasset)) {
            setErrUniqueAsset('Unique Sl No of the Asset is required');
            valid_Name = false; //alert('46');
            //return;
        } else {
            setErrUniqueAsset('');
        }

        if (!TEXT_REGEX.test(make)) {
            setErrMake('Make / Manufacturer is required');
            valid_Name = false; //alert('48');
            //return;
        } else {
            setErrMake('');
        }
        if (!TEXT_REGEX.test(modalno)) {
            setErrModalNo('Modal No is required');
            valid_Name = false; //alert('49');
            //return;
        } else {
            setErrModalNo('');
        }
        if (!TEXT_REGEX.test(partno)) {
            setErrPartNo('Part No is required');
            valid_Name = false; //alert('50');
            //return;
        } else {
            setErrPartNo('');
        }

        if (!TEXT_REGEX.test(natureOfAddition)) {
            setErrNatureOfAddition('Nature Of Addition is required');
            valid_Name = false; //alert('51');
            //return;
        } else {
            setErrNatureOfAddition('');
        }
        if (textShow) {
            if (!TEXT_REGEX.test(parentTag)) {
                setErrParentTag('Parent TAG is required');
                valid_Name = false; //alert('51');
                //return;
            } else {
                setErrParentTag('');
            }
        }

        if (!TEXT_REGEX.test(natureOfPresence)) {
            setErrNatureOfPresence('Nature of Presence is required');
            valid_Name = false; //alert('52');
            //return;
        } else {
            setErrNatureOfPresence('');
        }

        if (!TEXT_REGEX.test(principalAssetTag)) {
            setErrPrAsTag('Principal Asset to which Cost is Added is required');
            valid_Name = false; //alert('53');
            //return;
        } else {
            setErrPrAsTag('');
        }
        /*if (!fnclassification || !NUMBER_REGEX.test(fnclassification)) {
			setErrFnClassification('Financials Classification is required'); valid_Name=false; //alert('54');
			//return;
		}else{ setErrFnClassification('');  //alert('3');
        }*/
        if (!TEXT_SPCL_REGEX.test(tagname)) {
            setErrTagName('Asset TAG name is required');
            valid_Name = false; //alert('55');
            //return;
        } else {
            setErrTagName('');
        }
        if (!TEXT_REGEX.test(tagdivision)) {
            setErrTagDivision('Asset TAG Branch is required');
            valid_Name = false; //alert('56');
            //return;
        } else {
            setErrTagDivision('');
        }
        if (!TEXT_REGEX.test(ownershipId)) {
            setErrOwnershipId('Ownership is required');
            valid_Name = false; //alert('57');
            //return;
        } else {
            setErrOwnershipId('');
        }
        /*if (!TEXT_REGEX.test(uploadinvoice)) {
			setErrUploadInvoice('Upload invoice is required'); valid_Name=false; //alert(uploadinvoice+'54');
			//return;
		}else{ setErrUploadInvoice('');  }

        if (!TEXT_REGEX.test(uploadassetimage)) {
			setErrUploadAssetImage('Upload asset image is required'); valid_Name=false;
			//return;
		}else{ setErrUploadAssetImage('');  }*/

        if (!TEXT_REGEX.test(tag)) {
            setErrTag('TAG is required');
            valid_Name = false; //alert('58');
            //return;
        } else {
            setErrTag('');
        }
        if (valid_Name) { //alert('validName');
            saveData(); //submit form
        } else {
            //alert('valid_Name false');
            return false;
        }
    }
    // const checkCharcterOnly = (e) => { 
    //     const { name, value } = e.target; 
    //     if((/^[A-Za-z ]+$/.test(value) ) ){
    //         this.setState({ [name]: value }); 
    //         }
    //   }
    //const saveData = () => {
    const saveData = function () {
        var data = {
            'tag': tag,
            'year_of_addition': yearaddition,
            'type_of_addition': typeaddition,
            'project_marking': project,
            'po_no': pono,
            'supplier_code': suppiler,
            'supplier_name': suppilername,
            'invoice_no': invoiceno,
            'invoice_date': (invoicedate ? getFormatedDate(invoicedate) : ''),
            'boe_no': billofentryno,
            'boe_date': (boedate ? getFormatedDate(boedate) : ''),
            'shipping_awb_no': shippingawbno,
            'shipping_awb_date': (shippingawbdate ? getFormatedDate(shippingawbdate) : ''),
            'accounting_voucher_no': accountingvoucherno,
            'internal_approval_no': internalapprovalno,
            'cost_center_code': costcentercode,
            'cost_center_name': costcentername,
            'invoice_currency': invoicecurrency,
            'exchange_rate': exchangerate,
            'invoice_value_fx': invoicevaluefx,
            'invoice_value_inr': invoicevalueinr,
            'forwarding_and_insurance': forwardingandinsurance,
            'installation': installation,
            'errection': errection,
            'total_cost_without_tax': totalcostwithouttax,
            'customs': Customs,
            'cgst': cgst,
            'sgst': sgst,
            'igst': igst,
            'other_taxes': othertaxes,
            'total_tax': totaltax,
            //'total_cost_with_tax':totalcostwithtax,
            'qty': qty,
            'value_of_asset': totalcostwithtax,
            'tax': tax,
            //'value_with_tax':valuetax,
            'asset_name': nameasset,
            'asset_description': detailasset,
            'asset_serial_no': uniqueasset,
            'manufacturer': make,
            'model_no': modalno,
            'nature_of_presence': natureOfPresence,
            'nature_of_addition': natureOfAddition,
            'parent_tag': parentTag,
            'principal_asset_tag': principalAssetTag,
            'part_no': partno,
            'comp_ref_no': compRefNo,
            'tag_name': tagname,
            'tag_division': tagdivision,
            'unit_of_measure': unitofmeasure,
            'ownership_id': ownershipId,
            'security_seal_date': (securitysealdate ? getFormatedDate(securitysealdate) : ''),
            //'asset_image':uploadassetimage,
            //'asset_invoice':uploadinvoicename,
            'company_id': sessionStorage.getItem('LoggedInCompanyId'),
            'division_id': sessionStorage.getItem('LoggedInDivisionId'),
            'created_by': sessionStorage.getItem('LoggedInUserId'),
        };
        if (Tag !== '' && Tag !== null) {
            var objAudit = {
                'audit_module': 'Addition',
                'audit_method': 'updated',
                'audit_tag': tag,
                'old_values': auditInfo,
                'new_values': data
            }
        } else {
            var objAudit = {
                'audit_module': 'Addition',
                'audit_method': 'created',
                'audit_tag': tag,
                'old_values': '',
                'new_values': data
            }
        }

        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            //const formData = new FormData();
            //formData.append("file", file);
            //formData.append("data", natureOfPresence);
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': data, 'audit_params': objAudit,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData2 = {'token': EncryptData}
            saveSingle(oData2, config).then(function (res) {
                if ((typeof (res.data.errors) !== "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    reset();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className='container' id="single-asset">
            <div className='row' ref={elementRef}>
                <div className="col page-heading">
                    <h5>Single Asset Form</h5>
                </div>

                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i> {success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert">
                        <i className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>) : ('')
                )}
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data" method="post">
                <div className='row m-2'>
                    <div className="col-md-6">
                        {/*<FromFieldText label="Year of Addition" star="*" placeholder="YYYY-YY"
                        inputValue={yearaddition}
                        setInputValue={setyearaddition}
                        //checkInputValue={checkCharcterOnly}
                        setErrorMsg={ErrYearAddition}
                />*/}
                        <div className="row">
                            <div className="col-md-6">
                                <label>Year of Addition </label><span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">

                                <Select
                                    options={optionListYear}
                                    placeholder="Select One"
                                    value={selectedYearOptions}
                                    onChange={handleYearAddition}
                                />

                                <span className="invalid-feedback"> {ErrYearAddition} </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        {/*<FromFieldText label="Type of Addition" star="*"
                        inputValue={typeaddition}
                        setInputValue={settypeaddition}
                        setErrorMsg={ErrTypeAddition}
                    />*/}
                        <div className="row">
                            <div className="col-md-6">
                                <label>Type of Addition </label><span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">
                            <select onChange={handleTypeAddition} className="form-select form-select-md">
                                    <option value="">Select One</option>
                                    {type_of_addition_items && type_of_addition_items.length > 0 ? (
                                        type_of_addition_items.map((option) => (
                                            <option value={`${option.additions_type_name},${option.grouping_name}`}
                                                    selected={(typeaddition == option.additions_type_name) ? "selected" : ''}>{option.additions_type_name}</option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select><span className="invalid-feedback"> {ErrTypeAddition} </span>
                                {/*<Select
                                    options={optionTypeOfAddition}
                                    placeholder="Select One"
                                    value={selectedTypeOfAddition}
                                    onChange={handleTypeAddition}
                                /><span className="invalid-feedback"> {ErrTypeAddition} </span>*/}    
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Project Marking"
                                         inputValue={project}
                                         setInputValue={setproject}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldText label="PO No (If Any)"
                                         inputValue={pono}
                                         setInputValue={setpono}
                        />
                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Supplier Code (if Any)"
                                         inputValue={suppiler}
                                         setInputValue={setsuppiler}

                        />
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Name of the Supplier" star="*"
                                         inputValue={suppilername}
                                         setInputValue={setsuppilername}
                                         setErrorMsg={ErrSuppilerName}
                        />
                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Invoice No" star="*"
                                         inputValue={invoiceno}
                                         setInputValue={setinvoiceno}
                                         setErrorMsg={ErrInvoiceNo}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromFieldDate
                            label="Invoice Date" star="*" placeholder="YYYY-MM-DD"
                            inputValue={invoicedate}
                            setInputValue={setinvoicedate}
                            setErrorMsg={ErrInvoiceDate}
                        />
                    </div>
                </div>


                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Bill of Entry No" star="*"
                                         inputValue={billofentryno}
                                         setInputValue={setbillofentryno}
                                         setErrorMsg={ErrBillOfEntryNo}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromFieldDate
                            label="Date of BOE" star="*" placeholder="YYYY-MM-DD"
                            inputValue={boedate}
                            setInputValue={setboedate}
                            setErrorMsg={ErrBOEDate}
                        />
                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Shipping / AWB No " star="*"
                                         inputValue={shippingawbno}
                                         setInputValue={setshippingawbno}
                                         setErrorMsg={ErrShippingAWBNo}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromFieldDate
                            label="Date of Shipping/AWB" star="*" placeholder="YYYY-MM-DD"
                            inputValue={shippingawbdate}
                            setInputValue={setshippingawbdate}
                            setErrorMsg={ErrShippingAWBDate}
                        />
                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Accounting Voucher No" //star="*"
                                         inputValue={accountingvoucherno}
                                         setInputValue={setAccountingVoucherNo}
                                         setErrorMsg={ErrAccountingVoucherNo}
                            //readOnly="readonly"
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Internal Approval No" //star="*"
                                         inputValue={internalapprovalno}
                                         setInputValue={setInternalApprovalNo}
                                         setErrorMsg={ErrInternalApprovalNo}
                            //readOnly="readonly"
                        />
                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                        {/*<FromFieldText label="Cost Center Code" //star="*"
                        inputValue={costcentercode}
                        setInputValue={setCostCenterCode}
                        setErrorMsg={ErrCostCenterCode}
                        //readOnly="readonly"
                    />*/}
                        <div className="row">
                            <div className="col-md-6">
                                <label>Cost Center Code </label>
                            </div>
                            <div className="col-md-6">

                                <Select
                                    options={optionCostCenterList}
                                    placeholder="Select One"
                                    value={selectedOptionsCostCenterList}
                                    onChange={handleCostCenter}
                                />

                                <span className="invalid-feedback"> {ErrCostCenterCode} </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <FromFieldText label="Cost Center Name" //star="*"
                                       inputValue={costcentername}
                                       setInputValue={setCostCenterName}
                                       setErrorMsg={ErrCostCenterName}
                                       readOnly="readonly"
                        />
                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                        {/*<FromFieldSelect star="*"
                label="Invoice Currency"
                placeholder="Select Invoice Currency"
                selectedValue={invoicecurrency}
                setSelectedValue={setinvoicecurrency}
                values={["INR","USD"]}
                setErrorMsg={ErrInvoiceCurrency}
                    />*/}
                        <div className="row">
                            <div className="col-md-6">
                                <label>Invoice Currency </label><span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">

                                <Select
                                    options={optionInvoiceCurrencyList}
                                    placeholder="Select One"
                                    value={selectedInvoiceCurrencyList}
                                    onChange={handleInvoiceCurrency}
                                />

                                <span className="invalid-feedback"> {ErrInvoiceCurrency} </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> Exchange Rate </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number' onChange={exchangerate_handler} 
                                       name="exchange_rate"
                                       value={exchangerate}
                                       onBlur={exchangerate_handler} 
                                       readOnly="readOnly" 
                                       className="form-control"
                                       placeholder='Enter the exchange rate'/>
                                <span className="invalid-feedback"> {ErrExchangeRate} </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> Basic Cost p.u in Fx </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number' onChange={invoicevaluefx_handler}
                                       name="invoice_value_fx"
                                       onBlur={invoicevaluefx_handler}
                                       value={invoicevaluefx} className="form-control"
                                       placeholder='Enter the Invoice Value (Fx)'/>
                                <span className="invalid-feedback"> {ErrInvoiceValuefx} </span>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> Basic Cost p.u in INR </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number'
                                       onChange={invoicevalueinr_handler}
                                       name="invoicevalueinr"
                                       value={invoicevalueinr}
                                       className="form-control" readOnly="readonly"
                                       placeholder="Enter the basic cost p.u in INR tax p.u in INR"
                                />
                                <span className="invalid-feedback"> {ErrInvoiceValueinr} </span>
                            </div>
                        </div>


                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> Forwarding & Insurance </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type="number"
                                       onChange={forwardingandinsurance_handler}
                                       onBlur={forwardingandinsurance_handler}
                                       name="forwardingandinsurance"
                                       value={forwardingandinsurance}
                                       className="form-control"
                                       placeholder="Enter the Forwarding & Insurance"
                                />
                                <span className="invalid-feedback"> {ErrForwardingAndInsurance} </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> Installation </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number'
                                       onChange={installation_handler}
                                       onBlur={installation_handler}
                                       name="installation"
                                       value={installation}
                                       className="form-control"
                                       placeholder='Enter the Installation'
                                />
                                <span className="invalid-feedback"> {ErrInstallation} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> Errection </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number'
                                       onChange={errection_handler}
                                       onBlur={errection_handler}
                                       name="errection"
                                       value={errection}
                                       className="form-control"
                                       placeholder='Enter the Errection'
                                />
                                <span className="invalid-feedback"> {ErrErrection} </span>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6'>
                        <FromFieldNumber label="Total Cost Without Tax p.u" star="*"
                                         inputValue={totalcostwithouttax}
                                         setInputValue={setTotalCostWithoutTax}
                                         setErrorMsg={ErrTotalCostWithoutTax}
                                         readOnly="readonly"
                        />


                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> Customs </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number'
                                       onChange={Customs_handler}
                                       onBlur={Customs_handler}
                                       name="Customs"
                                       value={Customs}
                                       className="form-control"
                                       placeholder='Enter the Customs'
                                />
                                <span className="invalid-feedback"> {ErrCustoms} </span>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> CGST </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number'
                                       onChange={cgst_handler}
                                       onBlur={cgst_handler}
                                       name="cgst"
                                       value={cgst}
                                       className="form-control"
                                       placeholder='Enter the CGST'
                                />
                                <span className="invalid-feedback"> {ErrCGST} </span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> SGST </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number'
                                       onChange={sgst_handler}
                                       onBlur={sgst_handler}
                                       name="sgst"
                                       value={sgst}
                                       className="form-control"
                                       placeholder='Enter the SGST'
                                />
                                <span className="invalid-feedback"> {ErrSGST} </span>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> IGST </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number'
                                       onChange={igst_handler}
                                       onBlur={igst_handler}
                                       name="igst"
                                       value={igst}
                                       className="form-control"
                                       placeholder='Enter the Errection'
                                />
                                <span className="invalid-feedback"> {ErrIGST} </span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>

                        <div className="row">
                            <div className="col-md-6">
                                <label> Other Taxes </label>
                                <span className="star error">* </span>
                            </div>
                            <div className="col-md-6">
                                <input type='number'
                                       onChange={othertaxes_handler}
                                       onBlur={othertaxes_handler}
                                       name="othertaxes"
                                       value={othertaxes}
                                       className="form-control"
                                       placeholder='Enter the Other Taxes'
                                />
                                <span className="invalid-feedback"> {ErrOtherTaxes} </span>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6'>
                        <FromFieldNumber label="Total Tax p.u" star="*"
                                         inputValue={totaltax}
                                         setInputValue={setTotalTax}
                                         setErrorMsg={ErrTotalTax}
                                         readOnly="readonly"
                        />
                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromFieldNumber label="Total Cost with Tax p.u" star="*"
                                         inputValue={totalcostwithtax}
                                         setInputValue={setTotalCostWithTax}
                                         setErrorMsg={ErrTotalCostWithTax}
                                         readOnly="readonly"
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromFieldNumber label="Qty" star="*"
                                         inputValue={qty}
                                         setInputValue={setQty}
                                         setErrorMsg={ErrQty}
                        />
                    </div>


                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        {/*<FromFieldText star="*"
                label="Unit Of Measure"
                placeholder="Unit Of Measure" 
                inputValue={unitofmeasure} 
                setInputValue={setUnitOfMeasure}
                setErrorMsg={ErrUnitOfMeasure}
                    />*/}
                        <div className="row">
                            <div className="col-md-6">
                                <label>Unit Of Measure </label><span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">

                                <Select
                                    options={optionUnitOfMeasureList}
                                    placeholder="Select One"
                                    value={selectedUnitOfMeasureList}
                                    onChange={handleUnitOfMeasure}
                                />


                                <span className="invalid-feedback"> {ErrUnitOfMeasure} </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>

                        <FromSpFieldText label="Name of the Asset (as per Invoice)" star="*"
                                         inputValue={nameasset}
                                         setInputValue={setNameAsset}
                                         setErrorMsg={ErrNameAsset}
                        />
                    </div>

                </div>


                <div className="row m-2">
                    <div className='col-md-6'>
                        <FromSpFieldTextArea maxLength="500" numRows="3" label="Detailed Description of the Asset"
                                             star="*"
                                             inputValue={detailasset}
                                             setInputValue={setdetailasset}
                                             setErrorMsg={ErrDetailAsset}
                        />
                    </div>
                    <div className="col-md-6">
                        <FromFieldSelect star="*"
                                         label="Nature of Presence"
                                         placeholder="Select Nature of Presence"
                                         selectedValue={natureOfPresence}
                                         setSelectedValue={setNatureOfPresence}
                                         values={["Identifiable", "Non Identifiable"]}
                                         setErrorMsg={ErrNatureOfPresence}
                        />
                    </div>

                </div>

                <div className='row m-2'>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label> Nature of Addition </label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-6">

                                <select onChange={handleNatureOfAddition} name="nature_of_addition"
                                        className="form-select form-select-sm">
                                    <option value="">Select nature of addition</option>
                                    <option value="New addition"
                                            selected={(natureOfAddition == "New addition") ? "selected" : ''}>New
                                        addition
                                    </option>
                                    <option value="Added to Existing Asset"
                                            selected={(natureOfAddition == "Added to Existing Asset") ? "selected" : ''}>Added
                                        to Existing Asset
                                    </option>
                                </select> <span className="invalid-feedback"> {ErrNatureOfAddition} </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>

                        {textShow ? (/*<FromFieldText label="Principal Asset to which Cost is Added" star="*"
                    inputValue={parentTag}
                    setInputValue={setParentTag}
                    setErrorMsg={ErrParentTag} 
                          
                />*/<div className="row">
                            <div className="col-6">
                                <label> Principal Asset to which Cost is Added <span
                                    className="star error"> * </span></label>
                            </div>
                            <div className="col-md-6">
                                <Select
                                    options={optionList}
                                    placeholder="Select One"
                                    value={selectedOptions}
                                    onChange={handleSelect}
                                />
                                <span className="invalid-feedback"> {ErrParentTag} </span>
                            </div>
                        </div>) : (<FromFieldText
                            label="Principal Asset to which Cost is Added"
                            inputValue="No"
                            readOnly="readonly"
                        />)}
                    </div>

                </div>

                <div className='row m-2'>
                    <div className='col-md-6 tag_name'>
                        <FromSpFieldText label="Unique Sl No of the Asset" star="*"
                                         inputValue={uniqueasset}
                                         setInputValue={setuniqueasset}
                                         setErrorMsg={ErrUniqueAsset}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Make / Manufacturer" star="*"
                                         inputValue={make}
                                         setInputValue={setmake}
                                         setErrorMsg={ErrMake}
                        />
                    </div>

                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Model No" star="*"
                                         inputValue={modalno}
                                         setInputValue={setmodalno}
                                         setErrorMsg={ErrModelNo}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Part No" star="*"
                                         inputValue={partno}
                                         setInputValue={setpartno}
                                         setErrorMsg={ErrPartNo}
                        />

                    </div>

                </div>
                <div className='row m-2'>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Control Department </label><span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">

                                <Select
                                    options={optionListOwnership}
                                    placeholder="Select One"
                                    value={selectedOwnershipOptions}
                                    onChange={handleOwnership}
                                />

                                <span className="invalid-feedback"> {ErrOwnershipId} </span>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <FromFieldText //star="*"
                            label="Comp Ref. No."
                            placeholder="Comp Ref. No."
                            inputValue={compRefNo}
                            setInputValue={setCompRefNo}
                            setErrorMsg={ErrCompRefNo}
                        /></div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromFieldText star="*"
                                       label="Asset TAG Name"
                                       placeholder="Asset TAG Name"
                                       inputValue={tagname}
                                       setInputValue={setTagName}
                                       setErrorMsg={ErrTagName}
                        /></div>
                    <div className='col-md-6'>
                        <FromFieldText star="*"
                                       label="Asset TAG Branch"
                                       placeholder="Asset TAG Branch"
                                       inputValue={tagdivision}
                                       setInputValue={setTagDivision}
                                       setErrorMsg={ErrTagDivision}
                                       readOnly="readonly"
                        />
                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-6'>
                        <div className="row">
                            <div className="col-md-6">
                                <label> Security Seal Date </label>
                                <span className="star error"> * </span>
                            </div>
                            <div className="col-md-6">
                                {(Tag !== '' && Tag !== null) ?
                                    <DatePicker wrapperClassName="cutom-form-control" className="form-control"
                                                onChange={handleSealdate}
                                                placeholderText='Select Date'
                                                selected={securitysealdate}
                                                dateFormat="yyyy-MM-dd"
                                                readOnly="readonly"
                                    />
                                    :
                                    <DatePicker wrapperClassName="cutom-form-control" className="form-control"
                                                onChange={handleSealdate}
                                                placeholderText='Select Date'
                                                selected={securitysealdate}
                                                dateFormat="yyyy-MM-dd"

                                    />
                                }

                                <span className="invalid-feedback"> {ErrSecuritySealDate} </span>
                            </div>
                        </div>
                        { /*<FromFieldDate
                        label="Security Seal Date" star="*" placeholder="YYYY-MM-DD"
                        inputValue={securitysealdate}
                        setInputValue={setSecuritySealDate}
                        setErrorMsg={ErrSecuritySealDate}
                    />/*/}
                    </div>
                    <div className='col-md-6 tag_name'>
                        {(Tag !== '' && Tag !== null) ?
                            <FromFieldText label="TAG" star="*"
                                           readOnly="readonly"
                                           inputValue={tag}
                                           setInputValue={settag}
                                           setErrorMsg={ErrTAG}
                            />
                            :
                            <FromFieldText label="TAG" star="*"
                                           inputValue={tag}
                                           setInputValue={settag}
                                           setErrorMsg={ErrTAG}
                                           readOnly="readonly"
                            />
                        }
                    </div>

                </div>
                <div className='row save__button text-center'>
                    <div className='col-md-12'>
                        {(Tag !== '' && Tag !== null) ? <div>
                                <button className="btn btn-primary btn-md" disabled={disabled}><i class="bi bi-pencil"></i>Update</button>
                                &nbsp;&nbsp;
                                {(BackUrl !== '' && BackUrl !== null) ?( <a href={'/consumable-asset-details?tag=' + Tag}
                                               className="btn btn-secondary btn-md">Cancel</a>) :(<a href={'/asset-details?tag=' + Tag } className="btn btn-secondary btn-md">Cancel</a>)}
                                </div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                      disabled={disabled}><i class="bi bi-save2"></i> SAVE </button>
                        }

                    </div>
                </div>
            </form>
        </div>
    )
}
