//App.js
import React, {Suspense,useEffect, useState,useCallback} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Register from './organisms/login/Register';
import ManageMyProfile from './organisms/login/ManageMyProfile';
import ManageMyPassword from './organisms/login/ManageMyPassword';
import ViewManual from './organisms/login/ViewManual';
import Sidebar from './molecules/Sidebar';
import './App.css';
import './MegaMenu.css';

//import DataGrid from './organisms/datagrid/DataGrid';
// const ExcelData = lazy(import('./organisms/excelData/Excel'));

import AddInvoiceCurrency from './organisms/invoice/addInvoiceCurrency';
import InvoiceCurrencyList from './organisms/invoice/invoiceCurrencylist';
import Login from './organisms/login/Login';
import ForgotPasword from './organisms/login/ForgotPasword';
import ResetPassword from './organisms/login/ResetPassword';
import CompanyProfile from './organisms/company/CreateProfile';
import CompanyProfileList from './organisms/company/CompanyProfileList';

import AddContinent from './organisms/users/AddContinent';
import ListContinent from './organisms/users/ListContinent';
import AddCountryName from './organisms/users/AddCountryName';
import ListCountryName from './organisms/users/ListCountryName';
import AddRegion from './organisms/users/AddRegion';
import ListRegion from './organisms/users/ListRegion';


import AddRole from './organisms/users/AddRole';
import ListRole from './organisms/users/ListRole';
import AddCategory from './organisms/companysettings/AddCategory';
import CategoryList from './organisms/companysettings/CategoryList';

import AddTypeYearOfAddition from './organisms/users/AddTypeYearOfAddition';
import ListTypeYearOfAddition from './organisms/users/ListTypeYearOfAddition';

import AddDivision from './organisms/users/AddDivision';
import ListDivision from './organisms/users/ListDivision';

import AddDepartmentGroup from './organisms/users/AddDepartmentGroup';
import ListDepartmentGroup from './organisms/users/ListDepartmentGroup';

import AddRFIDKeyGenerate from './organisms/rfid/AddRFIDKeyGenerate';
import ListRFIDKeyMaster from './organisms/rfid/ListRFIDKeyMaster';

import PendingForInternalRepair from './organisms/internal-repair/PendingForInternalRepair';
import CreateNewIRAssignment from './organisms/internal-repair/CreateNewIRAssignment';
import ViewIRAssignment from './organisms/internal-repair/ViewIRAssignment';
import CreateNewIRAssignmentOthers from './organisms/internal-repair/CreateNewIRAssignmentOthers';
import ListOfIRAssignment from './organisms/internal-repair/ListOfIRAssignment';
import AcceptRejectAssignment from './organisms/internal-repair/AcceptRejectAssignment';
import MyIRAssignmentList from './organisms/internal-repair/MyIRAssignmentList';
import IRAssessmentReport from './organisms/internal-repair/IRAssessmentReport';
import ViewIRAssessmentReport from './organisms/internal-repair/ViewIRAssessmentReport';
import IRAssessmentViewReport from './organisms/internal-repair/IRAssessmentViewReport';
import IRAssignmentReportsList from './organisms/internal-repair/IRAssignmentReportsList';
import ERAssignmentReportsList from './organisms/external-repair/ERAssessmentReportList';
import ERAssessmentReport from './organisms/external-repair/ERAssessmentReport';
import ERAssessmentViewReport from './organisms/external-repair/ERAssessmentViewReport';
import PendingForExternalRepair from './organisms/external-repair/PendingForExternalRepair';

import CreateNewERAssignment from './organisms/external-repair/CreateNewERAssignment';
import CreateNewERAssignmentOthers from './organisms/external-repair/CreateNewERAssignmentOthers';
import ListOfERAssignment from './organisms/external-repair/ListOfERAssignment';

import GatePass from './organisms/gate-pass/GatePass';
import GatePassFilled from './organisms/gate-pass/GatePassFilled';
import GatePassFilledDin from './organisms/gate-pass/GatePassFilledDin';
import PrintGatePass from './organisms/gate-pass/PrintGatePass';
import GatePassPrint from './organisms/gate-pass/GatePassPrint';
import GatePassList from './organisms/gate-pass/GatePassList';
import SecurityClearanceGatePassList from './organisms/gate-pass/SecurityClearanceGatePassList';
import SecurityViewGatePass from './organisms/gate-pass/SecurityViewGatePass';
import UpdateGatePass from './organisms/gate-pass/UpdateGatePass';
import ViewGatePass from './organisms/gate-pass/ViewGatePass';
import ReceiveAsset from './organisms/gate-pass/ReceiveAsset';


import BondingConfigure from './organisms/stpi/BondingConfigure';
import CgApproval from './organisms/stpi/CgApproval';
import CgApprovalListing from './organisms/stpi/CgApprovalListing';
import StpiAddBond from './organisms/stpi/StpiAddBond';
import StpiBondListing from './organisms/stpi/StpiBondListing';
import RunningBalance from './organisms/stpi/RunningBalance';

import AddRFIDReader from './organisms/rfid/AddRFIDReader';
import ListRFIDReader from './organisms/rfid/ListRFIDReader';
import LinkDlinkRFID from './organisms/rfid/LinkDlinkRFID';
import ListRFIDMapping from './organisms/rfid/ListRFIDMapping';
import SingleAssetLog from './organisms/rfid/singleAssetLog';
import AllReaderLog from './organisms/rfid/allReaderLog';
import ActiveInActiveEPC from './organisms/rfid/activeInActiveEPC';

import AddDepartment from './organisms/users/AddDepartment';
import AddSubDepartment from './organisms/users/AddSubDepartment';
import ListSubDepartment from './organisms/users/ListSubDepartment';
import ListUsers from './organisms/users/ListUsers';
import ViewUser from './organisms/users/ViewUser';
import ListDepartment from './organisms/users/ListDepartment';
import AssetInfo from './organisms/ExcelUploads/AssetInfo/AssetInfo';
import AssetDetails from './organisms/asset/AssetDetails/AssetDetails';
import AssetBasicDetails from './organisms/asset/AssetDetails/AssetBasicDetails';

import RepositoryBasicDetails from './organisms/asset/AssetDetails/RepositoryBasicDetails';
import SingleAllocation from './organisms/asset/AssetDetails/SingleAllocation';
import SingleViewAllocation from './organisms/asset/AssetDetails/SingleViewAllocation';
import ViewAllocation from './organisms/asset/AssetDetails/ViewAllocation';
import FlagAsset from './organisms/asset/AssetDetails/FlagAsset';
import BulkFlagAsset from './organisms/asset/AssetDetails/BulkFlagAsset';
import STFBulkFlagAsset from './organisms/asset/AssetDetails/StfBulkFlagAsset';
import FlagListingIn from './organisms/asset/AssetDetails/FlagListingIn';
import FlagListingInView from './organisms/asset/AssetDetails/FlagListingInView';
import FlagListingOut from './organisms/asset/AssetDetails/FlagListingOut';
import NonUsableList from './organisms/asset/AssetDetails/NonUseableList';
import FlagAssetSTF from './organisms/asset/AssetDetails/FlagAssetSTF';
import BranchTransferList from './organisms/asset/AssetDetails/BranchTransferList';
import DisposalListing from './organisms/asset/AssetDetails/DisposalListing';
import AllocationLog from './organisms/asset/AssetDetails/AllocationLog';
import AllocationLogDetails from './organisms/asset/AssetDetails/AllocationLogDetails';
import SingleNonAllocation from './organisms/asset/AssetDetails/SingleNonAllocation';
import BulkAllocation from './organisms/asset/AssetDetails/BulkAllocation';
import SingleAsset from './organisms/ExcelUploads/AssetInfo/SingleAsset';
import UploadAssetImage from './organisms/ExcelUploads/AssetInfo/UploadAssetImage';
import UploadAssetImageNew from './organisms/ExcelUploads/AssetInfo/UploadAssetImageNew';
import UploadExcel from './organisms/ExcelUploads/AssetInfo/UploadExcel';
import AssetImageList from './organisms/ExcelUploads/AssetInfo/AssetImageList';
import UploadAssetInvoice from './organisms/ExcelUploads/AssetInfo/UploadAssetInvoice';
import AssetInvoiceList from './organisms/ExcelUploads/AssetInfo/AssetInvoiceList';
import UploadAssetCertificate from './organisms/ExcelUploads/AssetInfo/UploadAssetCertificate';
import AssetCertificateList from './organisms/ExcelUploads/AssetInfo/AssetCertificateList';

import NotAllocated from './organisms/asset/AssetDetails/NonAllocated';
import NonAllocable from './organisms/asset/AssetDetails/NonAllocable';
import TagMaster from './organisms/ExcelUploads/AssetInfo/TagMaster';
import AddTagDivision from './organisms/tag/addTagDivision';
import TagDivisionList from './organisms/tag/tagDivisionList';
import AddOtherTagDivision from './organisms/tag/addOtherTagDivision';
import OtherTagDivisionList from './organisms/tag/otherTagDivisionList';
import AddTypeOfAddition from './organisms/tag/addTypeOfAddition';
import TypeOfAdditionList from './organisms/tag/typeOfAdditionList';
import AddUnitOfMeasure from './organisms/tag/addUnitOfMeasure';
import UnitOfMeasureList from './organisms/tag/unitOfMeasureListList';
import AddComments from './organisms/asset/AssetDetails/AddComments';
import ViewComments from './organisms/asset/AssetDetails/ViewComments';
import AddCostCenter from './organisms/asset/AssetDetails/AddCostCenter';
import ViewCostCenter from './organisms/asset/AssetDetails/ViewCostCenter';

import AddIncomeTaxMaster from './organisms/asset/AssetDetails/AddIncomeTaxMaster';
import ViewIncomeTaxMaster from './organisms/asset/AssetDetails/ViewIncomeTaxMaster';

import ViewAuditLog from './organisms/asset/AssetDetails/ViewAuditLog';

import SingleTagDelete from './organisms/asset/AssetDetails/SingleTagDelete';
import BulkTagDelete from './organisms/asset/AssetDetails/BulkTagDelete';

import AddScanSerialNo from './organisms/asset/AssetDetails/AddScanSerialNo';
import ViewScanSerialNo from './organisms/asset/AssetDetails/ViewScanSerialNo';
import CustomNotificationList from './notifications/CustomNotificationList';
import AuditLogsList from './organisms/company/AuditLogsList';
import AuditLogsView from './organisms/company/AuditLogsView';
import ViewScanTagList from './organisms/asset/AssetDetails/ViewScanTagList';

import SingleAssetCapitalization from './organisms/ExcelUploads/AssetInfo/SingleAssetCapitalization';
import BulkCapitalization from './organisms/ExcelUploads/AssetInfo/BulkCapitalization';
import AssetCapitalizationList from './organisms/ExcelUploads/AssetInfo/AssetCapitalizationList';
import AssetCapitalized from './organisms/ExcelUploads/AssetInfo/AssetCapitalized';
import AssetNotCapitalized from './organisms/ExcelUploads/AssetInfo/AssetNotCapitalized';
import AssetCapitalizationView from './organisms/ExcelUploads/AssetInfo/AssetCapitalizationView';
import AssetDepreciation from './organisms/ExcelUploads/AssetInfo/AssetDepreciation';
import UserAssetDepreciation from './organisms/ExcelUploads/AssetInfo/UserAssetDepreciation';
import AssetGenrateDepreciation from './organisms/ExcelUploads/AssetInfo/AssetGenrateDepreciation';
import DisposalForm from './organisms/ExcelUploads/AssetInfo/DisposalForm';
import AssetList from './organisms/ExcelUploads/AssetInfo/Assetlist';
import MyAssetList from './organisms/ExcelUploads/AssetInfo/MyAssetList';
import MyAssetRepository from './organisms/ExcelUploads/AssetInfo/MyAssetRepository';
import MyConsumableAssetList from './organisms/ExcelUploads/AssetInfo/MyConsumableAssetList';
import ConsumablesAssetDetails from './organisms/asset/AssetDetails/ConsumablesAssetDetails';
import AllConsumableAssetList from './organisms/ExcelUploads/AssetInfo/AllConsumableAssetList';
import ConsumableAssetDetails from './organisms/asset/AssetDetails/ConsumableAssetDetails';
import PastAssetList from './organisms/ExcelUploads/AssetInfo/PastAssetList';
import MyPastAssetList from './organisms/ExcelUploads/AssetInfo/MyPastAssetList';
import DepreciationInfo from './organisms/ExcelUploads/DepreciationInfo/DepreciationInfo';
import DepreciationSummary from './organisms/ExcelUploads/DepreciationInfo/DepreciationSummary';
import FarDepreciation from './organisms/ExcelUploads/DepreciationInfo/FarDepreciation';
import IGaapAdjustmentTable from './organisms/ExcelUploads/DepreciationInfo/IGaapAdjustmentTable';
import IGaapAdjustment from './organisms/ExcelUploads/DepreciationInfo/IGaapAdjustment';
import UploadIGaapAdjustment from './organisms/ExcelUploads/DepreciationInfo/UploadIGaapAdjustment';
import UploadDepreciation from './organisms/ExcelUploads/DepreciationInfo/UploadDepreciation';
import PhysicalVerificationInfo from './organisms/ExcelUploads/PhysicalVerificationInfo/PhysicalVerificationInfo';
import PhysicalVerificationMaster from './organisms/ExcelUploads/PhysicalVerificationInfo/PhysicalVerificationMaster';
import PhysicalVerificationList from './organisms/ExcelUploads/PhysicalVerificationInfo/PhysicalVerificationList';
import SeekExtationRequest from './organisms/ExcelUploads/PhysicalVerificationInfo/SeekExtensionRequest';
import PhysicalVerificationReport from './organisms/ExcelUploads/PhysicalVerificationInfo/PhysicalVerificationReport';
import NotPhysicalVerifiedReport from './organisms/ExcelUploads/PhysicalVerificationInfo/NotPhysicalVerifiedReport';
import NotIdentifiableReport from './organisms/ExcelUploads/PhysicalVerificationInfo/NotIdentifiableReport';
import SingleTagDeletePV from './organisms/ExcelUploads/PhysicalVerificationInfo/SingleTagDeletePV';
import BulkTagDeletePV from './organisms/ExcelUploads/PhysicalVerificationInfo/BulkTagDeletePV';


import InternalTransfer from './organisms/transfers/InternalTransfer/InternalTransfer';
import InternalTransferList from './organisms/transfers/InternalTransfer/InternalTransferList';
import InternalTransferView from './organisms/transfers/InternalTransfer/InternalTransferView';

import Stpiform from './organisms/Stpiform/StpiForm';
import StpiformList from './organisms/Stpiform/StpiFormList';
import StpiformView from './organisms/Stpiform/StpiFormView';
import AssetDisposal from './organisms/asset/AssetDisposal/AssetDisposal';
import AssetDisposalList from './organisms/asset/AssetDisposal/AssetDisposalList';

import PendingTransfer from './organisms/transfers/branch/PendingTransfer';
import PendingTransferView from './organisms/transfers/branch/PendingTransferView';
import InitiateTransfer from './organisms/transfers/branch/InitiateTransfer';
import BulkTransfer from './organisms/transfers/branch/BulkTransfer';
import UpdateTransportDetails from './organisms/transfers/branch/UpdateTransportDetails';
import TransportList from './organisms/transfers/branch/TransportList';
import TransferMadeList from './organisms/transfers/branch/TransferMadeList';
import TransferReceived from './organisms/transfers/branch/TransferReceived';
import TransferReceivedList from './organisms/transfers/branch/TransferReceivedList';
import TransferConfirmReceivedList from './organisms/transfers/branch/TransferConfirmReceivedList';
import TransferReceivedView from './organisms/transfers/branch/TransferReceivedView';

import SendRepair from './organisms/transfers/TemproryTransfer/SendRepair';
import ReciveRepair from './organisms/transfers/TemproryTransfer/ReciveRepair';
import SendTesting from './organisms/transfers/TemproryTransfer/SendTesting';
import ReciveTesting from './organisms/transfers/TemproryTransfer/ReciveTesting';

import PermanentTransfer from './organisms/transfers/PermanentTransfer/PermanentTransfer';
import PermanentTransferList from './organisms/transfers/PermanentTransfer/PermanentTransferList';
import QRGenrate from './organisms/QRGenrate/QRGenrate';
import AllQRGenrate from './organisms/QRGenrate/AllQRGenrate';
import QRPending from './organisms/QRGenrate/QRPending';
import StickeringStatusList from './organisms/QRGenrate/StickeringStatusList';
//import Home from './organisms/Home/Home';
import Dashboard from './organisms/dashboard/Dashboard';
import Dashboard1 from './organisms/dashboard/Dashboard_1';
import UserDashboard from './organisms/dashboard/UserDashboard';

import PendingDisposalList from './organisms/disposal/disposallist/PendingDisposalList';
import AddForm from './organisms/disposal/initiatesingledisposal/AddForm';
import AddFormOne from './organisms/disposal/initiatesingledisposal/AddFormOne';
import BulkInitiateDisposal from './organisms/disposal/BulkInitiateDisposal';
import BulkAssetQuatation from './organisms/disposal/BulkAssetQuatation';
import BulkFinalizeDeal from './organisms/disposal/BulkFinalizeDeal';
import FormList from './organisms/disposal/initiatesingledisposal/FormList';
import BulkUpload from './organisms/disposal/bulkdisposal/BulkUpload';
import BulkUploadList from './organisms/disposal/bulkdisposal/BulkUploadList';
import UploadAuthorization from './organisms/disposal/uploadauthorization/UploadAuthorization';
import DisposalList from './organisms/disposal/disposallist/DisposalList';

import SingleAssetQuote from './organisms/disposal/singleassetquote/SingleAssetQuote';
import SingleAssetQuoteOne from './organisms/disposal/singleassetquote/SingleAssetQuoteOne';
import BulkAssetQuote from './organisms/disposal/bulkassetquote/BulkAssetQuote';
import BulkAssetQuoteList from './organisms/disposal/bulkassetquote/BulkAssetQuoteList';
import ListQuote from './organisms/disposal/listofquote/ListQuote';
import ListQuoteAssets from './organisms/disposal/listofquote/ListQuoteAssets';
import SingleFinalizeDeal from './organisms/disposal/singlefinalizedeal/SingleFinalizeDeal';
import SingleFinalizeDealList from './organisms/disposal/singlefinalizedeal/SingleFinalizeDealList';
//import BulkFinalizeDeal from './organisms/disposal/bulkfinalizedeal/BulkFinalizeDeal';
import BulkFinalizeDealList from './organisms/disposal/bulkfinalizedeal/BulkFinalizeDealList';
import ListofFinalizeDeal from './organisms/disposal/listoffinalizedeal/ListofFinalizeDeal';
import ExceptionReport from './organisms/disposal/exceptionreport/ExceptionReport';
import ExceptionReportList from './organisms/disposal/exceptionreport/ExceptionReportList';
import GSTEvaluation from './organisms/disposal/gstevaluation/GSTEvaluation';
import ITEvaluation from './organisms/disposal/itevaluation/ITEvaluation';
import ITEvaluationList from './organisms/disposal/itevaluation/ITEvaluationList';
import ConfirmDisposal from './organisms/disposal/confirmdisposal/ConfirmDisposal';
import ConfirmDisposalAdd from './organisms/disposal/confirmdisposal/ConfirmDisposalAdd';
import ConfirmDisposalConfirm from './organisms/disposal/confirmdisposal/ConfirmDisposalConfirm';
import GenerateReversalEntry from './organisms/disposal/generatereversalentry/GenerateReversalEntry';

import InitiateSingleTransfer from './organisms/transfers/initiatesingletransfer/InitiateSingleTransfer';
import InitiateSingleTransferOne from './organisms/transfers/initiatesingletransfer/InitiateSingleTransferOne';
import InitiateSingleTransferTwo from './organisms/transfers/initiatesingletransfer/InitiateSingleTransferTwo';
import InitiateSingleTransferThree from './organisms/transfers/initiatesingletransfer/InitiateSingleTransferThree';
import InitiateSingleTransferFour from './organisms/transfers/initiatesingletransfer/InitiateSingleTransferFour';
import InitiateBulkTransfer from './organisms/transfers/initiatebulktransfer/InitiateBulkTransfer';
import InitiateBulkTransferList from './organisms/transfers/initiatebulktransfer/InitiateBulkTransferList';
import UploadApproval from './organisms/transfers/uploadapproval/UploadApproval';
import InternalTransferListNew from './organisms/transfers/internaltransferlistnew/InternalTransferListNew';
import ExternalTransferListNew from './organisms/transfers/externaltransferlistnew/ExternalTransferListNew';
import Confirm from './organisms/transfers/confirm/Confirm';
import ConfirmOne from './organisms/transfers/confirm/ConfirmOne';
import OtpVerify from './organisms/login/OtpVerify';

import RaiseTickets from "./organisms/ticket/RaiseTickets";
import ViewRaisedTickets from "./organisms/ticket/ViewRaisedTickets";
import ViewAssignedTickets from "./organisms/ticket/ViewAssignedTickets";

import AddTicketAdmin from "./organisms/ticket/AddTicketAdmin";
import ListTicketAdmins from "./organisms/ticket/ListTicketAdmins";
import AddTicketSubject from "./organisms/ticket/AddTicketSubject";
import ListTicketSubject from "./organisms/ticket/ListTicketSubject";
import ViewAllTicketsList from "./organisms/ticket/ViewAllTicketsList";
import AddUserManual from "./organisms/users/AddUserManual";
import UserManualList from "./organisms/users/UserManualList";
//import AddIncomeTaxMasters from "./organisms/asset/AssetDetails/AddIncomeTaxMasters";
//import SingleTagDelete from "./organisms/asset/AssetDetails/SingleTagDelete";
//import { useEffect } from 'react';
//import { setupNotifications } from './notifications/firebase';

import { toastNotification, sendNativeNotification } from './notifications/notificationHelpers';
import useVisibilityChange from './notifications/useVisibilityChange';
import { register } from './notifications/serviceWorker';
import { getNotificationList } from './services/notification.service';
import { getCompanyProfileDDList, getDivisionDDList, getDepartmentDDList }  from './services/user.service';
import  * as CryptoJS  from "crypto-js";
import GatePassPrintPage from "./organisms/gate-pass/GatePassPrint";
//import SingleTagDeletePV from "./organisms/ExcelUploads/PhysicalVerificationInfo/SingleTagDeletePV";


Date.prototype.dbFormatedDate = function () {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return day + '-' + month + '-' + year;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}
var getRoutes = () => {
    let sModuleId = sessionStorage.getItem('LoggedInModuleId');
    let aAccessModuleId = sModuleId.split(',');
     // console.log(aAccessModuleId.indexOf('27'));
    let sSubModuleId = sessionStorage.getItem('LoggedInSubModuleId');
    let aSubModuleId = sSubModuleId.split(',');
      console.log(aSubModuleId.indexOf('175'));

    return (
        <>
            <Routes> 
                <Route path="/manage-my-profile" exact element={<ManageMyProfile/>}/>
                <Route path="/manage-my-password" exact element={<ManageMyPassword/>}/>
                <Route path="/view-manual" exact element={<ViewManual/>}/>
                <><Route path="/notifications" exact element={<CustomNotificationList/>}/></>
                {(aAccessModuleId.indexOf('27') > -1) ? (<>
                    {(aSubModuleId.indexOf('175') > -1) ? (<><Route path="/dashboard" exact element={<Dashboard1/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('176') > -1) ? (<><Route path="/user_dashboard" exact element={<UserDashboard/>}/></>) : (<></>)}
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('1') > -1) ? (<>
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/tag-master" exact element={<TagMaster/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/single-asset" exact element={<SingleAsset/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/asset-info" exact element={<AssetInfo/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('17') > -1) ? (<><Route path="/asset-list" exact element={<AssetList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('58') > -1) ? (<><Route path="/my-asset-list" exact element={<MyAssetList/>}/></>) : (<></>)}
                        
                        {(aSubModuleId.indexOf('101') > -1) ? (<><Route path="/my-consumable-asset-list" exact element={<MyConsumableAssetList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('101') > -1) ? (<><Route path="/consumables-asset-details" exact element={<ConsumablesAssetDetails/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('146') > -1) ? (<><Route path="/all-consumable-asset-list" exact element={<AllConsumableAssetList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('146') > -1) ? (<><Route path="/consumable-asset-details" exact element={<ConsumableAssetDetails/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('102') > -1) ? (<><Route path="/past-asset-list" exact element={<MyPastAssetList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('102') > -1) ? (<><Route path="/my-past-asset-list" exact element={<MyPastAssetList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('18') > -1) ? (<><Route path="/asset-details" exact element={<AssetDetails/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('57') > -1) ? (<><Route path="/asset-basic-details" exact element={<AssetBasicDetails/>}/></>) : (<></>)}
                        
                        {(aSubModuleId.indexOf('81') > -1) ? (<><Route path="/asset-basic-details" exact element={<AssetBasicDetails/>}/></>) : (<></>)}
                        
                        {(aSubModuleId.indexOf('79') > -1) ? (<><Route path="/add-comments" exact element={<AddComments/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('80') > -1) ? (<><Route path="/view-comments" exact element={<ViewComments/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('81') > -1) ? (<><Route path="/add-scan-serial-no" exact element={<AddScanSerialNo/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('81') > -1) ? (<><Route path="/view-scan-serial-no" exact element={<ViewScanSerialNo/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('81') > -1) ? (<><Route path="/view-scan-tag-list" exact element={<ViewScanTagList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('91') > -1) ? (<><Route path="/my-asset-repository" exact element={<MyAssetRepository/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('91') > -1) ? (<><Route path="/repository-basic-details" exact element={<RepositoryBasicDetails/>}/></>) : (<></>)}
                        {/* Ad dCost center master */}
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/add-cost-center" exact element={<AddCostCenter/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/view-cost-center" exact element={<ViewCostCenter/>}/></>) : (<></>)}

                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/add-income-tax-master" exact element={<AddIncomeTaxMaster/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/view-income-tax-master" exact element={<ViewIncomeTaxMaster/>}/></>) : (<></>)}

                        {(aSubModuleId.indexOf('93') > -1) ? (<><Route path="/single-tag-delete" exact element={<SingleTagDelete/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('94') > -1) ? (<><Route path="/bulk-tag-delete" exact element={<BulkTagDelete/>}/></>) : (<></>)}
                        
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/add-user-manual" exact element={<AddUserManual/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/user-manual-list" exact element={<UserManualList/>}/></>) : (<></>)}
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('25') > -1) ? (<>
                    {(aSubModuleId.indexOf('99') > -1) ? (<><Route path="/audit-log-list" exact element={<AuditLogsList/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('100') > -1) ? (<><Route path="/audit-log-view" exact element={<AuditLogsView/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('100') > -1) ? (<><Route path="/view-audit-log" exact element={<ViewAuditLog/>}/></>) : (<></>)}
                    </>
                    ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('29') > -1) ? (<>
                    {(aSubModuleId.indexOf('120') > -1) ? (<><Route path="/link-dlink-rfid" exact element={<LinkDlinkRFID/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('121') > -1) ? (<><Route path="/list-rfid-mapping" exact element={<ListRFIDMapping/>}/></>) : (<></>)}

                        {(aSubModuleId.indexOf('122') > -1) ? (<><Route path="/single-asset-log" exact element={<SingleAssetLog/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('123') > -1) ? (<><Route path="/all-reader-log" exact element={<AllReaderLog/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('124') > -1) ? (<><Route path="/active-in-active-epc" exact element={<ActiveInActiveEPC/>}/></>) : (<></>)}
                    </>
                    ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('26') > -1) ? (<>
                        {(aSubModuleId.indexOf('110') > -1) ? (<><Route path="/raise-tickets" exact
                                                                       element={<RaiseTickets/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('111') > -1) ? (<><Route path="/view-raised-tickets" exact
                                                                       element={<ViewRaisedTickets/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('112') > -1) ? (<><Route path="/view-assigned-tickets" exact
                                                                       element={<ViewAssignedTickets/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('113') > -1) ? (<><Route path="/view-all-tickets-list" exact
                                                                       element={<ViewAllTicketsList/>}/></>) : (<></>)}                                            
                    </>
                    ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('2') > -1) ? (<>
                    {(aSubModuleId.indexOf('19') > -1) ? (<><Route path="/qrgenrate" exact element={<QRGenrate/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('19') > -1) ? (<><Route path="/allqrgenrate" exact element={<AllQRGenrate/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('19') > -1) ? (<><Route path="/qrpending" exact element={<QRPending/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('95') > -1) ? (<><Route path="/stickering-status" exact element={<StickeringStatusList/>}/></>) : (<></>)}
                    </>
                    ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('13') > -1) ? (<>
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/upload-excel" exact element={<UploadExcel/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('20') > -1) ? (<><Route path="/upload-image-new" exact element={<UploadAssetImageNew/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('21') > -1) ? (<><Route path="/images-list" exact element={<AssetImageList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('11') > -1) ? (<><Route path="/upload-invoice" exact element={<UploadAssetInvoice/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('12') > -1) ? (<><Route path="/invoice-list" exact element={<AssetInvoiceList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('22') > -1) ? (<><Route path="/upload-certificate" exact element={<UploadAssetCertificate/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('23') > -1) ? (<><Route path="/certificate-list" exact element={<AssetCertificateList/>}/></>) : (<></>)}
                        
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('3') > -1) ? (<>
                        {(aSubModuleId.indexOf('27') > -1) ? (<><Route path="/single-allocation" exact element={<SingleAllocation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('28') > -1) ? (<><Route path="/bulk-allocation" exact element={<BulkAllocation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('29') > -1) ? (<><Route path="/single-nonallocated" exact element={<SingleNonAllocation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('29') > -1) ? (<><Route path="/view-allocation" exact element={<ViewAllocation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('75') > -1) ? (<><Route path="/not-allocated" exact element={<NotAllocated/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('96') > -1) ? (<><Route path="/not-allocable" exact element={<NonAllocable/>}/></>) : (<></>)}
                    
                        {(aSubModuleId.indexOf('29') > -1) ? (<><Route path="/allocation-log" exact element={<AllocationLog/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('29') > -1) ? (<><Route path="/single-view-allocation" exact element={ <SingleViewAllocation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('29') > -1) ? (<><Route path="/allocation-log-details" exact element={ <AllocationLogDetails/>}/></>) : (<></>)}
                    </>
                ) : (
                    <></>
                )}

                {(aAccessModuleId.indexOf('24') > -1) ? (<>
                        {(aSubModuleId.indexOf('76') > -1) ? (<><Route path="/flag-asset" exact element={<FlagAsset/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('181') > -1) ? (<><Route path="/bulk-flag-asset" exact element={<BulkFlagAsset/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('78') > -1) ? (<><Route path="/flag-listing-out" exact element={<FlagListingOut/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('77') > -1) ? (<><Route path="/flag-listing-in" exact element={<FlagListingIn/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('77') > -1) ? (<><Route path="/flag-listing-in-view" exact element={<FlagListingInView/>}/></>) : (<></>)}
                        
                        {(aSubModuleId.indexOf('97') > -1) ? (<><Route path="/branch-transfer-listing" exact element={<BranchTransferList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('98') > -1) ? (<><Route path="/disposal-listing" exact element={<DisposalListing/>}/></>) : (<></>)}
                    </>
                ) : (
                    <></>
                )}

                {(aAccessModuleId.indexOf('22') > -1) ? (<>
                    {(aSubModuleId.indexOf('83') > -1) ? (<><Route path="/pending-transfer" exact element={<PendingTransfer/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('83') > -1) ? (<><Route path="/pending-transfer-view" exact element={<PendingTransferView/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('84') > -1) ? (<><Route path="/initiate-transfer" exact element={<InitiateTransfer/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('85') > -1) ? (<><Route path="/bulk-transfer" exact element={<BulkTransfer/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('86') > -1) ? (<><Route path="/update-transport-details" exact element={<UpdateTransportDetails/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('87') > -1) ? (<><Route path="/transport-list" exact element={<TransportList/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('88') > -1) ? (<><Route path="/upload-approval" exact element={<UploadApproval/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('89') > -1) ? (<><Route path="/transfer-made-list" exact element={<TransferMadeList/>}/></>) : (<></>)}
                    {/* {(aSubModuleId.indexOf('90') > -1) ? (<><Route path="/transfer-received" exact element={<TransferReceived/>}/></>) : (<></>)} */}
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('23') > -1) ? (<>
                    {(aSubModuleId.indexOf('90') > -1) ? (<><Route path="/pending-received-list" exact element={<TransferReceivedList/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('90') > -1) ? (<><Route path="/transfer-received-list" exact element={<TransferConfirmReceivedList/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('90') > -1) ? (<><Route path="/transfer-received-view" exact element={<TransferReceivedView/>}/></>) : (<></>)}
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('28') > -1) ? (<>
                    {(aSubModuleId.indexOf('96') > -1) ? (<><Route path="/non-usable-list" exact element={<NonUsableList/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('96') > -1) ? (<><Route path="/start-assignment-stf" exact element={<FlagAssetSTF/>}/></>) : (<></>)}
                    {(aSubModuleId.indexOf('96') > -1) ? (<><Route path="/bulk-assignment-stf" exact element={<STFBulkFlagAsset/>}/></>) : (<></>)}
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('4') > -1) ? (<>
                        {(aSubModuleId.indexOf('24') > -1) ? (<><Route path="/single-asset-capitalization" exact element={ <SingleAssetCapitalization/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('25') > -1) ? (<> <Route path="/bulk-capitalization" exact element={<BulkCapitalization/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('26') > -1) ? (<><Route path="/assets-capitalization" exact element={ <AssetCapitalizationList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('26') > -1) ? (<><Route path="/assets-capitalization-view" exact element={ <AssetCapitalizationView/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('26') > -1) ? (<><Route path="/assets-capitalized" exact element={<AssetCapitalized/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('26') > -1) ? (<><Route path="/assets-notcapitalized" exact element={<AssetNotCapitalized/>}/></>) : (<></>)}
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('5') > -1) ? (<>
                        {(aSubModuleId.indexOf('40') > -1) ? (<><Route path="/depreciation-summary" exact element={<DepreciationSummary/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('40') > -1) ? (<><Route path="/far-depreciation" exact element={<FarDepreciation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('41') > -1) ? (<><Route path="/igaap-adjustment" exact element={<IGaapAdjustment/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('42') > -1) ? (<><Route path="/upload-igaap-adjustment" exact element={ <UploadIGaapAdjustment/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('43') > -1) ? (<><Route path="/igaap-adjustment-list" exact element={ <IGaapAdjustmentTable/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('44') > -1) ? (<><Route path="/depreciation" exact element={<DepreciationInfo/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('174') > -1) ? (<><Route path="/asset-depreciation" exact element={<AssetDepreciation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/user-asset-depreciation" exact element={<UserAssetDepreciation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('15') > -1) ? (<><Route path="/generate-depreciation" exact element={<AssetGenrateDepreciation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('92') > -1) ? (<><Route path="/upload-depreciation" exact element={<UploadDepreciation/>}/></>) : (<></>)}
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('6') > -1) ? (<>
                        <Route path="/disposal" exact element={<DisposalForm/>}/>
                        <Route path="/asset-disposal" exact element={<AssetDisposal/>}/>
                        <Route path="/asset-disposal-list" exact element={<AssetDisposalList/>}/>
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('7') > -1) ? (<>
                        <Route path="/internal-transfer" exact element={<InternalTransfer/>}/>
                        <Route path="/internal-transfer-list" exact element={<InternalTransferList/>}/>
                        <Route path="/internal-transfer-view" exact element={<InternalTransferView/>}/>
                        <Route path="/permanent-transfer" exact element={<PermanentTransfer/>}/>
                        <Route path="/permanent-transfer-list" exact element={<PermanentTransferList/>}/>

                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('8') > -1) ? (<>
                        <Route path="/send-repair" exact element={<SendRepair/>}/>
                        <Route path="/recieve-repair" exact element={<ReciveRepair/>}/>
                        <Route path="/send-testing" exact element={<SendTesting/>}/>
                        <Route path="/recive-testing" exact element={<ReciveTesting/>}/>
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('9') > -1) ? (<>
                        <Route path="/stpi" exact element={<Stpiform/>}/>
                        <Route path="/stpi-list" exact element={<StpiformList/>}/>
                        <Route path="/stpi-view" exact element={<StpiformView/>}/>
                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('10') > -1) ? (<>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/companyprofile" exact element={<CompanyProfile/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/companyprofile/list" exact element={<CompanyProfileList/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}

                        <Route path="/country/addcontinent" exact element={<AddContinent/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/country/listcontinent" exact element={<ListContinent/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}

                        <Route path="/country/addcountry" exact element={<AddCountryName/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/country/listcountry" exact element={<ListCountryName/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}

                        <Route path="/country/addregion" exact element={<AddRegion/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/country/listregion" exact element={<ListRegion/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}

                        <Route path="/role/add" exact element={<AddRole/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/role/list" exact element={<ListRole/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/division/add" exact element={<AddDivision/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/division/list" exact element={<ListDivision/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/department/add" exact element={<AddDepartment/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/department/addSubDepartment" exact element={<AddSubDepartment/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/department/list" exact element={<ListDepartment/>}/>
                        <Route path="/department/sub-department-list" exact element={<ListSubDepartment/>}/>


                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/category/add" exact element={<AddCategory/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/category/list" exact element={<CategoryList/>}/>
                        <Route path="/addinvoicecurrency" exact element={<AddInvoiceCurrency/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/invoicecurrencylist" exact element={<InvoiceCurrencyList/>}/>

                        <Route path="/addtagdivision" exact element={<AddTagDivision/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/tagdivisionlist" exact element={<TagDivisionList/>}/>

                        <Route path="/addothertagdivision" exact element={<AddOtherTagDivision/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/othertagdivisionlist" exact element={<OtherTagDivisionList/>}/>

                        <Route path="/addtypeofaddition" exact element={<AddTypeOfAddition/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/typeofadditionlist" exact element={<TypeOfAdditionList/>}/>

                        <Route path="/addunitofmeasure" exact element={<AddUnitOfMeasure/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/unitofmeasurelist" exact element={<UnitOfMeasureList/>}/>

                        <Route path="/division/add" exact element={<AddDivision/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/division/list" exact element={<ListDivision/>}/>

                        <Route path="/division/add-department-group" exact element={<AddDepartmentGroup/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/division/list-department-group" exact element={<ListDepartmentGroup/>}/>

                        <Route path="/division/add-type-year-of-addition" exact element={<AddTypeYearOfAddition/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/division/list-type-year-of-addition" exact element={<ListTypeYearOfAddition/>}/>

                        <Route path="/add-ticket-subject" exact element={<AddTicketSubject/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/list-ticket-subject" exact element={<ListTicketSubject/>}/>

                        <Route path="/add-ticket-admin" exact element={<AddTicketAdmin/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/list-ticket-admin" exact element={<ListTicketAdmins/>}/>

                        <Route path="/add-rfid-reader" exact element={<AddRFIDReader/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/list-rfid-reader" exact element={<ListRFIDReader/>}/>

                        <Route path="/add-rfid-key" exact element={<AddRFIDKeyGenerate/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/list-rfid-key" exact element={<ListRFIDKeyMaster/>}/>

                        <Route path="/pending-internal-repair" exact element={<PendingForInternalRepair/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/list-of-ir-assignment" exact element={<ListOfIRAssignment/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}
                        <Route path="/create-new-ir-assignment" exact element={<CreateNewIRAssignment/>}/>
                        <Route path="/view-ir-assignment" exact element={<ViewIRAssignment/>}/>
                        <Route path="/create-new-ir-assignment-others" exact element={<CreateNewIRAssignmentOthers/>}/>
                        
                        <Route path="/create-new-er-assignment" exact element={<CreateNewERAssignment/>}/>
                        <Route path="/create-new-er-assignment-others" exact element={<CreateNewERAssignmentOthers/>}/>
                        <Route path="/list-of-er-assignment" exact element={<ListOfERAssignment/>}/>
                        {(aSubModuleId.indexOf('16') > -1) ? (<></>) : (<></>)}

                        


                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('35') > -1) ? (<>
                        {(aSubModuleId.indexOf('149') > -1) ? (<><Route path="/pending-internal-repair" exact element={<PendingForInternalRepair/>}/></>) : (<></>)}

                        {(aSubModuleId.indexOf('150') > -1) ? (<><Route path="/create-new-ir-assignment" exact element={<CreateNewIRAssignment/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('150') > -1) ? (<><Route path="/view-ir-assignment" exact element={<ViewIRAssignment/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('151') > -1) ? (<><Route path="/create-new-ir-assignment-others" exact element={<CreateNewIRAssignmentOthers/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('152') > -1) ? (<><Route path="/list-of-ir-assignment" exact element={<ListOfIRAssignment/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('156') > -1) ? (<><Route path="/accept-reject-assignment" exact element={<AcceptRejectAssignment/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('152') > -1) ? (<><Route path="/my-ir-assignment" exact element={<MyIRAssignmentList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('157') > -1) ? (<><Route path="/ir-assessment-report" exact element={<IRAssessmentReport/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('157') > -1) ? (<><Route path="/view-ir-assessment-report" exact element={<ViewIRAssessmentReport/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('158') > -1) ? (<><Route path="/ir-assessment-report-list" exact element={<IRAssignmentReportsList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('158') > -1) ? (<><Route path="/ir-assessment-view-report" exact element={<IRAssessmentViewReport/>}/></>) : (<></>)}

                       
                        <Route path="/er-assessment-report" exact element={<ERAssessmentReport/>}/>
                        <Route path="/er-assessment-view-report" exact element={<ERAssessmentViewReport/>}/>
                        <Route path="/pending-for-external-repair" exact element={<PendingForExternalRepair/>}/>


                    </>
                ) : (
                    <><Route exact element={<Register/>}/></>
                )}
                {(aAccessModuleId.indexOf('36') > -1) ? (<>
                        {(aSubModuleId.indexOf('153') > -1) ? (<><Route path="/create-new-er-assignment" exact element={<CreateNewERAssignment/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('154') > -1) ? (<><Route path="/create-new-er-assignment-others" exact element={<CreateNewERAssignmentOthers/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('155') > -1) ? (<><Route path="/list-of-er-assignment" exact element={<ListOfERAssignment/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('161') > -1) ? (<><Route path="/er-assessment-report-list" exact element={<ERAssignmentReportsList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('155') > -1) ? (<><Route path="/er-assessment-report" exact element={<ERAssessmentReport/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('160') > -1) ? (<><Route path="/er-assessment-view-report" exact element={<ERAssessmentViewReport/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('155') > -1) ? (<><Route path="/pending-for-external-repair" exact element={<PendingForExternalRepair/>}/></>) : (<></>)}
                    </>
                ) : (
                    <><Route exact element={<Register/>}/></>
                )}
                {(aAccessModuleId.indexOf('37') > -1) ? (<>
                        {(aSubModuleId.indexOf('170') > -1) ? (<><Route path="/generate-gate-pass" exact element={<GatePass/>}/></>) : (<></>)}

                        {(aSubModuleId.indexOf('170') > -1) ? (<><Route path="/generate-gate-pass-filled" exact element={<GatePassFilled/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('170') > -1) ? (<><Route path="/generate-gate-pass-din" exact element={<GatePassFilledDin/>}/></>) : (<></>)}

                        {(aSubModuleId.indexOf('170') > -1) ? (<><Route path="/view-gate-pass" exact element={<ViewGatePass/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('170') > -1) ? (<><Route path="/update-gate-pass" exact element={<UpdateGatePass/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('172') > -1) ? (<><Route path="/print-gate-pass" exact element={<PrintGatePass/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('171') > -1) ? (<><Route path="/gate-pass-listing" exact element={<GatePassList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('172') > -1) ? (<><Route path="/gate-pass-print-page" exact element={<GatePassPrint/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('173') > -1) ? (<><Route path="/receive-asset" exact element={<ReceiveAsset/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('173') > -1) ? (<><Route path="/security-clearance-gate-pass-listing" exact element={<SecurityClearanceGatePassList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('173') > -1) ? (<><Route path="/security-view-gate-pass" exact element={<SecurityViewGatePass/>}/></>) : (<></>)}
                    </>
                ) : (
                    <><Route exact element={<Register/>}/></>
                )}
                {(aAccessModuleId.indexOf('9') > -1) ? (<>
                        {(aSubModuleId.indexOf('164') > -1) ? (<><Route path="/bonding-configure" exact element={<BondingConfigure/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('165') > -1) ? (<><Route path="/cg-approval" exact element={<CgApproval/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('166') > -1) ? (<><Route path="/cg-approval-list" exact element={<CgApprovalListing/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('167') > -1) ? (<><Route path="/stpi-bond" exact element={<StpiAddBond/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('168') > -1) ? (<><Route path="/stpi-bond-list" exact element={<StpiBondListing/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('169') > -1) ? (<><Route path="/running-balance" exact element={<RunningBalance/>}/></>) : (<></>)}
                    </>
                ) : (
                    <><Route exact element={<Register/>}/></>
                )}
                {(aAccessModuleId.indexOf('11') > -1) ? (<>
                        {(aSubModuleId.indexOf('13') > -1) ? (<></>) : (<></>)}<Route path="/user/list" exact element={<ListUsers/>}/>
                        {(aSubModuleId.indexOf('13') > -1) ? (<></>) : (<></>)}<Route path="/userview" exact element={<ViewUser/>}/>
                        {(aSubModuleId.indexOf('14') > -1) ? (<></>) : (<></>)}<Route path="/register" exact element={<Register/>}/>
                    </>
                ) : (
                    <><Route exact element={<Register/>}/></>
                )}
                {(aAccessModuleId.indexOf('12') > -1) ? (<>
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/physical-verfication" exact element={ <PhysicalVerificationInfo/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/physical-verfication-master" exact element={ <PhysicalVerificationMaster/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/physical-verfication-list" exact element={<PhysicalVerificationList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/seek-extation-request" exact element={<SeekExtationRequest/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/physical-verfication-report" exact element={ <PhysicalVerificationReport/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/not-verfied-report" exact element={ <NotPhysicalVerifiedReport/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/not-identifiable" exact element={ <NotIdentifiableReport/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/single-tag-delete-pv" exact element={ <SingleTagDeletePV/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('16') > -1) ? (<><Route path="/bulk-tag-delete-pv" exact element={ <BulkTagDeletePV/>}/></>) : (<></>)}

                    </>
                ) : (
                    <></>
                )}
                {(aAccessModuleId.indexOf('6') > -1) ? (<>
                        {(aSubModuleId.indexOf('104') > -1) ? (<><Route path="/pending-disposal-list" exact element={<PendingDisposalList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('105') > -1) ? (<><Route path="/addform" exact element={<AddForm/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('105') > -1) ? (<><Route path="/addformone" exact element={<AddFormOne/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('178') > -1) ? (<><Route path="/bulk-initiate-disposal" exact element={<BulkInitiateDisposal/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('179') > -1) ? (<><Route path="/bulk-asset-quotation" exact element={<BulkAssetQuatation/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('180') > -1) ? (<><Route path="/bulk-finalize-deal" exact element={<BulkFinalizeDeal/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('105') > -1) ? (<><Route path="/formlist" exact element={<FormList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('105') > -1) ? (<><Route path="/bulkupload" exact element={<BulkUpload/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('105') > -1) ? (<><Route path="/bulkuploadlist" exact element={<BulkUploadList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('105') > -1) ? (<><Route path="/uploadauthorization" exact element={<UploadAuthorization/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('48') > -1) ? (<><Route path="/disposallist" exact element={<DisposalList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('106') > -1) ? (<><Route path="/singleassetquote" exact element={<SingleAssetQuote/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('106') > -1) ? (<><Route path="/singleassetquoteone" exact element={<SingleAssetQuoteOne/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('106') > -1) ? (<><Route path="/bulkassetquote" exact element={<BulkAssetQuote/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('106') > -1) ? (<><Route path="/bulkassetquotelist" exact element={<BulkAssetQuoteList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('107') > -1) ? (<><Route path="/listquote" exact element={<ListQuote/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('107') > -1) ? (<><Route path="/listquoteassets" exact element={<ListQuoteAssets/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('108') > -1) ? (<><Route path="/singlefinalizedeal" exact element={<SingleFinalizeDeal/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('108') > -1) ? (<><Route path="/singlefinalizedeallist" exact element={<SingleFinalizeDealList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('108') > -1) ? (<><Route path="/bulkfinalizedeal" exact element={<BulkFinalizeDeal/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('108') > -1) ? (<><Route path="/bulkfinalizedeallist" exact element={<BulkFinalizeDealList/>}/></>) : (<></>)}
                        {(aSubModuleId.indexOf('109') > -1) ? (<><Route path="/listoffinalizedeal" exact element={<ListofFinalizeDeal/>}/></>) : (<></>)}
                        <Route path="/exceptionreport" exact element={<ExceptionReport/>}/>
                        <Route path="/exceptionreportlist" exact element={<ExceptionReportList/>}/>
                        <Route path="/gstevaluation" exact element={<GSTEvaluation/>}/>
                        <Route path="/itevaluation" exact element={<ITEvaluation/>}/>
                        <Route path="/itevaluationlist" exact element={<ITEvaluationList/>}/>
                        <Route path="/confirmdisposal" exact element={<ConfirmDisposal/>}/>
                        <Route path="/confirmdisposaladd" exact element={<ConfirmDisposalAdd/>}/>
                        <Route path="/confirmdisposalconfirm" exact element={<ConfirmDisposalConfirm/>}/>
                        <Route path="/generatereversalentry" exact element={<GenerateReversalEntry/>}/>
                        <Route path="/initiatesingletransfer" exact element={<InitiateSingleTransfer/>}/>
                        <Route path="/initiatesingletransferone" exact element={<InitiateSingleTransferOne/>}/>
                        <Route path="/initiatesingletransfertwo" exact element={<InitiateSingleTransferTwo/>}/>
                        <Route path="/initiatesingletransferthree" exact element={<InitiateSingleTransferThree/>}/>
                        <Route path="/initiatesingletransferfour" exact element={<InitiateSingleTransferFour/>}/>
                        <Route path="/initiatebulktransfer" exact element={<InitiateBulkTransfer/>}/>
                        <Route path="/initiatebulktransferlist" exact element={<InitiateBulkTransferList/>}/>
                        <Route path="/uploadapproval" exact element={<UploadApproval/>}/>
                        <Route path="/internaltransferlistnew" exact element={<InternalTransferListNew/>}/>
                        <Route path="/externaltransferlistnew" exact element={<ExternalTransferListNew/>}/>
                        <Route path="/confirm" exact element={<Confirm/>}/>
                        <Route path="/confirmation" exact element={<ConfirmOne/>}/>
                        
                    </>
                ) : (
                    <></>
                )}
                <><Route path="/forgotpassword" exact element={<ForgotPasword/>}/></>
                {(sessionStorage.getItem('Forgot_Email') !=='') ? (<><Route path="/verifyotp" exact element={<OtpVerify/>}/></>) : (<></>)}
                {(sessionStorage.getItem('Forgot_OTP') !=='') ? (<><Route path="/resetpassword" exact element={<ResetPassword/>}/></>) : (<></>)}
                 
            </Routes>
        </>
    );
};

function renderProtectedData({isLoggedIn, setisLoggedIn,count,notifications,toggleNotifications,showNotifications,toggleFilter,showFilter,
    companies,divisions,departments,searchCompany,handleSearchCompany,handleSelectCompany,selectedCompany,
    searchDivision,handleSearchDivision,handleSelectDivision,selectedDivision,
    searchDepartment,handleSearchDepartment,handleSelectDepartment,selectedDepartment,handleFilter,handleClearFilter}) {
    let sModuleId = sessionStorage.getItem('LoggedInModuleId');
    let aAccessModuleId = sModuleId.split(',');
    let sSubModuleId = sessionStorage.getItem('LoggedInSubModuleId');
    let aSubModuleId = sSubModuleId.split(',');
    
    return (
        <div id="app-div">
            <header className="navbar navbar-light sticky-top flex-md-nowrap p-0 shadow">
                <a className="navbar-brand col-md-4 col-lg-4 me-0 px-3"
                   href="/">{/*<img className="img-fluid" src='Microchip_logo.png'/>*/}<h5>{sessionStorage.getItem("LoggedInCompanyName")}</h5></a>
                    <button className="navbar-toggler position-absolute collapsed" type="button"
                            data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
            <div className="d-flex1 align-items-right"></div>
            <div className="d-flex align-items-center gap-lg-2">
                    <div className="nav-item text-nowrap">
                        <span className="account-user-avatar">
                        </span>
                        <span><strong> {sessionStorage.getItem("LoggedInName")} </strong> ({sessionStorage.getItem("LoggedInRoleName")})</span>
                    </div>
                <div className="notification-icon" onClick={toggleNotifications}>
                    <div role="img" aria-label="bell"><img className="img_sidebar_icon" src={process.env.REACT_APP_BASE_URL+"icon/notification.png"}/></div>
                    {count > 0 && <span className="badge">{count}</span>}
                </div>
                {showNotifications && (
                <div className="notification-popup">
                    <h5>Notification Alerts</h5>
                    {notifications.map(notification => (
                        <div className="row">
                        <div className="col-xs-12 col-sm-offset-3">
                            <div className="tip-box-info">
                                <p  key={notification.id} style={{marginBottom:'0'}}> <i className="bi bi-info-circle-fill"></i> {notification.message}
                                <a className="btn btn-sm" href="#"> view </a></p>
                                </div>
                        </div>
                        </div>
                    ))}
                    <div className="row">
                        <div className="col-xs-12 col-sm-offset-3">
                            <p> <a className="btn btn-sm" href="/notifications">Readmore</a></p>
                        </div>
                    </div>
                </div>
                )}
                
                <div className="filter-icon" onClick={toggleFilter}>
                    <span role="img" aria-label="bell" className="dropdown-toggle btn btn-primary1"><img className="img_sidebar_icon" src={process.env.REACT_APP_BASE_URL+"icon/filter.png"}/> Filter</span>
                </div>
                {showFilter && (
                <div className="filter-popup">
                    <h5>Filter</h5>
                    
                        <div className="row">
                        <div className="col-xs-12 col-sm-offset-3">
                            {(sessionStorage.getItem("LoggedInCompanyId") == 0 || sessionStorage.getItem('CurrentCompanyId')> 0 ) ?(
                            <div className="tip-box-filter">
                            <span><strong>Company: </strong></span>
                            <span><strong>&nbsp; </strong></span>
                            <div className="nav-item text-nowrap company-dropdown">
                                    <span><span>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="profile-dropdown">
                                                <i className="bi bi-diagram-2-fill"></i>{selectedCompany}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* Search input */}
                                                <input type="text" className='form-control' placeholder="Search company..." onChange={handleSearchCompany} />
                                                <Dropdown.Item key={0}  
                                                        active={parseInt(0) === parseInt(sessionStorage.getItem("CurrentCompanyId"))}
                                                        onClick={() => handleSelectCompany({'company_id':0,'company_name':'All Company'})}>All Company
                                                    </Dropdown.Item>
                                                {/* Filtered list of companies based on search term */}
                                                {companies && companies.length > 0 ? (
                                                    <>
                                                    { companies.filter(com_option => com_option.company_name.toLowerCase().includes(searchCompany.toLowerCase()))
                                                    .map((com_option) => (
                                                        <Dropdown.Item 
                                                            key={com_option.company_id} 
                                                            active={parseInt(com_option.company_id) === parseInt(sessionStorage.getItem("CurrentCompanyId"))}
                                                            onClick={() => handleSelectCompany(com_option)}>{com_option.company_name}
                                                            
                                                        </Dropdown.Item>
                                                        ))
                                                    }
                                                </>
                                                    ) : (
                                                        <Dropdown.Item disabled>No Data</Dropdown.Item>
                                                    )
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>  </span>
                                    </div>
                            </div>):(<span>{ /*sessionStorage.getItem("LoggedInCompanyName")*/ }</span>)}
                            <div className="tip-box-filter">
                            <div className="nav-item text-nowrap department-dropdown">
                                    <span><strong>Department: </strong>{(sessionStorage.getItem("LoggedInDepartmentId")==0 || sessionStorage.getItem('CurrentDepartmentId')> 0)?(<span>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="department-dropdown">
                                            <i className="bi bi-diagram-2-fill"></i>{selectedDepartment}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            {/* Search input */}
                                            <input type="text" className='form-control' placeholder="Search Department..." onChange={handleSearchDepartment} />
                                            
                                            {departments && departments.length > 0 ? (
                                                <><Dropdown.Item key={0}  
                                                active={parseInt(0) === parseInt(sessionStorage.getItem("CurrentDepartmentId"))}
                                                onClick={() => handleSelectDepartment({'id':'0','department_name':'All Department'})}>
                                                All Department
                                            </Dropdown.Item>
                                                { departments.filter(dep_option => dep_option.department_name.toLowerCase().includes(searchDepartment.toLowerCase()))
                                                        .map((dep_option) => (
                                                <Dropdown.Item key={dep_option.id} 
                                                    active={parseInt(dep_option.id) === parseInt(sessionStorage.getItem("CurrentDepartmentId"))}
                                                    onClick={() => handleSelectDepartment(dep_option)}>{dep_option.department_name}
                                                    </Dropdown.Item>
                                                ))
                                                }</> 
                                            ) : (
                                                <option value="">No Data</option>
                                            )}</Dropdown.Menu>
                                        </Dropdown>
                                    </span>):(<span>{ sessionStorage.getItem("LoggedInDepartmentName")}</span>)}  </span>
                                    </div>
                            </div>
                            <div className="tip-box-filter">
                            <div className="nav-item text-nowrap branch-dropdown">
                                    <span><strong>Branch: </strong>{(sessionStorage.getItem("LoggedInDivisionId")==0 || sessionStorage.getItem('CurrentDivisionId')> 0)?(<span>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="branch-dropdown">
                                            <i className="bi bi-diagram-2-fill"></i>{selectedDivision}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            {/* Search input */}
                                            <input type="text" className='form-control' placeholder="Search Branch..." onChange={handleSearchDivision} />

                                            
                                            {divisions && divisions.length > 0 ? (
                                                <><Dropdown.Item key={0} 
                                                active={parseInt(0) === parseInt(sessionStorage.getItem("CurrentDivisionId"))}
                                                onClick={() => handleSelectDivision({'id':'0','division_name':'All Branch'})}>
                                                    All Branch
                                                </Dropdown.Item>
                                                { divisions.filter(br_option => br_option.division_name.toLowerCase().includes(searchDivision.toLowerCase()))
                                                    .map((br_option) => (
                                                    <Dropdown.Item key={br_option.id} 
                                                    active={parseInt(br_option.id) === parseInt(sessionStorage.getItem("CurrentDivisionId"))}
                                                    onClick={() => handleSelectDivision(br_option)}>{br_option.division_name}    
                                                    
                                                    </Dropdown.Item>
                                                    ) ) 
                                                }</>
                                            ) : (
                                                <option value="">No Data</option>
                                            )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>):(<span>{sessionStorage.getItem("LoggedInDivisionName")}</span>)}  </span>
                                    </div>
                            </div>
                            
                            <div className="tip-box-filter">
                                <span>&nbsp;</span>
                            </div>
                        </div>
                        </div>
                    
                    <div className="row">
                        <div className="col-xs-12 col-sm-offset-3">
                        <button className='btn btn-success' onClick={() => handleFilter()}>Apply</button> {/*<button className='btn btn-secondary' onClick={() => handleClearFilter()}>Clear</button>*/}
                        </div>
                    </div>
                </div>
                )} 

                    {(aAccessModuleId.indexOf('26') > -1) ? (<>
                    <div className="nav-item text-nowrap department-dropdown">
                    <span><strong>&nbsp;</strong></span>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary1" id="profile-dropdown">
                        <img className="img_sidebar_icon" src={process.env.REACT_APP_BASE_URL+"icon/support.png"}/> Support
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {(aSubModuleId.indexOf('110') > -1) ? (<><Dropdown.Item href="/raise-tickets"><i className="bi bi-ticket"></i> Create New Ticket</Dropdown.Item></>) : (<></>)}
                            {(aSubModuleId.indexOf('111') > -1) ? (<><Dropdown.Item href="/view-raised-tickets"><i className="bi bi-ticket-detailed"></i> View Ticket</Dropdown.Item></>) : (<></>)}
                            {(aSubModuleId.indexOf('112') > -1) ? (<><Dropdown.Item href="/view-assigned-tickets"><i className="bi bi-ticket-detailed"></i> View Assigned Ticket</Dropdown.Item></>) : (<></>)}
                            {(aSubModuleId.indexOf('113') > -1) ? (<><Dropdown.Item href="/view-all-tickets-list"><i className="bi bi-ticket-detailed"></i> View All Ticket</Dropdown.Item></>) : (<></>)}
                            
                        </Dropdown.Menu>
                    </Dropdown></div></>
                        ) : (
                        <></>
                    )}
                    <div className="nav-item text-nowrap department-dropdown">
                    <span><strong>&nbsp;</strong></span>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary1" id="profile-dropdown">
                        <img className="img_sidebar_icon" src={process.env.REACT_APP_BASE_URL+"icon/user-settings.png"}/> Settings
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="/manage-my-profile"><img className="img_sidebar_icon" src={process.env.REACT_APP_BASE_URL+"icon/profile.png"}/> View Profile</Dropdown.Item>
                            <Dropdown.Item href="/manage-my-password"><img className="img_sidebar_icon" src={process.env.REACT_APP_BASE_URL+"icon/change-password.png"}/> Change Password</Dropdown.Item>
                            <Dropdown.Item href="/view-manual"><img className="img_sidebar_icon" src={process.env.REACT_APP_BASE_URL+"icon/manual.png"}/> Manual</Dropdown.Item>
                            <Dropdown.Item href="#"
                             onClick={() => {
                            sessionStorage.setItem('isLoggedIn', false);
                            sessionStorage.setItem('LoggedInName', false);
                            sessionStorage.setItem('LoggedInEmail', false);
                            sessionStorage.setItem('LoggedInUserId', false);
                            sessionStorage.setItem('LoggedInCompanyId', false);
                            sessionStorage.setItem('LoggedInDivisionId', false);
                            sessionStorage.setItem('LoggedInDepartmentId', false);
                            sessionStorage.setItem('LoggedInModuleId', false);
                            sessionStorage.setItem('LoggedInSubModuleId', false);
                            sessionStorage.setItem('LoggedInRoleName', false);
                            sessionStorage.setItem('LoggedInCompanyName', false);
                            sessionStorage.setItem('LoggedInCompanyShortName', false);
                            sessionStorage.setItem('LoggedInDivisionName', false);
                            sessionStorage.setItem('LoggedInDivisionShortName', false);
                            sessionStorage.setItem('LoggedInDepartmentName', false);
                            sessionStorage.setItem('LoggedInDepartmentShortName', false);
                            sessionStorage.setItem('CurrentCompanyId', false);
                            sessionStorage.setItem('CurrentDivisionId', false);
                            sessionStorage.setItem('CurrentDepartmentId', false);
                            sessionStorage.setItem('CurrentCompanyName', '');
                            sessionStorage.setItem('CurrentDivisionName', '');
                            sessionStorage.setItem('CurrentDepartmentName', '');
                            sessionStorage.setItem('', false);
                            setisLoggedIn(false);
                        }}> <img className="img_sidebar_icon" src={process.env.REACT_APP_BASE_URL+"icon/logout.png"}/> Logout   
                                </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </div>
            </div>
            
            </header>
            <div className="container-fluid">
                <div className="row">
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar collapse">
                        {<Sidebar/>}
                    </nav>
                    <main id="pageContainer" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        {getRoutes()}
                    </main>
                </div>
            </div>
        </div>
    );
}

function App() {
  
  const [count, setCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const isForeground = useVisibilityChange();
  const [showNotifications, setShowNotifications] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [searchCompany, setSearchCompany] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] =useState(0);
  const [selectedCompany, setSelectedCompany] = useState(sessionStorage.getItem("CurrentCompanyName") || 'Select Company');
  const [searchDivision, setSearchDivision] = useState('');
  const [selectedDivisionId, setSelectedDivisionId] = useState(0)
  const [selectedDivision, setSelectedDivision] = useState(sessionStorage.getItem("CurrentDivisionName") || 'Select Branch');
  const [searchDepartment, setSearchDepartment] = useState('');
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState(sessionStorage.getItem("CurrentDepartmentName") || 'Select Department');
  //const [applyFilter, setApplyFilter] = useState(false);
    // Function to handle search input change
    const handleSearchCompany = (event) => {
        setSearchCompany(event.target.value);
    };
    const handleSearchDivision = (event) => {
        setSearchDivision(event.target.value);
    };
    const handleSearchDepartment = (event) => {
        setSearchDepartment(event.target.value);
    };
    const handleSelectCompany = (company) => {
        const { company_id, company_name } = company;
        sessionStorage.setItem('CurrentCompanyId', parseInt(company_id));
        sessionStorage.setItem('LoggedInCompanyId', parseInt(company_id));
        sessionStorage.setItem('LoggedInDivisionId', '0');
        sessionStorage.setItem('LoggedInDepartmentId', '0');
        sessionStorage.setItem('CurrentDivisionId', '0');
        sessionStorage.setItem('CurrentDepartmentId', '0');
        sessionStorage.setItem('CurrentCompanyName', company_name); // Store the selected company name
        setSelectedCompanyId(company_id); // Update state
        setSelectedCompany(company_name); // Update state
        setSelectedDepartment(' All Department');
        setSelectedDivision(' All Branch');
        sessionStorage.setItem('CurrentDivisionName', '');
        sessionStorage.setItem('CurrentDepartmentName', '');
        fetchCurrentCompanyList();
        fetchCurrentDivisionList();
        fetchCurrentDepartmentList();
        sessionStorage.setItem('applyCompanyFilter', 'Yes');
        //window.location.reload(false);
    };
    
    const handleSelectDepartment = (department) => {
        const { id, department_name } = department;
        //sessionStorage.setItem('CurrentCompanyId', sessionStorage.getItem('LoggedInCompanyId') );
        //sessionStorage.setItem('CurrentDivisionId', sessionStorage.getItem('LoggedInDivisionId')); 
        
        sessionStorage.setItem('CurrentDepartmentId', parseInt(id)); 
        sessionStorage.setItem('LoggedInDepartmentId', parseInt(id));
        sessionStorage.setItem('CurrentDepartmentName', department_name); // Store the selected company name
        setSelectedDepartmentId(id);
        setSelectedDepartment(department_name); // Update state
        fetchCurrentDepartmentList();
        sessionStorage.setItem('applyDepartmentFilter', 'Yes');
        if(sessionStorage.getItem('LoggedInDivisionId')==0){ 
            sessionStorage.setItem('LoggedInDivisionId', '0');
            sessionStorage.setItem('CurrentDivisionId', '0');
            setSelectedDivision(' All Branch');
            sessionStorage.setItem('CurrentDivisionName', '');
            fetchCurrentDivisionList();
        }
        
        //window.location.reload(false);
    };
    const handleSelectDivision = (division) => {
        const { id, division_name } = division;
        //sessionStorage.setItem('CurrentCompanyId', sessionStorage.getItem('LoggedInCompanyId') );
        
        sessionStorage.setItem('CurrentDivisionId', parseInt(id)); 
        sessionStorage.setItem('LoggedInDivisionId', parseInt(id)); 
        
        sessionStorage.setItem('CurrentDivisionName', division_name); // Store the selected company name
        setSelectedDivisionId(id);
        setSelectedDivision(division_name); // Update state
        fetchCurrentDivisionList(); 
        
        if(sessionStorage.getItem('LoggedInDepartmentId')==0){ 
            sessionStorage.setItem('LoggedInDepartmentId', '0');
            sessionStorage.setItem('CurrentDepartmentId', '0');
            setSelectedDepartment(' All Department');
            sessionStorage.setItem('CurrentDepartmentName', '');
            fetchCurrentDepartmentList();
        }
        sessionStorage.setItem('applyDivisionFilter', 'Yes');
        //window.location.reload(false);
    };
  const fetchCurrentCompanyList = useCallback(async () => {
  try{
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{
                            'company_id':sessionStorage.getItem('LoggedInCompanyId'),
                            'division_id':sessionStorage.getItem('LoggedInDivisionId'),
                            'department_id':sessionStorage.getItem('LoggedInDepartmentId'),
                            'curr_company_id':sessionStorage.getItem('CurrentCompanyId'),
                            'curr_division_id':sessionStorage.getItem('CurrentDivisionId'),
                            'curr_department_id':sessionStorage.getItem('CurrentDepartmentId')
                        },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token':EncryptData }
        
        getCompanyProfileDDList(oData).then(res => {
            console.log('oData-CurrentCompanyId');
            console.log(res.data.oResult);
            if (res?.data?.oResult?.data?.length) setCompanies(res.data.oResult.data);
            //console.log(res.data);
            if(res?.data?.oResult?.curr_token && sessionStorage.getItem('CurrentCompanyId')>=0){
                sessionStorage.setItem('LoggedInToken',res.data.oResult.curr_token);
                //sessionStorage.setItem('CurrentCompanyId',false);
                //console.log('company-LoggedInToken');
                //console.log(res.data.oResult.curr_token);
            }
        });
        
    } catch (error) {
        console.error('Error fetching notifications:', error);
      }
  }, []);
  const fetchCurrentDivisionList = useCallback(async () => {
    try{ 
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
              'param_data':{
                              'company_id':sessionStorage.getItem('LoggedInCompanyId'),
                              'division_id':sessionStorage.getItem('LoggedInDivisionId'),
                              'department_id':sessionStorage.getItem('LoggedInDepartmentId'),
                              'curr_company_id':sessionStorage.getItem('CurrentCompanyId'),
                              'curr_division_id':sessionStorage.getItem('CurrentDivisionId'),
                              'curr_department_id':sessionStorage.getItem('CurrentDepartmentId')
                          },
              'authToken': sessionStorage.getItem('LoggedInToken'),
          }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
          let oData = { 'token':EncryptData }
          
          getDivisionDDList(oData).then(res=>{
              
              if(res?.data?.data?.length){ setDivisions(res.data.data);
              
              if(res?.data?.curr_token && sessionStorage.getItem('CurrentDivisionId')>= 0 ){
                  //console.log('oData-division');
                  //console.log(res.data.curr_token);
                  sessionStorage.setItem('LoggedInToken',res.data.curr_token);
                  //sessionStorage.setItem('CurrentDivisionId',false);
                  console.log('division-LoggedInToken');
                    console.log(res.data.curr_token);
                  }
              } 
          });
          
      } catch (error) {
          console.error('Error fetching notifications:', error);
        }
    }, []);
    const fetchCurrentDepartmentList = useCallback(async () => {
        try{
              let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                  'param_data': {
                        'company_id':sessionStorage.getItem('LoggedInCompanyId'),
                        'division_id':sessionStorage.getItem('LoggedInDivisionId'),
                        'department_id':sessionStorage.getItem('LoggedInDepartmentId'),
                        'curr_company_id':sessionStorage.getItem('CurrentCompanyId'),
                        'curr_division_id':sessionStorage.getItem('CurrentDivisionId'),
                        'curr_department_id':sessionStorage.getItem('CurrentDepartmentId')
                   },
                  'authToken': sessionStorage.getItem('LoggedInToken'),
              }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
              
              let oData = { 'token':EncryptData }

              getDepartmentDDList(oData).then(res => {
                  if (res?.data?.oResult?.data?.length) setDepartments(res.data.oResult.data);
                  console.log('oData-Departments');
                  console.log(res.data.oResult);
                  if(res?.data?.oResult?.curr_token && sessionStorage.getItem('CurrentDepartmentId')>=0){
                      sessionStorage.setItem('LoggedInToken',res.data.oResult.curr_token);
                      //sessionStorage.setItem('CurrentDepartmentId',false);
                      
                  }
              }); 
            } catch (error) {
              console.error('Error fetching notifications:', error);
            }
        }, []);
  //fetch notification from database
  const fetchNotifications = useCallback(async () => {
  //const fetchNotifications = async () => {
    try {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getNotificationList(oData).then(res=>{
            if(res?.data?.data?.length) setNotifications(res.data.data);
            setCount(res?.data?.data_count[0]?.total_notify);
          })
      //setNotifications(response.data);
      
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  },[]);
  
  const toggleNotifications = () => {
    setShowFilter(false);
    setShowNotifications(!showNotifications);
  }; 
  const toggleFilter = () => {
    fetchCurrentCompanyList();
            fetchCurrentDivisionList();
            fetchCurrentDepartmentList();
    setShowNotifications(false);
    setShowFilter(!showFilter);

  };
  var loginFlag = (sessionStorage.getItem('isLoggedIn') == 'true') ? true : false;
  const [isLoggedIn, setisLoggedIn] = useState(loginFlag);
  var updatepwd = (sessionStorage.getItem('LoggedInPwdStatus') == 'N') ? true : false;
  const [isUpdatePwd, setisUpdatePwd] = useState(updatepwd);
  
    const handleFilter = () => {
        //sessionStorage.setItem('applyFilter', 'yes');
        window.location.reload(false);
    };
    const handleClearFilter = () => {
        
        if(sessionStorage.getItem('applyCompanyFilter')=="Yes"){
            sessionStorage.setItem('CurrentCompanyId', '0');
            sessionStorage.getItem('LoggedInCompanyId', '0');
            sessionStorage.setItem('CurrentCompanyName', ''); 
            setSelectedCompany(' Select Company'); // Update state
            sessionStorage.setItem('applyCompanyFilter', 'no');
            fetchCurrentCompanyList();
        } 
        if(sessionStorage.getItem('applyDivisionFilter')=="Yes"){
            sessionStorage.setItem('LoggedInDivisionId', '0');
            sessionStorage.setItem('CurrentDivisionId', '0');
            sessionStorage.setItem('CurrentDivisionName', '');
            setSelectedDivision(' Select Branch');
            sessionStorage.setItem('applyDivisionFilter', 'no');
            fetchCurrentDivisionList();
        }
        if(sessionStorage.getItem('applyDepartmentFilter')=="Yes"){
            sessionStorage.setItem('LoggedInDepartmentId', '0');
            sessionStorage.setItem('CurrentDepartmentId', '0');
            sessionStorage.setItem('CurrentDepartmentName', '');
            setSelectedDepartment(' Select Department');
            sessionStorage.setItem('applyDepartmentFilter', 'no');
            fetchCurrentDepartmentList();
        }
        window.location.reload(false); 
    };
    useEffect(() => {
        /*setupNotifications((message) => {
          if (isForeground) {
            // App is in the foreground, show toast notification
            toastNotification({
                title:'Test1N',
                description: 'My Desc',
                status: "info",
              });
          } else {
            // App is in the background, show native notification
            sendNativeNotification({
                title:'Test2N',
                body:'My Desc2',
            });
          }
        });
        */
        if(isLoggedIn){
            //fetch notification
            fetchNotifications();
            //fetchCurrentCompanyList();
            //fetchCurrentDivisionList();
            //fetchCurrentDepartmentList();
        }
      }, [fetchNotifications,fetchCurrentCompanyList,fetchCurrentDivisionList,fetchCurrentDepartmentList]);
    return (
        <Router>
            <Suspense fallback={<span> Loading .... </span>}>
                {(isLoggedIn) ? renderProtectedData({isLoggedIn, setisLoggedIn,count,notifications,toggleNotifications,showNotifications,toggleFilter,showFilter,
                companies,divisions,departments,searchCompany,handleSearchCompany,handleSelectCompany,selectedCompany,
                        searchDivision,handleSearchDivision,handleSelectDivision,selectedDivision,
                        searchDepartment,handleSearchDepartment,handleSelectDepartment,selectedDepartment,handleFilter,handleClearFilter}) :
                    <Routes> <Route path="*" element={<Login setter={setisLoggedIn} getter={isLoggedIn}/>}/> 
                    <Route path="/forgotpassword" exact element={<ForgotPasword/>}/>
                    {(sessionStorage.getItem('Forgot_Email') !=='') ? (<><Route path="/verifyotp" exact element={<OtpVerify/>}/></>) : (<></>)}
                    {(sessionStorage.getItem('Forgot_OTP') !=='') ? (<><Route path="/resetpassword" exact element={<ResetPassword/>}/></>) : (<></>)}
                    {/*<Route path="/verifyotp" exact element={<OtpVerify/>}/>
                        <Route path="/resetpassword" exact element={<ResetPassword/>}/>*/}
                    </Routes>
                }
            </Suspense>
        </Router>
    );
}

export default App;
