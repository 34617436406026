import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer, GridToolbarExport,GridOverlay } from '@mui/x-data-grid';
import {updateReclasifyEr, getPendingER } from '../../services/flagging.service';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmBox from '../../atoms/ConfirmBox';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}


function PendingForExternalRepair() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [rejectReason, setRejectReason] = useState('');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const columns = [
        {"field": "tag", "editable": false, "hide": false, "headerName": "TAG Number", "width": 280},
        {"field": "tag_name", "editable": false, "hide": false, "headerName": "TAG Name", "width": 280},
        {
            "field": "created_at", "editable": false, "hide": false, "headerName": "create Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },
        {"field": "status", "editable": false, "hide": false, "headerName": "Source", "width": 230,
            renderCell: (params) => (
                <div>{(params.row.status=='1')?(<span class="btn-btn-warning p-1">Moved from Flag</span>):''} </div>
              )},
    ];

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        // Show loader when the request starts
        setLoading(true);

        // Fetch division list
        getPendingER(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching rfid list:', error);
            })
            .finally(() => {
                // Hide loader when the request completes (either success or failure)
                setLoading(false);
            });
    }, []);  // Empty dependency array means this effect runs once on mount

    const handleConfirmOpen = () => {
            setIsConfirmOpen(true);
        };
        
        const handleConfirmClose = () => {
            setIsConfirmOpen(false);
        };
        
        const handleConfirm = (inputValue) => {
            
            console.log('Confirmed with input:', inputValue);
            //setRejectReason(inputValue);
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'flag_id':'','flag_ids':selectionModel,'flag_status':'3','reject_reason':inputValue},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            try {
                //setLoading('..'); // Set loading before sending API request
                updateReclasifyEr(oData).then(function (res) {
                //setLoading(false); // Stop loading
                setSuccess(res.data.message); //alert('a');
                const updatedData = items.filter((item) => !selectionModel.includes(item.id) );
                setItems(updatedData);
            });
            } catch (err) {
                setSuccess(false);
                setLoading(false); // Stop loading
                if (!err?.data1) {
                    console.log(err);
                    //setErrMsg('No Server Response');
                    //setSuccess("Successfully saved Single Asset");
                } else if (err.data1?.status === 409) {
                    //setErrMsg('Record already exist');
                } else {
                    setErrMsg('Insert/Update Failed');
                }
                //elementRef.current.focus();
            }
        };
        const handleReject = async (flagId) => {
            //console.log('flagid'+flagId);
          if(selectionModel.length>0){ setErrMsg('');
          if (window.confirm('Are you sure you want to reject selected items?')){
            handleConfirmOpen();
          }}else{ setErrMsg('Please select atleast one asset.')}
        };
        const handleSelectionModelChange = (newSelection) => {
            setSelectionModel(newSelection);
          };
          
        const CustomLoader = () => (
            <GridOverlay>
                <CircularProgress color="primary" size={30}  />
            </GridOverlay>
            );
        function CustomToolbar() {
          return (
            <GridToolbarContainer>
              
            </GridToolbarContainer>
          );
        }

    // readDivision();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Pending For External Repair</h5></div>

            </div>
            {success ? (<div className="alert alert-success" role="alert">
                <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
                <div className="alert alert-danger" role="alert">
                    <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
            )}
        
            <div className='filter-container1 col-12'>
            <div className='col-8'></div><div className='col-2'>
                <button className='btn btn-danger btn-sm text-right' onClick={handleReject}>Reject Process</button>
                </div>
            </div>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (<div>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelectionModelChange}
                    autoHeight
                    rowsPerPageOptions={[50]}
                    components={{
                        LoadingOverlay: CustomLoader,
                        Toolbar: CustomToolbar,
                        }}
                    loading={loading} 
                />
                <ConfirmBox
                    isOpen={isConfirmOpen}
                    onClose={handleConfirmClose}
                    onConfirm={handleConfirm}
                
                /></div>
            )}

        </div>
    )
}

export default PendingForExternalRepair
