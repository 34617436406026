import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import imageCompression from 'browser-image-compression';
import Select from 'react-select';
import FromFieldText from '../../../atoms/FromFieldText';
import './SingleAsset.css';
import {
    getAllAssetsTag,
    getAssetsImages,
    getInvoices,
    getSTPI,
    getSuppliers,
    saveSingleImage,
    getAssetsImagesCount
} from '../../../services/asset.service';
//import FromFieldLabel from '../../../atoms/FromFieldLabel';
import * as CryptoJS from "crypto-js";
import AWS from 'aws-sdk';
// Configure AWS with your credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();
const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
//const app = express();
//const cors = require("cors");
//app.use(cors(corsOptions));
export default function UploadAssetImage() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const elementRef = useRef();

    useEffect(() => {
        if (Tag !== '' && Tag !== null) {
            getAssetsImagesInfo(Tag);
            //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAllAssetsTag(oData).then(res => {
            //console.log(res);
            if (res?.data?.data?.length) setAllAssetsData(res.data.data);
        });
        getSuppliers(oData).then(res => {
            if (res?.data?.data?.length) setSuppliersData(res.data.data);
        });
    }, []);
    const getAssetsImagesInfo = function (Tag) {
        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'tag': Tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            getAssetsImages(oData1).then(function (res) {
                if (res?.data?.data) {
                    //console.log(res.data.data);
                    //setImgDesc(stpiInfo.year_of_addition);
                    //setTag(stpiInfo.tag);
                }
            });
        } catch (err) {
            //setAssetInfo(''); 
        }
    }
    //const userRef = useRef();
    const errRef = useRef();
    const [imgDesc, setImgDesc,] = useState('');
    const [ErrImgDesc, setErrImgDesc] = useState(false);
    const [uploadassetimage, setUploadAssetImage] = useState([]);
    const [ErrUploadAssetImage, setErrUploadAssetImage] = useState(false);

    const [tag, setTag] = useState('');
    const [supplier, setSupplier] = useState('');
    const [invoice, setInvoice] = useState('');

    const [ErrTag, setErrTag] = useState(false);
    const [ErrInvoice, setErrInvoice] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [assetInfo, setAssetInfo] = useState({});
    const [allAssets, setAllAssetsData] = useState([]);
    const [suppliers, setSuppliersData] = useState([]);
    const [invoices, setInvoicesData] = useState([]);

    const [imageCount, setImageCount] = useState(0);

    const [uploadfrontimage, setUploadFrontImage] = useState('');
    const [ErrUploadFrontImage, setErrUploadFrontImage] = useState(false);
    const [uploadbackimage, setUploadBackImage] = useState('');
    const [ErrUploadBackImage, setErrUploadBackImage] = useState(false);
    const [uploadtopimage, setUploadTopImage] = useState('');
    const [ErrUploadTopImage, setErrUploadTopImage] = useState(false);
    const [uploadbottomimage, setUploadBottomImage] = useState('');
    const [ErrUploadBottomImage, setErrUploadBottomImage] = useState(false);
    const [uploadleftimage, setUploadLeftImage] = useState('');
    const [ErrUploadLeftImage, setErrUploadLeftImage] = useState(false);
    const [uploadrightimage, setUploadRightImage] = useState('');
    const [ErrUploadRightImage, setErrUploadRightImage] = useState(false);
    const [uploadotherimage, setUploadOtherImage] = useState('');
    const [ErrUploadOtherImage, setErrUploadOtherImage] = useState(false);

    const maxFiles = 6-imageCount; // Maximum number of files allowed
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    const handleUploadFrontImage = (e) => {
        const files = e.target.files;
        setUploadFrontImage(files[0]);
    }
    const handleUploadBackImage = (e) => {
        const files = e.target.files;
        setUploadBackImage(files[0]);
    }
    const handleUploadTopImage = (e) => {
        const files = e.target.files;
        setUploadTopImage(files[0]);
    }
    const handleUploadBottomImage = (e) => {
        const files = e.target.files;
        setUploadBottomImage(files[0]);
    }
    const handleUploadLeftImage = (e) => {
        const files = e.target.files;
        setUploadLeftImage(files[0]);
    }
    const handleUploadRightImage = (e) => {
        const files = e.target.files;
        setUploadRightImage(files[0]);
    }
    const handleUploadOtherImage = (e) => {
        const files = e.target.files;
        setUploadOtherImage(files[0]);
    }
    /*const handleUploadImage = (e) => {
        const files = e.target.files;
        console.log(files[0]);
        let totalSize = 0;
        const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes


        // Check file size and count
        if (files.length > maxFiles) {
            setErrUploadAssetImage(`You can only upload a maximum of ${maxFiles} files.`);
            return;
        }

        for (let i = 0; i < files.length; i++) {
            totalSize += files[i].size;
            const file = files[i];
            if (totalSize > maxFileSize) {
                setErrUploadAssetImage(`File '${file.name}' exceeds the maximum file size limit of 5MB.`);

                return;
            }
        }

        // Clear error if validations pass
        setErrUploadAssetImage('');

        // Set the files in state
        setUploadAssetImage(files);
    };*/

    //const maxFiles = 5; // Maximum number of files allowed

    // Calculate the remaining number of files
    const remainingFiles = maxFiles - uploadassetimage.length;


    const optionList = [];
    allAssets && allAssets.length > 0 ? (
        allAssets.map((option) => (
            optionList.push({value: option.tag, label: option.tag})
        ))
    ) : (
        optionList.push({value: "", label: "No Data"})
    )

    const [selectedOptions, setSelectedOptions] = useState();

    function handleSelect(data) {
        setSelectedOptions(data);
        setTag(data.value);

        // Fetch image count for the selected tag
        fetchImageCount(data.value);

    }

    const fetchImageCount = (selectedTag) => {

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': selectedTag,},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData3 = {'token': EncryptData}

        // Make an API call to get the image count
        getAssetsImagesCount(oData3).then(res => {

            // Assuming the API response contains image count in data property
            const imageCount = res.data.data[0]?.count;

            // Update the state with the fetched image count
            setImageCount(imageCount);
        }).catch(error => {
            console.error('Error fetching image count:', error);
            // Handle error if needed
        });
    };

    //get supplier list
    const supplierList = [];
    suppliers && suppliers.length > 0 ? (
        suppliers.map((option) => (
            supplierList.push({value: option.supplier_name, label: option.supplier_name})
        ))
    ) : (
        supplierList.push({value: "", label: "No Data"})
    )

    const [supplierOptions, setSupplierOptions] = useState();

    function handleSupplier(data) {
        setSupplierOptions(data);
        //console.log(data.value);
        setSupplier(data.value);
        //get invoice_number
        var oParam1 = {
            'supplier_name': data.value,
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': oParam1,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData2 = {'token': EncryptData}
        getInvoices(oData2).then(res => {
            if (res?.data?.data?.length) setInvoicesData(res.data.data);
        });
    }

    //get Invoice list
    const invoiceList = [];
    invoices && invoices.length > 0 ? (
        invoices.map((option) => (
            invoiceList.push({value: option.invoice_no, label: option.invoice_no})
        ))
    ) : (
        invoiceList.push({value: "", label: "No Data"})
    )

    const [invoiceOptions, setInvoiceOptions] = useState();

    function handleInvoice(data) {
        setInvoiceOptions(data);
        //console.log(data.value);
        setInvoice(data.value);
    }

    //subit image form
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true; //alert(valid_Name);
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(imgDesc);
        if (tag == "" && invoice == '') {
            setErrInvoice('Tag OR Invoice is required');
            valid_Name = false;
        } else {
            setErrInvoice('');
        }
        /*if (!TEXT_REGEX.test(tag)) { 
			setErrTag('Tag is required'); valid_Name=false;
			//return;
		}else{ setErrTag('');  }
        
        if (!TEXT_REGEX.test(invoice)) { 
            setErrInvoice('Invoice is required'); valid_Name=false;
            //return;
        }else{ setErrInvoice('');  } 
        */
        if (!TEXT_REGEX.test(imgDesc)) {
            setErrImgDesc('Image description is required');
            valid_Name = false;
            //return;
        } else {
            setErrImgDesc('');
        }
        //alert(uploadassetimage);
        if (uploadfrontimage == '') {
            setErrUploadFrontImage('Upload asset image is required');
            valid_Name = false;
            //return;
        } else {
            setErrUploadFrontImage('');
        }
        if (valid_Name) { //alert('validName');
            saveData(); //submit form
        } else {
            //alert(valid_Name);
            return false;
        }
    }
    const resetAssetInfo = function () {
        setTag('');setSelectedOptions('');setImgDesc('');
        setUploadFrontImage(''); setUploadBackImage(''); setUploadTopImage('');
        setUploadBottomImage(''); setUploadLeftImage(''); setUploadRightImage(''); 
        setUploadOtherImage('');
        
    }
    const getTagInfo = function () {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData3 = {'token': EncryptData}
        getSTPI(oData3).then(function (res) {
            //console.log(res.data.data);
            var aInfo = {};
            if (res?.data?.data?.assetInfo) {
                setErrMsg('');
                aInfo = res.data.data.assetInfo;
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
            } else {
                setAssetInfo({});
                setErrMsg('Assets Not Found');
            }

        });
    }

    const uploadDynamicFile = (file,i_name) => {
            let img_name = "";
            const fileName = file.name;
            console.log(fileName);
            if (fileName !== undefined && fileName!== "") {
                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                img_name = tag + (supplier ? supplier : '') + (invoice ? invoice : '') + '-' + i_name + '.' + fileExtension;
                uploadFile(file, img_name);
                if(img_name=="front"){
                    uploadthumbnail(file, img_name);
                }
            } console.log(img_name);
            return img_name;
    }
    const uploadFile = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'image/' + img_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                //return params.name;
            }
        });
    };

    const uploadthumbnail = async (file, imgName) => {
        try {
            // Compress and resize the image
            const options = { maxWidthOrHeight: 75, useWebWorker: true };
            const thumbnail = await imageCompression(file, options);
            // Upload thumbnail to S3
            const thumbnailParams = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: 'image/thumbnails/' + imgName,
                Body: thumbnail,
            };
            const thumbnailUpload = await s3.upload(thumbnailParams).promise();
    
            console.log('Thumbnail URL:', thumbnailUpload.Location);
            console.log(thumbnailUpload);
        } catch (err) {
            console.error('Error uploading files:', err);
        }
    };

    const saveData = function () {
        try {   /* const formData = new FormData();
                formData.append('tag',tag);
                formData.append('invoice_no',invoice);
                formData.append('asset_image_desc',imgDesc); 
                formData.append('company_id',sessionStorage.getItem('LoggedInCompanyId'));
                formData.append('division_id',sessionStorage.getItem('LoggedInDivisionId'));
                formData.append('upload_dir','asset_images');*/
            let oData = {
                'tag': tag,
                'invoice_no': invoice,
                'supplier_name': supplier,
                'asset_image_desc': imgDesc,
                'upload_dir': 'images',
                'application_type':'Web',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
                'companyshortname': sessionStorage.getItem("LoggedInCompanyShortName"),
                'users':{ 
                    'company_id':sessionStorage.getItem('LoggedInCompanyId'),
                    'department_id':sessionStorage.getItem('LoggedInDepartmentId'),
                    'division_id':sessionStorage.getItem('LoggedInDivisionId'),
                }
            }
            //console.log(uploadassetimage.length);
            const img = {};
            //for (let i = 0; i < uploadassetimage.length; i++) {
                //Front Image
                /*const file = uploadfrontimage;
                const fileName = file.name;
                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let img_name = tag + (supplier ? supplier : '') + (invoice ? invoice : '') + '-' + i + '.' + fileExtension;
                if (file) {
                    uploadFile(file, img_name);
                }*/
                img.front = uploadDynamicFile(uploadfrontimage,'front');
                img.back = uploadDynamicFile(uploadbackimage,'back');
                img.left = uploadDynamicFile(uploadleftimage,'left');
                img.right = uploadDynamicFile(uploadrightimage,'right');
                img.top = uploadDynamicFile(uploadtopimage,'top');
                img.bottom = uploadDynamicFile(uploadbottomimage,'bottom');
                img.other = uploadDynamicFile(uploadotherimage,'other');
                //Back Image
                oData.asset_image = img;
                console.log(oData);
                var objAudit = {'audit_module':'Upload Images','audit_method':'created','audit_tag':tag,'old_values':'','new_values':oData}
                let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data':oData, 'audit_params':objAudit,
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                let oData4 = {'token':EncryptData}
                //console.log(oData);
                saveSingleImage(oData4).then(function (res) {
                    if ((typeof (res.data.errors) !== "undefined") && res.data.errors.errno > 0) {
                        setErrMsg(res.data.message);
                        setSuccess(false);
                    } else {
                        setSuccess(res.data.message);
                        setDisabled(true);
                        setErrMsg(false);
                        setErrTag('');
                        resetAssetInfo();
                    }
                });
            //}
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    }

    return (
        <div className='container'>
            <div className='row single__assets' ref={elementRef}>
                <div className="col">
                    <h5>Upload Asset Images</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandetory field.
                </div>
                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    (Tag !== '' && Tag !== null) ? <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div> : <div className='row'>
                        <div className='col-4'><h5 className='align-right'>TAG </h5></div>
                        <div className='col-4'>
                            {/*<input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' />*/}
                            <Select options={optionList} placeholder="Select One" value={selectedOptions}
                                    onChange={handleSelect}/>
                            <span className="invalid-feedback"> {ErrTag} </span></div>

                        <div className='col-4'>
                            <button onClick={getTagInfo} className='btn btn-primary btn-md'><i
                                className="bi bi-search"></i> Search
                            </button>
                            &nbsp;<a href={'/upload-image-new'}>
                            <button className='btn btn-secondary btn-md'><i className="bi bi-arrow-clockwise"></i> Reset</button>
                        </a>&nbsp;</div>
                        <div className='col-2'>
                            <label>Search by Supplier</label>
                        </div>
                        <div className='col-3'>
                            <Select options={supplierList} placeholder="Select One" value={supplierOptions}
                                    onChange={handleSupplier}/>
                        </div>
                        <div className='col-2'>
                            <label>Search by Invoice No.</label>
                        </div>
                        <div className='col-3'>
                            <Select options={invoiceList} placeholder="Select One" value={invoiceOptions}
                                    onChange={handleInvoice}/><span className="invalid-feedback"> {ErrInvoice} </span>
                        </div>
                        <div className='col-3'>

                        </div>
                    </div>


                }

            </div>
            <div className='row'></div>
            <div className="row">

                <div className="col-6">
                    <FromFieldText
                        label="TAG"
                        inputValue={tag ? tag : ''}
                        readOnly="readonly"
                    />
                </div>
                <div className='col-6'></div>
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className='row single__asset__form'>
                    <div className="col-6">
                        <FromFieldText label="Image Description" star="*"
                                       inputValue={imgDesc}
                                       setInputValue={setImgDesc}
                            //checkInputValue={checkCharcterOnly}
                                       setErrorMsg={ErrImgDesc}
                        />

                    </div>
                    <div className='col-6'>

                    </div>
                </div>
                <div className='row'>
                <div className="col-6">
                    <div className='row single__asset__form'>
                        <div className="col-6">
                            <div className="label">
                                <label> Upload Front View Image </label>
                                <span className="star error">  </span>
                            </div>
                            
                        </div>
                        <div className='col-6'>
                            <input type="file" name='asset_front_image'  onChange={handleUploadFrontImage}/>
                            <span className="invalid-feedback"> {ErrUploadFrontImage} </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='row single__asset__form'>
                        <div className="col-6">
                            <div className="label">
                                <label> Upload Back View Image </label>
                                <span className="star error">  </span>
                            </div>
                        </div>
                        <div className='col-6'>
                            <input type="file" name='asset_back_image'  onChange={handleUploadBackImage}/>
                            <span className="invalid-feedback"> {ErrUploadBackImage} </span>
                        </div>
                    </div>
                </div>
                </div>
                <div className='row'>
                    <div className="col-6">
                    <div className='row single__asset__form'>
                        <div className="col-6">
                            <div className="label">
                                <label> Upload Left View Image </label>
                                <span className="star error">  </span>
                            </div>
                            
                        </div>
                        <div className='col-6'>
                            <input type="file" name='asset_left_image'  onChange={handleUploadLeftImage}/>
                            <span className="invalid-feedback"> {ErrUploadLeftImage} </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='row single__asset__form'>
                        <div className="col-6">
                            <div className="label">
                                <label> Upload Right View Image </label>
                                <span className="star error">  </span>
                            </div>
                            
                        </div>
                        <div className='col-6'>
                            <input type="file" name='asset_right_image'  onChange={handleUploadRightImage}/>
                            <span className="invalid-feedback"> {ErrUploadRightImage} </span>
                        </div>
                    </div>
                </div>
                </div>
                <div className='row'>
                    <div className="col-6">
                        <div className='row single__asset__form'>
                            <div className="col-6">
                                <div className="label">
                                    <label> Upload Top View Image </label>
                                    <span className="star error">  </span>
                                </div>
                            </div>
                            <div className='col-6'>
                                <input type="file" name='asset_top_image'  onChange={handleUploadTopImage}/>
                                <span className="invalid-feedback"> {ErrUploadTopImage} </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='row single__asset__form'>
                            <div className="col-6">
                                <div className="label6">
                                    <label> Upload Bottom View Image </label>
                                    <span className="star error">  </span>
                                </div>
                                
                            </div>
                            <div className='col-6'>
                                <input type="file" name='asset_bottom_image'  onChange={handleUploadBottomImage}/>
                                <span className="invalid-feedback"> {ErrUploadBottomImage} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-6">
                        <div className='row single__asset__form'>
                            <div className="col-6">
                                <div className="label">
                                    <label>Upload Other View Image </label>
                                    <span className="star error">  </span>
                                </div>
                            </div>
                            <div className='col-6'>
                                <input type="file" name='asset_other_image' onChange={handleUploadOtherImage}/>
                                <span className="invalid-feedback"> {ErrUploadOtherImage} </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div>
                            <p>Remaining attachments: {remainingFiles}</p>
                        </div>
                    </div>
                </div>
                
                <div className='row save__button text-center'>
                    <div className='col-md-12'>
                        {(Tag !== '' && Tag !== null) ? <div>
                                <button className="btn btn-primary btn-md">Update</button>
                                &nbsp;&nbsp;<a href={'/asset-details?tag=' + Tag}
                                               className="btn btn-secondary btn-md">Cancel</a></div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                      disabled={disabled}> SAVE </button>
                        }

                    </div>
                </div>
            </form>
        </div>
    )
}
