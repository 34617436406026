import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {
    getCompanyProfileList, getDivisionInfo, saveDivision, getContinentList, getRegionMasterList, getFilteredCountryList
} from '../../services/user.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function Division() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [countryId, setCountryId] = useState('');
    const [ErrCountryId, setErrCountryId] = useState(false);

    const [regions, setRegions] = useState('');
    const [ErrRegion, setErrRegions] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);

    const [divisionAddress, setDivisionAddress] = useState('');
    const [ErrDivisionAddress, setErrDivisionAddress] = useState(false);

    const [divisionGST, setDivisionGST] = useState('');
    const [ErrDivisionGST, setErrDivisionGST] = useState(false);

    const [divisionPhone, setDivisionPhone] = useState('');
    const [ErrDivisionPhone, setErrDivisionPhone] = useState(false);

    const [divisionEmail, setDivisionEmail] = useState('');
    const [ErrDivisionEmail, setErrDivisionEmail] = useState(false);

    const [divisionFAX, setDivisionFAX] = useState('');
    const [ErrDivisionFAX, setErrDivisionFAX] = useState(false);

    const [divisionname, setDivisionName] = useState('');
    const [ErrDivisionName, setErrDivisionName] = useState(false);
    const [divisioncode, setDivisionCode] = useState('');
    const [ErrDivisionCode, setErrDivisionCode] = useState(false);
    const [divisionshortname, setDivisionShortName] = useState('');
    const [ErrDivisionShortName, setErrDivisionShortName] = useState(false);
    const [companies, setCompanies] = useState([]);

    const [countries, setCountries] = useState([]);

    const [regionname, setRegionNames] = useState([]);

    const [continentId, setContinentId] = useState('');
    const [ErrContinentId, setErrContinentId] = useState(false);

    const [continents, setContinents] = useState([]);

    const resetUserInfo = function () {
        setDivisionName('');
        setDivisionCode('');
        setDivisionShortName('');
        setCompanyId('');
        setCountryId('');
        setRegions('');
        setDivisionAddress('');
        setDivisionGST('');
        setDivisionPhone('');
        setDivisionEmail('');
        setDivisionFAX('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Division_Id = query.get('divisionid');

    useEffect(() => {

        if (Division_Id !== '' && Division_Id != null) {
            const decryptedBytes = CryptoJS.AES.decrypt(Division_Id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const DivisionId = decrypt_data.division_id;
            getDivisionData(DivisionId);
        }
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getCompanyProfileList(oData).then(res => {
            if (res?.data?.data?.length) setCompanies(res.data.data);
        })
        getFilteredCountryList(oData).then(res => {
            if (res?.data?.data?.length) setCountries(res.data.data);
        })
        getContinentList(oData).then(res => {
            if (res?.data?.data?.length) setContinents(res.data.data);
        });
    }, [])
    const getDivisionData = function (DivisionId) {
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'division_id': DivisionId },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData1 = { 'token': EncryptData };

        getDivisionInfo(oData1).then(function (res) {
            if (res?.data?.data?.length > 0) {
                const divisInfo = res.data.data[0];

                // Set division data
                setContinentId(divisInfo.continent_id || '');
                setCompanyId(divisInfo.company_id || '');
                setDivisionName(divisInfo.division_name || '');
                setDivisionShortName(divisInfo.division_short_name || '');
                setDivisionCode(divisInfo.division_code || '');
                setDivisionAddress(divisInfo.division_address || '');
                setDivisionGST(divisInfo.division_gst || '');
                setDivisionPhone(divisInfo.division_phone || '');
                setDivisionEmail(divisInfo.division_email || '');
                setDivisionFAX(divisInfo.division_fax || '');

                // Fetch countries for the continent
                if (divisInfo.continent_id) {
                    fetchCountriesForContinent(divisInfo.continent_id).then(() => {
                        setCountryId(divisInfo.country_id || ''); // Set country after countries are loaded

                        // Fetch regions for the selected country
                        if (divisInfo.country_id) {
                            fetchRegionsForCountry(divisInfo.country_id);
                        }
                    });
                }
                if (divisInfo.country_id) {
                    const regionEncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                        'param_data': { 'country_id': divisInfo.country_id },
                        'authToken': sessionStorage.getItem('LoggedInToken'),
                    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

                    const regionOData = { 'token': regionEncryptData };

                    getRegionMasterList(regionOData).then((regionRes) => {
                        if (regionRes?.data?.data?.length > 0) {
                            setRegionNames(regionRes.data.data); // Populate region list

                            // Set the selected region ID
                            setRegions(divisInfo.region_id || '');
                        } else {
                            setRegionNames([]);
                            setRegions('');
                        }
                    }).catch((error) => {
                        console.error("Error fetching regions:", error);
                        setRegionNames([]);
                        setRegions('');
                    });
                }
            } else {
                console.error("Division data not found.");
            }
        }).catch((err) => {
            console.error("Error fetching division data:", err);
        });
    };
    const fetchRegionsForCountry = (countryId) => {
        if (!countryId) return;

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'country_id': countryId },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData = { 'token': EncryptData };

        getRegionMasterList(oData).then((res) => {
            if (res?.data?.data?.length > 0) {
                setRegionNames(res.data.data); // Populate regions
            } else {
                setRegionNames([]);
            }
        }).catch((err) => {
            setRegionNames([]);
            console.error("Error fetching regions:", err);
        });
    };


    const handleCompanyId = (e) => {
        setCompanyId(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        if (!TEXT_REGEX.test(countryId)) { //alert(name);
            setErrCountryId('Country name is required');
            valid_Name = false; //alert(valid_Name);
        } else {
            setErrCountryId('');
        }if (!TEXT_REGEX.test(regions)) { //alert(name);
            setErrRegions('Region name is required');
            valid_Name = false; //alert(valid_Name);
        } else {
            setErrRegions('');
        }if (!TEXT_REGEX.test(companyId)) { //alert(name);
            setErrCompanyId('Company name is required');
            valid_Name = false; //alert(valid_Name);
        } else {
            setErrCompanyId('');
        }
        if (!TEXT_REGEX.test(divisionname)) { //alert(name);
            setErrDivisionName('Branch name is required');
            valid_Name = false; //alert(valid_Name);
        } else {
            setErrDivisionName('');
        }
        if (!TEXT_REGEX.test(divisioncode)) { //alert(name);
            setErrDivisionCode('Branch code is required');
            valid_Name = false; //alert(valid_Name);
        } else {
            setErrDivisionCode('');
        }
        if (!TEXT_REGEX.test(divisionshortname)) { //alert(companyId);
            setErrDivisionShortName('Branch short name is required');
            valid_Name = false;
        } else {
            setErrDivisionShortName('');
        }if (!TEXT_REGEX.test(divisionAddress)) { //alert(companyId);
            setErrDivisionAddress('Branch address is required');
            valid_Name = false;
        } else {
            setErrDivisionAddress('');
        }if (!TEXT_REGEX.test(divisionGST)) { //alert(companyId);
            setErrDivisionGST('Branch GST number is required');
            valid_Name = false;
        } else {
            setErrDivisionGST('');
        }if (!TEXT_REGEX.test(divisionPhone)) { //alert(companyId);
            setErrDivisionPhone('Branch phone is required');
            valid_Name = false;
        } else {
            setErrDivisionPhone('');
        }if (!EMAIL_REGEX.test(divisionEmail)) { //alert(companyId);
            setErrDivisionEmail('Branch email is required');
            valid_Name = false;
        } else {
            setErrDivisionEmail('');
        }if (!TEXT_REGEX.test(divisionFAX)) { //alert(companyId);
            setErrDivisionFAX('Branch fax is required');
            valid_Name = false;
        } else {
            setErrDivisionFAX('');
        }
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {  //alert('fa');
            return false;
        }
    }

    const saveData = function () {
        try {

            if (Division_Id !== '' && Division_Id !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(Division_Id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedDivId = decrypt_data.division_id;
                var obj = {
                    'div_id': decryptedDivId,
                    'division_name': divisionname,
                    'division_short_name': divisionshortname,
                    'division_code': divisioncode,
                    'company_id': companyId,
                    'country_id': countryId,
                    'continent_id': continentId,
                    'region_id': regions,
                    'division_address': divisionAddress,
                    'division_gst': divisionGST,
                    'division_phone': divisionPhone,
                    'division_email': divisionEmail,
                    'division_fax': divisionFAX,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'division_name': divisionname,
                    'division_short_name': divisionshortname,
                    'division_code': divisioncode,
                    'company_id': companyId,
                    'country_id': countryId,
                    'continent_id': continentId,
                    'region_id': regions,
                    'division_address': divisionAddress,
                    'division_gst': divisionGST,
                    'division_phone': divisionPhone,
                    'division_email': divisionEmail,
                    'division_fax': divisionFAX,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            }

            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveDivision(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    const handleCountryId = (e) => {
        const selectedCountryId = e.target.value;
        setCountryId(selectedCountryId);

        // Clear dependent fields
        setRegionNames([]);
        setRegions('');

        if (!selectedCountryId) return; // Exit if no country is selected

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'country_id': selectedCountryId },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData = { 'token': EncryptData };

        // Fetch the regions based on the selected country
        getRegionMasterList(oData).then((res) => {
            if (res?.data?.data?.length > 0) {
                setRegionNames(res.data.data); // Populate regions
            } else {
                setRegionNames([]);
            }
        }).catch((error) => {
            console.error("Error fetching regions:", error);
            setRegionNames([]);
        });
    };
    const fetchCountriesForContinent = (continentId) => {
        if (!continentId) return Promise.resolve();

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'continent_id': continentId },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const oData = { 'token': EncryptData };

        return getFilteredCountryList(oData)
            .then(res => {
                if (res?.data?.data?.length > 0) {
                    setCountries(res.data.data); // Populate countries
                } else {
                    setCountries([]);
                }
            })
            .catch(() => {
                setCountries([]);
                console.error("Error fetching countries.");
            });
    };

    const handleContinentId = (e) => {
        const selectedContinentId = e.target.value;
        setContinentId(selectedContinentId);

        // Clear dependent fields
        setCountries([]);
        setCountryId('');
        setRegionNames([]);
        setRegions('');

        if (!selectedContinentId) return; // Exit if no continent is selected

        // Fetch the filtered country list
        fetchCountriesForContinent(selectedContinentId);
    };

    const handleRegionID = (e) => {
        setRegions(e.target.value); // Only set the selected region ID
    };

    return (
        <div className="container">

            <div className="row">
                <div className="col-6">
                    <h5>Add Branch</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>

            </div>
            <form onSubmit={handleSubmit}>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Continent</label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select
                            className="form-select form-select-sm mb-3"
                            onChange={handleContinentId}
                            value={continentId}
                        >
                            <option value="">Select Continent</option>
                            {continents.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.continent_name}
                                </option>
                            ))}
                        </select>
                        <span className="invalid-feedback"> {ErrContinentId} </span>
                    </div>
                </div>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Country</label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select
                            className="form-select form-select-sm mb-3"
                            onChange={handleCountryId}
                            value={countryId}
                            disabled={!continentId} // Disable if no continent is selected
                        >
                            <option value="">Select Country</option>
                            {countries.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.country_name}
                                </option>
                            ))}
                        </select>
                        <span className="invalid-feedback"> {ErrCountryId} </span>
                    </div>
                </div>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Region</label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select
                            onChange={handleRegionID}
                            value={regions} // Bind value to regions state
                            className="form-select form-select-sm mb-3"
                            disabled={!countryId} // Disable if no country is selected
                        >
                            <option value="">Select Region</option>
                            {regionname.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.region_name}
                                </option>
                            ))}
                        </select>
                        <span className="invalid-feedback">{ErrRegion}</span>
                    </div>
                </div>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Company </label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}>
                            <option value="">Select Company</option>
                            {companies && companies.length > 0 ? (
                                companies.map((option) => (
                                    <option value={option.company_id}
                                            selected={(option.company_id == companyId) ? "selected" : ''}>{option.company_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6 mb-2">
                        <FromFieldText star="*"
                                       label="Branch Name"
                                       inputValue={divisionname}
                                       setInputValue={setDivisionName}
                                       setErrorMsg={ErrDivisionName}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6 mb-2">
                        <FromFieldText star="*"
                                       label="Branch Short Name"
                                       inputValue={divisionshortname}
                                       setInputValue={setDivisionShortName}
                                       setErrorMsg={ErrDivisionShortName}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Branch Code"
                                       inputValue={divisioncode}
                                       setInputValue={setDivisionCode}
                                       setErrorMsg={ErrDivisionCode}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">

                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Branch Address "
                                       inputValue={divisionAddress}
                                       setInputValue={setDivisionAddress}
                                       setErrorMsg={ErrDivisionAddress}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">

                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Branch GST Number"
                                       inputValue={divisionGST}
                                       setInputValue={setDivisionGST}
                                       setErrorMsg={ErrDivisionGST}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">

                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Branch Phone"
                                       inputValue={divisionPhone}
                                       setInputValue={setDivisionPhone}
                                       setErrorMsg={ErrDivisionPhone}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">

                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Branch Email"
                                       inputValue={divisionEmail}
                                       setInputValue={setDivisionEmail}
                                       setErrorMsg={ErrDivisionEmail}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">

                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Branch Fax"
                                       inputValue={divisionFAX}
                                       setInputValue={setDivisionFAX}
                                       setErrorMsg={ErrDivisionFAX}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">

                    </div>
                </div>

                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary  btn-sm"> SAVE</button>
                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default Division


