import React,{useState,useEffect,useRef} from 'react';
import { useLocation } from "react-router-dom";
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import { getTransferByTinNo,getTransportViewByTinNo, saveTransportDetails } from '../../../services/transfer.service';

import  * as CryptoJS  from "crypto-js";

Date.prototype.dbFormatedDate = function () {
    
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
        } else {
            return null;
        }   
    }
    const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
    //const NUMBER_REGEX = /^[0-9]{1,15}$/;

function TransferReceivedView() {
    
    const [assetInfo, setTransferInfo] = useState({});
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
    //const [allocate, setAllocate] = useState('');
    const [transferId, setTransferId] = useState('');
    const [ErrTransferId, setErrTransferId] = useState(false);

    const [tinNo, setTinNo] = useState('');
    const [ErrTinNo, setErrTinNo] = useState(false);

    const [modeOfTransport,setModeOfTransport] = useState('');
    const [ErrModeOfTransport, setErrModeOfTransport] = useState(false);

    const [transporterName,setTransporterName] = useState('');
    const [ErrTransporterName, setErrTransporterName] = useState(false);

    const [shippingDocNo,setShippingDocNo] = useState('');
    const [ErrShippingDocNo, setErrShippingDocNo] = useState(false);

    const [shippingDate,setShippingDate] = useState('');
    const [ErrShippingDate, setErrShippingDate] = useState(false);

    const [contactPerson,setContactPerson] = useState('');
    const [ErrContactPerson, setErrContactPerson] = useState(false);

    const [contactNumber,setContactNumber] = useState('');
    const [ErrContactNumber, setErrContactNumber] = useState(false);

    const [receiverOfConsignment,setReceiverOfConsignment] = useState('');
    const [ErrReceiverOfConsignment, setErrReceiverOfConsignment] = useState(false);

    const [receiverMail,setReceiverMail] = useState('');
    const [ErrReceiverMail, setErrReceiverMail] = useState(false);

    const [receiverMobile,setReceiverMobile] = useState('');
    const [ErrReceiverMobile, setErrReceiverMobile] = useState(false);

    
    const resetTransferInfo = function() { setTinNo(''); setModeOfTransport(''); setTransporterName('');
    setShippingDocNo(''); setShippingDate(''); setContactPerson('');  setContactNumber(''); 
    setReceiverOfConsignment('');    setReceiverMail(''); setReceiverMobile('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const TinNo = query.get('tin_no'); 
    //const allocate = query.get('allocate');
    const elementRef = useRef();
    useEffect(() => {
        if(TinNo!=='' && TinNo!==null){
            const decryptedBytes = CryptoJS.AES.decrypt(TinNo.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTinNo = decrypt_data.tin_no;
            getTransferInfo(decryptedTinNo);
            //alert(decryptedTinNo);
        }else{resetTransferInfo(); }
        
    }, [])
    const getTransferInfo = function(decryptedTinNo) { resetTransferInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tin_no':decryptedTinNo},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token':EncryptData}
        getTransportViewByTinNo(oData1).then(function(res){
        //getTransferData(TinNo).then(function(res){
            if(res?.data?.data) {
                var aInfo = res.data.data[0];
                console.log(aInfo);
                setTransferInfo(aInfo);
                setTransferId(aInfo.trans_id);
                setTinNo(aInfo.tin_no);
                setModeOfTransport(aInfo.mode_of_transport);
                setTransporterName(aInfo.transport_name);
                setShippingDocNo(aInfo.shipping_doc_no);
                setShippingDate(aInfo.shipping_date?(new Date(aInfo.shipping_date)):'');
                setContactPerson(aInfo.contact_person);
                setContactNumber(aInfo.contact_number);
                setReceiverOfConsignment(aInfo.receiver_of_consignment);
                setReceiverMail(aInfo.receiver_mail);
                setReceiverMobile(aInfo.receiver_mobile);
                //console.log(allocation_status);
            }else{ setErrMsg('Assets Not Found'); }
            
        })
    }
    const getTinNoInfo = function() { //resetTransferInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tin_no':tinNo},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getTransferByTinNo(oData).then(function(res){
            console.log('res.data.data');
            console.log(res.data.data[0]);
            var aInfo={};
            if(res?.data?.data[0]) { setErrMsg('');
                aInfo = res?.data?.data[0];
                setTransferInfo(aInfo);
                setTransferId(aInfo.trans_id);
                setTinNo(aInfo.tin_no);
                
            }else{setTransferInfo({});setErrMsg('Assets Not Found');} 
            
        })
    }
    
    
    return (
        <div className="container">
            
            <div className="row">
                <div className="col">
                    <h5>Transfer Details</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandetory field.
                </div>

                {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            </div>
            
            <div className='row '>
            <div className='col-12'>&nbsp;</div>
            {
            (TinNo!=='' && TinNo!==null) ? <div className='row'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTinNo(event.target.value)}} inputValue={assetInfo.tin_no} type="text" className="form-control"  placeholder='Search TIN No' /><p className='invalid-feedback'>{ErrTinNo}</p></div>
            <div className='col'><button onClick={getTinNoInfo} className='btn btn-info btn-sm'><i className="bi bi-search"></i> Search </button>&nbsp;<a href={'/update-transport-details' }><button  className='btn btn-secondary btn-md'><i className="bi bi-arrow-clockwise"></i> Reset</button></a>&nbsp;</div></div>
            }

            </div>
            <div className='row'></div>
            <div className="row">
                
                <div className="col-6">
                    <FromFieldText  
                        label="TIN No" star="*"
                        inputValue={assetInfo.tin_no?assetInfo.tin_no:''}
                        readOnly="readonly"
                        setErrorMsg={ErrTinNo}
                        /> 
                </div><div className='col-6'></div>
            </div>
            
             
            <div className="row">
                <div className="col-6">
                <FromFieldText  star="*"
                        label="Mode of Transport" 
                        inputValue={modeOfTransport} 
                        setInputValue={setModeOfTransport}
                        readOnly="readonly"
                    />
                    
                </div>
                <div className="col-6">
                <FromFieldText  star="*"
                        label="Transporter Name" 
                        inputValue={transporterName} 
                        setInputValue={setTransporterName}
                        readOnly="readonly"
                        />
                </div>
                
            </div>
            <div className="row">
                <div className="col-6">
                <FromFieldText  star="*"
                    label="AWB/Shipping Doc No" 
                    inputValue={shippingDocNo}
                    setInputValue={setShippingDocNo}
                    readOnly="readonly"
                    /> 
                </div>
                <div className="col-6">
                <FromFieldDate star="*"
                  label="Date of Shipping" 
                  inputValue={shippingDate} 
                  setInputValue={setShippingDate}
                  readOnly="readonly"
                  />
                </div>
                
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Contact Person" 
                        inputValue={contactPerson}
                        setInputValue={setContactPerson}
                        readOnly="readonly"
                        /> 
                </div>
                <div className="col-6">
                <FromFieldText   star="*"
                        label="Contact Number" 
                        inputValue={contactNumber} 
                        setInputValue={setContactNumber}
                        readOnly="readonly"
                        />
                </div>
                
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Receiver of Consignment" 
                        inputValue={receiverOfConsignment}
                        setInputValue={setReceiverOfConsignment}
                        readOnly="readonly"
                        /> 
                </div>
                <div className="col-6">
                <FromFieldText   star="*"
                        label="Mail Id of Receiver" 
                        inputValue={receiverMail} 
                        setInputValue={setReceiverMail}
                        readOnly="readonly"
                        />
                </div>
                
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Mobile of Receiver" 
                        inputValue={receiverMobile}
                        setInputValue={setReceiverMobile}
                        readOnly="readonly"
                        /> 
                </div>
                <div className="col-6">
                </div>
            </div>
            
            <div className="row">
            <div className="col-3"></div>
              <div className="col-6">
{/*               
               {(TinNo!=='' && TinNo!==null) ?(<button className="btn btn-primary btn-md" > Edit </button>):(<button className="btn btn-primary btn-md" > Update </button>) } 
                     */}
              </div>
              <div className="col-3"></div>
            </div>
            
        </div>
    )
}

export default TransferReceivedView


