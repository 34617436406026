import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {getAllDisposalTagList} from "../../../services/disposal.service";

import * as CryptoJS from "crypto-js";
import PartialSpecifyPopupBox from "../../../atoms/PartialSpecifyPopupBox";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function DisposalList() {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [tag, setTag] = useState('');
    const [din_no, setDinNo] = useState('');
    const [disposalcaptilizedvalue, setDisposalCaptilizedValue] = useState('');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);


    const handleConfirmOpen = async (din_no, tag, disposalcaptilizedvalue) => {
        setDinNo(din_no);
        setTag(tag);
        setDisposalCaptilizedValue(disposalcaptilizedvalue);
        setIsConfirmOpen(true);
    };

    const handleConfirmClose = async (updatedDinNo, updatedValue) => {
        setIsConfirmOpen(false);

        // Update UI Immediately
        setItems(prevItems =>
            prevItems.map(item =>
                item.din_no === updatedDinNo ? { ...item, disposal_captilized_value: updatedValue } : item
            )
        );

        // Fetch Fresh Data After Update
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = { 'token': EncryptData };

        setLoading(true);

        getAllDisposalTagList(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data); // Ensures fresh data from API
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const columns = [
        {field: "id", headerName: "ID","hide": true, width: 50},
        {field: "din_no", headerName: "DIN No", width: 230},
        {
            "field": "tag", "hide": false, "headerName": "TAG No", "width": 220,
            renderCell: (params) => {
                let encryptedTag = CryptoJS.AES.encrypt(JSON.stringify({
                    'tag': params.value, 'back_url':'disposallist'
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

                return (
                    <a href={`/asset-basic-details?tag=${encryptedTag}`}>{params.value}</a>
                );
            }
        },
        {
            "field": "sale_initiated_by",
            "hideable": true,
            "hide": false,
            "headerName": "Sale initiated By",
            "width": 180
        },
        {
            "field": "initiation_date", "hide": false, "headerName": "Date of Initiation", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.initiation_date)}</p>
            )
        },
        {"field": "sale_authorized_by", "hide": false, "headerName": "Sale Authorized By", "width": 180},
        {
            "field": "authorization_date", "hide": false, "headerName": "Date of Authorization", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.authorization_date)}</p>
            )
        },
        {"field": "disposal_reason", "hide": false, "headerName": "Reason for Disposal", "width": 180},
        {
            "field": "sale_date", "hide": false, "headerName": "sale_date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.sale_date)}</p>
            )
        },
        {"field": "type_of_disposal", "hide": false, "headerName": "Type of Disposal", "width": 180},

        {
            "field": "disposal_captilized_value",
            "editable": false,
            "hide": false,
            "headerName": "If Partial, Specify as % of Capitalized Value",
            "width": 300,
            renderCell: (params) => (
                <div>

                    {params.row.type_of_disposal === 'Full' ? (
                        <button class="btn btn-success btn-sm text-right"
                                onClick={() => handleConfirmOpen(params.row.din_no, params.row.tag, params.row.disposal_captilized_value)}>
                            100 %
                        </button>
                    ) : (
                        params.row.disposal_captilized_value !== 0 ? (
                            <button
                                class="btn-btn-warning btn-sm text-right"
                                onClick={() => handleConfirmOpen(params.row.din_no, params.row.tag, params.row.disposal_captilized_value)}
                            >
                                {params.row.disposal_captilized_value} %
                            </button>


                        ) : (
                            <button
                                className="btn btn-danger btn-sm text-right"
                                onClick={() => handleConfirmOpen(params.row.din_no, params.row.tag, params.row.disposal_captilized_value)}
                            >
                                Specify
                            </button>
                        )
                    )}


                </div>
            )
        },
        {"field": "sub_status", "hide": false, "headerName": "Sub Status", "width": 180},
        {"field": "status_of_auth_doc", "hide": false, "headerName": "Status of Auth Doc", "width": 180},
        {"field":"created_at","editable":true,"hide":false ,"headerName":"Created Date","width":180,
            renderCell: (params) => (
                <p>{ getFormatedDate(params.row.created_at)}</p>
            ) },
    ];

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        setLoading(true);

        getAllDisposalTagList(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    // readAssets();
    return (
        <div className='container'>
            <div class="row">
                <div class="col">
                    <h5>List of Disposal</h5>
                </div>
            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}

            <PartialSpecifyPopupBox
                din_no={din_no}
                tag={tag}
                disposalcaptilizedvalue={disposalcaptilizedvalue}
                isOpen={isConfirmOpen}
                onClose={handleConfirmClose}
                onConfirm={handleConfirmClose} // Pass the update function
            />

        </div>
    )
}

export default DisposalList


