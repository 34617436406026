import React, {useEffect, useState} from 'react';
import {getFinalizeBuyerList} from '../../../services/disposal.service';
import {DataGrid} from '@mui/x-data-grid';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}


const columns = [

    {"field": "id", "hide": true, "headerName": "Sl No", "width": 50},
    {"field": "din_no", "headerName": "DIN No", "width": 220},
    {"field": "tag", "headerName": "TAG", "width": 220},

    {"field": "buyer_no", "hide": true, "headerName": "Final Buyer No", "width": 180},
    {"field": "buyer_name", "hide": false, "headerName": "Final Buyer Name", "width": 180},
    {"field": "buyer_price_quoted", "hide": false, "headerName": "Price Quoted", "width": 180},
    {"field": "buyer_final_price", "hide": false, "headerName": "Final Sale Price W/o GST", "width": 180},
    {
        "field": "buyer_quote_received_date", "hide": false, "headerName": "Date of Sale", "width": 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.buyer_quote_received_date)}</p>
        )
    },
    {
        "field": "final_approval_date", "hide": false, "headerName": "Final Approval Date", "width": 180,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.final_approval_date)}</p>
        )
    },
    {"field": "final_approval_by", "hide": false, "headerName": "Approval By", "width": 180},
    {"field": "gen_ref_no", "hide": false, "headerName": "Ref No", "width": 220},
    {"field":"created_at","editable":true,"hide":false ,"headerName":"Created Date","width":180,
        renderCell: (params) => (
            <p>{ getFormatedDate(params.row.created_at)}</p>
        ) },

];

function ListofFinalizeDeal() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        setLoading(true);

        getFinalizeBuyerList(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // readAssets();
    return (
        <div className='container'>
            <div class="row">
                <div class="col">
                    <h5>Finalize Deal List</h5>
                </div>
            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    //pageSize={5}
                    autoHeight
                    //rowsPerPageOptions={[5]}
                    onCellEditStop={onCellEditStopFn}
                />
            )}


        </div>
    )
}

export default ListofFinalizeDeal


