import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {getNonUseableList, getTagChildCountMulti} from '../../../services/asset.service';
import {getPendingIR, saveFlagAssetSTF} from '../../../services/flagging.service';
import * as CryptoJS from "crypto-js";
//import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldTextArea from '../../../atoms/FromFieldTextArea';
//import FromFieldSelect from '../../../atoms/FromFieldSelect';
import {getDepartmentList,getDepartmentGroupList} from '../../../services/user.service';
import {getOtherTagDivisionList} from '../../../services/master.service';
import {createNotifications} from '../../../services/notification.service';
import Select from "react-select";
function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}


function FlagAssetSTF() {
    //var block = props.show? "display-block": "";
    const [activeTab, setActiveTab] = useState(0); // State to track the active tab
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listOptions1, setListData1] = useState([]);
    //const [listOptions2, setListData2] = useState([]);
    const [catOfFlag, setCatOfFlag] = useState('');
    const [ErrCatOfFlag, setErrCatOfFlag] = useState(false);
    const [purpose, setPurpose] = useState('');
    const [purposeOfFlaging, setPurposeOfFlaging] = useState('');
    const [ErrPurpose, setErrPurpose] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [ErrRemarks, setErrRemarks] = useState(false);
    const [ownershipId, setOwnershipId] = useState(sessionStorage.getItem('LoggedInDepartmentId'));
    const [ErrOwnershipId, setErrOwnershipId] = useState(false);
    const [newOwnershipId, setNewOwnershipId] = useState('');
    const [ErrNewOwnershipId, setErrNewOwnershipId] = useState(false);
    const [foundAssets, setFoundAssets] = useState([]);
    const [oldfoundAssets, setOldFoundAssets] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [newdepartments, setNewDepartments] = useState([]);
    const [purposeItems, setPurposeItems] = useState([]);

    const [childFlagging, setChildFlagging] = useState('');
    const [ErrChildFlagging, setErrChildFlagging] = useState(false);

    const [disposal, setDisposal] = useState('');
    const [ErrDisposal, setErrDisposal] = useState(false);

    const [showTextBox, setShowTextBox] = useState('');

    const [textBoxValue, setTextBoxValue] = useState('');

    const [tagCount,setTagCount] = useState('');

    const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

    const filter = (e) => {
        const keyword = e.target.value.toLowerCase(); // Convert input to lowercase
        setName(e.target.value);

        if (keyword !== '') {
            const results = oldfoundAssets.filter((asset) =>
                asset.tag.toLowerCase().startsWith(keyword) // Convert tag to lowercase for comparison
            );
            setFoundAssets(results);
        } else {
            setFoundAssets(oldfoundAssets);
        }
    };

    useEffect(() => {
        //setListData2({listOptions1});
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getNonUseableList(oData).then((res) => {
            if (res?.data?.data?.length) {
                setFoundAssets(res.data.data);
                setOldFoundAssets(res.data.data); // ✅ Correctly updating oldFoundAssets
            }
            setLoading(false);
        });

        //For Purpose List
        getOtherTagDivisionList(oData).then(res => {
            if (res?.data?.data?.length) setPurposeItems(res.data.data);
        });
        /*getDepartmentList(oData).then(res => {
            if (res?.data?.data?.length) {
                setDepartments(res.data.data);
                setNewDepartments(res.data.data);
            }
        });*/
        callDepartmentList();
        //setFoundAssets(asset_data);
        setListData1(listOptions1);
    }, []);

    function callDepartmentList(value2='') {
        let obj = {};
        if(value2 == 'Other Control Dept'){
            obj = {'self_department_id':sessionStorage.getItem('LoggedInDepartmentId')}
        }else if(value2 == 'Branch Transfer'){
            // only Forwarding Department
            obj = {'department_group':'Forwarding Department'}
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getDepartmentList(oData).then(res => {
            if (res?.data?.data?.length) {
                setDepartments(res.data.data);
                setNewDepartments(res.data.data);
            }
        });
    }
    const optionListOwnership = [];
    newdepartments && newdepartments.length > 0 ? (
        newdepartments.map((option) => (
            optionListOwnership.push({value: option.id, label: option.department_name})
        ))
    ) : (
        optionListOwnership.push({value: "", label: "No Data"})
    )
    const [selectedOwnershipOptions, setSelectedOwnershipOptions] = useState();

    function handleNewOwnership(data) {
        setSelectedOwnershipOptions(data);
        setNewOwnershipId(data.value);
    }

    const pushListOptions = (obj) => {
        var flag = 0;
        setLoading('..');
        listOptions1.forEach((elem) => {
            if (obj.value === elem.value && obj.label === elem.label) {
                flag = 1;
            }
        });
        setLoading(false);
        if (flag === 1) {
            return false;
        } else {
            return true;
        }
    };

    //search filter
    // the value of the search field
    const [name, setName] = useState('');
    // the search result

    //start validation
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        if (listOptions1.length === 0) { //alert(listOptions1.length);
            setValidName(false);
            alert('Please select atleast one Asset');
            valid_Name = false;
        }
        if (!valid_Name) { //alert(valid_Name);
            return false;
        } else { //alert(mon); alert(fy);
            //saveData();
            handleNextClick();
        }
    }
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        /*if (!TEXT_REGEX.test(catOfFlag)) {
            setErrCatOfFlag('Disposal mode is required');
            valid_Name = false;
        } else {
            setErrCatOfFlag('');
        }*/
        if (!TEXT_REGEX.test(purpose)) {
            setErrPurpose('Purpose is required');
            valid_Name = false;
        } else {
            setErrPurpose('');
        }
        if (!TEXT_REGEX.test(remarks)) {
            setErrRemarks('Remarks is required');
            valid_Name = false;
        } else {
            setErrRemarks('');
        }
        if (!TEXT_REGEX.test(ownershipId)) {
            setErrOwnershipId('Purpose is required');
            valid_Name = false;
        } else {
            setErrOwnershipId('');
        }
        if (!TEXT_REGEX.test(newOwnershipId)) {
            setErrNewOwnershipId('Purpose is required');
            valid_Name = false;
        } else {
            setErrNewOwnershipId('');
        }
        if (!valid_Name) { //alert(valid_Name);
            return false;
        } else { //alert(mon); alert(fy);
            saveData();
            //handleNextClick();
        }
    }
    const saveData = async () => {
        
        var obj = {
            'tag': listOptions1,
            //'cat_of_flag': catOfFlag,
            'purpose': purpose,
            'remarks': remarks,
            'child_flagging' : childFlagging,
            'depriciation' : disposal,
            'depriciation_value' : textBoxValue,
            'existing_ownership_id': sessionStorage.getItem('LoggedInDepartmentId'),
            'new_ownership_id': newOwnershipId,
            'created_by': sessionStorage.getItem('LoggedInUserId'),
            //'status':'4'
        };

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj, 
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            setLoading('..'); // Set loading before sending API request
            saveFlagAssetSTF(oData).then(function (res) {
                setValidName(false);
                setLoading(false); // Stop loading
                //console.log(res);
                setSuccess(res.data.message); //alert('a');
                //elementRef.current.focus();
                //move to next screen
                const updatedData = foundAssets.filter((item) => !listOptions1.includes(item.id));
                setFoundAssets(updatedData);
                setListData1([]);
                setPurpose('');
                setCatOfFlag([]);
                setRemarks('');
                handlePrevClick();
            });
        } catch (err) {
            setSuccess(false);
            setLoading(false); // Stop loading
            if (!err?.data1) {
                console.log(err);
                //setErrMsg('No Server Response');
                //setSuccess("Successfully saved Single Asset");
            } else if (err.data1?.status === 409) {
                //setErrMsg('Record already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    const handleMoveList1 = (e) => {
        const selectedOptions = [];
        //alert('dd');
        var options1 = document.getElementById('lstBox1').options;
        //console.log(options1);
        for (var i = 0, l = options1.length; i < l; i++) {
            if (options1[i].selected) {
                var option1 = {
                    label: options1[i].label,
                    value: options1[i].value,
                }
                if (pushListOptions(option1)) {
                    listOptions1.push(option1);
                }
            }
        } //setListData2(listOptions1); 
        setListData1((prevList) => prevList.filter((option) => !selectedOptions.find((selected) => selected.value === option.value)));

        //console.log('listOptions1');console.log(listOptions1);
    }
    const handleMoveList2 = (e) => {
        const selectedOptions2 = [];
        var options2 = document.getElementById('lstBox2').options;
        //console.log(options1);
        for (var i = 0, l = options2.length; i < l; i++) {
            if (options2[i].selected) {
                var option1 = {
                    label: options2[i].label,
                    value: options2[i].value,
                }
                if (pushListOptions(option1)) {
                    listOptions1.push(option1);
                }
            }
        } //setListData2(listOptions1); 
        setListData1((prevList) => prevList.filter((option) => !selectedOptions2.find((selected) => selected.value === option.value)));

        //console.log('listOptions1');console.log(listOptions1);
    }
    const handleAllMoveList1 = (e) => {
        //var listOptions = [];
        var options1 = document.getElementById('lstBox1').options;
        for (var i = 0, l = options1.length; i < l; i++) {
            var option1 = {
                label: options1[i].label,
                value: options1[i].value,
            }
            if (pushListOptions(option1)) {
                listOptions1.push(option1);
            }
            //listOptions1.push(option1);
        } //setListData2(listOptions1); console.log(listOptions2);
        setListData1(listOptions1);
    }
    const handleOwnership = (e) => {
        setOwnershipId(e.target.value);
    }
    /*const handleNewOwnership = (e) => {
        setNewOwnershipId(e.target.value);
    }*/
    const handlePurpose = (e) => {
        const [value1, value2] = e.target.value.split(',');
            setPurpose(value1);
            setPurposeOfFlaging(value2);
            /* On the select purpose Other Control Dept
            in the new department dropdown do not show self department */
            callDepartmentList(value2);
            
    }
    const handleAllRemove = (e) => {
        const confirmRemove = window.confirm("Are you sure you want to remove all TAG from list?");
        if (confirmRemove) {
            setListData1([]);
        }
    }
    const handleNextClick = () => {
        // Handle the "Next" button click to move to the next tab
        setActiveTab(activeTab + 1);
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tags': listOptions1},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getTagChildCountMulti(oData).then((res) => {
            const tagCount = res.data.data.childTagCount || 0; // Ensure a valid number
            setTagCount(tagCount);
            setChildFlagging(tagCount > 0 ? "Yes" : "No");
        });
    };

    const handlePrevClick = () => {
        // Handle the "Previous" button click to move to the previous tab
        setActiveTab(activeTab - 1);
    };
    const removeTag = (value) => {
        const confirmRemove = window.confirm("Are you sure you want to remove TAG from list?");
        if (confirmRemove) {
            listOptions1.pop(value);
            const updatedList = listOptions1.filter((item) => item !== value);
            setListData1(updatedList);
        }
    }
    const handleCATofFlag = (e) => {
        //let name= e.target.name;
        //let value= e.target.value;
        setCatOfFlag(e.target.value);
    }

    const handleChildFlagging = (e) => {
        // Allow changes only if childFlagging is not predefined by tagCount
        if (tagCount > 0) {
            setChildFlagging("Yes"); // Ensure it's Yes if tagCount > 0
        } else if (tagCount === 0) {
            setChildFlagging("No"); // Ensure it's No if tagCount === 0
        } else {
            setChildFlagging(e.target.value); // Allow manual selection if not predefined
        }
    };

    const handleDisposal = (event) => {
        const selectedValue = event.target.value;
        setDisposal(selectedValue);
        setShowTextBox(selectedValue === 'Partial');
    };

    const handleTextBoxChange = (event) => {
        const value = event.target.value;
        // Add any validation logic here
        setTextBoxValue(value);
    };

    return (
        <div className='container'>

            <div className="tab-content">
                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>
                    <div>
                        {success ? (<div className="alert alert-success" role="alert"><i
                            className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
                                <div className="alert alert-danger" role="alert"><i
                                    className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                        )}
                        <div className="title">
                            <h5>Single Reclassify</h5>

                        </div>

                        <>
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <div className="subject-info-box-1">
                                    <input
                                        type="search"
                                        value={name}
                                        onChange={filter}
                                        className="form-control"
                                        placeholder="Filter"
                                    />
                                    <select multiple="multiple" id="lstBox1" className="form-select form-select-sm">
                                        {foundAssets?.length > 0 ? (
                                            foundAssets.map((option) => (
                                                <option key={option.tag} value={option.tag}>
                                                    {option.tag}
                                                </option>
                                            ))
                                        ) : (
                                            <option key="" value="">
                                                No result
                                            </option>
                                        )}
                                    </select>
                                    <p>Total count: {foundAssets?.length}</p>
                                </div>
                            )}
                        </>
                        {/*{(!foundAssets || foundAssets.length === 0) && <p>No results</p>}*/}
                        <div className="subject-info-arrows text-center">{loading ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">{loading}</span>
                            </div>) : ('')}
                            {/*<button onClick={filter} value="" className="btn btn-info btn-sm refresh">Refresh</button>*/}
                            <br/><br/>
                            <input type='button' id='btnAllRight' onClick={handleAllMoveList1} value='Add All'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnRight' onClick={handleMoveList1} value='>'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnRight' onClick={handleMoveList2} value='<'
                                   className="btn btn-default"/><br/>
                            <input type='button' id='btnAllLeft' onClick={handleAllRemove} value='Remove All'
                                   className="btn btn-default"/>
                        </div>
                        <div className="subject-info-box-2">
                            <select multiple="multiple" id='lstBox2' className="form-select form-select-sm">
                                {listOptions1 && listOptions1.length > 0 ? (
                                    listOptions1.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))
                                ) : (
                                    <h1>No results</h1>
                                )}
                            </select>
                            <p>Total count: {listOptions1.length || 0}</p>
                        </div>
                        <div className="clearfix"></div>
                        <div className="row mt-4 text-center">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-4">
                                <form name="" onSubmit={handleSubmit}>
                                    <button className="btn btn-primary btn-md float-end"> Next <i class="bi bi-arrow-right-short"></i></button>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className={`container tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>
                    <div>
                        <div className="title">
                            <div className='row'>
                                <div className='col'>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Purpose 2</label><span className="star error"> *</span>
                                            </div>
                                            <div className="col-md-6">
                                                <select onChange={handlePurpose} className="form-select form-select-sm">
                                                    <option value="">Select Purpose</option>
                                                    {purposeItems && purposeItems.length > 0 ? (
                                                        purposeItems.map((option) => (
                                                            <option value={`${option.id},${option.purpose_of_flaging}`}
                                                                    selected={(purpose == option.id) ? "selected" : ''}>{option.tag_division_name}</option>
                                                        ))
                                                    ) : (
                                                        <option value="">No Data</option>
                                                    )}
                                                </select>
                                                <span className="invalid-feedback"> {ErrOwnershipId} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'></div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <div className='col-6'>
                                        <FromFieldTextArea
                                            label="Remarks"
                                            inputValue={remarks}
                                            setInputValue={setRemarks}
                                            setErrorMsg={ErrRemarks}
                                        />
                                    </div>
                                    <div className='col-6'></div>
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <div className='col-6'>
                                        <div className="row">
                                            <div className='col-6'>
                                                <label>New Control Department </label><span
                                                className="star error"> *</span>
                                            </div>
                                            <div className="col-md-6">

                                                <Select
                                                    options={optionListOwnership}
                                                    placeholder="Select One"
                                                    value={selectedOwnershipOptions}
                                                    onChange={handleNewOwnership}
                                                />


                                                <span className="invalid-feedback"> {ErrNewOwnershipId} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'></div>
                                </div>
                            </div>
                        {/*{(purposeOfFlaging== "Disposable")? ( */}
                            <div className="row">
                                <div className="col">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <label> Do You want Include Child?</label>
                                                <span className="star error">  </span>
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    onChange={handleChildFlagging}
                                                    name="childFlagging"
                                                    id="childFlagging"
                                                    className="form-select form-select-sm"
                                                    disabled={childFlagging !== "Yes"} // Editable only if childFlagging is "Yes"
                                                >
                                                    <option value="">Select Child</option>
                                                    <option value="Yes" selected={childFlagging === "Yes"}>Yes</option>
                                                    <option value="No" selected={childFlagging === "No"}>No</option>
                                                </select>
                                                <span className="invalid-feedback">{ErrChildFlagging} </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*):''}*/}
                            {(purposeOfFlaging == "Disposable") ? (
                                <div className="row">
                                    <div className="col">
                                    <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                <label> Select type of Disposal </label>
                                                <span className="star error">  </span>
                                            </div>
                                            <div className="col-md-6">
                                                <select onChange={handleDisposal} name="childFlagging"
                                                        id="childFlagging"
                                                        className="form-select form-select-sm">
                                                    <option value="">Select type of Disposal</option>
                                                    <option value="Full"
                                                            selected={disposal === "Full" ? "selected" : ''}>Full
                                                        Disposal
                                                    </option>
                                                    <option value="Partial"
                                                            selected={disposal === "Partial" ? "selected" : ''}>Partial
                                                        Disposal
                                                    </option>
                                                </select>
                                                <span className="invalid-feedback">{ErrDisposal} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            ):''}

                            {purposeOfFlaging === "Disposable" && showTextBox && (
                                <div className="col">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <label> Partial Disposal </label>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    value={textBoxValue}
                                                    onChange={handleTextBoxChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className='row'>
                                <div className='col'>
                                    <div className='col-6'>
                                        <div className="row">
                                            <div className='col-6'>
                                                <form name="" onSubmit={handleSubmit1}>
                                                    <button className="btn btn-primary btn-md float-end"> Submit
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="col-md-6">

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'></div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className={`container tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>
                    <div>
                        <div className="col">
                            <h5>Flag Asset List</h5>
                            <table class="table">
                                <thead>
                                <th scope="col">TAG No</th>
                                {/*<th>Asset Name</th>
                                <th>Allocation Status</th>
                                <th>Invoice Date</th>*/}
                                <th scope="col">Action</th>
                                </thead>
                                <tbody>
                                {listOptions1 && listOptions1.length > 0 ? (
                                    listOptions1.map((option) => (
                                        <tr>
                                            <td value={option.vale}>{option.label}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <span className="btn btn-danger btn-sm" id={option.vale}
                                                        onClick={() => removeTag(option.vale)}><i
                                                    class="bi bi-trash"></i></span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <option value="">No Data</option>
                                )}</tbody>
                            </table>

                        </div>

                        <div className="clearfix"></div>
                        {/*<form name="" onSubmit={handleSubmit}>
                            <button className="btn btn-primary btn-md float-end"> Next</button>
                                </form>*/}

                    </div>
                </div>


            </div>

            {/*<button onClick={handlePrevClick}>Previous</button>
            <button onClick={handleNextClick}>Next</button>*/
            }
        </div>

    )
}

export default FlagAssetSTF
    

