import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import {getAllocation, deleteTAG} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

//const NUMBER_REGEX = /^[0-9]{1,15}$/;

function SingleTagDelete() {

    const [assetInfo, setAssetInfo] = useState({});
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    //const [allocate, setAllocate] = useState('');
    const [tag, setTag] = useState('');
    const [ErrTag, setErrTag] = useState(false);

    const resetAssetInfo = function () {
        setTag('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');

    const elementRef = useRef();

    useEffect(() => {

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

    }, [])


    const getTagInfo = function () {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAllocation(oData).then(function (res) {
            console.log(res.data.data);
            var aInfo = {};
            //getTransferData(tag).then(function(res){
            if (res?.data?.data?.assetInfo) {
                setErrMsg('');
                aInfo = res.data.data.assetInfo;
                var aLogInfo = res.data.data.logInfo;
                setAssetInfo(aInfo);


                //setInvoiceDate((new Date(aInfo.invoice_date)));
                setTag(aInfo.tag);
                //console.log(aLogInfo.length);


            } else {
                setAssetInfo({});
                setErrMsg('Assets Not Found');
            }

        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(division);
        //alert(tag);
        if (!TEXT_REGEX.test(tag) && tag !== 0) {
            setErrTag('TAG is required');
            valid_Name = false;
            //return;
        } else {
            setErrTag('');
        }

        //alert(valid_Name);
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function () {
        var obj = {
            'tag': tag,
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            deleteTAG(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setTag('');
                    setAssetInfo('');
                    resetAssetInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>Single TAG Delete</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    (Tag !== '' && Tag !== null) ? <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div> : <div className='row'>
                        <div className='col'><h5 className='align-right'>TAG </h5></div>
                        <div className='col'><input onChange={function (event) {
                            setTag(event.target.value)
                        }} inputValue={assetInfo.tag} type="text" className="form-control"
                                                    placeholder='Enter the asset TAG'/><p
                            className='invalid-feedback'>{ErrTag}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-info btn-md'><i className="bi bi-search"></i> Search
                            </button>
                            &nbsp;<a href={'/single-allocation'}>
                            <button className='btn btn-info btn-md'><i className="bi bi-arrow-clockwise"></i> Reset</button>
                        </a>&nbsp;</div>
                    </div>
                }

            </div>
            <div className='row'></div>
            <div className="row">

                <div className="col-6">
                    <FromFieldText
                        label="TAG"
                        inputValue={assetInfo.tag ? assetInfo.tag : ''}
                        readOnly="readonly"
                    />
                </div>
                <div className='col-6'></div>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">

                        <button className="btn btn-primary btn-md"> Delete</button>

                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default SingleTagDelete


