import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import Select from 'react-select';
import FromFieldText from '../../../atoms/FromFieldText';
import './SingleAsset.css';
import {
    getAllAssetsTag, getAssetsCertificateCount,
    getInvoices,
    getSTPI,
    getSuppliers,
    saveSingleCertificate,
} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[a-zA-Z0-9-_/ ]{1,255}$/;
export default function UploadAssetCertificate() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const elementRef = useRef();

    useEffect(() => {
        if (Tag !== '' && Tag !== null) {
            getTagAasetInfo(Tag);
            //alert(Tag);
        }

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAllAssetsTag(oData).then(res => {
            if (res?.data?.data?.length) setAllAssetsData(res.data.data);
        });

        getSuppliers(oData).then(res => {
            if (res?.data?.data?.length) setSuppliersData(res.data.data);
        });

    }, []);
    const getTagAasetInfo = function (Tag) {
        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'tag': Tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            getSTPI(oData).then(function (res) {
                if (res?.data?.data) {
                    var stpiInfo = res.data.data.assetInfo;
                    //setImgDesc(stpiInfo.year_of_addition);
                    setTag(stpiInfo.tag);
                }
            });
        } catch (err) {
            //setAssetInfo(''); 
        }

    }
    //const userRef = useRef();
    const errRef = useRef();
    const [imgDesc, setImgDesc,] = useState('');
    const [ErrImgDesc, setErrImgDesc] = useState(false);
    const [uploadassetcertificate, setUploadAssetCertificate] = useState([]);
    const [ErrUploadAssetCertificate, setErrUploadAssetCertificate] = useState(false);

    const [tag, setTag] = useState('');
    const [supplier, setSupplier] = useState('');
    const [invoice, setInvoice] = useState('');

    const [ErrTag, setErrTag] = useState(false);
    const [ErrInvoice, setErrInvoice] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [assetInfo, setAssetInfo] = useState({});
    const [allAssets, setAllAssetsData] = useState([]);
    const [suppliers, setSuppliersData] = useState([]);
    const [invoices, setInvoicesData] = useState([]);

    const [imageCount, setImageCount] = useState(0);
    const [NoOfDoc, setNoOfDoc] = useState('');
    const [ErrNoOfDoc, setErrNoOfDoc] = useState(false);
    const [DocTypeList, setDocTypeList] = useState([]);
    const [supplierReadonly, setSupplierReadonly] = useState(false);
    // Maximum file size limit in bytes (5 MB)
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    // Maximum number of files allowed
    const maxFileCount = 5-imageCount;
    const handleUploadCertificate = (e) => {
        const files = e.target.files;
        const selectedFileCount = files.length;

        // Check if number of selected files exceeds the limit
        if (selectedFileCount > maxFileCount) {
            setErrUploadAssetCertificate(`You can only upload a maximum of ${maxFileCount} files.`);
            return;
        }

        // Iterate through selected files
        for (let i = 0; i < selectedFileCount; i++) {
            const file = files[i];

            // Check if file size exceeds the limit
            if (file.size > maxFileSize) {
                setErrUploadAssetCertificate(`File '${file.name}' exceeds the maximum file size limit of 5MB.`);
                return;
            }
        }

        // Reset error message if file selection is valid
        setErrUploadAssetCertificate('');
        setUploadAssetCertificate(files);
    };

    //const maxFiles = 5; // Maximum number of files allowed

    // Calculate the remaining number of files
    const remainingFiles = maxFileCount - uploadassetcertificate.length;

    const optionList = [];
    allAssets && allAssets.length > 0 ? (
        allAssets.map((option) => (
            optionList.push({value: option.tag, label: option.tag})
        ))
    ) : (
        optionList.push({value: "", label: "No Data"})
    )

    const [selectedOptions, setSelectedOptions] = useState();

    function handleSelect(data) {
        setSelectedOptions(data);
        console.log(data.value);
        setSupplierReadonly(true);
        setTag(data.value);
        fetchImageCount(data.value);

    }

    const fetchImageCount = (selectedTag) => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': selectedTag,},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData3 = {'token': EncryptData}
        // Make an API call to get the image count
        getAssetsCertificateCount(oData3).then(res => {
            // Assuming the API response contains image count in data property
            const imageCount = res.data.data[0]?.count;
            // Update the state with the fetched image count
            setImageCount(imageCount);
        }).catch(error => {
            console.error('Error fetching image count:', error);
            // Handle error if needed
        });
    };
    const fetchInvoiceCount = (invoice_no) => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag,'invoice_no': invoice_no,'supplier_name': supplier,},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData3 = {'token': EncryptData}
        // Make an API call to get the image count
        getAssetsCertificateCount(oData3).then(res => {
            // Assuming the API response contains image count in data property
            const imageCount = res.data.data[0]?.count;
            const docTypeList = res.data?.doc_type || [];
            // Update the state with the fetched image count
            setImageCount(imageCount);
            setDocTypeList(docTypeList);
            
        }).catch(error => {
            console.error('Error fetching image count:', error);
            // Handle error if needed
        });
    };

    //get supplier list
    const supplierList = [];
    suppliers && suppliers.length > 0 ? (
        suppliers.map((option) => (
            supplierList.push({value: option.supplier_name, label: option.supplier_name})
        ))
    ) : (
        supplierList.push({value: "", label: "No Data"})
    )

    const [supplierOptions, setSupplierOptions] = useState();

    function handleSupplier(data) {
        setSupplierOptions(data);
        //console.log(data.value);
        setSupplier(data.value);
        //get certificate_number
        var oParam1 = {
            'supplier_name': data.value,
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': oParam1,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getInvoices(oData).then(res => {
            if (res?.data?.data?.length) setInvoicesData(res.data.data);
        });
    }

    //get Invoice list
    const invoiceList = [];
    invoices && invoices.length > 0 ? (
        invoices.map((option) => (
            invoiceList.push({value: option.invoice_no, label: option.invoice_no})
        ))
    ) : (
        invoiceList.push({value: "", label: "No Data"})
    )

    const [invoiceOptions, setInvoiceOptions] = useState();

    function handleInvoice(data) {
        setInvoiceOptions(data);
        //console.log(data.value);
        setInvoice(data.value);
        setNoOfDoc('');
        fetchInvoiceCount(data.value);
    }
    const handleNoDoc = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setNoOfDoc(e.target.value);
    }
    //subit certificate form
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true; //alert(valid_Name);
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(imgDesc);
        if (tag == "" && invoice == '') {
            setErrInvoice('Tag OR Invoice is required');
            valid_Name = false;
        } else {
            setErrInvoice('');
        }

        if (!TEXT_REGEX.test(imgDesc)) {
            setErrImgDesc('Certificate description is required');
            valid_Name = false;
            //return;
        } else {
            setErrImgDesc('');
        }
        if (!TEXT_REGEX.test(NoOfDoc)) {
            setErrNoOfDoc('Certificate Number is required');
            valid_Name = false;
            //return;
        } else {
            setErrNoOfDoc('');
        }
        //alert(uploadassetcertificate);
        if (uploadassetcertificate == '') {
            setErrUploadAssetCertificate('Upload asset certificates is required');
            valid_Name = false;
            //return;
        } else {
            setErrUploadAssetCertificate('');
        }
        if (valid_Name) { //alert('validName');
            saveData(); //submit form
        } else {
            //alert(valid_Name);
            return false;
        }
    }
    const resetAssetInfo = function () {
        setTag('');
        setUploadAssetCertificate('');
        setImgDesc('');
    }
    const getTagInfo = function () {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getSTPI(oData1).then(function (res) {
            //console.log(res.data.data);
            var aInfo = {};
            if (res?.data?.data?.assetInfo) {
                setErrMsg('');
                aInfo = res.data.data.assetInfo;
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
            } else {
                setAssetInfo({});
                setErrMsg('Assets Not Found');
            }

        });
    }
    
    const saveData = function () {
        try {
            const formData = new FormData();
            let jsonData = {
                'tag': tag,
                'invoice_no': invoice,
                'supplier_name': supplier,
                'asset_certificate_desc': imgDesc,
                'upload_dir': 'certificate',
                'application_type':'Web',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
                'companyshortname': sessionStorage.getItem("LoggedInCompanyShortName"),
                'users':{ 
                    'company_id':sessionStorage.getItem('LoggedInCompanyId'),
                    'department_id':sessionStorage.getItem('LoggedInDepartmentId'),
                    'division_id':sessionStorage.getItem('LoggedInDivisionId'),
                }
            }
            for (let i = 0; i < uploadassetcertificate.length; i++) {
                    const file = uploadassetcertificate[i];
                    formData.append("certificates[]", file);
                }
            //console.log(oData);
            var objAudit = {'audit_module':'Upload Certificate','audit_method':'created','audit_tag':tag,'old_values':'','new_values':JSON.stringify(jsonData)}
            jsonData.audit_params = objAudit;
            jsonData.authToken = sessionStorage.getItem('LoggedInToken');
            //jsonData.dimension = types;
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonData), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            formData.append("metadata", encryptedData);
            
            saveSingleCertificate(formData).then(function (res) {
                if ((typeof (res.data.errors) !== "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    resetAssetInfo();
                    setErrTag('');
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className='container'>
            <div className='row single__assets' ref={elementRef}>
                <div className="col">
                    <h5>Upload Asset Certificates</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>
                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    (Tag !== '' && Tag !== null) ? <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div> : <div className='row'>
                        <div className='col-2'><h5 className='align-right'>TAG </h5></div>
                        <div className='col-4'>
                            {/*<input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' />*/}
                            <Select options={optionList} placeholder="Select One" value={selectedOptions}
                                    onChange={handleSelect} readOnly={supplierReadonly}/>
                            <span className="invalid-feedback"> {ErrTag} </span></div>

                        <div className='col-4'>
                            <button onClick={getTagInfo} className='btn btn-primary btn-md'><i
                                className="bi bi-search"></i> Search
                            </button>
                            &nbsp;<a href={'/upload-certificate'}>
                            <button className='btn btn-secondary btn-md'><i className="bi bi-arrow-clockwise"></i> Reset</button>
                        </a>&nbsp;</div>
                        <div className='col-4'></div><div className='col-2'><h5>OR</h5></div><div className='col-4'></div>
                        <div class="filter-container"><div className='row'>
                            <div className='col-2'>
                                <label>Search by Supplier</label>
                            </div>
                            <div className='col-3'>
                                <Select options={supplierList} placeholder="Select One" value={supplierOptions}
                                        onChange={handleSupplier}/>
                            </div>
                            <div className='col-2'>
                                <label>Search by Invoice No.</label>
                            </div>
                            <div className='col-3'>
                                <Select options={invoiceList} placeholder="Select One" value={invoiceOptions}
                                        onChange={handleInvoice}/><span className="invalid-feedback"> {ErrInvoice} </span>
                            </div>
                            <div className='col-3'>
                            </div>
                        </div></div>
                    </div>
                }
            </div>
            <div className='row'></div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="TAG"
                        inputValue={tag ? tag : ''}
                        readOnly="readonly"
                    />
                </div>
                <div className='col-6'></div>
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className='row single__asset__form'>
                    <div className="col-6">
                        <FromFieldText label="Certificate Description" star="*"
                                       inputValue={imgDesc}
                                       setInputValue={setImgDesc}
                            //checkInputValue={checkCharcterOnly}
                                       setErrorMsg={ErrImgDesc}
                        />

                    </div><div className='col-6'>&nbsp;</div>
                </div>
                <div className='row single__asset__form'>
                        <div className="col-6">
                        <div className='row'>
                            <div className="col-6">
                                <div className="label col-6">
                                <label> Upload Certificates </label>
                                <span className="star error">  </span>
                                    <p>Remaining attachments: {remainingFiles}</p>
                                </div>
                            </div>
                        <div className='col-6'>
                        <select onChange={handleNoDoc} name="no_of_doc" className="form-select form-select-sm">
                                    <option value="">Document Type</option>
                                    { DocTypeList.filter(option => option.doc_type === "First").length === 0 && ( <option value="First">Document First</option>)}
                                    { DocTypeList.filter(option => option.doc_type === "Second").length  === 0 && (<option value="First">Document Second</option>)}
                                    { DocTypeList.filter(option => option.doc_type === "Third").length  === 0 && (<option value="First">Document Third</option>)}
                                    { DocTypeList.filter(option => option.doc_type === "Fourth").length  === 0 && (<option value="First">Document Fourth</option>)}
                                    { DocTypeList.filter(option => option.doc_type === "Fifth").length  === 0 && (<option value="First">Document Fifth</option>)}
                                </select>
                                <span className="invalid-feedback"> {ErrNoOfDoc} </span>
                            <input type="file" name='asset_certificate' multiple onChange={handleUploadCertificate}/>
                            <span className="invalid-feedback"> {ErrUploadAssetCertificate} </span>
                        </div>
                        </div>
                        </div><div className='col-6'>&nbsp;</div>
                </div>


                <div className='row save__button text-center'>
                    <div className='col-md-8'>
                        {(Tag !== '' && Tag !== null) ? <div>
                                <button className="btn btn-primary btn-md">Update</button>
                                &nbsp;&nbsp;<a href={'/asset-details?tag=' + Tag}
                                               className="btn btn-secondary btn-md">Cancel</a></div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                      disabled={disabled}><i class="bi bi-upload"></i> UPLOAD </button>
                        }

                    </div>
                </div>
            </form>
        </div>
    )
}
