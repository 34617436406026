import axios from "axios";
var baseURL = process.env.REACT_APP_API_BASE_URL;
var apis = {

    'getModuleList' : `${baseURL}tickets/getmodulelist`,
    'getSubModuleList' : `${baseURL}tickets/getsubmodulelist`,
    'createTicket' : `${baseURL}tickets/createticket`,
    'getRaisedTickets' : `${baseURL}tickets/getraisedtickets`,
    'replyTicket' : `${baseURL}tickets/replyticket`,
    'getTicketDetails' : `${baseURL}tickets/getticketdetails`,
    'getTicketReply' : `${baseURL}tickets/getticketteply`,
    'getAssignedTickets' : `${baseURL}tickets/getassignedtickets`,
    'getTicketSubjectList' : `${baseURL}tickets/getticketsubjectlist`,
    'updateTicketStatus' : `${baseURL}tickets/updateticketstatus`,
    'getUserRollList' : `${baseURL}tickets/getuserrolllist`,
    'getUserList' : `${baseURL}tickets/getuserlist`,
    'updateTicketAssignee' : `${baseURL}tickets/updateticketassignee`,
    'saveTicketSubject' : `${baseURL}tickets/saveticketsubject`,
    'getTicketSubjectInfo' : `${baseURL}tickets/getticketsubjectinfo`,
    'deleteTicketSubject' : `${baseURL}tickets/deleteticketsubject`,
    'getTicketSubjectListMaster' : `${baseURL}tickets/getticketsubjectlistmaster`,
    'getAllTicketsListAdmin' : `${baseURL}tickets/getallticketslistadmin`,
    'getUsersList' : `${baseURL}tickets/getuserslist`,
    'saveTicketAdmin' : `${baseURL}tickets/saveticketadmin`,
    'getTicketAdmin' : `${baseURL}tickets/getticketadmin`,
    'getTicketAdminInfo' : `${baseURL}tickets/getticketadmininfo`,
    'deleteTicketAdmin' : `${baseURL}tickets/deleteticketadmin`,

}

export function getModuleList(data) {
    return axios.put(apis.getModuleList,data);
}
export function getSubModuleList(data) {
    return axios.put(apis.getSubModuleList,data);
}

export function createTicket(data) {
    return axios.post(apis.createTicket,data);
}
export function getRaisedTickets(data) {
    return axios.put(apis.getRaisedTickets,data);
}
export function replyTicket(data) {
    return axios.put(apis.replyTicket,data);
}
export function getTicketDetails(data) {
    return axios.put(apis.getTicketDetails,data);
}
export function getTicketReply(data) {
    return axios.put(apis.getTicketReply,data);
}
export function getAssignedTickets(data) {
    return axios.put(apis.getAssignedTickets,data);
}
export function getTicketSubjectList(data) {
    return axios.put(apis.getTicketSubjectList,data);
}
export function updateTicketStatus(data) {
    return axios.put(apis.updateTicketStatus,data);
}
export function getUserRollList(data) {
    return axios.put(apis.getUserRollList,data);
}
export function getUserList(data) {
    return axios.put(apis.getUserList,data);
}
export function updateTicketAssignee(data) {
    return axios.put(apis.updateTicketAssignee,data);
}
export function saveTicketSubject(data) {
    return axios.put(apis.saveTicketSubject,data);
}
export function saveTicketAdmin(data) {
    return axios.put(apis.saveTicketAdmin,data);
}
export function getTicketSubjectInfo(data) {
    return axios.put(apis.getTicketSubjectInfo,data);
}
export function getTicketAdminInfo(data) {
    return axios.put(apis.getTicketAdminInfo,data);
}
export function getUsersList(data) {
    return axios.put(apis.getUsersList,data);
}
export function deleteTicketSubject(data) {
    return axios.put(apis.deleteTicketSubject,data);
}
export function deleteTicketAdmin(data) {
    return axios.put(apis.deleteTicketAdmin,data);
}
export function getTicketSubjectListMaster(data) {
    return axios.put(apis.getTicketSubjectListMaster,data);
}
export function getTicketAdmin(data) {
    return axios.put(apis.getTicketAdmin,data);
}
export function getAllTicketsListAdmin(data) {
    return axios.put(apis.getAllTicketsListAdmin,data);
}