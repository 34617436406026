import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import FromFieldEmail from '../../atoms/FromFieldEmail';
import FromFieldNumber from '../../atoms/FromFieldNumber';
import {
    getCompanyProfileList, getContinentList, getFilteredCountryList, getRegionListing,
    getDepartmentList,
    getDivisionList,
    getRoleList,
    getSubDepartmentList,
    getUserDetails,
    registerUser,
    updateUser
} from '../../services/user.service';

import * as CryptoJS from "crypto-js";
import Select from "react-select";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
const Email_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const Mobile_REGEX = /^[0-9]{10,10}$/;
const strongPasswordRegex = /^(?=.*[a-z])^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

function Register() {
    const [assetInfo, setAssetInfo] = useState({});
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [name, setName] = useState('');
    const [ErrName, setErrName] = useState(false);

    const [email, setEmail] = useState('');
    const [ErrEmail, setErrEmail] = useState(false);

    const [mobile, setMobile] = useState('');
    const [ErrMobile, setErrMobile] = useState(false);

    const [role, setRole] = useState('');
    const [ErrRole, setErrRole] = useState(false);

    const [division, setDivision] = useState('');
    const [ErrDivision, setErrDivision] = useState(false);

    const [department, setDepartment] = useState('');
    const [ErrDepartment, setErrDepartment] = useState(false);

    const [subDepartment, setSubDepartment] = useState('');
    const [ErrSubDepartment, setErrSubDepartment] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [roles, setRoles] = useState([]);
    //const [access,setAccess] = useState(0);
    const [divisions, setDivisions] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [subDepartments, setSubDepartments] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [userStatus, setUserStatus] = useState('');
    const [ErrUserStatus, setErrUserStatus] = useState('');

    const [selectedOptionsDivisionList, setOptionsDivisionList] = useState(null);

    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState('');
    const [ErrCountries, setErrCountries] = useState(false);

    const [region, setRegion] = useState('');
    const [regions, setRegions] = useState('');
    const [ErrRegion, setErrRegion] = useState(false);

    const [continents, setContinents] = useState([]);
    const [continent, setContinent] = useState('');
    const [ErrContinent, setErrContinent] = useState(false);

    const resetUserInfo = function () {
        setName('');
        setEmail('');
        setMobile('');
        setRole('');
        setDivision('');
        setDepartment('');
        setSubDepartment('');
        setCompanyId('');
        //setPassword('');
        //setCnfPassword('');
        setUserStatus('');
        setContinent('');
        setCountry('');
        setRegion('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const userid = query.get('data');
    const compid = query.get('compid');
    const subDepartmentIdFromUrl = query.get('subDeptId'); // Extract subDepartmentId from URL params

    const elementRef = useRef();
    const [isSubDepartmentsLoaded, setIsSubDepartmentsLoaded] = useState(false); // Track if sub-departments have been loaded

    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        getContinentList(oData).then(res => {
            if (res?.data?.data?.length) setContinents(res.data.data);
        });

        getCompanyProfileList(oData).then(res => {
            if (res?.data?.data?.length) setCompanies(res.data.data);
        });

        if (userid !== '' && userid !== null && compid !== '' && compid !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(userid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedRoleid = decrypt_data.userid;
            const comp_id = decrypt_data.compid;

            getUserInfo(decryptedRoleid);

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'company_id': comp_id},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            let oData1 = {'token': EncryptData};

            getRoleList(oData1).then(res => {
                if (res?.data?.data?.length) setRoles(res.data.data);
            });

            getDivisionList(oData1).then(res => {
                if (res?.data?.data?.length) setDivisions(res.data.data);
            });
        }
    }, []);

    useEffect(() => {
        // Fetch sub-departments based on URL parameter after department is selected
        if (department && department !== '') {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'department_id': department },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { 'token': EncryptData };

            getSubDepartmentList(oData).then(res => {
                if (res?.data?.data?.length) {
                    setSubDepartments(res.data.data);
                    setIsSubDepartmentsLoaded(true); // Mark sub-departments as loaded

                    // Automatically select the sub-department from URL params if it exists
                    if (subDepartmentIdFromUrl) {
                        setSubDepartment(subDepartmentIdFromUrl);
                    }
                } else {
                    setSubDepartments([]);
                }
            }).catch(() => {
                setSubDepartments([]);
            });
        }
    }, [department, subDepartmentIdFromUrl]); // Ensure it runs when department or subDeptId changes

    const getUserInfo = function (userid) {
        try {
            let data1 = { 'user_id': userid };
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': data1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            let oData1 = { 'token': EncryptData };

            getUserDetails(oData1).then((res) => {
                if (res?.data?.data.length) {
                    let userInfo = res.data.data[0];

                    setUserInfo(userInfo);
                    setCompanyId(userInfo.company_id);
                    setRole(userInfo.role_id);
                    setDivision(userInfo.division_id);
                    setDepartment(userInfo.department_id);
                    setSubDepartment(userInfo.sub_department_id);
                    setContinent(userInfo.continent_id);
                    setName(userInfo.name);
                    setEmail(userInfo.email);
                    setMobile(userInfo.mobile);
                    setUserStatus(userInfo.status);

                    // Fetch country list and then set country value
                    fetchCountryList(userInfo.continent_id, userInfo.country_id);

                    // Fetch region list and then set region value
                    fetchRegionList(userInfo.country_id, userInfo.region_id);
                }
            });
        } catch (err) {
            console.error("Error fetching user info:", err);
        }
    };

    const fetchCountryList = (continent_id, selectedCountry = null) => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'continent_id': continent_id },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData2 = { 'token': EncryptData };

        getFilteredCountryList(oData2).then(res => {
            if (res?.data?.data?.length) {
                setCountries(res.data.data);

                // Set country **after** list is available
                if (selectedCountry) setCountry(selectedCountry);
            }
        });
    };

    const fetchRegionList = (country_id, selectedRegion = null) => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'country_id': country_id },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData3 = { 'token': EncryptData };

        getRegionListing(oData3).then(res => {
            if (res?.data?.data?.length) {
                setRegions(res.data.data);

                // Set region **after** list is available
                if (selectedRegion) setRegion(selectedRegion);
            }
        });
    };


    const handleCompanyId = (e) => {
        let name = e.target.name;
        let company_value = e.target.value;
        setCompanyId(company_value);
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'company_id': company_value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData2 = {'token': EncryptData}
        getRoleList(oData2).then(res => {
            if (res?.data?.data?.length) setRoles(res.data.data);
        });
        getDivisionList(oData2).then(res => {
            if (res?.data?.data?.length) setDivisions(res.data.data);
        });
        getDepartmentList(oData2).then(res => {
            if (res?.data?.data?.length) setDepartments(res.data.data);
        });
    }
    const handleRole = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (e.target.value == '') {
            setRole(0);
        } else {
            setRole(e.target.value);
        }
    }

    const optionDivisionList = divisions.map((option) => ({
        value: option.id,
        label: option.division_name,
    }));

    function handleDivision(data) {
        setOptionsDivisionList(data);
        setDivision(data.value);
    }
    const handleDepartment = (e) => {
        const departmentId = e.target.value;
        if (departmentId === '') {
            setDepartment(0);
            setSubDepartments([]); // Clear sub-departments if no department selected
        } else {
            setDepartment(departmentId);

            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'department_id': departmentId },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { 'token': EncryptData };

            getSubDepartmentList(oData).then(res => {
                if (res?.data?.data?.length) {
                    setSubDepartments(res.data.data); // Set the list of sub-departments
                } else {
                    setSubDepartments([]); // Clear if no data
                }
            }).catch(() => {
                setSubDepartments([]); // Handle API errors
            });
        }
    };

    const handleSubDepartment = (e) => {
        const subDepartmentId = e.target.value;
        setSubDepartment(subDepartmentId);  // Correctly update selected sub-department ID
        console.log("Selected Sub-Department ID: ", subDepartmentId);  // For debugging
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        if (!TEXT_REGEX.test(name)) {
            setErrName('Name is required');
            isValid = false;
        } else {
            setErrName('');
        }

        if (!Email_REGEX.test(email)) {
            setErrEmail('Valid Email is required');
            isValid = false;
        } else {
            setErrEmail('');
        }

        if (!TEXT_REGEX.test(companyId)) {
            setErrCompanyId('Company is required');
            isValid = false;
        } else {
            setErrCompanyId('');
        }

        /*if (!TEXT_REGEX.test(department)) {
            setErrDepartment('Department is required');
            isValid = false;
        } else {
            setErrDepartment('');
        }

        if (!TEXT_REGEX.test(subDepartment)) {
            setErrSubDepartment('Sub-department is required');
            isValid = false;
        } else {
            setErrSubDepartment('');
        }

        if (!TEXT_REGEX.test(continent)) {
            setErrContinent('Continent is required');
            isValid = false;
        } else {
            setErrContinent('');
        }
            if (!TEXT_REGEX.test(region)) {
            setErrRegion('Region is required');
            isValid = false;
        } else {
            setErrRole('');
        }
        if (!TEXT_REGEX.test(division)) {
            setErrDivision('Division is required');
            isValid = false;
        } else {
            setErrRole('');
        }*/

        if (!TEXT_REGEX.test(role)) {
            setErrRole('Role is required');
            isValid = false;
        } else {
            setErrRole('');
        }
        if (!Mobile_REGEX.test(mobile)) {
            setErrMobile('Mobile number is required and must be 10 digits');
            isValid = false;
        } else {
            setErrMobile('');
        }

        if (!TEXT_REGEX.test(userStatus)) {
            setErrUserStatus('User Status is required');
            isValid = false;
        } else {
            setErrUserStatus('');
        }

        if (!isValid) return; // Stop execution if validation fails

        // If all validations pass, proceed with saving or updating data
        if (userid) {
            updateData(); // Update existing data
        } else {
            saveData();  // Submit new data
        }
    };

    const handleUserStatus = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setUserStatus(e.target.value);
    }

    const saveData = function () {
        try {
            var obj = {
                'name': name,
                'Email': email,
                'created_company_id': companyId,
                'role_id': role?role:0,
                'created_division_id': division?division:0,
                'created_department_id': department?department:0,
                'continent_id': continent,
                'country_id': country,
                'region_id': region,
                'mobile': mobile,
                'created_sub_dep_id': subDepartment?subDepartment:0,
                'status': userStatus,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData3 = {'token': EncryptData}

            registerUser(oData3).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const updateData = function () {
        const decryptedBytes = CryptoJS.AES.decrypt(userid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
        // Convert the decrypted bytes to a string
        const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        const decryptedRoleid = decrypt_data.userid;
        try {
            var obj = {
                'user_id': decryptedRoleid,
                'name': name,
                'Email': email,
                'updated_company_id': companyId,
                'role_id': role,
                'continent_id': continent,
                'country_id': country,
                'region_id': region,
                'updated_division_id': division,
                'updated_department_id': department,
                'mobile': mobile,
                'created_sub_dep_id': subDepartment,
                'status': userStatus,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData3 = {'token': EncryptData}

            updateUser(oData3).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    resetUserInfo();
                }
            });
            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    useEffect(() => {
        if (division && divisions.length > 0) {
            const selectedDivision = divisions.find((div) => div.id === division);
            if (selectedDivision) {
                setOptionsDivisionList({
                    value: selectedDivision.id,
                    label: selectedDivision.division_name,
                });
            }
        }
    }, [division, divisions]);

    const handleContinentId = (e) => {
        let continent_value = e.target.value;
        setContinent(continent_value);

        // Fetch countries for the selected continent
        fetchCountryList(continent_value);
    };


    const handleRegionId = (e) => {
        setRegion(e.target.value);
    }

    const handleCountryId = (e) => {
        let country_value = e.target.value;
        setCountry(country_value);

        // Fetch regions for the selected country
        fetchRegionList(country_value);
    };


    return (
        <div className="container">
            <div className="row m-2">
                <div className="col-6">
                    {(userid !== '' && userid !== null) ? <h5>Update User</h5>
                        : <h5>Add User</h5>}
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>
                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row m-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Company </label><span className="star error"> *</span>
                            </div>

                            <div className="col-md-6">

                                <select onChange={handleCompanyId} className="form-select form-select-sm">
                                    <option value="">Select Company</option>
                                    {companies && companies.length > 0 ? (
                                        companies.map((option) => (
                                            <option value={option.company_id}
                                                    selected={(companyId === option.company_id) ? "selected" : ''}>{option.company_name}</option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrCompanyId} </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6"></div>
                </div>

                <div className="row m-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Role Code</label><span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">
                                <select onChange={handleRole} className="form-select form-select-sm"
                                        value={role}>
                                    <option value="">Select Role</option>
                                    {roles && roles.length > 0 ? (
                                        roles.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.role_name} / {option.role_short_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrRole} </span>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Control Department </label>
                            </div>
                            <div className="col-md-6">
                                <select value={department} onChange={handleDepartment}
                                        className="form-select form-select-sm">
                                    <option value="">Select Department</option>
                                    {departments && departments.length > 0 ? (
                                        departments.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.department_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrDepartment} </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Sub Control Department </label>
                            </div>
                            <div className="col-md-6">
                                <select value={subDepartment} onChange={handleSubDepartment}
                                        className="form-select form-select-sm">
                                    <option value="">Select Sub Department</option>
                                    {subDepartments && subDepartments.length > 0 ? (
                                        subDepartments.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.sub_department_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrSubDepartment} </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Branch (Division) </label>
                            </div>

                            <div className="col-md-6">
                                <Select
                                    options={optionDivisionList}
                                    placeholder="Select One"
                                    value={selectedOptionsDivisionList}
                                    onChange={handleDivision}
                                />
                                <span className="invalid-feedback"> {ErrDivision} </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Select Continent </label>
                            </div>
                            <div className="col-md-6">
                                <select value={continent} onChange={handleContinentId}
                                        className="form-select form-select-sm">
                                    <option value="">Select Continent</option>
                                    {continents && continents.length > 0 ? (
                                        continents.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.continent_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrContinent} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Select Country </label>
                            </div>
                            <div className="col-md-6">
                                <select value={country} onChange={handleCountryId}
                                        className="form-select form-select-sm">
                                    <option value="">Select Country</option>
                                    {countries && countries.length > 0 ? (
                                        countries.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.country_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrContinent} </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Select Region </label>
                            </div>
                            <div className="col-md-6">
                                <select value={region} onChange={handleRegionId}
                                        className="form-select form-select-sm">
                                    <option value="">Select Region</option>
                                    {regions && regions.length > 0 ? (
                                        regions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.region_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrRegion} </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Name"
                                       inputValue={name}
                                       setInputValue={setName}
                                       setErrorMsg={ErrName}
                        />
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldEmail star="*"
                                        label="Email"
                                        inputValue={email}
                                        setInputValue={setEmail}
                                        setErrorMsg={ErrEmail}
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldNumber star="*"
                                         label="Mobile"
                                         inputValue={mobile}
                                         setInputValue={setMobile}
                                         setErrorMsg={ErrMobile}
                                         maxSize="10"
                        />
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <div className="row form-fields">
                            <div className="col-md-6">
                                <label> User Status </label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-6">

                                <select onChange={handleUserStatus} name="user_status"
                                        className="form-select form-select-sm">
                                    <option value="">Select Status</option>
                                    <option value="1" selected={(userStatus == "1") ? "selected" : ''}>Active
                                    </option>
                                    <option value="0"
                                            selected={(userStatus == "0") ? "selected" : ''}>Deactive
                                    </option>
                                </select> <span className="invalid-feedback"> {ErrUserStatus} </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        {(userid !== '' && userid !== null) ? <div>
                                <button className="btn btn-primary btn-md" disabled={disabled}>Update</button>
                                &nbsp;&nbsp;<a href={'/user/list/?tag=' + userid}
                                               className="btn btn-secondary btn-md">Cancel</a></div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                      disabled={disabled}> SAVE </button>
                        }
                    </div>
                    <div className="col-3"></div>
                </div>

            </form>
        </div>
    )
}

export default Register