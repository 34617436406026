import React, {useState} from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";
import * as CryptoJS from "crypto-js";
import {
    checkFinalizeDinNo,
    getBuyerList, getBuyerList1,
    getBuyerQuotedPrice,
    getDinNoTagList,
    saveFinalisedDeal
} from "../../../services/disposal.service";
import {useLocation, useNavigate} from 'react-router-dom';


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function SingleFinalizeDeal() {
    const location = useLocation();
    const [items, setItems] = useState([]);

    const [activeTab, setActiveTab] = useState(0);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [success, setSuccess] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    const [dinno, setDinno] = useState('');
    const [dinnoError, setdinNoError] = useState('');

    const [finalSalePrice, setFinalSalePrice] = useState('');
    const [finalSalePriceError, setFinalSalePriceError] = useState('');

    const [quotedReceivedDate, setQuotedReceivedDate] = useState('');
    const [quotedReceivedDateError, setQuotedReceivedDateError] = useState('');

    const [finalApprovalDate, setFinalApprovalDate] = useState('');
    const [finalApprovalDateError, setFinalApprovalDateError] = useState('');

    const [finalApprovalBy, setFinalApprovalBy] = useState('');
    const [finalApprovalByError, setFinalApprovalByError] = useState('');

    const [selectedBuyer, setSelectedBuyer] = useState('');

    const [disposal, setDisposal] = useState('');
    const [ErrDisposal, setErrDisposal] = useState(false);

    const [assetInfo, setAssetInfo] = useState({});
    const [tagList, setTagList] = useState({});
    const [tagSubStatus, setTagSubStatus] = useState({});

    const [buyer, setBuyer] = useState('');
    const [setbuyer, buyerError] = useState('');

    const [buyer_no, setBuyerNo] = useState('');
    const [buyerNameOptions, setBuyerNameOptions] = useState([]);

    const [isFinalized, setIsFinalized] = useState(false); // Tracks if the DIN is finalized

    const updatedDinNo = assetInfo.din_no ? `${assetInfo.din_no}-F` : '';

    const [selectedTagList, setSelectedTagList] = useState([]);

    const navigate = useNavigate();

    const handleBuyerDetails = (e) => {
        const selectedName = e.target.value;

        if (selectedName === "") {
            setSelectedBuyer("");
            setBuyerNo("");
            setBuyer(""); // 🔹 Reset "Price Quoted" when selecting "Select Buyer"
            return; // Stop further execution
        }

        const selectedBuyerObj = buyerNameOptions.find(buyer => buyer.buyer_name === selectedName);

        if (selectedBuyerObj) {
            setSelectedBuyer(selectedBuyerObj.buyer_name);
            setBuyerNo(selectedBuyerObj.buyer_no);
            fetchBuyerQuotedPrice(selectedBuyerObj.buyer_name);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let valid_Name = true;

        // Validate DIN number
        if (!(dinno) && dinno !== 0) {
            setdinNoError('DIN no is required');
            valid_Name = false;
        } else {
            setdinNoError('');
        }

        // Validate Disposal Type
        if (!(disposal) && disposal !== 0) {
            setErrDisposal('Select type of finalization required');
            valid_Name = false;
        } else {
            setErrDisposal('');
        }

        if (valid_Name) {
            //handleFormSubmit1(e);
            handleNextClick();
        } else {
            return false;
        }
    };

    const handleFormSubmit1 = (e) => {
        e.preventDefault();
        let valid_Name = true;
        let updatedSelectedTags = selectedTags;

        // Validate Selected Tags for Partial Disposal
        if (disposal === "Partial") {
            if (selectedTags.length === 0) {
                setErrMsg('At least one tag must be selected for Partial disposal.');
                valid_Name = false;
            } else {
                setErrMsg('');
            }
        } else if (disposal === "Full") {
            // Automatically select all tags for Full disposal
            updatedSelectedTags = tagList.map(({ tag }) => tag);
        }

        // Save selected tags in state
        setSelectedTagList(updatedSelectedTags);

        if (valid_Name) {
            // Encrypt and send selected tags to getBuyerList
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'din_no': dinno, 'tags': updatedSelectedTags },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            const oData = { 'token': EncryptData };

            getBuyerList(oData).then((res) => {
                try {
                    if (res?.data?.data?.res && Array.isArray(res.data.data.res) && res.data.data.res.length > 0) {
                        console.log("Raw API Response:", JSON.stringify(res.data.data.res, null, 2)); // Debugging Step

                        // Use a Set to ensure uniqueness before updating state
                        const uniqueBuyersSet = new Set();
                        const uniqueBuyers = res.data.data.res.filter(item => {
                            const buyerNameNormalized = item.buyer_name.trim().toLowerCase();
                            if (!uniqueBuyersSet.has(buyerNameNormalized)) {
                                uniqueBuyersSet.add(buyerNameNormalized);
                                return true; // Keep this entry
                            }
                            return false; // Ignore duplicates
                        });

                        console.log("Filtered Unique Buyers:", uniqueBuyers); // Debugging Step

                        // Set state only if data is actually different to prevent unnecessary renders
                        setBuyerNameOptions(prev => {
                            const prevBuyerNames = prev.map(item => item.buyer_name.toLowerCase());
                            const newBuyerNames = uniqueBuyers.map(item => item.buyer_name.toLowerCase());

                            if (JSON.stringify(prevBuyerNames) !== JSON.stringify(newBuyerNames)) {
                                return uniqueBuyers;
                            }
                            return prev; // Avoid redundant re-renders
                        });

                        setErrMsg('');
                    } else {
                        setBuyerNameOptions([]);
                        setErrMsg('No buyers found for selected tags.');
                    }
                } catch (error) {
                    console.error('Error processing buyer list:', error);
                    setBuyerNameOptions([]);
                    setErrMsg('Error processing buyer list');
                }
            }).catch((error) => {
                console.error('Error fetching buyer list:', error);
                setBuyerNameOptions([]);
                setErrMsg('Error fetching buyer list');
            });

            // Proceed to next step after fetching buyers
            handleNextClick();
        } else {
            return false;
        }
    };

    const handleNextClick = () => {
        // Handle the "Next" button click to move to the next tab
        setActiveTab(activeTab + 1);
    };

    const resetAssetDisposalInfo = function () {
        setDinno('');
    }

    const getTagInfo = function () {
        if (!(dinno) && dinno !== 0) {
            setdinNoError('DIN no is required');
        } else {
            setdinNoError('');

            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'din_no': dinno},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            const oData = {'token': EncryptData};

            getDinNoTagList(oData).then(function (res) {
                if (res?.data?.tag_data && Array.isArray(res.data.tag_data)) {
                    // Merge buyerCount with tag_data
                    const mergedTagList = res.data.tag_data.map(tagItem => {
                        const buyerData = res.data.buyerCount.find(buyer => buyer.tag === tagItem.tag);
                        return {
                            ...tagItem,
                            buyerCount: buyerData ? buyerData.count : 0 // Default to 0 if not found
                        };
                    });

                    setTagList(mergedTagList); // Set the merged data in state
                    setErrMsg('');
                } else {
                    setTagList([]); // Clear the list if no data
                    setErrMsg('Din no TAG Not found');
                }
            });

            checkFinalizeDinNo(oData).then(function (res) {
                if (res?.data?.data?.res && res.data.data.res.length > 0) {
                    const data = res.data.data.res[0];
                    setErrMsg('');
                    setAssetInfo(data);
                    setBuyerNameOptions(res.data.data.res);


                    // Automatically select first buyer and fetch quoted price
                    if (res.data.data.res.length > 0) {
                        const firstBuyer = res.data.data.res[0];
                        setSelectedBuyer(firstBuyer.buyer_name);
                        setBuyerNo(firstBuyer.buyer_no);
                        fetchBuyerQuotedPrice(firstBuyer.buyer_name);
                        setFinalSalePrice(firstBuyer.buyer_final_price);
                        setFinalApprovalBy(firstBuyer.final_approval_by);
                        setQuotedReceivedDate(new Date(firstBuyer.buyer_quote_received_date));
                        setFinalApprovalDate(new Date(firstBuyer.final_approval_date));
                    }

                    setIsFinalized(true);
                } else {
                    getBuyerList1(oData).then(function (res) {
                        try {
                            if (res?.data?.data?.res && Array.isArray(res.data.data.res) && res.data.data.res.length > 0) {
                                const aInfo = res.data.data.res;
                                setErrMsg('');
                                setAssetInfo(aInfo[0]);
                                //setBuyerNameOptions(aInfo);

                                // Ensure "Select Buyer" is default
                                setSelectedBuyer("");
                                setBuyerNo("");

                            } else {
                                setAssetInfo({});
                                //setBuyerNameOptions([]);
                                setSelectedBuyer("");
                                setBuyerNo("");
                                setErrMsg('DIN no not found');
                            }
                        } catch (error) {
                            console.error('Error processing response:', error);
                            setAssetInfo({});
                            setBuyerNameOptions([]);
                            setSelectedBuyer("");
                            setBuyerNo("");
                            setErrMsg('Error processing DIN data');
                        }
                    }).catch((error) => {
                        console.error('Error fetching buyer list:', error);
                        setAssetInfo({});
                        setBuyerNameOptions([]);
                        setSelectedBuyer("");
                        setBuyerNo("");
                        setErrMsg('Error fetching buyer list');
                    });
                }
            }).catch((err) => {
                console.error('Error fetching DIN details:', err);
                setErrMsg('Error fetching DIN details');
                resetAssetDisposalInfo();
                setIsFinalized(false);
            });
        }
    };

    const fetchBuyerQuotedPrice = (buyerName) => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'din_no': dinno, 'buyer_name': buyerName},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData};

        getBuyerQuotedPrice(oData).then(function (res) {
            if (res?.data?.data?.res) {
                setErrMsg('');
                setBuyer(res.data.data.res[0]['total_buyer_price_quoted']);
            } else {
                setBuyer('');
                setErrMsg('Quoted Price Not found');
            }
        });
    };


    const handleFormSubmitFinal = (e) => {
        e.preventDefault();
        var valid_Name1 = true;

        if (!(finalSalePrice) && finalSalePrice !== 0) {
            setFinalSalePriceError('Final sale price is required');
            valid_Name1 = false;
        } else {
            setFinalSalePriceError('');
        }
        if (!(buyer) && buyer !== 0) {
            buyerError('Final sale price is required');
            valid_Name1 = false;
        } else {
            buyerError('');
        }
        if (!(quotedReceivedDate) && quotedReceivedDate !== 0) {
            setQuotedReceivedDateError('Quoted Received Date is required');
            valid_Name1 = false;
        } else {
            setQuotedReceivedDateError('');
        }
        if (!(finalApprovalDate) && finalApprovalDate !== 0) {
            setFinalApprovalDateError('Quoted Approval Date is required');
            valid_Name1 = false;
        } else {
            setFinalApprovalDateError('');
        }
        if (!(finalApprovalBy) && finalApprovalBy !== 0) {
            setFinalApprovalByError('Final approval by is required');
            valid_Name1 = false;
        } else {
            setFinalApprovalByError('');
        }
        if (!selectedBuyer) {
            buyerError('Buyer is required');
            valid_Name1 = false;
        } else {
            buyerError('');
        }

        if (valid_Name1) {
            //alert('test1');
            saveFinalDeal(); //submit form
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const saveFinalDeal = function () {
        try {
            var obj = {
                'tag': selectedTagList,
                'din_no': assetInfo.din_no,
                'buyer_no': buyer_no,
                'buyer_name': selectedBuyer,
                'buyer_price_quoted': buyer,
                'buyer_final_price': finalSalePrice,
                'buyer_quote_received_date': quotedReceivedDate ? getFormatedDate(quotedReceivedDate) : '',
                'final_approval_date': finalApprovalDate ? getFormatedDate(finalApprovalDate) : '',
                'final_approval_by': finalApprovalBy,
                'gen_ref_no': updatedDinNo,
            };

            let EncryptData = CryptoJS.AES.encrypt(
                JSON.stringify({
                    'param_data': obj,
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }),
                process.env.REACT_APP_ENCRYPT_SECRET_KEY
            ).toString();

            let oData = { 'token': EncryptData };

            saveFinalisedDeal(oData).then((res) => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetDisposalInfo();
                    setFormSubmitted(true);
                    getTagInfo();

                    const userChoice = window.confirm("Do you want to generate Gate Pass for this transaction?");
                    if (userChoice) {
                        handleGenerateNow();
                    } else {
                        handleGenerateLater();
                    }
                }
            });

        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const handleGenerateNow = () => {
        const dinNoToEncrypt = assetInfo.din_no;
        const encryptedDinNo = CryptoJS.AES.encrypt(
            JSON.stringify(dinNoToEncrypt),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();
        navigate(`/generate-gate-pass-din?dinNo=${encodeURIComponent(encryptedDinNo)}`);
    };

    const handleGenerateLater = () => {
        console.log("Gate Pass generation deferred.");
        navigate(0); // Reload the current page
    };

    const handleDisposal = (event) => {
        setDisposal(event.target.value);
    };

    const [selectedTags, setSelectedTags] = useState([]);

    const handleCheckboxChange = (tag) => {
        setSelectedTags((prevSelected) =>
            prevSelected.includes(tag)
                ? prevSelected.filter((t) => t !== tag) // Uncheck
                : [...prevSelected, tag] // Check
        );
    };

    const handlePrevClick = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setActiveTab(activeTab - 1);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Finalize Deal</h5>
                </div>
            </div>


            {success ? (<div className="alert alert-success" role="alert"><i
                className="bi bi-check-circle-fill"></i> {success}</div>) : (errMsg ? (
                <div className="alert alert-danger" role="alert"><i
                    className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>) : (''))}


            <div className="tab-content">

                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>

                    <div className='row '>
                        <div className='col-12'>&nbsp;</div>
                        <div className='row'>
                            <div className='col-10'></div>
                            <div className='col-2'></div>
                        </div>
                        : <div className='row'>
                        <div className='col'><h5 className='align-right'>DIN No. </h5></div>

                        <div className='col'>
                            <input onChange={function (event) {
                                setDinno(event.target.value)
                            }} inputValue={assetInfo.tag} type="text"
                                   setInputValue={setDinno}
                                   setErrorMsg={setdinNoError}
                                   className="form-control"
                                   placeholder='Enter DIN No.'/>
                            <p className='invalid-feedback'>{dinnoError}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-primary btn-md'><i
                                className="bi bi-search"></i> Search
                            </button>
                        </div>
                    </div>

                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="DIN No."
                                placeholder="DIN No."
                                inputValue={assetInfo?.din_no}// Use optional chaining here
                                readOnly="readonly"
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                readOnly="readonly"
                                label="DIN Created Date"
                                inputValue={assetInfo?.created_at ? getFormatedDate(assetInfo.created_at) : ''} // Use optional chaining here
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6">
                                        <label> Type of Finalize </label>
                                        <span className="star error">  </span>
                                    </div>
                                    <div className="col-md-6">
                                        <select onChange={handleDisposal} name="childFlagging"
                                                id="childFlagging"
                                                className="form-select form-select-sm">
                                            <option value="">Select type of Disposal</option>
                                            <option value="Full"
                                                    selected={disposal === "Full" ? "selected" : ''}>Full
                                            </option>
                                            <option value="Partial"
                                                    selected={disposal === "Partial" ? "selected" : ''}>Partial
                                            </option>
                                        </select>
                                        <span className="invalid-feedback">{ErrDisposal} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-5">&nbsp;</div>
                        <div className="col-2">
                            <form name="" onSubmit={handleFormSubmit}>
                                <button className="btn btn-primary btn-md"> Next <i
                                    className="bi bi-arrow-right-short"></i>
                                </button>
                            </form>
                        </div>
                        <div className="col-5">&nbsp;</div>
                    </div>

                </div>

                <div className={`tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>

                    <div className="row mt-4">
                        <div className="col-5">
                            <FromFieldText
                                label="DIN No."
                                placeholder="DIN No."
                                inputValue={assetInfo?.din_no} // Use optional chaining here
                                readOnly="readonly"
                            />
                        </div>
                        <div className="col-3">
                            <FromFieldText
                                readOnly="readonly"
                                label="DIN Created Date"
                                inputValue={assetInfo?.created_at ? getFormatedDate(assetInfo.created_at) : ''}
                            />
                        </div>
                        <div className="col-4">
                            <FromFieldText
                                label="Type of Finalize"
                                inputValue={disposal}
                                setInputValue={setDisposal}
                                setErrorMsg={ErrDisposal}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <table className="table table-bordered">
                            <thead className="text-center">
                            <tr>
                                <th>Sl #</th>
                                <th>TAG No</th>
                                <th>Quote Received Status</th>
                                <th>Remarks</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {tagList.length > 0 ? (
                                tagList.map(({tag, sub_status, buyerCount}, index) => (
                                    <tr key={index}>
                                        <td>
                                            {sub_status !== "Disposal Initiated" && sub_status !== "Sale Completed" && ( // Hide checkbox if Disposal Initiated
                                                <input
                                                    type="checkbox"
                                                    name={`selectedTag[${index}]`}
                                                    checked={selectedTags.includes(tag)} // Dynamically check state
                                                    onChange={() => handleCheckboxChange(tag)} // Handle checkbox selection
                                                    disabled={disposal === "Full"} // Disable if disposal is Full
                                                />
                                            )}
                                        </td>
                                        <td>{tag}</td>
                                        <td>{sub_status === "Disposal Initiated" ? "No Quote Received" : sub_status}</td>
                                        <td>
                                            {buyerCount === 0
                                                ? "No Quote Received"
                                                : `Quote Received from ${buyerCount} Buyers`}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No Tags Available</td>
                                    {/* Ensure colspan matches table headers */}
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-4">
                            <form name="" onSubmit={handlePrevClick}>
                                <button className="btn btn-secondary btn-md float-end"><i
                                    className="bi bi-arrow-left"></i> Back
                                </button>
                            </form>
                        </div>
                        <div className="col-2">
                            <form name="" onSubmit={handleFormSubmit1}>
                                <button className="btn btn-primary btn-md"> Next <i
                                    className="bi bi-arrow-right-short"></i>
                                </button>
                            </form>
                        </div>
                        <div className="col-5">&nbsp;</div>
                    </div>

                </div>

                <div className={`tab-pane ${activeTab === 2 ? 'active' : 'hidden'}`}>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="DIN Number"
                                placeholder="DIN Number"
                                inputValue={assetInfo.din_no ? assetInfo.din_no : ''}
                                readOnly="readonly"
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="selectBuyer">Select Buyer Name</label>
                                </div>
                                <div className="col-md-6">
                                    <select
                                        id="selectBuyer"
                                        className="form-select"
                                        onChange={handleBuyerDetails}
                                        required
                                        value={selectedBuyer}
                                    >
                                        <option value="">Select Buyer</option>
                                        {buyerNameOptions.length > 0 ? (
                                            buyerNameOptions.map((option, index) => (
                                                <option key={option.buyer_no || `buyer_${index}`}
                                                        value={option.buyer_name}>
                                                    {option.buyer_name.charAt(0).toUpperCase() + option.buyer_name.slice(1).toLowerCase()}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">No Data</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Price Quoted"
                                inputValue={buyer}
                                setInputValue={setbuyer}
                                setErrorMsg={buyerError}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Final Sale Price W/o GST"
                                inputValue={finalSalePrice}
                                setInputValue={setFinalSalePrice}
                                setErrorMsg={finalSalePriceError}
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldDate
                                label="Final Sale Date"
                                placeholder="Final Sale Date"
                                inputValue={quotedReceivedDate}
                                setInputValue={setQuotedReceivedDate}
                                setErrorMsg={quotedReceivedDateError}
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldDate
                                label="Final Approval Date"
                                placeholder="Final Approval Date"
                                inputValue={finalApprovalDate}
                                setInputValue={setFinalApprovalDate}
                                setErrorMsg={finalApprovalDateError}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Final Approval By"
                                placeholder="Final Approval By"
                                inputValue={finalApprovalBy}
                                setInputValue={setFinalApprovalBy}
                                setErrorMsg={finalApprovalByError}
                            />
                        </div>

                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="Gen Ref No"
                                inputValue={updatedDinNo}
                                readOnly="readonly"
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <div className='text-center'>
                                <div className="d-flex justify-content-center">
                                    <div className="col-md-4">
                                        <form name="" onSubmit={handlePrevClick}>
                                            <button className="btn btn-secondary btn-md float-end"><i
                                                className="bi bi-arrow-left"></i> Back
                                            </button>
                                        </form>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        {!formSubmitted && (
                                            <form name="" onSubmit={handleFormSubmitFinal}>
                                                <button
                                                    className="btn btn-primary btn-md"
                                                    disabled={formSubmitted } //isFinalized Disable if already finalized
                                                >
                                                Submit {/*{isFinalized ? 'Already Finalized' : 'Submit'} */}
                                                </button>
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>


            </div>


        </div>
    )
}

export default SingleFinalizeDeal;